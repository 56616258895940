import { Box, Divider, Typography } from '@mui/material';

import PlaceholderDeskSvg from '@/assets/placeholder-desk.svg?react';

/**
 * Notifications List Placeholder
 *
 */
const NotificationsListPlaceholder = () => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    justifyContent="center"
    alignItems="center"
    data-test="notifications-placeholder"
  >
    <Typography variant="h6" color="text.secondary">
      No unread notifications for the moment
    </Typography>

    <Typography variant="body2" color="text.secondary" mb={6}>
      New notifications will be shown here.
    </Typography>

    <PlaceholderDeskSvg />

    <Box width="100%" px={4}>
      <Divider />
    </Box>
  </Box>
);

export default NotificationsListPlaceholder;
