import type { ApiExportRequest, ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';
import axios from 'axios';
import { useEffect, useState } from 'react';

import type { SkipToken } from '@reduxjs/toolkit/query';

import { useAppDispatch } from '@/store';
import api from '@/store/api';
import { athena, atlas } from '@/store/api/instances';
import { actions } from '@/store/reducers/modules/common/persistence';
import type {
  AssignMachineToLocationRequest,
  InstalledMachine,
  Location,
  LocationDetail,
  LocationStats,
  LocationStatsResponse,
  RemoveMachineFromLocationRequest,
  UseGetLocationStatsResult,
} from '@/types/location';

/**
 * RTK Query API for Locations.
 */
export const locationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLocations: build.query<ApiResponse<Location[]>, ApiListingRequest>({
      query: (data) =>
        athena({
          url: '/v2/locations',
          method: 'POST',
          data,
        }),
      providesTags: (result) =>
        result?.data
          ? result.data.map(({ location_id }) => ({ type: 'Locations', location_id }))
          : [],
    }),
    getLocationStats: build.query<ApiResponse<LocationStats>, void>({
      query: () =>
        athena({
          url: '/v2/locations/summary',
          method: 'GET',
        }),
      providesTags: [{ type: 'LocationsSummary' }],
    }),
    assignMachineToLocation: build.mutation<
      ApiResponse<LocationDetail>,
      AssignMachineToLocationRequest
    >({
      query: (data) =>
        atlas({
          url: '/v2/locations/assign-atms',
          method: 'POST',
          data,
        }),
      invalidatesTags: (result, _error) => [
        { type: 'Location', id: result?.data.location_id },
        { type: 'InstallableMachines' },
        { type: 'Machine' },
        { type: 'Machines' },
      ],
    }),
    removeMachineFromLocation: build.mutation<ApiResponse<void>, RemoveMachineFromLocationRequest>({
      query: ({ location_id, atm_id }) =>
        atlas({
          url: `/v2/locations/${location_id}/uninstall-machine/${atm_id}`,
          method: 'POST',
        }),
      invalidatesTags: (_result, _error) => [
        { type: 'Locations' },
        { type: 'Location' },
        { type: 'Machine' },
        { type: 'Machines' },
      ],
    }),
    getInstallableMachines: build.query<ApiResponse<InstalledMachine[]>, ApiListingRequest>({
      query: (data) => {
        return athena({
          url: '/v2/locations/installable-atms',
          method: 'POST',
          data,
        });
      },
      providesTags: [{ type: 'InstallableMachines' }],
    }),
    exportLocations: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data }) =>
        athena({
          url: `/v2/locations/${type}`,
          method: 'POST',
          data,
        }),
    }),
  }),
});

/**
 * Custom hooks for Locations API
 */

export const useGetLocations = (payload: ApiListingRequest | SkipToken) => {
  const dispatch = useAppDispatch();
  const { data, isFetching, error, requestId } =
    locationsApi.endpoints.getLocations.useQuery(payload);

  useEffect(() => {
    dispatch(actions.setLastGetMachinesId(requestId));
  }, [requestId]);

  return { data, isFetching, error };
};

export const useGetLocationStats = (): UseGetLocationStatsResult => {
  const [data, setData] = useState<LocationStats | null>(null);
  const [isFetching, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await axios.get<LocationStatsResponse>('/v2/locations/stats');
        setData(response.data.data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, []);

  return { data, isFetching, error };
};

/**
 * Export hooks and endpoints
 */
export const {
  useGetLocationsQuery,
  useGetLocationStatsQuery,
  useAssignMachineToLocationMutation,
  useRemoveMachineFromLocationMutation,
  useGetInstallableMachinesQuery,
  useExportLocationsMutation,
} = locationsApi;

export const {
  endpoints: { getLocations, getLocationStats },
} = locationsApi;
