import { memo } from 'react';

import { Chip } from '@mui/material';
import type { ChipProps } from '@mui/material';

import { getCustomerStatus } from '@/helpers/customer';

export interface CustomersStatusColumnProps {
  value: string;
}

const CustomersStatus = ({ value }: CustomersStatusColumnProps) => {
  // Get the customer status
  const status = getCustomerStatus(value);

  // If there is not status we return null
  if (!status) {
    return null;
  }

  const { label, color } = status;

  return <Chip size="small" label={label} color={color as ChipProps['color']} component="span" />;
};

export default memo(CustomersStatus);
