import { Box, Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { format, parseISO, set } from 'date-fns';
import { Fragment, useMemo, useState } from 'react';

import { DateRangePicker } from '@bitstopco/bitstop-theme';

import CardTable from '@/components/ui/card/CardTable';
import { HEARTBEAT_STATUS_COLORS, HEARTBEAT_STATUS_LABELS } from '@/constants/app/monitoring';
import { MachineStatusEnum } from '@/types/generated/heartbeat-api';

import type { MachineMonitoringResponse } from '@/types/generated/heartbeat-api';
import type { DateRange } from '@bitstopco/bitstop-theme';

const CAPTION_SIZE = 72;
const CELL_SIZE = 16;

type HeartbeatCalendarProps = {
  data: MachineMonitoringResponse[];
  dateRange: DateRange;
  onDateRangeChange: (range: DateRange) => void;
  isLoading?: boolean;
  onDateRangeReset?: () => void;
};

type HeartbeatData = Pick<MachineMonitoringResponse, 'status'>;

const HeartbeatChart = ({
  data,
  dateRange,
  onDateRangeChange,
  isLoading = false,
  onDateRangeReset,
}: HeartbeatCalendarProps) => {
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);
  const [hoveredHour, setHoveredHour] = useState<string | null>(null);

  const { dataMap, dates, hours } = useMemo(() => {
    const dataMap = new Map<string, HeartbeatData>();

    for (let i = data.length - 1; i >= 0; i--) {
      const { time: timeStr, status } = data[i];
      const time = parseISO(`${timeStr}Z`);
      const formattedDate = format(time, 'MM/dd/yyyy');
      const formattedHour = format(time, 'hh:mm a');
      const key = `${formattedDate}T${formattedHour}`;
      dataMap.set(key, { status: status ?? MachineStatusEnum.$0 });
    }

    const dates = Array.from(new Set(Array.from(dataMap.keys(), (key) => key.split('T')[0])));

    const hours = Array.from({ length: 24 }, (_, i) => {
      const hourDate = set(new Date(), { hours: i, minutes: 0, seconds: 0, milliseconds: 0 });
      return format(hourDate, 'hh:mm a');
    }).reverse();

    return { dataMap, dates, hours };
  }, [data]);

  const handleMouseEnter = (date: string, hour: string) => {
    setHoveredDate(date);
    setHoveredHour(hour);
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
    setHoveredHour(null);
  };

  return (
    <CardTable sx={{ py: 2 }} data-test="monitoring-card">
      <Typography variant="h6" px={3}>
        Heartbeat Data
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Box px={3}>
        <Stack direction="row" gap={2}>
          <DateRangePicker
            defaultRenderValueIcon="calendar"
            onChange={onDateRangeChange}
            value={dateRange}
          />
          {onDateRangeReset && (
            <Button variant="contained" onClick={onDateRangeReset}>
              Reset
            </Button>
          )}
        </Stack>
        <Box display="flex" flexWrap="wrap" gap={1} justifyContent="space-between" mt={3}>
          <Typography variant="overline">Machine heartbeats chart</Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {Object.keys(HEARTBEAT_STATUS_COLORS).map((key) => (
              <Box key={key} display="flex" alignItems="center">
                <Box
                  m={1}
                  ml={0}
                  width={8}
                  height={8}
                  borderRadius={8}
                  bgcolor={HEARTBEAT_STATUS_COLORS[key]}
                />
                <Typography variant="body2">{HEARTBEAT_STATUS_LABELS[key]}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {!isLoading && data.length === 0 && (
        <Typography my={2} p={3}>
          No data available
        </Typography>
      )}

      {isLoading && (
        <Box
          my={2}
          px={3}
          sx={{
            overflowY: 'hidden',
          }}
        >
          <Skeleton variant="rounded" height={72} />
          {hours.map((key) => (
            <Box key={key} mt={1}>
              <Skeleton variant="rounded" height={16} />
            </Box>
          ))}
        </Box>
      )}

      {!isLoading && data.length !== 0 && (
        <Box
          display="grid"
          gap={1}
          mt={2}
          pb={2}
          px={3}
          onMouseLeave={handleMouseLeave}
          sx={{
            overflowX: 'auto',
            gridTemplateColumns: `${CAPTION_SIZE}px repeat(${dates.length}, ${CELL_SIZE}px)`,
            gridTemplateRows: `${CAPTION_SIZE}px repeat(24, ${CELL_SIZE}px)`,
          }}
        >
          <Box />
          {dates.map((date) => (
            <Box
              key={date}
              display="flex"
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                fontWeight={date === hoveredDate ? 'bold' : 'normal'}
                color={date === hoveredDate ? 'black' : 'text.secondary'}
                sx={{
                  transform: 'rotate(-90deg)',
                  transformOrigin: 'center',
                  position: 'absolute',
                  bottom: '50%',
                  left: '50%',
                  translate: '-50% 50%',
                  whiteSpace: 'nowrap',
                }}
              >
                {date}
              </Typography>
            </Box>
          ))}
          {hours.map((hour) => (
            <Fragment key={hour}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  variant="caption"
                  fontWeight={hour === hoveredHour ? 'bold' : 'normal'}
                  color={hour === hoveredHour ? 'black' : 'text.secondary'}
                >
                  {hour}
                </Typography>
              </Box>
              {dates.map((date) => {
                const cellKey = `${date}-${hour}`;
                const dataItem = dataMap.get(`${date}T${hour}`);
                const status = dataItem?.status;
                const color = status
                  ? HEARTBEAT_STATUS_COLORS[status]
                  : HEARTBEAT_STATUS_COLORS[MachineStatusEnum.$0];
                return (
                  <Box
                    key={cellKey}
                    borderRadius={0.5}
                    width={CELL_SIZE}
                    height={CELL_SIZE}
                    bgcolor={color}
                    onMouseEnter={() => handleMouseEnter(date, hour)}
                  />
                );
              })}
            </Fragment>
          ))}
        </Box>
      )}
    </CardTable>
  );
};

export default HeartbeatChart;
