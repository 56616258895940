import toast from 'react-hot-toast';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import { useGenerateAddressesMutation } from '@/store/api/services/wallet/wallet';

import SCHEMAS from '@/forms/schemas';

import type { NewAddressesModel } from '@/types';

/**
 * Wallets New Addresses Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const GenerateAddresses = ({ hide }: ModalChildrenProps) => {
  const [generateAddresses] = useGenerateAddressesMutation();

  const { schema } = useForm(SCHEMAS.NEW_ADDRESSES);

  const handleSubmit = async ({ addresses: limit }: NewAddressesModel) => {
    /**
     * Submit the getWalletAddresses request
     */
    try {
      await generateAddresses(Number.parseInt(limit)).unwrap();
      toast.success('Addresses generated successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<NewAddressesModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }} data-test="wallet-generate-addresses-modal">
        <Typography variant="h6">How many addresses do you want to generate?</Typography>

        <Box mt={2}>
          <AutoFields />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField data-test="wallet-generate-addresses-modal-submit-btn">
          Generate addresses
        </SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default GenerateAddresses;
