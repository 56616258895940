import { useUnpublishLocationMutation } from '@/store/api/services/locations/location';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import toast from 'react-hot-toast';

/**
 * Unpublish Location Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const UnpublishLocation = ({ hide, props }: ModalChildrenProps) => {
  const [unpublishLocation] = useUnpublishLocationMutation();
  const [isLoading, setIsLoading] = useState(false); // Track loading state

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      await unpublishLocation({
        locationId: props?.location_id,
      }).unwrap();

      hide();
      toast.success(`Successfully unpublished: ${props?.location_name} from Nonce.`);
    } catch (_err) {
      toast.error(`Error unpublishing location: ${props?.location_name}`);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">
          Are you sure you want to unpublish location: {props?.location_name}?
        </Typography>

        <Typography mt={1} variant="body1">
          Doing so will unpublish the location from all Bitstop Maps. You can request to re-publish
          the location at anytime.
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="remove-match-back-button">
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading} // Add loading state
          data-test="remove-match-submit-button"
        >
          Unpublish
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UnpublishLocation;
