import toast from 'react-hot-toast';

import { AutoField, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Link,
  Typography,
} from '@mui/material';

import SCHEMAS from '@/forms/schemas';

import {
  useAddOperatorBitgoApiKeyConfigMutation,
  useCreateBitgoWalletConfigMutation,
  useLazyGetOperatorBitgoApiKeyConfigQuery,
  useUpdateOperatorBitgoApiKeyConfigMutation,
} from '@/store/api/services/wallet/wallet';
import type { WalletBitgoSettingsModel } from '@/types';

/**
 * Wallet Bitgo Settings Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const BitgoSettings = ({ hide }: ModalChildrenProps) => {
  const { schema } = useForm(SCHEMAS.WALLET_BITGO_SETTINGS);

  const [getOperatorBitgoApiKeyConfig] = useLazyGetOperatorBitgoApiKeyConfigQuery();
  const [createBitgoWalletConfig] = useCreateBitgoWalletConfigMutation();
  const [addOperatorBitgoApiKeyConfig] = useAddOperatorBitgoApiKeyConfigMutation();
  const [updateOperatorBitgoApiKeyConfig] = useUpdateOperatorBitgoApiKeyConfigMutation();

  const handleSubmit = async ({
    api_key,
    wallet_id,
    wallet_password,
  }: WalletBitgoSettingsModel) => {
    try {
      const {
        data: { api_key: currentApiKey },
      } = await getOperatorBitgoApiKeyConfig().unwrap();

      const action = currentApiKey ? updateOperatorBitgoApiKeyConfig : addOperatorBitgoApiKeyConfig;

      await action(api_key).unwrap();

      const payload = {
        wallet_id,
        wallet_password,
        coin_type: 'bitcoin',
        friendly_name: 'BITCOIN Wallet',
        low_balance_threshold: 0,
      };

      await createBitgoWalletConfig(payload).unwrap();

      toast.success('Bitgo configuration updated');

      hide();
    } catch {
      toast.error('Error updating Bitgo configuration');
    }
  };

  return (
    <AutoForm<WalletBitgoSettingsModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <Box mt={2}>
          <Typography variant="h6">Bitgo API Key</Typography>
          <Typography variant="caption" mt={0.5}>
            Your API key will be available in your Bitgo Account
          </Typography>
          <AutoField name="api_key" />

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6">BTC Wallet</Typography>
          <Typography variant="caption" mt={0.5}>
            Please retrieve your Wallet ID and Passphrase using the{' '}
            <Link target="_blank" href="https://docs.nonce.services/operator-documentation">
              Operator Bitgo Setup guide.
            </Link>
          </Typography>

          <AutoField name="wallet_id" />
          <AutoField name="wallet_password" />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField>Save</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default BitgoSettings;
