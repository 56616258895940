import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path
      d="M15.6 7.69998C15.6 8.65476 15.2207 9.57043 14.5455 10.2456C13.8704 10.9207 12.9547 11.3 12 11.3C11.0452 11.3 10.1295 10.9207 9.45437 10.2456C8.77924 9.57043 8.39995 8.65476 8.39995 7.69998C8.39995 6.7452 8.77924 5.82952 9.45437 5.15439C10.1295 4.47926 11.0452 4.09998 12 4.09998C12.9547 4.09998 13.8704 4.47926 14.5455 5.15439C15.2207 5.82952 15.6 6.7452 15.6 7.69998ZM16.8 18.5C16.8 17.2269 16.2942 16.006 15.3941 15.1059C14.4939 14.2057 13.273 13.7 12 13.7C10.7269 13.7 9.50601 14.2057 8.60584 15.1059C7.70566 16.006 7.19995 17.2269 7.19995 18.5V22.1H16.8V18.5Z"
      fill="currentColor"
    />
    <g opacity="0.5">
      <path
        d="M20.8971 11.7971C21.3472 11.347 21.6 10.7365 21.6 10.1C21.6 9.46349 21.3472 8.85304 20.8971 8.40296C20.447 7.95287 19.8366 7.70001 19.2 7.70001C18.5635 7.70001 17.9531 7.95287 17.503 8.40296C17.0529 8.85304 16.8 9.46349 16.8 10.1C16.8 10.7365 17.0529 11.347 17.503 11.7971C17.9531 12.2472 18.5635 12.5 19.2 12.5C19.8366 12.5 20.447 12.2472 20.8971 11.7971Z"
        fill="currentColor"
      />
      <path
        d="M19.2 18.5V22.1H22.8V18.5C22.7991 17.9509 22.6727 17.4092 22.4306 16.9163C22.1885 16.4234 21.8369 15.9923 21.4029 15.6559C20.9688 15.3195 20.4636 15.0867 19.9259 14.9753C19.3882 14.8638 18.8321 14.8767 18.3 15.0128C18.892 16.0796 19.2018 17.28 19.2 18.5Z"
        fill="currentColor"
      />
    </g>
    <g opacity="0.5">
      <path
        d="M6.49701 11.7971C6.9471 11.347 7.19995 10.7365 7.19995 10.1C7.19995 9.46349 6.9471 8.85304 6.49701 8.40296C6.04692 7.95287 5.43647 7.70001 4.79995 7.70001C4.16343 7.70001 3.55298 7.95287 3.1029 8.40296C2.65281 8.85304 2.39995 9.46349 2.39995 10.1C2.39995 10.7365 2.65281 11.347 3.1029 11.7971C3.55298 12.2472 4.16343 12.5 4.79995 12.5C5.43647 12.5 6.04692 12.2472 6.49701 11.7971Z"
        fill="currentColor"
      />
      <path
        d="M4.79995 18.5C4.79827 17.28 5.10805 16.0797 5.69995 15.0128C5.16785 14.8754 4.61136 14.8617 4.07312 14.9726C3.53489 15.0836 3.0292 15.3163 2.59481 15.6529C2.16042 15.9895 1.80887 16.4211 1.56709 16.9146C1.32531 17.4082 1.19972 17.9505 1.19995 18.5V22.1H4.79995V18.5Z"
        fill="currentColor"
      />
    </g>
  </>,
  'CustomUserGroup',
);
