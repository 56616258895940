import { useEffect, useMemo } from 'react';

import omit from 'lodash/omit';

import type { ApiExportRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import { getLocationByAddress } from '@/helpers/geocoder';
import { useAppDispatch, useAppSelector } from '@/store';
import type { RootState } from '@/store';
import api from '@/store/api';
import { athena, boro } from '@/store/api/instances';
import { actions } from '@/store/reducers/modules/common/persistence';
import { selectLastGetMachine } from '@/store/reducers/modules/common/persistence/selectors';

import type {
  CommandQueue,
  CommandQueueStatusRequest,
  EditMachineRequest,
  EditMachineResponse,
  Geolocation,
  LastAtmCommandRequest,
  MachineDetail,
} from '@/types';
import type { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';

/**
 * Function to handle the update of machine Location resource [PATCH] as
 * well as creation of machine Location resource [POST] in RTK onQueryStarted
 * for optimistic updates after mutation.
 * @param data
 * @type LocationUpdateRequest
 *
 * @param dispatch
 * @param queryFulfilled
 *
 * @returns Promise<void>
 */
const handleMachineCache = async (
  data: EditMachineRequest,
  {
    dispatch,
    queryFulfilled,
  }: {
    dispatch: ThunkDispatch<any, any, UnknownAction>;
    queryFulfilled: any;
  },
) => {
  try {
    const { data: editMachineResponse } = await queryFulfilled;
    const patchedResult = dispatch(
      machineApi.util.updateQueryData('getMachine', data.atm_id!, (draft) => {
        /*
         * draft is the state of the machine in the store
         * the state of the store is based on the response passed to\
         * param endpointName i.e. 'getMachine'
         * thus respect the json structure of the getMachine response
         * draft.data = {} // would comprise the 'atm'
         * */
        const cachedLocation = omit(editMachineResponse.data, ['id']);
        draft.data = {
          ...draft.data,
          ...cachedLocation,
        };
      }),
    );
    // ensures changes to UI are reverted if backend fails like 409 or other non success status
    queryFulfilled.catch(patchedResult.undo);
  } catch {
    // we handle the error on the modal itself
    // but can do other actions here if needed in future
  }
};

/**
 * Machine RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const machineApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMachine: build.query<ApiResponse<MachineDetail>, number>({
      query: (id) =>
        athena({
          url: `v2/atm/${id}`,
        }),
      providesTags: (_, __, id) => [{ type: 'Machine', id }],
    }),
    updateMachine: build.mutation<ApiResponse<EditMachineResponse>, EditMachineRequest>({
      query: (data) =>
        boro({
          url: `v2/atms/${data.atm_id}`,
          method: 'PUT',
          data,
          showError: true,
        }),
      onQueryStarted: handleMachineCache,
      invalidatesTags: (data) => [
        { type: 'Machine', id: data?.data.id },
        { type: 'Machines' as const, id: data?.data.id },
      ],
    }),
    getMachineGeolocation: build.query<Geolocation, string>({
      queryFn: async (address) => {
        try {
          const data = await getLocationByAddress(address);
          return { data };
        } catch {
          return {
            data: {
              lat: 0,
              lng: 0,
            },
          };
        }
      },
      providesTags: (_, __, address) => [{ type: 'MachineGeolocation', id: address }],
    }),
    getMachineLastCommand: build.query<ApiResponse<CommandQueue>, LastAtmCommandRequest>({
      query: ({ atmId }) =>
        boro({
          url: `v1/CommandQueue/${atmId}/latest`,
        }),
      providesTags: (_, __, { atmId }) => [{ type: 'MachineLastCommand', id: atmId }],
    }),
    putCommandQueueRestart: build.mutation<ApiResponse<CommandQueue>, string>({
      query: (atmId) =>
        boro({
          url: `/v1/CommandQueue/${atmId}/restart`,
          method: 'PUT',
          showError: true,
        }),
      invalidatesTags: (_, __, atmId) => [
        { type: 'MachineLastCommand', id: atmId },
        { type: 'Machines' as const, id: atmId },
      ],
    }),
    putCommandQueueStatus: build.mutation<ApiResponse<CommandQueue>, CommandQueueStatusRequest>({
      query: ({ atmId, status }) =>
        boro({
          url: `v1/CommandQueue/${atmId}/status/${status}`,
          method: 'PUT',
          showError: true,
        }),
      invalidatesTags: (_, __, { atmId }) => [
        { type: 'MachineLastCommand', id: atmId },
        { type: 'Machines' as const, id: atmId },
      ],
    }),
    putCommandQueueClearCashbox: build.mutation<ApiResponse<CommandQueue>, number>({
      query: (atmId) =>
        boro({
          url: `v1/CommandQueue/${atmId}/clear_cash`,
          method: 'PUT',
          showError: true,
        }),
      onQueryStarted: (atmId, { dispatch, queryFulfilled }) => {
        //Optimistic response for clear cashbox
        try {
          const patchResult = dispatch(
            machineApi.util.updateQueryData('getMachine', atmId, (draft) => {
              draft.data.bill_denominations = {
                1: 0,
                2: 0,
                5: 0,
                10: 0,
                20: 0,
                50: 0,
                100: 0,
              };
            }),
          );

          queryFulfilled.catch(patchResult.undo);
        } catch {
          //
        }
      },
      invalidatesTags: (_, __, atmId) => [{ type: 'MachineLastCommand', id: atmId }],
    }),
    getMachineSalesExport: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data: params }) =>
        athena({
          url: `sales/download/${type}`,
          params,
        }),
    }),
  }),
});

export const {
  useGetMachineQuery,
  useLazyGetMachineQuery,
  useGetMachineGeolocationQuery,
  useGetMachineLastCommandQuery,
  useUpdateMachineMutation,
  usePutCommandQueueStatusMutation,
  useGetMachineSalesExportMutation,
  usePutCommandQueueRestartMutation,
  usePutCommandQueueClearCashboxMutation,
} = machineApi;

export const {
  endpoints: {
    getMachine,
    updateMachine,
    putCommandQueueStatus,
    putCommandQueueRestart,
    putCommandQueueClearCashbox,
  },
} = machineApi;

export const getMachineById = (id: number) => (state: RootState) =>
  (machineApi.endpoints.getMachine.select(id)(state)?.data ?? {}) as ApiResponse<MachineDetail>;

export const getMachineByGlobalId = (globalAtmId: string) => (state: RootState) => {
  const validKey = Object.keys(state.api.queries).filter((key) => {
    const query = state.api.queries[key];
    const machine = (query?.data as ApiResponse<MachineDetail>)?.data;
    const globalIdFound = machine?.global_id === globalAtmId;

    return query?.endpointName === 'getMachine' && globalIdFound;
  })[0];

  return (state.api.queries[validKey]?.data as ApiResponse<MachineDetail>)?.data?.id;
};

export const useGetMachine = (machineId: number) => {
  const dispatch = useAppDispatch();
  const {
    data: _machine,
    isFetching: machineIsFetching,
    error,
    requestId,
  } = useGetMachineQuery(machineId);
  const machine = useMemo(() => {
    const cashboxes = _machine?.data
      ? [
          {
            id: 1,
            created_at: _machine?.data?.created_at,
            total_amount_of_bills: _machine?.data?.total_amount_of_bills,
            total_worth_of_bills: _machine?.data?.total_worth_of_bills,
            one: _machine?.data?.bill_denominations?.['1'],
            two: _machine?.data?.bill_denominations?.['2'],
            five: _machine?.data?.bill_denominations?.['5'],
            ten: _machine?.data?.bill_denominations?.['10'],
            twenty: _machine?.data?.bill_denominations?.['20'],
            fifty: _machine?.data?.bill_denominations?.['50'],
            hundred: _machine?.data?.bill_denominations?.['100'],
          },
        ]
      : [];

    return { ..._machine, data: { ..._machine?.data, cashboxes } };
  }, [_machine]);

  const address = machine?.data?.location?.full_address;
  const { data: geolocation, isLoading: geolocationIsFetching } = useGetMachineGeolocationQuery(
    address!,
    {
      skip: !address,
    },
  );
  const { data: lastCommand } = useGetMachineLastCommandQuery({
    atmId: machineId,
  });

  useEffect(() => {
    dispatch(actions.setLastGetMachineId(requestId));
  }, [requestId]);

  const isLoading = machineIsFetching || geolocationIsFetching;
  const delivered = !!lastCommand?.data.delivered;
  const lastCommandByName = lastCommand?.data.command || '-';
  let currentCommandByName = '-';
  if (!delivered) currentCommandByName = lastCommandByName;

  const data = {
    ...machine,
    data: {
      ...machine?.data,
      geolocation: geolocation || { lng: 0, lat: 0 },
      delivered,
      formattedCommandInProgress: delivered ? 'No' : 'Yes',
      lastCommand: lastCommandByName,
      currentCommand: currentCommandByName,
    } as MachineDetail,
  };

  return { data, error, isLoading };
};

export const useGetLastMachine = () => {
  const data = useAppSelector(selectLastGetMachine);

  return data;
};
