import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

export const KycStatusesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getKycStatuses: build.query<ApiResponse<string[]>, void>({
      query: () =>
        athena({
          url: 'v2/resources/kyc_statuses',
          method: 'GET',
        }),
    }),
  }),
});

export const {
  // Queries
  useGetKycStatusesQuery,
} = KycStatusesApi;

export const {
  endpoints: { getKycStatuses },
} = KycStatusesApi;
