import { memo, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { HiEye } from 'react-icons/hi';

interface CustomerKycVerificationsItemActivitySsnProps {
  value: string;
}

/**
 * Kyc Verifications SSN Table mapper
 *
 * @param {CustomerKycVerificationsItemActivitySsnProps} {
 *   value,
 * }
 * @return {*}
 */
const CustomerKycVerificationsItemActivitySsn = ({
  value,
}: CustomerKycVerificationsItemActivitySsnProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        ml: 'auto',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'inline-flex',
      }}
      onClick={handleToggle}
    >
      <Typography variant="body2" color="primary" fontWeight={500}>
        {isVisible ? value : '***-**-****'}
      </Typography>
      <HiEye />
    </Stack>
  );
};

export default memo(CustomerKycVerificationsItemActivitySsn);
