export const DEFAULT_PAGINATION = {
  total_items: 0,
  total_pages: 1,
  current_page: 1,
  items_per_page: 10,
};

export const DEFAULT_LISTING_PAYLOAD = {
  pagination: {
    current_page: 1,
    items_per_page: 10,
    sort_ascending: false,
    sort_by: 'id',
  },
};

export const BASE_RESPONSE = {
  meta: {
    pagination: { ...DEFAULT_PAGINATION },
    sort_by: 'CreatedAt',
    sort_ascending: true,
  },
  correlation_id: '',
  data: [],
};

export const KINDS = {
  csv: 'Export as CSV',
  excel: 'Export as Excel',
};

export const KINDS_EXTENSIONS = {
  csv: 'csv',
  excel: 'xlsx',
};

export const KINDS_VALID = ['csv'];

export const VALID_KINDS_OPTIONS = KINDS_VALID.map((type) => ({
  value: type,
  label: KINDS?.[type as keyof typeof KINDS] ?? `export as ${type}`,
}));

export const DEFAULT_LISTING_LEGACY_PAYLOAD = {
  page: DEFAULT_LISTING_PAYLOAD.pagination.current_page,
  items_per_page: DEFAULT_LISTING_PAYLOAD.pagination.items_per_page,
};

export const EXTENDED_PAGE_SELECTOR_OPTIONS = [5, 10, 20, 25, 50, 100, 1000, 5000];
