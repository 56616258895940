import type { JSONSchemaType } from 'ajv';

import { FileUploadField, NumericFormatField, PhoneField } from '@bitstopco/bitstop-theme';

import { ADDRESSES_LIST } from '@/constants/app/addresses';
import { BATCHING_MINIMUM_USD_RANGE } from '@/constants/app/batching';
import { MACHINES_COMMAND_STATUSES_VALUES } from '@/constants/app/machines';

import type {
  BlockchainAnalyticsModel,
  BlockchainAnalyticsUpdateVendorModel,
  CommandStatusModel,
  ConnectWalletModel,
  CreateAffiliateModel,
  CreateUserModel,
  CreateUserWithRoleModel,
  CustomNoteModel,
  DefaultAddNewProviderModel,
  EditMachineModel,
  EditUserRoleModel,
  EditWalletModel,
  LocationCustomNoteModel,
  LocationEditNoteModel,
  NewAddressesModel,
  NotificationsSettingsModel,
  OperatorSettingsUpdateModel,
  ReasonModel,
  UploadDocumentModel,
  UpsertTagModel,
  WalletBitgoSettingsModel,
} from '@/types';

const TEXTAREA_CONFIG = {
  rows: 3,
  multiline: true,
  sx: {
    '.MuiInputBase-root': {
      p: 2,
      height: 'auto',
      lineHeight: 'normal',
    },
    '.MuiFormLabel-root': {
      fontSize: '12px',
      lineHeight: '19px',
    },
  },
};

const PHONE = {
  type: 'object',
  properties: {
    phone: {
      type: 'string',
    },
    fullPhone: {
      type: 'string',
      format: 'fullPhone',
    },
    isoCode: {
      type: 'string',
    },
    country: {
      type: 'string',
    },
    dialCode: {
      type: 'string',
    },
    formattedValue: {
      type: 'string',
    },
    floatValue: {
      type: 'number',
    },
  },
  uniforms: {
    size: 'small',
    defaultDial: 'US',
    component: PhoneField,
    label: 'Phone Number',
    placeholder: '(___) ___ - ____',
  },
  required: ['phone', 'isoCode', 'fullPhone'],
  errorMessage: 'Please be sure to enter a valid mobile number.',
} as const;

const CREATE_USER: JSONSchemaType<CreateUserModel> = {
  type: 'object',
  properties: {
    first_name: {
      type: 'string',
      format: 'noEmptyString',
      uniforms: {
        size: 'small',
        label: 'First Name',
      },
      errorMessage: 'A valid first name is required',
    },
    last_name: {
      type: 'string',
      format: 'noEmptyString',
      uniforms: {
        size: 'small',
        label: 'Last Name',
      },
      errorMessage: 'A valid last name is required',
    },
    email: {
      minLength: 4,
      type: 'string',
      format: 'email',
      uniforms: {
        size: 'small',
        label: 'Email',
      },
      errorMessage: 'A valid email is required',
    },
    phone: {
      ...PHONE,
    },
  },
  required: ['first_name', 'last_name', 'email', 'phone'],
};

/**
 * Uniforms form schemas
 * * More information about uniforms https://uniforms.tools/docs/tutorials-basic-uniforms-usage/
 */
const schemas: {
  NOTIFICATIONS_SETTINGS: JSONSchemaType<NotificationsSettingsModel>;
  CONNECT_WALLET: JSONSchemaType<ConnectWalletModel>;
  EDIT_WALLET: JSONSchemaType<EditWalletModel>;
  ADD_NEW_PROVIDER: JSONSchemaType<DefaultAddNewProviderModel>;
  NEW_ADDRESSES: JSONSchemaType<NewAddressesModel>;
  CHANGE_MACHINE_STATUS: JSONSchemaType<CommandStatusModel>;
  EDIT_MACHINE_DETAILS: JSONSchemaType<EditMachineModel>;
  UPSERT_MACHINE_TAG: JSONSchemaType<UpsertTagModel>;
  REASON: JSONSchemaType<ReasonModel>;
  CUSTOM_NOTE: JSONSchemaType<CustomNoteModel>;
  LOCATION_CUSTOM_NOTE: JSONSchemaType<LocationCustomNoteModel>;
  LOCATION_EDIT_NOTE: JSONSchemaType<LocationEditNoteModel>;
  UPLOAD_DOCUMENT: JSONSchemaType<UploadDocumentModel>;
  CONNECT_VENDOR: JSONSchemaType<BlockchainAnalyticsModel>;
  UPDATE_VENDOR: JSONSchemaType<BlockchainAnalyticsUpdateVendorModel>;
  OPERATOR_SETTINGS: JSONSchemaType<OperatorSettingsUpdateModel>;
  WALLET_BITGO_SETTINGS: JSONSchemaType<WalletBitgoSettingsModel>;
  EDIT_USER_ROLE: JSONSchemaType<EditUserRoleModel>;
  CREATE_USER: JSONSchemaType<CreateUserModel>;
  CREATE_USER_WITH_ROLE: JSONSchemaType<CreateUserWithRoleModel>;
  CREATE_AFFILIATE: JSONSchemaType<CreateAffiliateModel>;
} = {
  /**
   *  Notifications settings schema
   * * Related View: Notifications settings
   */
  NOTIFICATIONS_SETTINGS: {
    type: 'object',
    properties: {
      balance: {
        type: 'number',
        uniforms: {
          size: 'small',
          label: 'Minimum Balance',
        },
        errorMessage: 'A valid balance is required',
      },
      notifyMe: {
        type: 'boolean',
        uniforms: {
          label: '',
          appearance: 'switch',
        },
      },
    },
    required: ['balance'],
  },
  /**
   *  Connect wallet schema
   * * Related View: Wallets
   */
  CONNECT_WALLET: {
    type: 'object',
    properties: {
      wallet_id: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Wallet ID',
        },
        errorMessage: 'A valid Wallet ID is required',
      },
      wallet_password: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Wallet Passphrase',
        },
        errorMessage: 'A valid Wallet Passphrase is required',
      },
      friendly_name: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Wallet Name',
        },
        errorMessage: 'A valid Wallet Name is required',
      },
    },
    required: ['wallet_id', 'friendly_name', 'wallet_password'],
  },
  /**
   *  Edit wallet schema
   * * Related View: Wallets
   */
  EDIT_WALLET: {
    type: 'object',
    properties: {
      friendly_name: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Wallet Name',
        },
        errorMessage: 'A valid wallet name is required',
      },
    },
    required: ['friendly_name'],
  },
  /**
   *  Add new provider default schema
   * * Related View: Wallets
   */
  ADD_NEW_PROVIDER: {
    type: 'object',
    properties: {
      provider: {
        type: 'string',
        uniforms: {
          options: [],
          size: 'small',
          label: 'Provider',
          placeholder: 'Select a provider',
        },
        errorMessage: 'A valid provider is required',
      },
    },
    required: ['provider'],
  },
  /**
   *  New addresses schema
   * * Related View: Wallets
   */
  NEW_ADDRESSES: {
    type: 'object',
    properties: {
      addresses: {
        type: 'string',
        default: ADDRESSES_LIST[0].value,
        uniforms: {
          size: 'small',
          label: 'Number of addresses',
          options: ADDRESSES_LIST,
        },
      },
    },
    required: ['addresses'],
  },
  /**
   * Change machine status schema
   * * Related View: Machine
   */
  CHANGE_MACHINE_STATUS: {
    type: 'object',
    properties: {
      status: {
        type: 'string',
        errorMessage: 'A valid status is required',
        default: MACHINES_COMMAND_STATUSES_VALUES[0].value,
        uniforms: {
          size: 'small',
          label: 'New Machine Status',
          options: MACHINES_COMMAND_STATUSES_VALUES,
        },
      },
    },
    required: ['status'],
  },
  /**
   * Edit machine details schema
   * * Related View: Machine
   */
  EDIT_MACHINE_DETAILS: {
    type: 'object',
    properties: {
      pick_up_id: {
        type: 'string',
        // format: 'noEmptyString',
        errorMessage: 'A valid Pick-up ID is required',
        uniforms: {
          size: 'small',
          label: 'Pick-up ID',
          placeholder: 'Pick-up ID',
        },
      },
      name: {
        type: 'string',
        errorMessage: 'A valid ATM Name is required',
        uniforms: {
          size: 'small',
          label: 'ATM Name',
          placeholder: 'ATM Name',
        },
        minLength: 1,
      },
    },
    required: ['name', 'pick_up_id'],
  },
  /**
   * Upsert machine tag schema
   * * Related View: Machine
   */
  UPSERT_MACHINE_TAG: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid tag name is required',
        uniforms: {
          size: 'small',
          label: 'Tag name',
          placeholder: 'Tag name...',
        },
      },
    },
    required: ['name'],
  },
  /**
   * Reason schema
   * * Related View: Customers
   */
  REASON: {
    type: 'object',
    properties: {
      reason: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid reason is required',
        uniforms: {
          label: 'Reason',
          ...TEXTAREA_CONFIG,
        },
      },
    },
    required: ['reason'],
  },
  /**
   * Custom note schema
   * * Related View: Customers
   */
  CUSTOM_NOTE: {
    type: 'object',
    properties: {
      content: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid content is required',
        uniforms: {
          label: 'Content',
          ...TEXTAREA_CONFIG,
        },
      },
      reason: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid reason is required',
        uniforms: {
          label: 'Reason',
          ...TEXTAREA_CONFIG,
        },
      },
    },
    required: ['content', 'reason'],
  },
  /**
   * Custom note schema for Locations
   */
  LOCATION_CUSTOM_NOTE: {
    type: 'object',
    properties: {
      content: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid content is required',
        uniforms: {
          label: 'Content',
          ...TEXTAREA_CONFIG,
        },
      },
    },
    required: ['content'],
  },
  /**
   * Edit location note schema
   * * Related View: EditLocationNote
   */
  LOCATION_EDIT_NOTE: {
    type: 'object',
    properties: {
      author: {
        type: 'string',
      },
      content: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid content is required',
        uniforms: {
          label: 'Content',
          rows: 3,
          placeholder: 'Write a note for this location here',
          multiline: true,
          sx: {
            '.MuiInputBase-root': {
              p: 2,
              height: 'auto',
              lineHeight: 'normal',
            },
            '.MuiFormLabel-root': {
              fontSize: '12px',
              lineHeight: '19px',
            },
          },
        },
      },
    },
    required: ['content'],
  },
  /**
   * Upload document schema
   * * Related View: Customers
   */
  UPLOAD_DOCUMENT: {
    type: 'object',
    properties: {
      reason: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid reason is required',
        uniforms: {
          label: 'Reason',
          ...TEXTAREA_CONFIG,
        },
      },
      file: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
          },
          file: {
            type: 'object',
          },
        },
        required: ['url', 'file'],
        errorMessage: 'A valid file is required',
        uniforms: {
          label: 'Document',
          maxSize: 20,
          component: FileUploadField,
        },
      },
    },
    required: ['reason', 'file'],
  },
  /**
   * Connect blockchain analytics vendor schema
   * * Related View: Blockchain Analytics settings
   */
  CONNECT_VENDOR: {
    type: 'object',
    properties: {
      vendor: {
        type: 'string',
        errorMessage: 'A valid vendor is required',
        uniforms: {
          size: 'small',
          label: 'Vendor',
          placeholder: 'Select a vendor',
          options: [],
        },
      },
      api_key: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid API Key is required',
        uniforms: {
          size: 'small',
          label: 'API Key',
          placeholder: 'API Key...',
        },
      },
    },
    required: ['vendor', 'api_key'],
  },
  /**
   * update blockchain analytics vendor schema
   * * Related View: Blockchain Analytics settings
   */
  UPDATE_VENDOR: {
    type: 'object',
    properties: {
      api_key: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid API Key is required',
        uniforms: {
          size: 'small',
          label: 'API Key',
          placeholder: 'API Key...',
        },
      },
    },
    required: ['api_key'],
  },
  OPERATOR_SETTINGS: {
    type: 'object',
    properties: {
      batching_minimum_usd: {
        type: 'object',
        properties: {
          floatValue: {
            type: 'number',
            minimum: BATCHING_MINIMUM_USD_RANGE.minimum,
            maximum: BATCHING_MINIMUM_USD_RANGE.maximum,
          },
          value: {
            type: 'string',
          },
        },
        required: ['floatValue'],
        errorMessage: `A valid amount between ${BATCHING_MINIMUM_USD_RANGE.minimum} and ${BATCHING_MINIMUM_USD_RANGE.maximum} is required.`,
        uniforms: {
          label: 'Transaction amount limit',
          component: NumericFormatField,
          numberFormatProps: {
            size: 'small',
            prefix: '$',
            sx: { '& input': { textAlign: 'right' } },
          },
        },
      },
    },
    required: ['batching_minimum_usd'],
  },
  WALLET_BITGO_SETTINGS: {
    type: 'object',
    properties: {
      api_key: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A valid API Key is required',
        uniforms: {
          size: 'small',
        },
      },
      wallet_id: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A wallet ID is required',
        uniforms: {
          size: 'small',
          label: 'Wallet ID',
        },
      },
      wallet_password: {
        type: 'string',
        format: 'noEmptyString',
        errorMessage: 'A wallet password is required',
        uniforms: {
          size: 'small',
          label: 'Wallet Passphrase',
          type: 'password',
        },
      },
    },
    required: ['api_key', 'wallet_id', 'wallet_password'],
  },
  /**
   *  Edit User Role
   * * Related View: Users
   */
  EDIT_USER_ROLE: {
    type: 'object',
    properties: {
      role: {
        type: 'number',
        uniforms: {
          size: 'small',
          label: 'New role',
          options: [],
        },
      },
    },
    required: ['role'],
  },
  /**
   *  Create User
   * * Related View: Affiliate User
   */
  CREATE_USER,
  /**
   *  Create User With Role
   * * Related View: Users
   */
  CREATE_USER_WITH_ROLE: {
    type: 'object',
    properties: {
      ...CREATE_USER.properties,
      role: {
        type: 'number',
        uniforms: {
          size: 'small',
          label: 'Role',
          options: [],
        },
      },
    },
    required: [...CREATE_USER.required, 'role'],
  } as JSONSchemaType<CreateUserWithRoleModel>,
  /**
   *  Create Affiliate
   * * Related View: Affiliates
   */
  CREATE_AFFILIATE: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Name',
        },
        errorMessage: 'A valid organization name is required',
      },
      description: {
        type: 'string',
        format: 'noEmptyString',
        minLength: 5,
        uniforms: {
          size: 'small',
          label: 'Description',
        },
        errorMessage: 'A valid organization description is required',
      },
      first_name: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'First Name',
        },
        errorMessage: 'A valid first name is required',
      },
      last_name: {
        type: 'string',
        format: 'noEmptyString',
        uniforms: {
          size: 'small',
          label: 'Last Name',
        },
        errorMessage: 'A valid last name is required',
      },
      email: {
        minLength: 4,
        type: 'string',
        format: 'email',
        uniforms: {
          size: 'small',
          label: 'Email',
        },
        errorMessage: 'A valid email is required',
      },
      phone: {
        ...PHONE,
      },
    },
    required: ['name', 'description', 'first_name', 'last_name', 'email', 'phone'],
  },
};

export default schemas;
