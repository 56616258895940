import { useRemoveMachineFromLocationMutation } from '@/store/api/services/locations/locations';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const RemoveMachines = ({ hide, props }: ModalChildrenProps) => {
  const [removeAtm, { isLoading, isError, isSuccess }] = useRemoveMachineFromLocationMutation();
  const [error, setError] = useState<string | null>(null);
  const [selectedAtms, setSelectedAtms] = useState<number[]>([]);

  const handleSelectChange = (event: any) => {
    setSelectedAtms(event.target.value || []);
  };

  const handleSubmit = async () => {
    if (selectedAtms.length === 0) return;
    try {
      setError(null);
      const removePromises = selectedAtms.map((atm_id) =>
        removeAtm({ location_id: props?.data?.location_id, atm_id }).unwrap(),
      );

      await Promise.all(removePromises);

      toast.success(
        `Machines ${selectedAtms.join(', ')} removed successfully from location ${
          props?.data?.location_name
        }`,
      );
      setSelectedAtms([]); // Reset selected machines after successful removal
      hide();
    } catch (err: any) {
      setError(err?.data?.meta || 'An unknown error occurred');
    }
  };

  useEffect(() => {
    return () => {
      setSelectedAtms([]); // Reset state when modal closes
      setError(null);
    };
  }, [hide]);

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        {isSuccess && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Machines successfully removed from location <b>{props?.data?.location_name}</b>.
          </Alert>
        )}

        {isError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {!isLoading && !isError && (
          <>
            <Typography variant="h6">
              Are you sure you want to remove the selected machines from this location?
            </Typography>
            <Select
              multiple
              value={selectedAtms}
              onChange={handleSelectChange}
              renderValue={(selected) =>
                selected.length > 0 ? selected.join(', ') : 'Select machines'
              }
              fullWidth
              displayEmpty
              sx={{ mt: 2 }}
            >
              <MenuItem disabled value="">
                Select machines
              </MenuItem>
              {(props?.data?.installed_atms || []).map((atm: any) => (
                <MenuItem key={atm.atm_id} value={atm.atm_id}>
                  {atm.atm_id}
                </MenuItem>
              ))}
            </Select>
            <Alert severity="warning" icon={<ErrorIcon />} sx={{ mt: 2 }}>
              <AlertTitle>Removing machines from location</AlertTitle>
              <Typography variant="body1">
                This action will remove the following machines from location{' '}
                <b>{props?.data?.location_name}</b>.
              </Typography>
            </Alert>
          </>
        )}

        {isLoading && (
          <Typography mt={1} variant="body1">
            Removing machines from location...
          </Typography>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          disabled={isLoading || selectedAtms.length === 0}
        >
          Remove machine(s)
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RemoveMachines;
