import { AutoField, AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';

import type { ConnectWalletModel } from '@/types';

import SCHEMAS from '@/forms/schemas';

/**
 * Connect Wallet Modal
 * TODO: WIP
 *
 * @param {ModalChildrenProps} { hide, props, setTitle }
 * @return {*}
 */
const ConnectWallet = ({ hide, props }: ModalChildrenProps) => {
  const { schema } = useForm(SCHEMAS.CONNECT_WALLET, props?.data);

  // TODO: add coin data here
  const selectedCoinData: Record<any, string> = {};

  const handleSubmit = async (/*data: ConnectWalletModel*/) => {
    try {
      // TODO: connect wallet request

      toast.success('New Provider added successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<ConnectWalletModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <Stack direction="row" spacing={2}>
          <img loading="lazy" src={selectedCoinData?.image} width="24" height={24} alt="Coin" />
          <Typography variant="h6">
            <Typography component="span" variant="h6" textTransform="uppercase">
              {selectedCoinData?.ticker}
            </Typography>{' '}
            Wallet
          </Typography>
        </Stack>

        <Typography variant="body1" mt={1}>
          This user will have limited viewing access to the sales from machines assigned to the
          organization.
        </Typography>
        <AutoFields omitFields={['friendly_name']} />

        <Typography variant="h6" mt={3}>
          Personalize your Wallet
        </Typography>

        <AutoField name="friendly_name" />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to Settings</Button>
        <SubmitField label="Save and connect Wallet" />
      </DialogActions>
    </AutoForm>
  );
};

export default ConnectWallet;
