import { useEffect, useState } from 'react';

import { Loader } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import toast from 'react-hot-toast';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import type { ActionMeta, SingleValue } from 'react-select';

import CustomerLinkAccountNewLink from '@/components/customer/tabs/linkedAccounts/CustomerLinkAccountNewLink';
import CustomersStatus from '@/components/customers/tableColumns/CustomersStatus';
import StyledAsyncSelect from '@/components/ui/StyledAsyncSelect';
import StyledList from '@/components/ui/StyledList';
import { useCustomer, useLinkAccountMutation } from '@/store/api/services/customers/customer';
import { useLazyGetCustomersQuery } from '@/store/api/services/customers/customers';

import { CUSTOMER_DETAILS_BASE } from '@/constants/app/customers/customer';

import TableColumnCustomer from '@/components/ui/table/TableColumnCustomer';

import type { Customer, LinkedCustomer } from '@/types';

type SelectedCustomer = (Customer | LinkedCustomer) & {
  value: number;
  label: string;
};

const getSyncSelectValue = (data: Customer | LinkedCustomer) => ({
  ...data,
  value: data.id,
  label: data.full_name,
});

/**
 * Customer Link account Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const LinkAccount = ({ hide, props }: ModalChildrenProps) => {
  const customerId = useState<number>(+(props?.customer?.id || 0))[0];

  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer | null>(null);

  const [getCustomers] = useLazyGetCustomersQuery();

  const [linkAccount, { isLoading: isWorking }] = useLinkAccountMutation();

  const { customer, isLoading, error: customerError } = useCustomer(customerId);
  const {
    customer: selectedCustomerData,
    maxPurchaseLimit,
    isFetching: isFetchingLinkedCustomer,
    error: linkedCustomerError,
  } = useCustomer(selectedCustomer?.id);

  const error = customerError || linkedCustomerError;

  useEffect(() => {
    props?.linkedCustomer && setSelectedCustomer(getSyncSelectValue(props.linkedCustomer));
  }, [props?.linkedCustomer]);

  if (error) {
    hide();
  }

  if (isLoading) {
    return <Loader />;
  }

  const searchOptions = async (inputValue: string) => {
    const { data = [] } = await getCustomers({
      search_text: inputValue,
      filter: {
        property: 'id',
        value: customerId,
        operator: 'not_equal',
      },
      pagination: {
        sort_by: 'id',
        current_page: 1,
        sort_ascending: false,
        items_per_page: 1000,
      },
    }).unwrap();

    return data.map((customer) => getSyncSelectValue(customer));
  };

  const handleSearch = async (
    newValue: SingleValue<SelectedCustomer>,
    actionMeta: ActionMeta<any>,
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
      case 'clear':
        setSelectedCustomer(null);
    }

    setSelectedCustomer(newValue);
  };

  const handleSubmit = async () => {
    try {
      await linkAccount({
        customer_id_1: customer!.id,
        customer_id_2: selectedCustomer!.id,
      });

      toast.success('Account linked successfully');

      hide();
    } catch {
      //
    }
  };

  return (
    <Stack justifyContent="space-between" height="100%">
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h5">Customer Search</Typography>
        <Typography variant="caption" mt={0.5} component="div">
          Search for the customer that you want link to this account.
        </Typography>

        <Typography variant="caption" mt={2} mb={0.5} component="div">
          Customer
        </Typography>

        <StyledAsyncSelect<SelectedCustomer>
          isClearable
          value={selectedCustomer}
          loadOptions={searchOptions}
          noOptionsMessage={() => 'No Customers'}
          placeholder="Search by customer ID, and name..."
          onChange={handleSearch}
        />

        {isFetchingLinkedCustomer && <Loader />}

        {!isFetchingLinkedCustomer && customer && selectedCustomerData && (
          <>
            <CustomerLinkAccountNewLink
              data-test="link-account-link-card"
              my={6}
              customer={customer}
              linkedCustomer={selectedCustomerData}
            />

            <Box data-test="link-account-data-content">
              <Stack mt={2} direction="row" justifyContent="space-between">
                <TableColumnCustomer data={selectedCustomerData} size="large" link />
                <CustomersStatus value={selectedCustomerData.kyc_status} />
              </Stack>

              <Box my={2} mx={-4}>
                <Divider sx={{ mb: 1 }} />

                <Stack mx={4} direction="row" justifyContent="space-between">
                  <Typography variant="subtitle2" color="text.secondary">
                    Max. Purchase Limit
                  </Typography>
                  <Typography variant="subtitle2">{maxPurchaseLimit}</Typography>
                </Stack>

                <Divider sx={{ my: 1 }} />

                <StyledList
                  data={selectedCustomerData}
                  lineColor="#FAFAFB"
                  columns={CUSTOMER_DETAILS_BASE}
                  listPropsSx={{ '& li': { px: 4 } }}
                />
              </Box>
            </Box>
          </>
        )}
      </DialogContent>

      <Box>
        <Divider />
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button onClick={hide} data-test="link-account-back-button">
            Back to Customer detail
          </Button>
          {selectedCustomer && (
            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              loading={isWorking}
              data-test="link-account-submit-button"
            >
              Link account
            </LoadingButton>
          )}
        </DialogActions>
      </Box>
    </Stack>
  );
};

export default LinkAccount;
