import { Box, Stack } from '@mui/material';
import type { BoxProps } from '@mui/material';

import DateTooltipIcon from '../DateTooltipIcon';
import type { DateTooltipIconProps } from '../DateTooltipIcon';

interface TableHeaderTooltipIconProps extends DateTooltipIconProps {
  label: string;
  boxLabelProps?: BoxProps;
}

const TableHeaderTooltipIcon = ({
  label,
  boxLabelProps,
  ...props
}: TableHeaderTooltipIconProps) => (
  <Stack spacing={0.5} direction="row" alignItems="center">
    <DateTooltipIcon {...props} />
    <Box {...boxLabelProps} data-test="table-header-tooltip-icon-label">
      {label}
    </Box>
  </Stack>
);

export default TableHeaderTooltipIcon;
