import { useSubmitLocationForReviewMutation } from '@/store/api/services/locations/location';
import { type ModalChildrenProps, useModal } from '@bitstopco/bitstop-theme';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';

const PublishLocation = ({ hide, props }: ModalChildrenProps) => {
  const [submitLocationForReview] = useSubmitLocationForReviewMutation();
  const [isLoading, setIsLoading] = useState(false); // Track loading state
  const { show } = useModal();

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      // Call the mutation function with the data
      await submitLocationForReview({
        locationId: props?.location_id,
        // atm_id: props?.atms[0].atm_id,
      }).unwrap(); // unwrap to handle success or failure cleanly

      hide(); // Close the modal
      toast.success(`Successfully submitted location: ${props?.location_name} for publishing`);
    } catch (_err) {
      toast.error('Error submitting location for publishing');
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        {props?.atms && props?.atms.length > 0 ? (
          <>
            <Typography variant="h6">
              Are you sure you want to publish location: <b>{props?.location_name}</b>?
            </Typography>
            <Typography mt={1} variant="body1">
              Publishing this location will make it visible across all Bitstop maps and marketing
              channels.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6">Machine Required for Location</Typography>
            <Typography mt={1} mb={3} variant="body1">
              Before publishing a location it must have a machine installed.
            </Typography>
            <Alert
              severity="error"
              iconMapping={{
                error: (
                  <ErrorIcon
                    fontSize="inherit"
                    sx={{
                      borderRadius: '50%',
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '5px',
                    }}
                  />
                ),
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
                borderRadius: '4px',
                padding: '16px 16px 16px 16px',
              }}
            >
              <Typography variant="body2">
                In order to continue publishing this location, please install a machine.
              </Typography>
            </Alert>
          </>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="remove-match-back-button">
          Cancel
        </Button>
        {props?.atms && props?.atms.length > 0 ? (
          <LoadingButton
            variant="contained"
            onClick={handleSubmit}
            loading={isLoading} // Add loading state
            data-test="remove-match-submit-button"
          >
            Publish
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              hide();
              show('location-install-machine', {
                data: {
                  location_id: Number(props.location_id),
                  location_name: props.location_name,
                },
              });
            }}
          >
            Install Machine
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default PublishLocation;
