import { useState } from 'react';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import theme from '@/helpers/theme';

import type { EditWalletModel } from '@/types';

/**
 * Edit Wallet Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const EditWallet = ({ hide, props }: ModalChildrenProps) => {
  const data = useState(props?.data || {})[0];

  // TODO: the wallet info must be replace here
  const walletInfo: Record<string, any> = {};

  const { schema } = useForm(SCHEMAS.EDIT_WALLET, data);

  const handleSubmit = async (/*data: EditWalletModel*/) => {
    try {
      // TODO: perform edit wallet request
      toast.success('New Provider added successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<EditWalletModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <Stack direction="row" spacing={2}>
          <img loading="lazy" src={walletInfo.icon} width="24" height={24} alt="Icon" />
          <Typography variant="h6">{walletInfo.name}</Typography>
        </Stack>

        <Typography variant="body1" mt={1}>
          This user will have limited viewing access to the sales from machines assigned to the
          organization.
        </Typography>

        <Box mt={2}>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Wallet ID
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">{walletInfo.id}</Typography>
            <IconButton>
              <FileCopyIcon htmlColor="#aaabb2" sx={{ fontSize: '14px' }} />
            </IconButton>
          </Stack>
        </Box>

        <Box mt={2}>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Wallet Passphrase
          </Typography>
          <Typography variant="body2">•••••••...y6t5r</Typography>
        </Box>

        <Typography variant="h6" mt={5}>
          Personalize your Wallet
        </Typography>
        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to Settings</Button>
        <SubmitField label="Save changes" />
      </DialogActions>
    </AutoForm>
  );
};

export default EditWallet;
