import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Chip, IconButton, ListItem, Typography } from '@mui/material';

import type { MachinesTag } from '@/types';

const BG_COLOR = { backgroundColor: 'rgba(80, 97, 118, 0.08)' };

export type EventTarget = React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>;

interface MachineMachineExistingTagsItemProps extends MachinesTag {
  variant?: 'create' | 'regular';
  onClick?: (id: string | null, event: EventTarget) => void;
  onClickEdit?: (id: string, event: EventTarget) => void;
}

const MachineMachineExistingTagsItem = ({
  id,
  name,
  color,
  variant = 'regular',

  onClick,
  onClickEdit,
  ...props
}: MachineMachineExistingTagsItemProps) => {
  const isCreate = variant === 'create';

  const handleEdit = (event: EventTarget) => {
    event.stopPropagation();
    onClickEdit?.(id, event);
  };

  const handleClick = (event: EventTarget) => {
    onClick?.(id, event);
  };

  return (
    <ListItem
      {...props}
      onClick={handleClick}
      sx={{
        p: 1,
        cursor: 'pointer',
        borderRadius: 1.5,
        '&:hover': BG_COLOR,
        ...(isCreate && BG_COLOR),
      }}
      secondaryAction={
        onClickEdit ? (
          <IconButton
            edge="end"
            size="small"
            onClick={handleEdit}
            data-test="machine-details-update-tag"
          >
            <MoreHorizIcon color="primary" />
          </IconButton>
        ) : null
      }
    >
      {isCreate && (
        <Typography variant="subtitle2" fontWeight="bold" mr={1}>
          Create
        </Typography>
      )}
      <Chip
        size="small"
        label={name}
        component="span"
        sx={{
          color: 'white',
          cursor: 'pointer',
          backgroundColor: color,
        }}
      />
    </ListItem>
  );
};

export default MachineMachineExistingTagsItem;
