import { useContext, useEffect, useRef, useState } from 'react';

import { matchRoutes, useLocation } from 'react-router';

import { PermissionsContext } from '@bitstopco/bitstop-theme';

import { ROUTES_PERMISSION } from '@/constants/app/views';

const useViewPermissions = () => {
  const view = useRef<string>();
  const [isLoading, setIsLoading] = useState(true);

  const reactLocation = useLocation();

  const { isAllowedTo } = useContext(PermissionsContext);

  useEffect(() => {
    if (view.current !== reactLocation.pathname) {
      view.current = reactLocation.pathname;

      (async () => {
        try {
          setIsLoading(true);

          const matchedRoute = matchRoutes(
            ROUTES_PERMISSION.map(({ path }) => ({ path })),
            reactLocation,
          );

          let permission: string | undefined | null;
          const matchedPath = matchedRoute?.[0]?.route?.path;
          if (matchedPath) {
            permission = ROUTES_PERMISSION.find(({ path }) => path === matchedPath)?.permission;

            if (permission) {
              const isAllowed = await isAllowedTo(permission, 'AND');

              if (!isAllowed) {
                location.href = location.origin;
                return;
              }
            }
          }

          setIsLoading(false);
        } catch {
          setIsLoading(false);
        }
      })();
    }
  }, [reactLocation]);

  return { isLoading };
};

export default useViewPermissions;
