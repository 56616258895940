import { useMemo } from 'react';

import { formatDate } from '@bitstopco/bitstop-theme/helpers';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

import { fillTemplate } from '@/helpers/string';
import { useUpdatePolicyMutation } from '@/store/api/services/kyc/policies';

import { MANUAL_VERIFICATION } from '@/constants/app/customers/customer';
import { DATE_FORMATS, LOCAL_TIME_LEGEND } from '@/constants/app/date';

import type { Customer, ManualApprovalEnrollmentPolicy } from '@/types';

interface CustomerManualVerificationSatisfiedProps {
  customer: Customer;
  policy: ManualApprovalEnrollmentPolicy;
}

const CustomerManualVerificationSatisfied = ({
  customer,
  policy: { is_satisfied, operator_interacted_at, satisfied_at, has_operator_interacted },
}: CustomerManualVerificationSatisfiedProps) => {
  const [updatePolicy, { isLoading }] = useUpdatePolicyMutation();

  const { icon, textContent, dateContent } = useMemo(() => {
    const { icon, text } =
      MANUAL_VERIFICATION[
        (!has_operator_interacted
          ? 'manual_approval'
          : `satisfied_${is_satisfied.toString()}`) as keyof typeof MANUAL_VERIFICATION
      ];

    const dateString = satisfied_at || operator_interacted_at || new Date().toDateString();
    const date = formatDate(dateString, DATE_FORMATS.dateHoursLocal);

    return { icon, dateContent: date, textContent: fillTemplate(text, { ...customer }) };
  }, [customer, is_satisfied, satisfied_at, operator_interacted_at, has_operator_interacted]);

  const handleUpdatePolicy = (is_satisfied: boolean) => {
    try {
      updatePolicy({
        is_satisfied,
        customer_id: customer.id,
        policy_type: 'manual_review',
      });
    } catch {
      //
    }
  };

  return (
    <Stack direction="row" spacing={2}>
      {icon}
      <Box>
        <Typography mt={0.5} textTransform="uppercase" fontWeight={600} fontSize={12}>
          {customer.full_name} - manual verification review
        </Typography>

        <Typography
          mt={0.5}
          fontSize={14}
          component="span"
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />

        {has_operator_interacted && (
          <Tooltip title={LOCAL_TIME_LEGEND}>
            <Typography
              fontSize={14}
              component="span"
              color="text.secondary"
              data-test="customer-manual-verification-date"
            >
              {dateContent}
            </Typography>
          </Tooltip>
        )}

        {!has_operator_interacted && (
          <Stack mt={3} direction="row" spacing={1}>
            <LoadingButton
              size="small"
              color="success"
              variant="contained"
              loading={isLoading}
              onClick={() => handleUpdatePolicy(true)}
            >
              Approve Verification
            </LoadingButton>

            <LoadingButton
              size="small"
              color="error"
              variant="contained"
              loading={isLoading}
              onClick={() => handleUpdatePolicy(false)}
            >
              Deny Verification
            </LoadingButton>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default CustomerManualVerificationSatisfied;
