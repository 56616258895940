import CashLogsTable from '@/components/ui/CashLogsTable';

/**
 * Machines Cash Logs Table
 * * For tables we have the TableWrapper component from the Bitstop Theme, for more information please visit the Bitstop Theme documentation
 * @return {*}
 */
const MachinesCashLogs = () => <CashLogsTable />;

export default MachinesCashLogs;
