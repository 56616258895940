import type { ReactNode } from 'react';

import { Box } from '@mui/material';
import type { SxProps } from '@mui/material';

interface TabsContainerProps {
  sx?: SxProps;
  children: ReactNode;
}

/**
 * Tabs container wrapper
 * @param {TabsContainerProps} { sx, children }
 * @return {*}
 */
const TabsContainer = ({ sx, children, ...props }: TabsContainerProps) => {
  return (
    <Box
      {...props}
      sx={{
        px: 2,
        display: 'flex',
        borderBottom: 1,
        alignItems: 'center',
        borderColor: 'divider',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default TabsContainer;
