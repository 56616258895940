import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <>
    <path
      fill="currentColor"
      d="M2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2V10H18C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10Z"
    />
    <path
      fill="currentColor"
      opacity="0.5"
      d="M12 2.25195C13.3836 2.61042 14.6462 3.3324 15.6569 4.34307C16.6676 5.35375 17.3895 6.61633 17.748 7.99995H12V2.25195Z"
    />
  </>,
  'CustomChartPie',
);
