import { useMemo } from 'react';

import {
  Footer,
  Header,
  Loader,
  PermissionsProvider,
  Sidebar,
  authReducer,
  themeReducer,
} from '@bitstopco/bitstop-theme';

import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';

import { Outlet, matchRoutes, useLocation } from 'react-router-dom';

import HeaderLogo from '@/components/ui/header/HeaderLogo';
import HeaderRightContent from '@/components/ui/header/HeaderRightContent';

import usePrefetchResources from '@/hooks/usePrefetchResources';
import useSidebarPermissions from '@/hooks/useSidebarPermissions';
import useViewPermissions from '@/hooks/useViewPermissions';

import { useAppDispatch, useAppSelector } from '@/store';

import { fetchPermission } from '@/helpers/user';

import { ROUTES } from '@/constants/app/views';

const {
  actions: themeActions,
  selectors: { pinSidebar },
} = themeReducer;

const {
  selectors: { userInfo },
} = authReducer;

const MainPermissionsWrapper = ({ settings }: { settings?: boolean }) => {
  const userData = useAppSelector(userInfo);
  const userPermissions = userData?.permissions;

  return (
    <PermissionsProvider fetchPermission={fetchPermission(userPermissions)}>
      <Main settings={settings} />
    </PermissionsProvider>
  );
};

/**
 * Main Layout
 */
const Main = ({ settings }: { settings?: boolean }) => {
  const dispatch = useAppDispatch();
  const reactLocation = useLocation();
  const isSidebarPinned = useAppSelector(pinSidebar);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { isLoading } = useViewPermissions();

  const { sidebarItems } = useSidebarPermissions(settings);

  /**
   * Prefetch async data
   * * See the usePrefetchResources hook for check the prefetch data list
   */
  const { isPrefetching } = usePrefetchResources();

  const { title } = useMemo(() => {
    /**
     * We want to retrieve the title from the route configuration. Later we will showing this title in the markup
     */
    const matched = matchRoutes(
      ROUTES.map(({ path }) => ({ path })),
      reactLocation,
    );

    const path = matched?.[0]?.route?.path;
    const { title } = ROUTES.filter(({ path: p }) => p === path)?.[0] ?? { title: '/' };

    return { title };
  }, [settings, reactLocation]);

  const handlePinSidebar = () => {
    dispatch(themeActions.updatePinSidebar(!isSidebarPinned));
  };

  if (isLoading || isPrefetching) {
    return <Loader />;
  }

  return (
    <>
      <Header
        sx={{
          zIndex: 1200,
          '> div': {
            px: {
              xs: 1,
              md: 3,
            },
          },
        }}
        showAccountPopover
        menuItems={sidebarItems}
        renderLogo={<HeaderLogo />}
        renderPopover={<HeaderRightContent />}
      />

      {!mdDown && (
        <Box
          sx={{
            '& > .MuiDrawer-docked': {
              position: 'relative',
            },
          }}
        >
          <Sidebar items={sidebarItems} onPin={handlePinSidebar} pinned={isSidebarPinned} />
        </Box>
      )}

      <Box
        sx={{
          flexGrow: 1,
          minHeight: '100%',
          position: 'relative',
          backgroundColor: 'neutral.100',
          ml: {
            md: isSidebarPinned ? '270px' : '73px',
          },
        }}
      >
        <Container
          sx={{
            zIndex: 2,
            display: 'flex',
            minHeight: '100%',
            position: 'relative',
            flexDirection: 'column',
          }}
          maxWidth="xl"
        >
          <Box pt={12} pb={4}>
            {title && (
              <Typography variant="h4" mb={2}>
                {title}
              </Typography>
            )}
            <Outlet />
          </Box>
          <Footer sx={{ pt: 10, pb: 4 }} />
        </Container>
      </Box>
    </>
  );
};

export default MainPermissionsWrapper;
