import { set, setHours, subMonths } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeartbeatChart from '@/components/ui/HeartbeatChart';
import { useGetMachineMonitoringQuery } from '@/store/api/services/machines/monitoring';

import type { DateRange } from '@bitstopco/bitstop-theme';

const getDefaultRange = () => {
  const now = new Date();
  return {
    startDate: set(subMonths(now, 2), { hours: 0, minutes: 0, seconds: 0 }),
    endDate: set(now, { hours: 23, minutes: 0, seconds: 0 }),
  };
};

/**
 * Machine Monitoring
 * @return {*}
 */
const MachineMonitoring = () => {
  const { id = '0' } = useParams();
  const [dateRange, setDateRange] = useState<DateRange>(getDefaultRange);

  const { data, isFetching } = useGetMachineMonitoringQuery({
    atm_ids: id,
    from: dateRange.startDate?.toISOString() ?? '',
    to: dateRange.endDate?.toISOString() ?? '',
  });

  const handleSetDateRange = ({ startDate, endDate }: DateRange) => {
    if (!startDate || !endDate) return;
    setDateRange({
      startDate: setHours(startDate, 0),
      endDate: setHours(endDate, 23),
    });
  };

  const handleResetRange = () => setDateRange(getDefaultRange);

  const filteredData = useMemo(() => {
    if (!data) return [];
    const edge = formatInTimeZone(new Date(), 'UTC', "yyyy-MM-dd'T'HH:00:00");
    return data?.data.filter(({ time }) => time && time < edge);
  }, [data]);

  return (
    <HeartbeatChart
      isLoading={isFetching}
      dateRange={dateRange}
      data={filteredData}
      onDateRangeChange={handleSetDateRange}
      onDateRangeReset={handleResetRange}
    />
  );
};

export default MachineMonitoring;
