import { Box } from '@mui/material';
import type { BoxProps } from '@mui/material';

interface BoxCircleProps extends BoxProps {
  size?: number;
}

const BoxCircle = ({ size = 36, ...props }: BoxCircleProps) => (
  <Box
    {...props}
    sx={{
      width: size,
      height: size,
      minWidth: size,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '100%',
      justifyContent: 'center',
      ...props.sx,
    }}
  />
);

export default BoxCircle;
