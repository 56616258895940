import { TableColumnStatus } from '@bitstopco/bitstop-theme';
import type { TableColumnSaleStatusCodes } from '@bitstopco/bitstop-theme';

import { Box, Stack, Typography } from '@mui/material';

interface TransactionDetailsStatusProps {
  value: string;
}

const TransactionDetailsStatus = ({ value }: TransactionDetailsStatusProps) => (
  <Stack direction="row" justifyContent="flex-end" spacing={1} alignItems="center" mt={-0.5}>
    <Typography pt={0.5} variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value.replaceAll('_', ' ')}
    </Typography>
    <Box height={14} width={14}>
      <TableColumnStatus value={value as TableColumnSaleStatusCodes} />
    </Box>
  </Stack>
);

export default TransactionDetailsStatus;
