import { env } from '@bitstopco/bitstop-theme';

const testnetPrefix = env.isTestnet() ? 'testnet.' : '';
export const NONCE_V1_HOST = `https://${testnetPrefix}nonce.lol`;

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, try again later!';

export const TABLE_SHOWING_VALUES = [5, 10, 20, 25, 50, 100, 1000];

export const NOT_AVAILABLE = 'n/a';
