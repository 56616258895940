import { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@mui/material';
import type { SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

const isItem = (item: Item | string): item is Item => (item as Item).label !== undefined;

interface Item {
  label: string;
  disabled: boolean;
}

export interface BasePopoverProps {
  items: (string | Item)[];
  dataTest?: string;
  buttonText?: string;
  icon?: OverridableComponent<SvgIconTypeMap<Record<string, any>, 'svg'>> & {
    muiName: string;
  };
  buttonType?: 'button' | 'iconButton';
  onClick?: (index: number) => void;
}

const BasePopover = ({
  items,
  onClick,
  icon: Icon = MoreVertIcon,
  dataTest = 'base-popover',
  buttonText = 'Actions',
  buttonType = 'button',
}: BasePopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const isButton = buttonType === 'button';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event?: MouseEvent | TouchEvent | Record<string, any>) => {
    event?.stopPropagation?.();
    setAnchorEl(null);
  };

  const handleClickItem =
    (index: number) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      onClick?.(index);
      handleClose();
    };

  return (
    <Box>
      {isButton ? (
        <Button
          variant="contained"
          endIcon={<ExpandMoreIcon />}
          onClick={handleClick}
          data-test={dataTest}
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton onClick={handleClick} data-test={dataTest}>
          <Icon />
        </IconButton>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={(ev) => {
          ev.stopPropagation();
        }} // Stop event propogation when menu item button is disabled
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper data-test="more-popover-content">
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open}>
              {items.map((item, index) => {
                let label = item as string;
                let disabled = false;
                if (isItem(item)) {
                  label = item.label;
                  disabled = item.disabled;
                }

                return (
                  <MenuItem
                    key={`more-popover-item-${label}-${index}`}
                    onClick={handleClickItem(index) ?? !disabled}
                    disabled={disabled}
                  >
                    {label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </Box>
  );
};

export default BasePopover;
