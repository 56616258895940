import { useEffect, useRef, useState } from 'react';

import { AutoFields, AutoForm, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import type { AddressComponent } from '@bitstopco/bitstop-theme/components/form/AddressInput';

import SCHEMAS from '@/forms/schemas';
import { useUpdateMachineMutation } from '@/store/api/services/machines/machine';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';
import { useForm as useFormContext } from 'uniforms';

import { GOOGLE_ADDRESS_FIELDS } from '@/constants/app/addresses';

import type { EditMachineModel } from '@/types';

export interface UserAddressPayload {
  city: string;
  state: string;
  country: string;
  zipcode: string;
  street_address: string;
  address2: string;
  place_id: string;
}

const getAddressPayload = (addressComponents?: AddressComponent[]) => {
  const googleAddressFields: Partial<UserAddressPayload> = {};

  GOOGLE_ADDRESS_FIELDS.forEach(({ name, values }) => {
    googleAddressFields[name as keyof typeof googleAddressFields] = values
      .map(
        (value) =>
          addressComponents?.filter(({ types }) => types.indexOf(value) !== -1)[0]?.short_name ??
          '',
      )
      .join(' ');
  });

  return googleAddressFields as UserAddressPayload;
};

/**
 * We need to create a custom Form fields implementation in order to get inside the form context
 * and be able to access the submitting/error information.
 * ! This is not very common, but because the design has the save button outside the actual form we
 * ! will need to handle the submitting/error flags in this way.
 *
 * @param {{
 *   submitRef: React.RefObject<HTMLInputElement>;
 *   onChangeDisabled: (disabled: boolean) => void;
 *   onChangeSubmitting: (submitting: boolean) => void;
 * }} {
 *   submitRef,
 *   onChangeDisabled,
 *   onChangeSubmitting,
 * }
 * @return {*}
 */
const FormFields = ({
  submitRef,
  onChangeDisabled,
  onChangeSubmitting,
}: {
  submitRef: React.RefObject<HTMLInputElement>;
  onChangeDisabled: (disabled: boolean) => void;
  onChangeSubmitting: (submitting: boolean) => void;
}) => {
  const { error, submitting } = useFormContext();

  useEffect(() => {
    onChangeDisabled(!!error);
  }, [error]);

  useEffect(() => {
    onChangeSubmitting(submitting);
  }, [submitting]);

  return (
    <>
      <AutoFields />

      <input type="submit" ref={submitRef} style={{ display: 'none' }} />
    </>
  );
};

/**
 * Edit Machine Modal
 *
 * @param {AddressComponent[]} [addressComponents]
 * @return {*}
 */

const EditMachine = ({ hide, props }: ModalChildrenProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const formRef = useRef<any>(null);
  // const [_schedule, _setSchedule] = useState(['24-hs']);
  const [emptyPickUpId, setEmptyPickUpId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  /**
   * Get the Machine upsert mutations
   */
  const [updateMachine] = useUpdateMachineMutation();

  /**
   * Get the form schema using the initial values (the previous generated address value + the machine values)
   */
  const { schema } = useForm(SCHEMAS.EDIT_MACHINE_DETAILS, {
    initValues: {
      ...props?.data,
    },
  });

  const handleSubmit = async (data: EditMachineModel) => {
    /**
     * Submit the new location details calling the upsert mutation
     */
    const locationPayload = {
      name: data.name, // atm location name
      atm_id: props?.data?.id, // take it from original value 'prop'
      pick_up_id: emptyPickUpId ? '' : data.pick_up_id,
    };
    // if atm already has location we PATCH otherwise its the first location so we POST
    try {
      const updateMachineAction = updateMachine;

      // execute the location change action and unwrap the response
      await updateMachineAction(locationPayload).unwrap();

      toast.success('Machine details updated successfully');
      hide();
    } catch {
      // let middleware handle it
    } finally {
      hide();
    }
  };

  const handleChange = (field: string, model: any) => {
    if (field === 'pick_up_id' && model === undefined) {
      setEmptyPickUpId(true);
    } else if (field === 'pick_up_id' && model !== undefined) {
      setEmptyPickUpId(false);
    }
    /**
     * Since we are using a custom input components, we want to populate those components
     * when the google address information is updated
     */
    if (field === 'address' && model?.address_components?.length) {
      const addressPayload = getAddressPayload(model?.address_components);

      Object.keys(addressPayload).forEach((key) => {
        const value = addressPayload[key as keyof typeof addressPayload];
        value && formRef?.current?.change(key, value.trim());
      });
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <AutoForm<EditMachineModel>
          key={JSON.stringify(props?.data)} // Ensures form re-renders when data changes
          ref={formRef}
          showInlineError
          schema={schema}
          onChange={handleChange}
          onSubmit={handleSubmit}
        >
          <FormFields
            onChangeDisabled={setDisabled}
            onChangeSubmitting={setSubmitting}
            submitRef={ref}
          />
          {/* <EditMachineSchedule initValue={props?.data?.location?.hours} onChange={setSchedule} /> */}
        </AutoForm>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Discard changes</Button>
        <LoadingButton
          variant="contained"
          disabled={disabled}
          loading={submitting}
          onClick={() => ref?.current?.click()}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditMachine;
