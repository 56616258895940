import { getLocationByAddress } from '@/helpers/geocoder';
import api from '@/store/api';
import type {
  CreateLocationRequest,
  Geolocation,
  LocationDetail,
  LocationMarketing,
  LocationMarketingRequest,
  LocationUploadImageRequest,
  LocationV2UpdateRequest,
  LocationV2UpdateResponse,
} from '@/types';
import type { ApiResponse } from '@bitstopco/bitstop-theme';
import { athena, atlas } from '../../instances';

export const locationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLocation: build.query<ApiResponse<LocationDetail>, number>({
      query: (id) => athena({ url: `v2/locations/${id}` }),
      providesTags: (_, __, location_id) => [{ type: 'Location', location_id }],
    }),
    getLocationGeolocation: build.query<Geolocation, string>({
      queryFn: async (address) => {
        try {
          const data = await getLocationByAddress(address);
          return { data };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: 'Failed to fetch geolocation' },
            },
          };
        }
      },
    }),
    createLocation: build.mutation<ApiResponse<Location>, CreateLocationRequest>({
      query: (data) =>
        atlas({
          url: '/v2/locations',
          method: 'POST',
          data,
          showError: true,
        }),
      invalidatesTags: [{ type: 'LocationsSummary' }, { type: 'Locations' }],
    }),
    updateLocation: build.mutation<ApiResponse<LocationV2UpdateResponse>, LocationV2UpdateRequest>({
      query: (data) =>
        atlas({
          url: 'v2/locations',
          method: 'PATCH',
          data,
          showError: true,
        }),
      invalidatesTags: (_, __, location_id) => [
        { type: 'Location', location_id: location_id },
        { type: 'Locations' },
      ],
    }),
    uploadLocationImage: build.mutation<ApiResponse<any>, LocationUploadImageRequest>({
      query: ({ locationId, file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return atlas({
          url: `v2/locations/${locationId}/images`,
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      invalidatesTags: (_, __, { locationId }) => [{ type: 'Location', location_id: locationId }],
    }),
    deleteLocationImage: build.mutation<ApiResponse<any>, { locationId: number; imageId: string }>({
      query: ({ locationId, imageId }) =>
        atlas({
          url: `/v2/locations/${locationId}/images/${imageId}`,
          method: 'DELETE',
        }),
    }),
    submitLocationForReview: build.mutation<
      ApiResponse<LocationMarketing>,
      LocationMarketingRequest
    >({
      query: ({ locationId }) => {
        return atlas({
          url: `/v2/locations/${locationId}/marketing`,
          method: 'POST',
          data: { action: 'ask_for_review' },
          showError: true,
        });
      },
      invalidatesTags: [{ type: 'Location' }],
    }),
    unpublishLocation: build.mutation<ApiResponse<LocationMarketing>, LocationMarketingRequest>({
      query: ({ locationId }) => {
        return atlas({
          url: `/v2/locations/${locationId}/marketing`,
          method: 'POST',
          data: { action: 'unpublish' },
          showError: true,
        });
      },
      invalidatesTags: [{ type: 'Location' }],
    }),
  }),
});

export const {
  useGetLocationQuery,
  useGetLocationGeolocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useUploadLocationImageMutation,
  useDeleteLocationImageMutation,
  useSubmitLocationForReviewMutation,
  useUnpublishLocationMutation,
} = locationApi;
