import { useModal } from '@bitstopco/bitstop-theme';

import { Badge, IconButton } from '@mui/material';
import { HiBell } from 'react-icons/hi';

import { useGetNotificationsCountByCategoryQuery } from '@/store/api/services/notifications/notifications';

const HeaderNotifications = () => {
  const { show } = useModal();
  const {
    data: {
      included: { unread },
    } = { included: { unread: 0 } },
  } = useGetNotificationsCountByCategoryQuery();

  const handleNotifications = () => {
    show('notifications');
  };

  return (
    <Badge
      sx={{ mt: 0.5 }}
      color="success"
      overlap="circular"
      variant="dot"
      badgeContent={unread}
      data-test="header-notifications-badge"
    >
      <IconButton
        data-test="header-notifications-button"
        onClick={handleNotifications}
        sx={{
          width: 36,
          height: 36,
          padding: '8px',
          borderRadius: '50%',
          backgroundColor: '#E60009',
        }}
      >
        <HiBell fontSize={20} color="white" />
      </IconButton>
    </Badge>
  );
};

export default HeaderNotifications;
