import { useMemo, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import toast from 'react-hot-toast';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider } from '@mui/material';

import { type ApiListingRequest, TableWrapper } from '@bitstopco/bitstop-theme';

import {
  useAddAffiliateMachinesMutation,
  useGetAffiliateMachinesQuery,
} from '@/store/api/services/affiliates/affiliates';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { AFFILIATE_MACHINES_COLUMNS } from '@/constants/app/affiliates/affiliate';

import { getOperatorId } from '@/helpers/jwt';

import type { AffiliateAtm } from '@/types';

const COLUMNS = {
  ...AFFILIATE_MACHINES_COLUMNS,
  items: [
    ...AFFILIATE_MACHINES_COLUMNS.items.filter(({ label }) => label?.toLowerCase() !== 'remove'),
  ],
};

/**
 * Affiliate Add Machines Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const AffiliateAddMachines = ({ hide, props }: ModalChildrenProps) => {
  const id = useState(props)[0];

  const [multiSelectValues, setMultiSelectValues] = useState<boolean[]>([]);
  const [payload, setPayload] = useState<ApiListingRequest>();

  const computedPayload = useMemo(() => {
    if (!payload) {
      return skipToken;
    }

    return { ...payload, affiliateId: null };
  }, [payload]);

  const [addMachines, { isLoading }] = useAddAffiliateMachinesMutation();

  const {
    data: {
      data = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetAffiliateMachinesQuery(computedPayload);

  const handleSubmit = async () => {
    try {
      const atmIds = multiSelectValues
        .map((isSelected, index) => isSelected && data[index]?.id)
        .filter(Boolean) as number[];

      await addMachines({
        operator_id: getOperatorId(),
        affiliate_id: id,
        atm_ids: atmIds,
      }).unwrap();

      toast.success(`Atm's (${atmIds.toString()}) added successfully`);

      hide();
    } catch {}
  };

  const isSomeSelected = !multiSelectValues?.some(Boolean);

  return (
    <>
      <DialogContent sx={{ my: 2 }} data-test="affiliate-add-machines-modal">
        <TableWrapper<AffiliateAtm>
          data={data}
          hideActionMenu
          hideDateRangePicker
          urlSync={false}
          sortByDefaultValue="id"
          isLoading={isFetching || !payload}
          columns={COLUMNS}
          multiSelectValues={multiSelectValues}
          onMultiSelectValuesChange={setMultiSelectValues}
          totalPages={metaPagination?.total_pages}
          totalItems={metaPagination?.total_items}
          tableProps={{
            placeholder: {
              empty: {
                title: 'No machines found',
                content:
                  "We can't find any machine that matches your search, please try a different request",
              },
            },
          }}
          tableToolbarProps={{
            searchPlaceholder: 'Search by ATM ID, Name or Serial...',
          }}
          onChange={setPayload}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!!isSomeSelected || isLoading}
          onClick={handleSubmit}
          data-test="affiliate-add-machines-modal-submit-btn"
        >
          Add Machines
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AffiliateAddMachines;
