/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BillAcceptorCashMetrics {
  bill_denominations?: Record<string, number | null>;
  /** @format int32 */
  total_amount_of_bills?: number;
  /** @format double */
  total_worth_of_bills?: number;
}

export interface BillAcceptorCashMetricsLegacy {
  billdenominations?: BillDenominationsLegacy;
  /** @format int32 */
  totalamountofbills?: number | null;
  /** @format int32 */
  totalworthofbills?: number | null;
}

export interface BillDenominationsLegacy {
  /** @format int32 */
  fifty?: number | null;
  /** @format int32 */
  five?: number | null;
  /** @format int32 */
  hundred?: number | null;
  /** @format int32 */
  one?: number | null;
  /** @format int32 */
  ten?: number | null;
  /** @format int32 */
  twenty?: number | null;
  /** @format int32 */
  two?: number | null;
}

export interface BillDispenserManagerModel {
  /** @format int32 */
  totalamount?: number;
  /** @format int32 */
  twenties?: number;
}

export interface BtmHeartbeat {
  bill_acceptor_cash_metrics?: BillAcceptorCashMetrics;
  billacceptorcashmetrics?: BillAcceptorCashMetricsLegacy;
  billdispensercashmetrics?: BillDispenserManagerModel;
  /** @format float */
  cpuusage?: number;
  /** @format date-time */
  created?: string;
  device_statuses?: Record<string, DeviceStatusHardwareResponse>;
  devices?: Record<string, DeviceHeartbeatDto>;
  hddcapacity?: string | null;
  in_transaction?: boolean;
  ip_addresses?: string[] | null;
  machinestatus?: MachineStatus;
  manufacturer?: MachineManufacturer;
  /** @format double */
  memory_available?: number | null;
  /** @format int64 */
  memory_total_mib?: number | null;
  /** @format int64 */
  memoryusage?: number;
  /** @format double */
  memoryusagepercentage?: number;
  miniverse_version?: string | null;
  pending_windows_updates?: WindowsUpdateInformation[] | null;
  robotface_version?: string | null;
  task_executor_version?: string | null;
  uptime?: TimeSpan;
  version?: string | null;
}

export interface DeviceHeartbeatDto {
  devicestatus?: DeviceStatus;
  metadata?: string | null;
}

/** @format int32 */
export enum DeviceKind {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $_1 = -1,
}

/** @format int32 */
export enum DeviceStatus {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
}

export interface DeviceStatusHardwareResponse {
  data?: DeviceStatus;
  device_kind?: DeviceKind;
  /** @format int64 */
  duration?: number | null;
  hardware_result?: HardwareResult;
  meta_data?: Record<string, any>;
}

export interface Fee {
  /** @format float */
  flat?: number;
  /** @format int32 */
  max?: number;
  /** @format int32 */
  min?: number;
  /** @format float */
  percent?: number;
}

/** @format int32 */
export enum HardwareResult {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
}

export interface HeartbeatNewCreateParams1 {
  /**
   * The requested API version
   * @default "1"
   */
  'api-version'?: string;
}

export interface HeartbeatResponse {
  args?: string | null;
  command?: string | null;
}

/** @format int32 */
export enum MachineManufacturer {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
}

export interface MachineMonitoringListParams {
  /** Atm Ids separated by `,` */
  atm_ids?: string;
  /** @format date-time */
  from?: string;
  /** Hourly, Daily or Weekly */
  interval?: string;
  /** @format date-time */
  to?: string;
}

export interface MachineMonitoringListParams2 {
  /**
   * The requested API version
   * @default "1"
   */
  'api-version'?: string;
  /** Atm Ids separated by `,` */
  atm_ids?: string;
  /** @format date-time */
  from?: string;
  /** Hourly, Daily or Weekly */
  interval?: string;
  /** @format date-time */
  to?: string;
}

export interface MachineMonitoringResponse {
  status?: MachineStatusEnum;
  /** @format date-time */
  time?: string;
}

/** @format int32 */
export enum MachineStatus {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $7 = 7,
  $8 = 8,
  $9 = 9,
  $_1 = -1,
}

/** @format int32 */
export enum MachineStatusEnum {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $3 = 3,
}

export interface PostHeartbeatParams {
  /**
   * The requested API version
   * @default "1"
   */
  'api-version'?: string;
}

export interface RobotClientHeartbeat {
  fees?: Fee[] | null;
  /** @format int32 */
  setting_id?: number;
  status?: RobotClientStatus;
  version?: string | null;
  [key: string]: any;
}

/** @format int32 */
export enum RobotClientStatus {
  $0 = 0,
  $1 = 1,
  $2 = 2,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $9 = 9,
}

export interface TimeSpan {
  /** @format int32 */
  days?: number;
  /** @format int32 */
  hours?: number;
  /** @format int32 */
  microseconds?: number;
  /** @format int32 */
  milliseconds?: number;
  /** @format int32 */
  minutes?: number;
  /** @format int32 */
  nanoseconds?: number;
  /** @format int32 */
  seconds?: number;
  /** @format int64 */
  ticks?: number;
  /** @format double */
  total_days?: number;
  /** @format double */
  total_hours?: number;
  /** @format double */
  total_microseconds?: number;
  /** @format double */
  total_milliseconds?: number;
  /** @format double */
  total_minutes?: number;
  /** @format double */
  total_nanoseconds?: number;
  /** @format double */
  total_seconds?: number;
}

export interface WindowsUpdateInformation {
  update_id?: string | null;
  update_title?: string | null;
}
