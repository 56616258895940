import { useState } from 'react';

import { TabContext } from '@mui/lab';
import { Box, DialogContent } from '@mui/material';

import NotificationsTabs from '@/components/notifications/tabs/NotificationsTabs';
import NotificationsTabsPanels from '@/components/notifications/tabs/NotificationsTabsPanels';

/**
 * Notifications modal
 */
const Notifications = () => {
  const [pathname, setPathname] = useState('customers');

  return (
    <>
      <DialogContent sx={{ m: 0, p: 0 }}>
        <TabContext value={pathname}>
          <Box display="flex" flexDirection="column" height="100%">
            <NotificationsTabs onChange={setPathname} />
            <NotificationsTabsPanels />
          </Box>
        </TabContext>
      </DialogContent>
    </>
  );
};

export default Notifications;
