import api from '@/store/api';
import { athena, atlas } from '@/store/api/instances';
import type { CreateMerchantRequest, Merchant } from '@/types';
import type { ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

/**
 * RTK Query API for Merchants.
 */
export const merchantsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMerchants: build.query<ApiResponse<Merchant[]>, ApiListingRequest>({
      query: (data) => {
        return athena({
          url: '/v2/merchants/find',
          method: 'POST',
          data,
        });
      },
    }),
    createMerchant: build.mutation<ApiResponse<Merchant>, CreateMerchantRequest>({
      query: (data) =>
        atlas({
          url: '/v2/merchants',
          method: 'POST',
          data,
        }),
    }),
  }),
});

/**
 * Custom hooks for Merchants API
 */

/**
 * Export hooks and endpoints
 */
export const { useCreateMerchantMutation, useGetMerchantsQuery } = merchantsApi;

export const {
  endpoints: { createMerchant, getMerchants },
} = merchantsApi;
