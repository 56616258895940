import { Loader } from '@googlemaps/js-api-loader';

/**
 * Loader for google API libraries
 *
 * @return {*}  {Promise<typeof google>}
 */
export const initGoogleScript = () => {
  const loader = new Loader({
    apiKey: import.meta.env.VITE_MAPS_API_KEY ?? '',
    libraries: ['places'],
    id: '__googleMapsScriptId',
  });

  return loader.load();
};
