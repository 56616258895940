//import TagListTruncated from '@/components/ui/TagListTruncated';
import TagList from '@/components/ui/TagList';
import { useGetLastMachines } from '@/store/api/services/machines/machines';

import type { Machine } from '@/types';

interface MachinesCashBoxProps {
  value: string[];
  data: Machine;
}

const MachinesCashBox = ({ value = [], data }: MachinesCashBoxProps) => {
  const machine = useGetLastMachines();
  const tags = machine?.included?.tags || [];

  /*if (value.length > 2) {
    return <TagListTruncated selectedTagIds={value} tagsCollection={tags} containerId={data?.id} />;
  }*/

  return <TagList selectedTagIds={value} tagsCollection={tags} containerId={data?.id} />;
};

export default MachinesCashBox;
