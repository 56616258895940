import { useState } from 'react';

import { Loader } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import type { TypographyProps } from '@mui/material';
import {
  Alert,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';

import { useDeleteTagMutation, useGetTagStatsByIdQuery } from '@/store/api/services/common/tags';

const TypographyStrong = (props: TypographyProps) => (
  <Typography
    {...props}
    fontWeight={600}
    component="strong"
    variant="subtitle2"
    color="text.primary"
  />
);

/**DeleteTagModal takes props with relevant tag information, hide event */
const DeleteTag = ({ props, hide }: ModalChildrenProps) => {
  // props contains the tag information
  // useState to load props into local state
  // deconstruct to get what we want, additionally can call color if need
  const { id, name, color } = useState(props)[0];

  const [deleteTag, { isLoading: isLoadingDeleteTag }] = useDeleteTagMutation();

  // get the resources this tag is associated with
  const {
    data: {
      data: { atm: tagStats } = { atm: { count: 0 } },
    } = {},
    isLoading: isLoadingResourcesCount,
  } = useGetTagStatsByIdQuery(id);

  const handleDelete = () => {
    deleteTag(id);
    hide();
  };

  return (
    <>
      <DialogContent sx={{ py: 3 }}>
        <Typography variant="h6" data-test="delete-tag-title">
          Are you sure you would like to delete {name} tag?
        </Typography>
        <Typography mt={1} variant="body1">
          This action cannot be undone.
        </Typography>
        <Chip
          size="small"
          label={name}
          sx={{
            my: 2,
            color: 'white',
            backgroundColor: color,
          }}
          data-test="delete-tag-chip"
          data-color={color}
        />

        {isLoadingResourcesCount ? (
          <Loader size={16} />
        ) : (
          <Alert severity="warning" icon={<ErrorIcon />}>
            <Typography variant="subtitle2" color="text.secondary" data-test="delete-tag-resources">
              This <TypographyStrong>{name}</TypographyStrong> tag is associated to{' '}
              <TypographyStrong>{tagStats?.count} resources</TypographyStrong>
            </Typography>
          </Alert>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ py: 2, justifyContent: 'space-between' }}>
        <Button size="small" variant="text" onClick={hide} data-test="delete-tag-back-button">
          Back
        </Button>

        <LoadingButton
          size="small"
          color="error"
          variant="contained"
          onClick={handleDelete}
          loading={isLoadingDeleteTag}
          data-test="delete-tag-delete-button"
        >
          Yes, delete tag
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DeleteTag;
