import { Box, Button, Typography } from '@mui/material';

import { useErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import DeskImg from '@/assets/placeholder-desk.svg?react';

const ErrorPage = () => {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  const handleGoHome = () => {
    resetBoundary();
    navigate('/');
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <DeskImg
          style={{
            margin: '0 auto',
            width: '100%',
            height: '280px',
            maxWidth: '460px',
          }}
        />
        <Typography mt={3} variant="h2">
          Oops! Something Went Wrong
        </Typography>
        <Typography mt={1} variant="body2">
          We're sorry, but an unexpected error occurred. Please try again later or contact support
          for assistance.
        </Typography>
        <Button sx={{ mt: 3 }} variant="contained" onClick={handleGoHome}>
          Go Back to Home Page
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorPage;
