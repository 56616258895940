import { CircularProgress, List, Stack } from '@mui/material';
import SimpleBar from 'simplebar-react';

import type { MachinesTag } from '@/types';

import TagsItem from './TagsItem';
import type { EventTarget } from './TagsItem';

interface TagsExistingTagsProps {
  data: MachinesTag[];
  loading?: boolean;
  selectedTags?: string[];
  onClick?: (id: string | null, event: EventTarget) => void;
  onClickEdit?: (id: string, event: EventTarget) => void;
}

const TagsExistingTags = ({
  data,
  loading,
  onClick,
  onClickEdit,
}: //selectedTags = [],
TagsExistingTagsProps) => {
  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" py={2}>
        <CircularProgress size={20} />
      </Stack>
    );
  }

  //TODO: Filtered tags functionality hidden until we decide how to edit tags
  //const filteredData = data.filter(({ id }) => selectedTags.indexOf(id) === -1);
  const filteredData = data;

  if (!filteredData.length) {
    return null;
  }

  return (
    <SimpleBar style={{ maxHeight: 200 }}>
      <List sx={{ pb: 0 }} data-test="machine-details-add-tag-search">
        {filteredData.map((tag) => (
          <TagsItem
            key={`machines-exiting-tag-${tag.id}`}
            {...tag}
            onClick={onClick}
            onClickEdit={onClickEdit}
          />
        ))}
      </List>
    </SimpleBar>
  );
};

export default TagsExistingTags;
