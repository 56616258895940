import TableColumnCustomer from '@/components/ui/table/TableColumnCustomer';
import { TRANSACTION_DETAILS_COLUMNS } from '@/constants/app/transactions';
import type { Customer, TransactionDetail, TransactionImageSource } from '@/types';
import { List } from '@bitstopco/bitstop-theme';
import { useMemo } from 'react';

import { Box, Grid, Typography } from '@mui/material';

type CustomValueMapper = (
  value: string,
  data: TransactionDetail,
  hide?: () => void,
) => React.ReactNode;

type TransactionDetailsProps = {
  transaction: TransactionDetail;
  images: TransactionImageSource[];
  hideAtmValue: boolean;
  hideCustomerLink: boolean;
  hide: () => void;
};

const TransactionDetailsTab = ({
  transaction,
  images,
  hideAtmValue,
  hideCustomerLink,
  hide,
}: TransactionDetailsProps) => {
  const listConfig = useMemo(() => {
    const hideValues = { atm_id: hideAtmValue };

    return {
      ...TRANSACTION_DETAILS_COLUMNS,
      items: TRANSACTION_DETAILS_COLUMNS.items
        .filter(({ value }) => !hideValues[value as keyof typeof hideValues])
        .map((item) => {
          //If the config value is atm_id we need to pass the modal hide function
          if (item.value === 'atm_id') {
            return {
              ...item,
              ...(item.valueMapper && {
                valueMapper: (value: string, data: TransactionDetail) =>
                  (item.valueMapper as CustomValueMapper)(value, data, hide),
              }),
            };
          }

          if (item.value === 'customer' && hideCustomerLink) {
            return {
              ...item,
              ...(item.valueMapper && {
                valueMapper: (customer: Customer, data) => (
                  <TableColumnCustomer data={{ ...customer, id: data.customer_id }} fixIcon />
                ),
              }),
            };
          }

          return item;
        }),
    };
  }, [hide, hideAtmValue]);

  return (
    <>
      <List<TransactionDetail>
        data={transaction}
        columns={listConfig}
        sx={{ px: 1 }}
        listItemProps={{
          sx: {
            '& > div': {
              width: '100%',
              '& > div:first-of-type': {
                width: '40%',
              },
              '& > div': {
                margin: 0,
              },
            },
          },
        }}
      />

      {!!images.length && (
        <Box mt={2}>
          <Typography variant="subtitle2">Transaction Photos</Typography>

          <Grid container spacing={1} mt={1}>
            {images.map(({ view, link_to_image }, index) => (
              <Grid item lg={6} xs={12} key={`transaction-details-image-${index}`}>
                <Box sx={{ p: 1.5, backgroundColor: 'neutral.100', borderRadius: '6px' }}>
                  <img
                    src={link_to_image}
                    width="100%"
                    height="auto"
                    loading="lazy"
                    alt={view}
                    style={{ borderRadius: 6, maxHeight: 150, objectFit: 'contain' }}
                    data-test="transaction-details-image"
                  />
                  <Typography
                    display="block"
                    color="#506176"
                    variant="caption"
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    {(view || '').replaceAll('_', ' ')}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TransactionDetailsTab;
