import { createSlice } from '@reduxjs/toolkit';

import {
  _setLastGetLocationId,
  _setLastGetLocationsId,
  _setLastGetMachineId,
  _setLastGetMachinesId,
} from './actions';
import initialState from './state';

export const persistenceSlice = createSlice({
  name: 'common/persistence',
  initialState,
  reducers: {
    setLastGetMachineId: _setLastGetMachineId,
    setLastGetMachinesId: _setLastGetMachinesId,
    setLastGetLocationId: _setLastGetLocationId,
    setLastGetLocationsId: _setLastGetLocationsId,
  },
});

export const actions = persistenceSlice.actions;
export default persistenceSlice.reducer;
