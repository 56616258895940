import { useMemo } from 'react';

import Tabs from '@/components/tabs/Tabs';
import TabsContainer from '@/components/tabs/TabsContainer';
import { useGetNotificationsCountByCategoryQuery } from '@/store/api/services/notifications/notifications';

import { PANELS, PANELS_IDS, PANELS_ROUTES } from '@/constants/app/notifications/notificationsTabs';

interface NotificationsTabsProps {
  onChange: (newValue: string) => void;
}

const NotificationsTabs = ({ onChange }: NotificationsTabsProps) => {
  const {
    data: { data } = { data: [] },
  } = useGetNotificationsCountByCategoryQuery();

  const computedPanels = useMemo(() => {
    return PANELS.map((name, index) => {
      const panelId = PANELS_IDS[index];

      let count = '';
      const categoryFound = data?.find(
        ({ category }) => category.toLowerCase() === panelId.toLowerCase(),
      );

      if (categoryFound?.unread) {
        count = ` (${categoryFound.unread})`;
      }

      return `${name}${count}`;
    });
  }, [data]);

  return (
    <TabsContainer sx={{ flex: '0 1 auto' }} data-test="notifications-tabs">
      <Tabs panels={computedPanels} routes={PANELS_ROUTES} onChange={onChange} />
    </TabsContainer>
  );
};

export default NotificationsTabs;
