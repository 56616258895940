import { lazy } from 'react';

import { Navigate } from 'react-router';

import { DefaultRouteWithParam } from '@/components/DefaultRouteWithParam';

export const POPOVER_ACTIONS = ['/settings/integrations/blockchain-analytics'];

// We want to lazy load the pages for better performance
const Machine = lazy(() => import('@/pages/machines/Machine'));
const Machines = lazy(() => import('@/pages/machines/Machines'));
const Customer = lazy(() => import('@/pages/customers/Customer'));
const Customers = lazy(() => import('@/pages/customers/Customers'));
const Transactions = lazy(() => import('@/pages/transactions/Transactions'));

const ProvisioningQrCode = lazy(() => import('@/pages/settings/maintenance/ProvisioningQrCode'));
const BlockchainAnalytics = lazy(() => import('@/pages/settings/integrations/BlockchainAnalytics'));

const Wallet = lazy(() => import('@/pages/wallet/Wallet'));

const Compliance = lazy(() => import('@/pages/compliance/Compliance'));

const Invoices = lazy(() => import('@/pages/invoices/Invoices'));
const Invoice = lazy(() => import('@/pages/invoices/invoice'));

const Stats = lazy(() => import('@/pages/stats/Stats'));

const Users = lazy(() => import('@/pages/users/Users'));

const Affiliates = lazy(() => import('@/pages/affiliates/Affiliates'));
const Affiliate = lazy(() => import('@/pages/affiliates/Affiliate'));

const Dashboard = lazy(() => import('@/pages/Dashboard'));

const Locations = lazy(() => import('@/pages/locations/Locations'));
const Location = lazy(() => import('@/pages/locations/Location'));

/**
 * Settings routes configuration
 * * Settings has his own configuration, this is because we are using a different layout for those routes
 */
export const SETTINGS_ROUTES = [
  {
    path: '/settings/integrations/blockchain-analytics',
    element: <BlockchainAnalytics />,
    title: null,
  },
  {
    path: '/settings/integrations',
    element: <Navigate to="/settings/integrations/blockchain-analytics" replace />,
  },
  {
    path: '/settings/maintenance/provisioning-qr-code',
    element: <ProvisioningQrCode />,
    title: null,
  },
  {
    path: '/settings/maintenance',
    element: <Navigate to="/settings/integrations/blockchain-analytics" replace />,
  },
  {
    path: '/settings',
    element: <Navigate to="/settings/integrations/blockchain-analytics" replace />,
  },
];

/**
 * Main routes configuration
 * * The routes visibility can be configured here
 * ! If you want to hide or show an existing view, please remember to update the sidebar menu configuration (sidebar.ts)
 */
export const MAIN_ROUTES = [
  // Dashboard views
  { path: '/', element: <Dashboard />, title: 'Dashboard' },

  // Transactions Views
  { path: '/sales/transactions', element: <Transactions />, title: 'Sales' },
  { path: '/sales/transactions/:saleId', element: <Transactions />, title: 'Sales' },
  { path: '/sales/batching', element: <Transactions />, title: 'Sales' },
  { path: '/sales', element: <Navigate to="/sales/transactions" replace /> },

  // Machines Views
  { path: '/machines/atms', element: <Machines />, title: 'Machines' },
  { path: '/machines/cash-logs', element: <Machines />, title: 'Machines' },
  { path: '/machine/:id', element: <Machine /> },
  { path: '/machine/:id/sales', element: <Machine /> },
  { path: '/machine/:id/sales/:saleId', element: <Machine /> },
  { path: '/machine/:id/cashbox', element: <Machine /> },
  { path: '/machine/:id/cash-logs', element: <Machine /> },
  { path: '/machine/:id/monitoring', element: <Machine /> },
  { path: '/machines', element: <Navigate to="/machines/atms" replace /> },

  {
    path: '/location/:id',
    //element: <Navigate to="/location/:id/details" replace />,
    element: <Location />,
  },
  { path: '/location/:id/details', element: <Location /> },
  { path: '/location/:id/notes', element: <Location /> },
  { path: '/location/:id/sales', element: <Location /> },
  // { path: '/location/:id/settings', element: <Location />},
  { path: '/locations', element: <Locations />, title: 'Locations' },

  // Wallets Views
  // comment items for hiding wallets v2 views
  /*{ path: '/wallets/activity', element: <Wallets />, title: 'Wallets' },
  { path: '/wallets/funding', element: <Wallets />, title: 'Wallets' },
  { path: '/wallets/settings', element: <Wallets />, title: 'Wallets' },
  { path: '/wallets', element: <Navigate to="/wallets/activity" replace /> },*/

  { path: '/wallet/transactions', element: <Wallet />, title: 'Wallet' },
  { path: '/wallet/addresses', element: <Wallet />, title: 'Wallet' },
  { path: '/wallet', element: <Navigate to="/wallet/transactions" replace /> },

  // Compliance Views
  { path: '/compliance', element: <Compliance />, title: 'Compliance' },

  // Invoices Views
  { path: '/invoices', element: <Invoices />, title: 'Invoices' },
  { path: '/invoice/:id', element: <Invoice /> },

  // Stats Views
  { path: '/stats', element: <Stats />, title: 'Stats' },

  // Users Views
  { path: '/users', element: <Users />, title: 'Users' },

  // Affiliates Views
  { path: '/affiliates', element: <Affiliates />, title: 'Affiliates' },
  { path: '/affiliate/:id/users', element: <Affiliate /> },
  { path: '/affiliate/:id/machines', element: <Affiliate /> },
  { path: '/affiliate/:id/sales', element: <Affiliate /> },
  {
    path: '/affiliate/:id',
    element: <DefaultRouteWithParam path="/affiliate/:id/users" />,
  },

  // Customers
  { path: '/customers', element: <Customers />, title: 'Customers' },
  { path: '/customer/:id/sales', element: <Customer /> },
  { path: '/customer/:id/kyc', element: <Customer /> },
  { path: '/customer/:id/manual-verification', element: <Customer /> },
  { path: '/customer/:id/notes', element: <Customer /> },
  { path: '/customer/:id/linked-accounts', element: <Customer /> },
  { path: '/customer/:id/potential-matches', element: <Customer /> },
  {
    path: '/customer/:id',
    element: <DefaultRouteWithParam path="/customer/:id/sales" />,
  },

  // Default redirect
  { path: '*', element: <Navigate to="/machines/atms" replace /> },
];

export const ROUTES_PERMISSION = [
  {
    path: '/sales/*',
    permission: 'sales.view',
  },
  {
    path: '/wallet/addresses',
    permission: 'wallets.generate_address',
  },
  {
    path: '/compliance',
    permission: 'kyc.view',
  },
  {
    path: '/invoices',
    permission: 'invoices.view',
  },
  {
    path: '/stats',
    permission: 'customer_statistics.view',
  },
  {
    path: '/users',
    permission: 'users.view',
  },
  {
    path: '/affiliates',
    permission: 'affiliates.view',
  },
  {
    path: '/affiliate/*',
    permission: 'affiliates.view',
  },
];

export const ROUTES = [...SETTINGS_ROUTES, ...MAIN_ROUTES];

export const ROUTES_PATHS = ROUTES.map(({ path }) => path);
export const ROUTES_TITLE = ROUTES.map(({ title }) => title);
