import { useMemo, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type { ApiListingRequest, TableWrapperRef } from '@bitstopco/bitstop-theme';
import { TableWrapper } from '@bitstopco/bitstop-theme';

import MachineMachineTabTitle from '@/components/machine/tabs/machine/MachineMachineTabTitle';
import CardTable from '@/components/ui/card/CardTable';
import {
  useGetCashLogsExportMutation,
  useGetCashLogsQuery,
} from '@/store/api/services/machines/cashlogs';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { CASH_LOGS_COLUMNS, MACHINE_CASH_LOGS_COLUMNS } from '@/constants/app/machines';

import { appendToFilters } from '@/helpers/api';

import type { MachinesCashLog } from '@/types';

export interface CashLogsTableProps {
  machineId?: number;
}

/**
 * CashLogs Table
 * @return {*}
 */
const CashLogsTable = ({ machineId }: CashLogsTableProps) => {
  const [exportMutation] = useGetCashLogsExportMutation();
  const [payload, setPayload] = useState<ApiListingRequest>();

  const tableRef = useRef<TableWrapperRef>(null);

  const computedPayload = useMemo(() => {
    if (!payload) {
      return skipToken;
    }

    if (!machineId) {
      return payload;
    }

    const affiliateIdFilter = {
      operator: 'equal',
      property: 'atm_id',
      value: machineId,
    };

    return appendToFilters(payload, [affiliateIdFilter]);
  }, [payload, machineId]);

  const columns = useMemo(
    () => (!machineId ? CASH_LOGS_COLUMNS : MACHINE_CASH_LOGS_COLUMNS),
    [machineId],
  );

  /**
   * Get Machines cash logs filtered by the desired payload
   */
  const {
    data: {
      data = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetCashLogsQuery(computedPayload);

  const handleExport = (type: string) => {
    if (computedPayload === skipToken) {
      return;
    }

    // Use the getExportFile helper to export the Table data using the current payload
    tableRef.current?.getExportFile({
      type,
      overridePayload: computedPayload,
      mutation: exportMutation,
      fileName: 'Nonce-Cash-Logs-Report',
    });
  };

  return (
    <CardTable data-test="cash-logs-table">
      {!!machineId && (
        <MachineMachineTabTitle
          title="Cash Logs"
          sxDivider={{ mb: '4px' }}
          sxContainer={{
            pt: 2,
            px: 2,
          }}
        />
      )}

      <TableWrapper<MachinesCashLog>
        data={data}
        ref={tableRef}
        hideSearch
        dateRangeFilterKey="created_at"
        isLoading={isFetching || !payload}
        columns={columns}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        tableProps={{
          placeholder: {
            empty: {
              title: 'No cash logs found',
              content:
                "We can't find any cash log that matches your search, please try a different request",
            },
          },
        }}
        onChange={setPayload}
        onActionMenuItemClick={handleExport}
      />
    </CardTable>
  );
};

export default CashLogsTable;
