import { RECENT_DATE_RANGE } from '@bitstopco/bitstop-theme/constants';

import type { ApiListingFilter, ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import { endOfDay, isValid, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';

import type { RootState } from '@/store';
import { store } from '@/store';
import { customerApi } from '@/store/api/services/common/customer';
import { locationsApi } from '@/store/api/services/locations/locations';
import { machineApi } from '@/store/api/services/machines/machine';
import { machinesApi } from '@/store/api/services/machines/machines';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { DATE_FORMATS } from '@/constants/app/date';

import type { ApiListingLegacyRequest, ApiMetaResponse } from '@/types';
import type { ApiFilterValidationConfigType } from '@/types';

const FILTER_VALIDATION_CONFIG: ApiFilterValidationConfigType = {
  customer: {
    label: 'Customer',
    action: customerApi.endpoints.getV1Customer,
  },
  machine: {
    label: 'Machine',
    action: machineApi.endpoints.getMachine,
  },
  location: {
    label: 'Location',
    action: locationsApi.endpoints.getLocations,
  },
  pick_up_id: {
    label: 'Pick up ID',
    action: machinesApi.endpoints.getMachines,
  },
};

const getDate = (value: string) => format(new Date(value), DATE_FORMATS.simpleVariant);
const getDateStartEnd = (value: string) =>
  format(new Date(value), `${DATE_FORMATS.simpleVariant} HH:mm:ss`);

/**
 * Get Legacy pagination response and transform it to V2 response (without headers manipulation)
 *
 * @template T
 * @param {T[]} data
 * @param {number} [page=1]
 * @param {number} [limit=10]
 * @param {number} [totalItems=0]
 * @return {*}
 */
export const getLegacyPaginatedResponse = <T = any>(
  data: T[],
  page = 1,
  limit = 10,
  totalItems = 0,
) => {
  return {
    data,
    correlation_id: uuid(),
    meta: {
      filters: [],
      pagination: {
        items_per_page: limit,
        current_page: page,
        total_items: totalItems,
        total_pages: Math.ceil(totalItems / limit),
      },
      sort_by: 'id',
      sort_ascending: true,
    },
  } as ApiResponse<T[]>;
};

/**
 * Get date range value from listing filter payload
 *
 * @param {ApiListingFilter} [filter]
 * @return {*}
 */
export const getDateRangeValueFromListingFilter = (filter?: ApiListingFilter) => {
  try {
    const startDate = startOfDay(
      new Date(
        filter?.operands?.find(
          ({ property, operator }) => property === 'date' && operator === 'greater_than_or_equal',
        )?.value,
      ),
    );

    const endDate = endOfDay(
      new Date(
        filter?.operands?.find(
          ({ property, operator }) => property === 'date' && operator === 'less_than_or_equal',
        )?.value,
      ),
    );

    if (isValid(startDate) && isValid(endDate)) {
      return { startDate, endDate };
    }
  } catch {}

  return null;
};

/**
 * Get filter value from listing filter payload
 *
 * @param {string} id
 * @param {ApiListingFilter} [filter]
 * @return {*}
 */
export const getValueFromListingFilter = (
  id: string,
  filter?: ApiListingFilter,
): null | string | number => {
  if (!filter) {
    return null;
  }

  if (filter?.property === id) {
    return filter.value;
  }

  return filter?.operands?.find?.(({ property }) => property === id)?.value;
};

/**
 * Get Legacy paginated payload and transform it to V2 payload
 *
 * @param {ApiListingRequest} payload
 * @return {ApiListingLegacyRequest}
 */
export const getLegacyPaginatedPayload = (
  { search_text, pagination, filter }: ApiListingRequest,
  extraParamsIds?: string[],
): ApiListingLegacyRequest & Record<string, any> => {
  const dateRange = getDateRangeValueFromListingFilter(filter);

  const page = pagination?.current_page;
  const items_per_page = pagination?.items_per_page;

  const rangeFilter =
    dateRange?.startDate && dateRange?.endDate
      ? {
          start: getDateStartEnd(dateRange?.startDate.toString()),
          range_start: getDate(dateRange?.startDate.toString()),
          range_end: getDate(dateRange?.endDate.toString()),
          end: getDateStartEnd(dateRange?.endDate.toString()),
        }
      : {};

  const extraParams =
    extraParamsIds?.reduce((extraParams, id) => {
      const newValue = getValueFromListingFilter(id, filter);
      if (newValue != null) {
        extraParams[id] = newValue;
      }

      return extraParams;
    }, {}) || {};

  return {
    page,
    limit: items_per_page,
    items_per_page,
    ...rangeFilter,
    ...extraParams,
    ...(search_text && { query: search_text }),
    ...(search_text && { search_text }),
  };
};

/**
 * Get Legacy pagination response and transform it to V2 response (including headers manipulation)
 *
 * @template T
 * @param {T[]} data
 * @param {ApiMetaResponse} meta
 * @param {FetchRequest} payload
 * @return {*}
 */

export const transformLegacyPaginationResponse = <T>(
  data: T[],
  meta: ApiMetaResponse,
  payload: ApiListingRequest,
) => {
  const totalItems = Number(meta?.headers?.['x-total-count'] || 0);
  return getLegacyPaginatedResponse<T>(
    data,
    payload?.pagination?.current_page,
    +(payload?.pagination?.items_per_page || 1),
    totalItems,
  );
};

/**
 * Get last RTK-Query endpoint data by his request ID
 * * This helper is very useful to get the last request data (including from cache) if we have the requestID
 *
 * @param {RootState} state
 * @param {string} requestId
 * @return {*}
 */
export const getLastEndpointDataByRequestId = (state: RootState, requestId: string) => {
  const found = Object.keys(state.api.queries).filter((key) => {
    const current = state.api.queries[key];

    return current?.requestId === requestId;
  })[0];

  if (found) {
    return state.api.queries[found];
  }
};

/**
 * Append a new Array of filters to the filters object.
 * - If the filters object has no filters, this function sets the new filter as the plain filter object.
 * - If the filters object has a plain filter, this function converts it to an `operands` array and appends the new filter.
 * - If the filters object already has multiple filters (in the `operands` array), this function appends the new filter to the `operands`.
 *
 * @param {ApiListingRequest} filters - The main object that holds the filters
 * @param {Record<string, any>} newFilter - The new filter object Array to append to the filters object
 * @param {"and" | "or"} operator - The logical operator to use when combining filters ("and" or "or")
 * @returns {ApiListingRequest} - The updated filters object with the appended filter
 */
export const appendToFilters = (
  filters: ApiListingRequest,
  newFilters: Record<string, any>[],
  operator: 'and' | 'or' = 'and',
) => {
  const _newFilters = cloneDeep(filters);

  // Check if filters.filter object is null/undefined
  if (!_newFilters.filter) {
    // No filters exist in filters object, set newFilter as the plain filter object
    if (newFilters.length > 1) {
      _newFilters.filter = {
        operands: newFilters,
        operator: 'and',
      };
    } else {
      _newFilters.filter = newFilters[0];
    }
  } else if (!_newFilters.filter.operands) {
    // A plain filter exists in filters object, convert it to operands and add newFilter
    const existingFilter = _newFilters.filter;
    _newFilters.filter = {
      operator,
      operands: [existingFilter, ...newFilters],
    };
  } else {
    // Filters object already has an 'operands' array (multiple filters), so we just append newFilter to it
    _newFilters.filter.operands = [..._newFilters.filter.operands, ...newFilters];
  }

  // Return the updated filters object with the appended filter
  return _newFilters;
};

/**
 * Validate id handler used in some Table filters
 * ! See FILTER_VALIDATION_CONFIG configuration if you want to add more validations
 */
export const validateFilterById =
  (id: string) => async (value: ApiListingRequest | string | number) => {
    const { label, action } = FILTER_VALIDATION_CONFIG[id as keyof typeof FILTER_VALIDATION_CONFIG];

    try {
      const response = await store.dispatch(action.initiate(value)).unwrap();

      if (Array.isArray(response?.data) && !response?.data?.length) {
        throw new Error();
      }

      return null;
    } catch {
      let searchIdValue = value;
      /**
       * In Locations view, when filtering by ID, I must force id
       * value in order for it to appear in error text.
       */
      // Ensure value is an object before accessing its properties
      if (id === 'location' && typeof value === 'object' && 'filter' in value) {
        searchIdValue = value.filter?.operands?.[0]?.value ?? 'Unknown';
        return `• ${label} with ID ${searchIdValue} not found.`;
      }
      return `• ${label} with ID ${value} not found.`;
    }
  };
/**
 * Get a valid filters for a specific date range
 ** By default the startDate and endDate will be set for the recent 30 days
 *
 * @param {string} [property='created_at']
 * @param {*} [startDate=RECENT_DATE_RANGE.startDate?.toISOString()]
 * @param {*} [endDate=RECENT_DATE_RANGE.endDate?.toISOString()]
 */
export const getDateRangeFilter = (
  property = 'created_at',
  startDate = RECENT_DATE_RANGE.startDate?.toISOString(),
  endDate = RECENT_DATE_RANGE.endDate?.toISOString(),
) => ({
  filter: {
    operator: 'and',
    operands: [
      {
        property,
        operator: 'greater_than_or_equal',
        value: startDate,
      },
      {
        property,
        operator: 'less_than_or_equal',
        value: endDate,
      },
    ],
  },
});

/**
 * Locally paginate the data to simulate the endpoint pagination (for endpoints with missing pagination)
 *
 * @template T
 * @param {T[]} data
 * @param {ApiListingRequest} payload
 * @return {*}
 */
export const locallyPaginatedData = <T>(data: T[], payload?: ApiListingRequest) => {
  const { current_page, items_per_page } = payload?.pagination || DEFAULT_PAGINATION;

  const start = (current_page - 1) * items_per_page;
  const end = start + items_per_page;
  const paginatedData = data.slice(start, end);
  const totalPages = Math.ceil(data.length / items_per_page);

  const pagination = {
    total_items: data.length,
    total_pages: totalPages,
    current_page,
    items_per_page,
  };

  const sortBy = payload?.pagination?.sort_by;
  const sortAscending = payload?.pagination?.sort_ascending;
  if (sortBy) {
    paginatedData.sort((a, b) => {
      const valA = a[sortBy];
      const valB = b[sortBy];

      // Determine the sort order
      const sortOrder = sortAscending ? 1 : -1;

      // Check if the property is a number
      if (typeof valA === 'number' && typeof valB === 'number') {
        return (valA - valB) * sortOrder;
      }

      // Check if the property is a string
      if (typeof valA === 'string' && typeof valB === 'string') {
        return valA.localeCompare(valB) * sortOrder;
      }

      // Fallback for other types or mixed types
      return 0;
    });
  }

  return {
    data: paginatedData,
    meta: {
      pagination,
    },
  };
};
