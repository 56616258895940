import type { Geolocation } from '@/types';

let googleMapsFailed = false;

/**
 * Get { lat: number, lng: number } values from an input address (using google geocoder API)
 *
 * @param {string} address
 * @return {*}  {Promise<Geolocation>}
 */
export const getLocationByAddress = (address: string): Promise<Geolocation> => {
  return new Promise((resolve, reject) => {
    if (googleMapsFailed) {
      reject('Google maps failed to load!');
      return;
    }
    const w = window as any;
    try {
      //Hack to catch google maps auth error
      w.gm_authFailure = () => {
        googleMapsFailed = true;
        reject('Google maps failed to load!');
      };

      const geocoder = new w.google.maps.Geocoder();
      geocoder.geocode(
        { address },
        (results: { geometry: { location: any } }[], status: string) => {
          if (status.toLowerCase() !== 'ok' || !results[0]) {
            reject(status);
            return;
          }
          const location = results[0].geometry.location;

          resolve({
            lat: location.lat(),
            lng: location.lng(),
          });
        },
      );
    } catch (error) {
      reject(error);
    }
  });
};
