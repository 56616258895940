import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { usePlacesWidget } from 'react-google-autocomplete';

import { GOOGLE_ADDRESS_FIELDS } from '@/constants/app/addresses';

import type { AddressComponent } from '@bitstopco/bitstop-theme/components/form/AddressInput';
import type { TextFieldProps } from '@mui/material';

export type Place = {
  place_id?: string;
  street_address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
};

type AddressSearchProps = {
  onPlaceSelect?: (place: Place) => void;
} & TextFieldProps;

const getAddressPayload = (addr?: AddressComponent[]) => {
  const placeFields: Partial<Place> = {};
  GOOGLE_ADDRESS_FIELDS.forEach(({ name, values, format }) => {
    placeFields[name as keyof typeof placeFields] = values
      .map((value) => addr?.filter(({ types }) => types.indexOf(value) !== -1)[0]?.[format] ?? '')
      .join(' ')
      .trim();
  });
  return placeFields as unknown as Place;
};

export const AddressSearch = ({ onPlaceSelect, ...props }: AddressSearchProps) => {
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_MAPS_API_KEY ?? '',
    libraries: ['places'],
    options: {
      componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] },
      fields: ['address_components', 'place_id'],
      types: ['address'],
    },
    onPlaceSelected: ({ address_components, place_id }) => {
      onPlaceSelect?.({
        ...getAddressPayload(address_components),
        place_id,
      });
      if (ref.current) ref.current.value = '';
    },
  });
  return (
    <TextField
      inputRef={ref}
      placeholder=""
      label={'Location Address Lookup'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ pr: 1 }}>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
