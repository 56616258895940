import { useEffect, useState } from 'react';

import { Loader } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import {
  useGetTransactionImagesQuery,
  useGetTransactionQuery,
} from '@/store/api/services/transactions/transactions';

import usePathInfo from '@/hooks/usePathInfo';

import TransactionTabs from '@/components/transaction/tabs/TransactionTabs';
import TransactionTabsPanels from '@/components/transaction/tabs/TransactionTabsPanels';
import { PANELS_ROUTES } from '@/constants/app/transaction';
import type { TransactionImageSource } from '@/types';
import { TabContext } from '@mui/lab';

/**
 * Transaction details Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const TransactionDetailsMachine = ({ hide, props }: ModalChildrenProps) => {
  /**
   * Get the current pathname without the sale ID
   */
  const { pathWithoutParam } = usePathInfo();

  /**
   * Get the current Sale ID
   */
  const saleId = useState(props?.data?.id)[0];

  const hideUrlParams = useState(!!props?.hideUrlParams)[0];

  /**
   * If hideAtmValue is true we will need to hide the atm column (if the modal was shown from the machine view)
   */
  const hideAtmValue = useState(props?.data?.hideAtmValue)[0];
  const hideCustomerLink = useState(props?.data?.hideCustomerLink)[0];

  const [activeTab, setActiveTab] = useState(PANELS_ROUTES[0]);

  /**
   * Get the Transactions data (this is a standalone modal)
   */
  const {
    data: { data } = {},
    isLoading,
    error,
  } = useGetTransactionQuery(saleId);
  const { data: imagesData, isLoading: isLoadingImages } = useGetTransactionImagesQuery(saleId);

  const images: TransactionImageSource[] = imagesData?.data || [];

  useEffect(() => {
    if (error) {
      /**
       * If there is an error (loading the transaction data) we will show a toast notification and close this modal
       */
      toast.error('Transaction not found');
      hide();
    }
  }, [error]);

  useEffect(() => {
    if (!hideUrlParams) {
      /**
       * We need to clean the filtering values from the previous listing page
       */
      const query = new URLSearchParams(window.location.search);
      window.history.replaceState(null, '', `${pathWithoutParam}/${saleId}`);
      return () => {
        /**
         * If we unmount this modal we will want to put the URL back as before
         */
        window.history.replaceState(null, '', `${pathWithoutParam}?${query.toString()}`);
      };
    }
  }, []);

  const handleLocation = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <DialogContent
        sx={{ my: 2, maxWidth: 552, width: '100vw' }}
        data-test="transaction-details-modal"
      >
        {isLoading || isLoadingImages ? (
          <Loader />
        ) : (
          <>
            <TabContext value={activeTab}>
              <TransactionTabs onChange={handleLocation} />
              <TransactionTabsPanels
                transaction={data!}
                images={images}
                hideAtmValue={hideAtmValue}
                hideCustomerLink={hideCustomerLink}
                hide={hide}
              />
            </TabContext>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={hide}>Back to sales</Button>
      </DialogActions>
    </>
  );
};

export default TransactionDetailsMachine;
