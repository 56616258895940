export const NOTIFICATIONS_SETTINGS = [
  {
    id: 'customers',
    title: 'Customers',
    validations: [
      { id: 'shouldSendToEmail', title: 'Email' },
      { id: 'shouldSendToSms', title: 'SMS' },
    ],
  },
  {
    id: 'machines',
    title: 'Machines',
    validations: [
      { id: 'shouldSendToEmail', title: 'Email' },
      { id: 'shouldSendToSms', title: 'SMS' },
    ],
  },
  {
    id: 'transactions',
    title: 'Transactions',
    validations: [
      { id: 'shouldSendToEmail', title: 'Email' },
      { id: 'shouldSendToSms', title: 'SMS' },
    ],
  },
  /*{
    id: 'wallet',
    title: 'Wallets',
    validations: [
      { id: 'shouldSendToEmail', title: 'Email' },
      { id: 'shouldSendToSms', title: 'SMS' },
    ],
  },*/
];

export const NOTIFICATIONS_ITEMS_PER_PAGE = 10;
