import { useEffect } from 'react';

import type { SkipToken } from '@reduxjs/toolkit/query';

import type { ApiExportRequest, ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import type { ChipProps } from '@mui/material/Chip';
import startCase from 'lodash/startCase';

import { useAppDispatch, useAppSelector } from '@/store';
import api from '@/store/api';
import { athena } from '@/store/api/instances';
import { actions } from '@/store/reducers/modules/common/persistence';
import { selectLastGetMachines } from '@/store/reducers/modules/common/persistence/selectors';

import type { Machine, MachineSeverities, MachineSeveritiesModel, MachinesStats } from '@/types';

/**
 * Machines RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const machinesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMachines: build.query<ApiResponse<Machine[]>, ApiListingRequest>({
      query: (data) =>
        athena({
          url: 'v2/atms',
          method: 'POST',
          data,
        }),
      providesTags: (result) => {
        const data = result?.data || [];

        return [
          ...data.map(({ id }) => ({ type: 'Machines', id }) as const),
          { type: 'Machines' as const, id: 'LIST' },
        ];
      },
    }),
    getMachinesStats: build.query<ApiResponse<MachinesStats>, void>({
      query: () => {
        return athena({ url: 'v2/atms/status/summary', method: 'GET' });
      },
    }),
    getMachinesCashTotal: build.query<ApiResponse<{ total_cash: number }>, void>({
      query: () => athena({ url: 'atms/cash-total', method: 'GET' }),
    }),
    getMachinesSeverities: build.query<MachineSeverities, void>({
      query: () =>
        athena({
          url: 'v1/resources/machine_status_severity',
          method: 'GET',
        }),
      transformResponse: ({ data }: ApiResponse<MachineSeveritiesModel>) => {
        try {
          return Object.keys(data).map((key) => ({
            key,
            label: startCase(key),
            color: data[key as keyof typeof data].severity as ChipProps['color'],
          }));
        } catch {
          return [];
        }
      },
    }),
    getMachinesExport: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data }) =>
        athena({
          url: `v2/atms/${type}`,
          method: 'POST',
          data,
        }),
    }),
  }),
});

export const {
  useGetMachinesQuery,
  useGetMachinesStatsQuery,
  useGetMachinesCashTotalQuery,
  useGetMachinesSeveritiesQuery,

  useLazyGetMachinesQuery,

  useGetMachinesExportMutation,
} = machinesApi;

export const {
  endpoints: { getMachines, getMachinesStats, getMachinesCashTotal, getMachinesSeverities },
} = machinesApi;

/**
 * useGetMachines hook, we want to use this custom hook if we want to persist the requestId
 * * The requestId is useful if we want to know the last query data requested (even from cache)
 *
 * @returns ApiResponse<Machine[]>
 */
export const useGetMachines = (payload: ApiListingRequest | SkipToken) => {
  const dispatch = useAppDispatch();
  const response = useGetMachinesQuery(payload);

  useEffect(() => {
    dispatch(actions.setLastGetMachinesId(response?.requestId));
  }, [response]);

  return response;
};

/**
 * Get last machines query data (including cached data)
 *
 * @returns ApiResponse<Machine[]>
 */
export const useGetLastMachines = () => {
  const data = useAppSelector(selectLastGetMachines);

  return data;
};
