import { Card } from '@mui/material';
import type { CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * Simple Styled Card used to wrap the table components
 */
const CardTable = styled(Card)<CardProps>(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(2),
}));

export default CardTable;
