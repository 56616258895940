import { useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import { UsersRoleColumn } from '@/components/users/tableColumns/UsersRoleColumn';

import { useGetUserRolesQuery, usePutEditUserRoleMutation } from '@/store/api/services/users/users';

import SCHEMAS from '@/forms/schemas';

import type { EditUserRoleModel } from '@/types';

/**
 * User Edit Role Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const UserEditRole = ({ hide, props }: ModalChildrenProps) => {
  const user = useState(props)[0];

  const { data = [] } = useGetUserRolesQuery();
  const [editRole] = usePutEditUserRoleMutation();

  const baseSchema = useMemo(() => {
    const schema = cloneDeep(SCHEMAS.EDIT_USER_ROLE);
    schema.properties.role.uniforms.options = data
      .filter(({ id }) => id !== user?.role_id)
      .map(({ id, name }) => ({
        key: id,
        value: id,
        label: name,
      }));

    return schema;
  }, [data, user]);

  const { schema } = useForm(baseSchema);

  const handleSubmit = async ({ role }: EditUserRoleModel) => {
    try {
      await editRole({
        id: user.id,
        role_ids: [role],
      }).unwrap();

      toast.success('Role updated successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<EditUserRoleModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }} data-test="edit-user-role-modal">
        <Typography variant="h6">Current Role</Typography>
        <UsersRoleColumn value={user?.role_id} />

        <Box mt={3}>
          <AutoFields />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField data-test="edit-user-role-modal-submit-btn">Edit Role</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default UserEditRole;
