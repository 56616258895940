import { type ModalChildrenProps, useModal } from '@bitstopco/bitstop-theme';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

const EditLocationPrompt = ({ hide, props }: ModalChildrenProps) => {
  const { show } = useModal();
  const proceed = async () => {
    hide(); // Close the modal
    show('location-edit', { ...props });
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">
          Are you sure you want to make changes to this location?
        </Typography>
        <Typography mt={1} variant="body1">
          Doing so will unpublish the location from all Bistop Maps. You can re-publish the location
          after editing the details.
        </Typography>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => {
            hide();
          }}
          data-test="remove-match-back-button"
        >
          Back to Locations
        </Button>
        <LoadingButton variant="contained" onClick={proceed} data-test="remove-match-submit-button">
          Yes, Edit Location
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditLocationPrompt;
