import { useMemo, useState } from 'react';

import { Loader, useModal } from '@bitstopco/bitstop-theme';

import { Box, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

import useInfiniteScrollNextFix from '@/hooks/useInfiniteScrollNextFix';
import {
  useAcknowledgeAllNotificationsMutation,
  useGetNotificationsCountByCategoryQuery,
  useNotifications,
} from '@/store/api/services/notifications/notifications';

import type { NotificationsCountByCategory } from '@/types';

import NotificationsTabFooter from './NotificationsTabFooter';
import NotificationsList from './list/NotificationsList';
import NotificationsListPlaceholder from './list/NotificationsListPlaceholder';

interface NotificationsTabBaseProps {
  category: 'machines' | 'customers' | 'transactions';
}

/**
 * Notifications Tab content component
 *
 * @return {*}
 */

const NotificationsTabContent = ({ category }: NotificationsTabBaseProps) => {
  const [switchValue, setSwitchValue] = useState(false);

  const { show } = useModal();

  const { notifications, page, setPage, hasMore, reset, isFetchingNotifications } =
    useNotifications({
      category,
      unread: switchValue,
    });

  const {
    data: { data: dataCount } = {
      data: [{ category, unread: 0 }] as NotificationsCountByCategory[],
    },
  } = useGetNotificationsCountByCategoryQuery();

  const [markAllAsRead, { isLoading: isLoadingMarkAllAsRead }] =
    useAcknowledgeAllNotificationsMutation();

  const isLoading = (isFetchingNotifications && !notifications.length) || isLoadingMarkAllAsRead;

  // Get the unread count for this category
  const unreadCount = useMemo(
    () => dataCount.find(({ category: dataCategory }) => dataCategory === category)?.unread || 0,
    [dataCount],
  );

  // Computed title text
  const titleText = unreadCount ? `${unreadCount} unread notifications` : 'Past notifications';

  const handleSwitch = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSwitchValue(checked);
  };

  const handleFetchMore = () => {
    setPage((page) => page + 1);
  };

  const handleSettings = () => {
    show('notifications-settings');
  };

  const handleMarkAsRead = () => {
    markAllAsRead();
    reset();
  };

  useInfiniteScrollNextFix({
    hasMoreItems: hasMore,
    fetchMoreItems: handleFetchMore,
    isLoading: isFetchingNotifications,
  });

  return (
    <Box display="flex" flexDirection="column" height="100%" data-test="notifications-content">
      <Stack
        mt={2}
        px={4}
        flex="0 1 auto"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="overline"
          color="text.disabled"
          data-test="notifications-tab-content-title"
        >
          {titleText}
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={<Switch checked={switchValue} onChange={handleSwitch} />}
            label="Only show unread"
            data-test="notifications-tab-content-only-unread-switch"
          />
        </FormGroup>
      </Stack>

      {isLoading && <Loader />}

      {!notifications.length && !isLoading && <NotificationsListPlaceholder />}

      <Box
        id="scrollableDiv"
        sx={{ flex: '1 1 auto', overflowY: 'auto', minHeight: 0 }}
        data-test="notifications-tab-content-scroll"
      >
        {!!notifications.length && !isLoading && (
          <InfiniteScroll
            dataLength={notifications?.length || 0}
            next={handleFetchMore}
            hasMore={hasMore}
            loader={
              <Box
                py={2}
                sx={{
                  '.MuiCircularProgress-root': {
                    width: '16px !important',
                    height: '16px !important',
                  },
                }}
              >
                <Loader />
              </Box>
            }
            endMessage={
              <Typography
                variant="caption"
                textAlign="center"
                my={2}
                display={page === 1 ? 'none' : 'block'}
              >
                No more notifications
              </Typography>
            }
            scrollableTarget="scrollableDiv"
          >
            <NotificationsList data={notifications} />
          </InfiniteScroll>
        )}
      </Box>

      <NotificationsTabFooter
        isLoading={isLoadingMarkAllAsRead}
        onClickSettings={handleSettings}
        onClickMarkAllRead={handleMarkAsRead}
      />
    </Box>
  );
};

export default NotificationsTabContent;
