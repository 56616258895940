import { Loader, PermissionsRestrictedView } from '@bitstopco/bitstop-theme';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import type { BoxProps } from '@mui/material';

import type { OperatorSettings } from 'types/operatorSettings';

interface BatchingSettingsSectionProps extends BoxProps {
  isLoading: boolean;
  operatorSettings?: OperatorSettings;
  onClickChangeSettings: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const BatchingSettingsSection = ({
  isLoading,
  operatorSettings,
  onClickChangeSettings,
  ...props
}: BatchingSettingsSectionProps) => (
  <Box {...props}>
    {isLoading ? (
      <Loader size={18} />
    ) : (
      <Grid container spacing={1} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6" mb={1}>
            Batch settings
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 2 }}>
            <Typography color="neutral.600" variant="caption">
              All transactions under
              <Typography color="primary" variant="caption">
                {' '}
                <strong data-test="batching_minimum_usd">
                  ${operatorSettings?.batching_minimum_usd}
                </strong>
              </Typography>
            </Typography>
            <Typography variant="caption" color="neutral.300" display={{ xs: 'none', sm: 'block' }}>
              •
            </Typography>
            <Typography color="neutral.600" variant="caption">
              Batch every
              <Typography color="primary" variant="caption">
                {' '}
                <strong>30 MINUTES</strong>
              </Typography>
            </Typography>
          </Stack>
        </Grid>
        <PermissionsRestrictedView to="operator_settings.modify">
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              onClick={onClickChangeSettings}
              data-test="change_settings_btn"
            >
              Change settings
            </Button>
          </Grid>
        </PermissionsRestrictedView>
      </Grid>
    )}
  </Box>
);

export default BatchingSettingsSection;
