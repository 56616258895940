import type { JSONSchemaType } from 'ajv';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';

import type { SubmitFieldProps } from '@bitstopco/bitstop-theme/forms/components/SubmitField';

import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import type { CustomerNoteModel } from '@/types';

interface CustomerReasonFormProps<T> {
  onBack: () => void;
  onSubmit: (data: CustomerNoteModel) => void;
  schema: JSONSchemaType<T>;

  title?: string;
  content?: string;
  subtitle?: string;
  submitText?: string;
  isLoading?: boolean;
  submitColor?: SubmitFieldProps['color'];
}

const CustomerReasonForm = <T = unknown>({
  title,
  schema,
  content,
  subtitle,
  isLoading,
  submitColor,

  onBack,
  onSubmit,

  submitText = 'send',
}: CustomerReasonFormProps<T>) => {
  const { schema: bridge } = useForm(schema);

  return (
    <AutoForm<CustomerNoteModel> showInlineError schema={bridge} onSubmit={onSubmit}>
      <DialogContent sx={{ my: 2 }}>
        {title && (
          <Typography variant="h6" mb={1}>
            {title}
          </Typography>
        )}

        {subtitle && (
          <Typography variant="body1" mb={2}>
            {subtitle}
          </Typography>
        )}

        {content && (
          <Box mt={1} mb={2}>
            <Typography variant="caption">Notification for customer:</Typography>

            <Typography
              variant="body2"
              sx={{ p: 2, backgroundColor: 'background.gray', borderRadius: '6px' }}
            >
              {content}
            </Typography>
          </Box>
        )}

        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onBack}>Back</Button>
        <SubmitField variant="contained" color={submitColor} disabled={isLoading}>
          {submitText}
        </SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default CustomerReasonForm;
