import type { PayloadAction } from '@reduxjs/toolkit';

import type { PersistenceState } from './state/types';

export const _setLastGetLocationId = (
  state: PersistenceState,
  { payload }: PayloadAction<string | undefined>,
) => {
  state.lastGetLocationId = payload;
};
export const _setLastGetLocationsId = (
  state: PersistenceState,
  { payload }: PayloadAction<string | undefined>,
) => {
  state.lastGetLocationsId = payload;
};

export const _setLastGetMachineId = (
  state: PersistenceState,
  { payload }: PayloadAction<string | undefined>,
) => {
  state.lastGetMachineId = payload;
};

export const _setLastGetMachinesId = (
  state: PersistenceState,
  { payload }: PayloadAction<string | undefined>,
) => {
  state.lastGetMachinesId = payload;
};
