import TimelineDotBase from '@mui/lab/TimelineDot';
import type { TimelineDotProps } from '@mui/lab/TimelineDot';

const TimelineDot = (props: TimelineDotProps) => (
  <TimelineDotBase
    {...props}
    sx={{
      m: 0,
      p: '2px',
      boxShadow: 'none',
      background: 'white',
      border: '2px solid rgba(225, 227, 234, 0.9)',
      ...props.sx,
    }}
  />
);

export default TimelineDot;
