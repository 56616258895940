import type { ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { TransactionsBatch } from '@/types';

/**
 * Transactions Cash Logs RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const cashlogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBatches: build.query<ApiResponse<TransactionsBatch[]>, ApiListingRequest>({
      query: (data) =>
        athena({
          url: 'v2/batches',
          method: 'POST',
          data,
        }),
      providesTags: (response) => {
        const result = response?.data || [];
        return [
          ...result.map(({ id }) => ({ type: 'TransactionsBatches', id }) as const),
          { type: 'TransactionsBatches' as const, id: 'LIST' },
        ];
      },
    }),
  }),
});

export const { useGetBatchesQuery } = cashlogsApi;

export const {
  endpoints: { getBatches },
} = cashlogsApi;
