import { createSlice } from '@reduxjs/toolkit';

import { walletApi } from '@/store/api/services/wallet/wallet';

import initialState from './state';

export const walletSlice = createSlice({
  name: 'pages/wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      walletApi.endpoints.generateAddresses.matchFulfilled,
      (state, { payload }) => {
        state.addresses = [...state.addresses, ...payload];
      },
    );
  },
});

export const actions = walletSlice.actions;
export default walletSlice.reducer;
