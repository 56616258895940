import { lazy } from 'react';

import { ClickableText, TableColumnStatus } from '@bitstopco/bitstop-theme';
import { ONE_LINE_ELLIPSIS } from '@bitstopco/bitstop-theme/constants/styles';
import { formatCurrency, formatCurrencyBySymbol } from '@bitstopco/bitstop-theme/helpers';

import type { Columns, TableColumnSaleStatusCodes } from '@bitstopco/bitstop-theme';

import StopIcon from '@mui/icons-material/Stop';
import { HiCheckCircle, HiIdentification, HiPause } from 'react-icons/hi';
import { MdBlock, MdLink } from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';

import CustomerActionIcon from '@/components/customer/CustomerActionIcon';
import CustomerAddress from '@/components/customer/CustomerAddress';
import CustomerManualVerification from '@/components/customer/tabs/manualVerification/CustomerManualVerification';
import CustomerTransactions from '@/components/customer/tabs/transactions/CustomerTransactions';
import BoxCircle from '@/components/ui/BoxCircle';
import TimelineDot from '@/components/ui/TimelineDot';

import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableColumnMachine from '@/components/ui/table/TableColumnMachine';
import TableHeaderTooltipIcon from '@/components/ui/table/TableHeaderDateTooltipIcon';

import { parsePhoneNumber } from '@/helpers/phone';

import theme from '@/helpers/theme';

import type { Customer, Machine, TransactionsSale } from '@/types';

import { DATE_FORMATS } from '../date';
import { TRANSACTIONS_FILTERS_CONFIG } from '../transactions';

const CustomerKyc = lazy(() => import('@/components/customer/tabs/kyc/CustomerKyc'));
const CustomerNotes = lazy(() => import('@/components/customer/tabs/notes/CustomerNotes'));
const CustomerLinkedAccounts = lazy(
  () => import('@/components/customer/tabs/linkedAccounts/CustomerLinkedAccounts'),
);

const {
  palette: { error, success },
} = theme;

export const PANELS = ['Sales', 'KYC', 'Manual Verification', 'Notes', 'Linked Accounts'];
export const PANELS_ROUTES = [
  '/customer/:id/sales',
  '/customer/:id/kyc',
  '/customer/:id/manual-verification',
  '/customer/:id/notes',
  '/customer/:id/linked-accounts',
];
export const PANELS_COMPONENTS = [
  CustomerTransactions,
  CustomerKyc,
  CustomerManualVerification,
  CustomerNotes,
  CustomerLinkedAccounts,
];

/**
 * Customer details base (without atm data) Table configuration: this configuration will be used to generate all the Table columns with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const CUSTOMER_DETAILS_BASE: Columns<Customer> = {
  defaultValue: '-',
  valueSx: {
    textAlign: 'right',
  },
  items: [
    {
      label: 'First Name',
      value: 'first_name',
    },
    {
      label: 'Last Name',
      value: 'last_name',
    },
    {
      label: 'Phone Number',
      value: 'phone_number',
      valueMapper: (phone: Customer['phone_number']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
    },
    {
      label: 'ID Number',
      value: 'id_number',
    },
    {
      label: 'Date of Birth',
      value: 'date_of_birth',
      valueMapper: (value: Customer['date_of_birth']) =>
        value && (
          <TableColumnDate
            value={value}
            format={DATE_FORMATS.simple}
            options={{
              timeZone: 'UTC',
            }}
            variant="subtitle2"
          />
        ),
    },
    {
      label: 'Age',
      value: 'age',
      valueMapper: (value: Customer['age']) => value && `${value} years old`,
    },
    {
      label: 'Address',
      value: 'address',
      valueMapper: (value: Customer['address'], data) =>
        value && <CustomerAddress customer={data} />,
    },
  ],
};

/**
 * Customer details Table configuration: this configuration will be used to generate all the Table columns with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const CUSTOMER_DETAILS: Columns<Customer> = {
  defaultValue: '-',
  valueSx: {
    textAlign: 'right',
  },
  items: [
    ...CUSTOMER_DETAILS_BASE.items,
    {
      label: 'Enrollment Machine',
      value: 'enrollment_machine',
      valueMapper: (value: Customer['enrollment_atm']) =>
        value && (
          <TableColumnMachine
            link
            fixIcon
            data={{ name: value?.name, id: value?.atm_id } as Machine}
          />
        ),
      labelSx: {
        paddingRight: 4,
      },
      valueSx: {
        '& h6': ONE_LINE_ELLIPSIS,
      },
    },
    {
      label: 'Enrollment Date',
      value: 'enrolled_on',
      valueMapper: (value: Customer['enrolled_on']) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
          />
        ),
    },
    {
      label: 'Total Transactions',
      value: 'total_transactions',
      valueMapper: (value: number) => value.toString(),
    },
    {
      label: 'Total Cash Inserted',
      value: 'total_sales',
      valueMapper: (value: number) => formatCurrency(value),
    },
    {
      label: 'Total BTC Purchased',
      value: 'total_sent',
      valueMapper: (value: number) => formatCurrency(value),
    },
    {
      label: 'Total BTC Sent',
      value: 'total_btc',
      valueMapper: (value: number) => `${value} BTC`,
    },
  ],
};

/**
 * Customers filters configuration
 * * CUSTOMERS_FILTERS_CONFIG: Table filters configuration. You can learn more about the Theme Table filters here:
 * * https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const CUSTOMERS_FILTERS_CONFIG = TRANSACTIONS_FILTERS_CONFIG.filter(
  ({ property }) => property !== 'customer_id',
);

export const CUSTOMER_INIT_VALUES: Partial<Customer> = {
  id: 0,
  first_name: '',
  last_name: '',
};

export const MANUAL_VERIFICATION = {
  satisfied_true: {
    text: 'Manual review was <strong>accepted</strong> on ',
    icon: (
      <TimelineDot>
        <HiCheckCircle color={success.main} size={20} />
      </TimelineDot>
    ),
  },
  satisfied_false: {
    text: 'Manual review was <strong>rejected</strong> on ',
    icon: (
      <TimelineDot>
        <RiCloseCircleFill color={error.main} size={20} />
      </TimelineDot>
    ),
  },
  manual_approval: {
    icon: (
      <BoxCircle sx={{ background: 'rgba(225, 227, 234, 0.9)' }}>
        <StopIcon sx={{ color: 'error.main', fontSize: 14 }} />
      </BoxCircle>
    ),
    text: 'Manually verify ${first_name} ${last_name}.',
  },
};

export const CUSTOMER_SALES_COLUMNS: Columns<TransactionsSale> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Sale ID',
      valueMapper: (value: TransactionsSale['id']) =>
        value && (
          <ClickableText
            text={value}
            clipboardValue={value}
            truncate={{ twoSegments: true }}
            name="Sale ID"
          />
        ),
    },
    {
      value: 'created_at',
      label: 'Date',
      labelMapper: (value: string) => <TableHeaderTooltipIcon label={value} />,
      valueMapper: (value: TransactionsSale['created_at'], { local_created_at, local_time_zone }) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
            auxiliaryDate={{
              value: local_created_at,
              options: {
                timeZone: local_time_zone,
                normalizeAsUTC: false,
                performUtcToZonedTime: false,
              },
            }}
          />
        ),
      valueSx: {
        whiteSpace: 'nowrap',
      },
    },
    {
      value: 'atm',
      label: 'Machine',
      valueMapper: (value: TransactionsSale['atm']) => {
        const text = value?.name || value?.machine_id || '';

        return (
          <ClickableText
            name="Machine ID"
            text={text}
            clipboardValue={value.atm_id.toString()}
            truncate={{
              maxLength: 32,
            }}
            to={`${window.location.origin}/machine/${value?.atm_id}`}
            overrideTooltipLinkText="Go to Machine"
          />
        );
      },
    },
    {
      value: 'inserted',
      label: 'Inserted',
      valueMapper: (value: TransactionsSale['inserted'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'stage',
      label: 'Status',
      valueMapper: (value: TransactionsSale['stage']) =>
        value && <TableColumnStatus value={value as TableColumnSaleStatusCodes} />,
      valueSx: {
        textAlign: 'center',
        pr: 5,
      },
    },
  ],
};

/**
 * Mapping for customer action button items by customer status
 */
export const CUSTOMER_ACTIONS_BY_STATUS = {
  none: ['scanId', 'suspend', 'linkAccount', 'ban'],
  cleared: ['scanId', 'suspend', 'linkAccount', 'ban'],
  scan_id: ['suspend', 'linkAccount', 'ban'],
  banned: ['scanId', 'unban', 'linkAccount'],
  unbanned: ['scanId', 'suspend', 'linkAccount', 'ban'],
  admin_unban: ['scanId', 'suspend', 'linkAccount', 'ban'],
  suspended: ['scanId', 'unban', 'linkAccount', 'ban'],
};

/**
 * Customer valid actions
 */
export const CUSTOMER_ACTIONS = {
  scanId: {
    value: 'scan_id',
    label: 'Request Scan ID',
    icon: <CustomerActionIcon icon={HiIdentification} />,
  },
  ban: {
    value: 'ban',
    label: 'Ban Customer',
    icon: <CustomerActionIcon icon={MdBlock} />,
  },
  suspend: {
    value: 'suspend',
    label: 'Suspend Customer',
    icon: <CustomerActionIcon icon={HiPause} />,
  },
  unban: {
    value: 'unban',
    label: 'Reinstate Customer',
    icon: <CustomerActionIcon icon={HiCheckCircle} />,
  },
  linkAccount: {
    value: 'linkAccount',
    label: 'Link Account',
    icon: <CustomerActionIcon icon={MdLink} />,
  },
};

/**
 * Customer valid note type his behavior impact the status
 */
export const CUSTOMER_VALID_NOTE_TYPE_AS_STATUS = ['admin_ban', 'admin_unban', 'ofac'];

export const RESENDABLE_INQUIRY_BY_STATUSES = ['failed', 'expired'];
