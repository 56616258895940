import type { ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import { getLegacyPaginatedPayload, transformLegacyPaginationResponse } from '@/helpers/api';

import api from '@/store/api';
import { bitgo, ergot, walletProvider } from '@/store/api/instances';

import type { ApiMetaResponse, WalletTransaction } from '@/types';

/**
 * Wallets RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const walletApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWalletTransactions: build.query<ApiResponse<WalletTransaction[]>, ApiListingRequest>({
      query: (params) =>
        ergot({
          params: getLegacyPaginatedPayload(params),
          url: 'wallet/transactions',
        }),
      providesTags: ['WalletTransactions'],
      transformResponse: (baseQueryReturnValue: WalletTransaction[], meta: ApiMetaResponse, arg) =>
        transformLegacyPaginationResponse<WalletTransaction>(baseQueryReturnValue, meta, arg),
    }),
    getBitgoConfiguration: build.query<ApiResponse<any[]>, void>({
      query: () =>
        bitgo({
          url: 'configuration',
        }),
      providesTags: ['WalletBitgoConfiguration'],
    }),
    getOperatorBitgoApiKeyConfig: build.query<ApiResponse<{ api_key: string }>, void>({
      query: () =>
        walletProvider({
          url: 'v1/operatorProviders/bitgo/configuration',
        }),
    }),
    updateOperatorBitgoApiKeyConfig: build.mutation<
      ApiResponse<{
        api_key: string;
      }>,
      string
    >({
      query: (api_key) =>
        walletProvider({
          data: {
            provider_id: 'bitgo',
            configuration: {
              api_key,
            },
          },
          url: 'v1/OperatorProviders/Configuration',
          method: 'PUT',
        }),
    }),
    addOperatorBitgoApiKeyConfig: build.mutation<
      ApiResponse<{
        api_key: string;
      }>,
      string
    >({
      query: (api_key) =>
        walletProvider({
          data: {
            provider_id: 'bitgo',
            configuration: {
              api_key,
            },
          },
          url: 'v1/OperatorProviders/Configuration',
          method: 'POST',
        }),
    }),
    createBitgoWalletConfig: build.mutation<
      void,
      {
        wallet_id: string;
        coin_type: string;
        friendly_name: string;
        wallet_password: string;
        low_balance_threshold: number;
      }
    >({
      query: (configuration) =>
        walletProvider({
          data: {
            provider_id: 'bitgo',
            configuration,
          },
          url: 'v1/Wallets/Configuration',
          method: 'POST',
        }),
      invalidatesTags: ['WalletBitgoConfiguration'],
    }),
    generateAddresses: build.mutation<string[], number>({
      query: (limit) =>
        ergot({
          params: {
            limit,
          },
          url: 'wallet/addresses',
          showError: true,
        }),
    }),
  }),
});

export const {
  // Queries
  useGetWalletTransactionsQuery,
  useGetBitgoConfigurationQuery,
  useGetOperatorBitgoApiKeyConfigQuery,
  useLazyGetOperatorBitgoApiKeyConfigQuery,

  // Mutations
  useUpdateOperatorBitgoApiKeyConfigMutation,
  useAddOperatorBitgoApiKeyConfigMutation,
  useCreateBitgoWalletConfigMutation,
  useGenerateAddressesMutation,
} = walletApi;

export const {
  endpoints: {
    getWalletTransactions,
    updateOperatorBitgoApiKeyConfig,
    addOperatorBitgoApiKeyConfig,
    createBitgoWalletConfig,
    generateAddresses,
  },
} = walletApi;
