import { formatCurrency } from '@bitstopco/bitstop-theme/helpers';

import api from '@/store/api';

import type { PrecioResponse } from '@/types';

/**
 * Precio RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 * ! For the precio webhook implementation we are using the custom query method `queryfn`
 * ! for more information see
 * ! https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-queryfn
 */
export const precioApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPrecio: build.query<PrecioResponse, void>({
      //Return default precio value
      queryFn: () => ({ data: { value: 0, formattedValue: '' } }),
      onCacheEntryAdded: async (_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        // create a websocket connection when the cache subscription starts
        const ws = new WebSocket(`${import.meta.env.VITE_PRECIO_WSS_URI}`);

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          const listener = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            const value = data?.C_RAW || 0;
            const formattedValue = formatCurrency(value, '');

            updateCachedData((draft) => {
              draft.value = value;
              draft.formattedValue = formattedValue;
            });
          };

          ws.addEventListener('message', listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }

        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;

        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
    }),
  }),
});

export const { useGetPrecioQuery } = precioApi;

export const {
  endpoints: { getPrecio },
} = precioApi;
