import NotificationsCustomers from '@/components/notifications/tabs/customers/NotificationsCustomers';
import NotificationsMachines from '@/components/notifications/tabs/machines/NotificationsMachines';
import NotificationsTransactions from '@/components/notifications/tabs/transactions/NotificationsTransactions';

/**
 * Notifications Panels configuration (Tabs)
 * * PANELS: tab names
 * * PANELS_ROUTES: tab routes
 * * PANELS_COMPONENTS: tab related components
 */
export const PANELS = ['Customers', 'Machines', 'Transactions'];
export const PANELS_IDS = ['customers', 'Machines', 'Transactions'];
export const PANELS_ROUTES = ['customers', 'machines', 'transactions'];
export const PANELS_COMPONENTS = [
  NotificationsCustomers,
  NotificationsMachines,
  NotificationsTransactions,
];
