import api from '@/store/api';

import { heartbeat } from '@/store/api/instances';
import type {
  MachineMonitoringListParams,
  MachineMonitoringResponse,
} from '@/types/generated/heartbeat-api';
import type { ApiResponse } from '@bitstopco/bitstop-theme';

/**
 * Machines Monitoring RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const monitoringApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMachineMonitoring: build.query<
      ApiResponse<MachineMonitoringResponse[]>,
      MachineMonitoringListParams
    >({
      query: (params) =>
        heartbeat({
          url: '/v1/machine-monitoring',
          method: 'GET',
          params,
        }),
    }),
  }),
});

export const { useGetMachineMonitoringQuery } = monitoringApi;

export const {
  endpoints: { getMachineMonitoring },
} = monitoringApi;
