import { useMemo } from 'react';

import merge from 'lodash/merge';

import type { SxProps } from '@mui/material';

import { List } from '@bitstopco/bitstop-theme';
import type { ListProps } from '@bitstopco/bitstop-theme';

const StyledList = <T,>({
  lineColor = 'neutral.100',
  listPropsSx,
  ...props
}: ListProps<T> & { lineColor?: string; listPropsSx?: SxProps }) => {
  const sx = useMemo(() => {
    const baseStyles: SxProps = {
      py: 0,
      '& li': {
        px: 2,
        '& > div > div': {
          color: 'inherit',
          '&:nth-of-type(1)': {
            color: 'text.secondary',
          },
          '&:nth-of-type(2)': {
            fontWeight: 500,
            textAlign: 'right',
          },
        },
      },
      '& li:nth-of-type(even)': {
        backgroundColor: lineColor,
      },
    };

    return merge(baseStyles, listPropsSx);
  }, [lineColor, listPropsSx]);

  return (
    <List
      {...props}
      listProps={{
        sx,
      }}
    />
  );
};

export default StyledList;
