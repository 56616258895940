import { CONSTANTS, ClickableText, TableColumnStatus } from '@bitstopco/bitstop-theme';

import { ONE_LINE_ELLIPSIS } from '@bitstopco/bitstop-theme/constants/styles';
import { formatCurrency, formatCurrencyBySymbol } from '@bitstopco/bitstop-theme/helpers';

import type {
  Columns,
  TableColumnSaleStatusCodes,
  TableToolbarFilterConfig,
} from '@bitstopco/bitstop-theme';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

import TransactionsBatchingStatus from '@/components/transactions/tabs/batching/tableColumns/TransactionsBatchingStatus';
import TransactionsTransactions from '@/components/transactions/tabs/sales/TransactionsSales';
import TransactionsSaleSent from '@/components/transactions/tabs/sales/tableColumns/TransactionsSaleSent';
import TransactionsSaleType from '@/components/transactions/tabs/sales/tableColumns/TransactionsSaleType';

import TableColumnBlockstream from '@/components/ui/table/TableColumnBlockstream';
import TableColumnCustomer from '@/components/ui/table/TableColumnCustomer';
import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableColumnMachine from '@/components/ui/table/TableColumnMachine';

import TransactionsBatching from '@/components/transactions/tabs/batching/TransactionsBatching';
import { validateFilterById } from '@/helpers/api';

import type {
  Customer,
  Machine,
  TransactionDetail,
  TransactionsBatch,
  TransactionsSale,
  TransactionsStats,
} from '@/types';

import TransactionDetailsStatus from '@/components/transactions/TransactionDetailsStatus';
import TableHeaderTooltipIcon from '@/components/ui/table/TableHeaderDateTooltipIcon';

import { DATE_FORMATS, LOCAL_TIME_LEGEND } from './date';

export const PANELS = ['Sales', 'Batching'];
export const PANELS_ROUTES = ['/sales/transactions', '/sales/batching'];
export const PANELS_COMPONENTS = [TransactionsTransactions, TransactionsBatching];

export const BATCHING_STATUSES = {
  yes: {
    Icon: CheckCircleIcon,
    color: 'success.main',
  },
  no: {
    Icon: CheckCircleIcon,
    color: 'warning.main',
  },
};

export const SALES_COLUMNS: Columns<TransactionsSale> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Sale ID',
      hideSort: true,
      valueMapper: (value: TransactionsSale['id']) =>
        value && (
          <ClickableText
            text={value}
            clipboardValue={value}
            name="Sales ID"
            truncate={{
              twoSegments: true,
            }}
          />
        ),
    },
    {
      value: 'transaction_hash',
      label: 'TX Hash',
      hideSort: true,
      valueMapper: (value: TransactionsSale['transaction_hash']) =>
        value && (
          <TableColumnBlockstream
            name="TX Hash"
            to={value}
            text={value}
            clipboardValue={value}
            truncate={{ twoSegments: true }}
          />
        ),
    },
    {
      value: 'created_at',
      label: 'Date',
      labelMapper: (value: string) => <TableHeaderTooltipIcon label={value} />,
      valueMapper: (value: TransactionsSale['created_at'], { local_created_at, local_time_zone }) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
            auxiliaryDate={{
              value: local_created_at,
              options: {
                timeZone: local_time_zone,
                normalizeAsUTC: false,
                performUtcToZonedTime: false,
              },
            }}
          />
        ),
      valueSx: {
        whiteSpace: 'nowrap',
      },
    },
    {
      value: 'customer_id',
      label: 'Customer',
      valueMapper: (_, { customer, customer_id: id }) =>
        customer && <TableColumnCustomer data={{ ...customer, id }} link />,
    },
    {
      value: 'inserted',
      label: 'Inserted',
      valueMapper: (value: TransactionsSale['inserted'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'crypto_amount',
      label: 'Sent',
      valueMapper: (value: TransactionsSale['crypto_amount'], data) => (
        <TransactionsSaleSent value={(value || 0).toString()} data={data} />
      ),
    },
    {
      value: 'rate',
      label: 'Rate',
      valueMapper: (value: TransactionsSale['rate'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'stage',
      label: 'Status',
      valueMapper: (value: TransactionsSale['stage']) =>
        value && <TableColumnStatus value={value as TableColumnSaleStatusCodes} />,
      valueSx: {
        textAlign: 'center',
        pr: 5,
      },
    },
    {
      value: 'order_type',
      label: 'Type',
      hideSort: true,
      valueMapper: (value: TransactionsSale['order_type'], data) => (
        <TransactionsSaleType value={value} data={data} />
      ),
    },
    {
      value: 'fee',
      label: 'Fee',
      valueMapper: (value: TransactionsSale['fee'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'address',
      label: 'Address',
      hideSort: true,
      valueMapper: (value: TransactionsSale['address']) =>
        value && (
          <ClickableText
            text={value}
            clipboardValue={value}
            name="Address"
            truncate={{
              twoSegments: true,
            }}
          />
        ),
    },
    {
      value: 'atm_id',
      label: 'Machine',
      valueMapper: (value: TransactionsSale['atm_id'], { atm }) =>
        atm && (
          <TableColumnMachine
            link
            truncate={{ maxLength: 50 }}
            data={{ name: atm.name, id: value } as Machine}
          />
        ),
      valueSx: {
        whiteSpace: 'nowrap',
      },
    },
  ],
};

export const BATCHING_COLUMNS: Columns<TransactionsBatch> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Batch ID',
      hideSort: true,
      valueMapper: (value: TransactionsBatch['id']) =>
        value && (
          <ClickableText
            text={value.toString()}
            clipboardValue={value.toString()}
            name="Batch ID"
          />
        ),
    },
    {
      value: 'created_at',
      label: 'Date Created',
      labelMapper: (value: string) => (
        <TableHeaderTooltipIcon label={value} tooltipTitle={LOCAL_TIME_LEGEND} />
      ),
      valueMapper: (value: TransactionsBatch['created_at']) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.fullLocal} />,
    },
    {
      value: 'tx_count',
      label: 'TX Count',
      hideSort: true,
      valueMapper: (value = 0) => `${value}`,
    },
    {
      value: 'tx_hash',
      label: 'TX Hash',
      hideSort: true,
      valueMapper: (value: TransactionsBatch['tx_hash']) =>
        value && (
          <TableColumnBlockstream
            name="TX Hash"
            to={value}
            text={value}
            clipboardValue={value}
            truncate={{ twoSegments: true }}
          />
        ),
    },
    {
      value: 'closed',
      label: 'Closed',
      valueMapper: (value: TransactionsBatch['closed']) => (
        <TransactionsBatchingStatus value={value} />
      ),
    },
    {
      value: 'funded',
      label: 'Funded',
      valueMapper: (value: TransactionsBatch['closed']) => (
        <TransactionsBatchingStatus value={value} />
      ),
    },
    {
      value: 'updated_at',
      label: 'Last Updated',
      labelMapper: (value: string) => (
        <TableHeaderTooltipIcon label={value} tooltipTitle={LOCAL_TIME_LEGEND} />
      ),
      valueMapper: (value: TransactionsBatch['updated_at']) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.fullLocal} />,
    },
  ],
};

export const TRANSACTIONS_STATS = {
  total_sales: {
    label: 'Total Sales',
    mapper: (value: number) => formatCurrency(value),
  },
  total_fees: {
    label: 'Total Fees',
    mapper: (value: number) => formatCurrency(value),
  },
  total_transactions: {
    label: 'Total transactions',
    mapper: (value: number) => value.toString(),
  },
};

export const TRANSACTIONS_FILTERS_KEYS = ['inserted', 'stage', 'customer_id', 'atm_id'];

export const TRANSACTIONS_FILTERS_CONFIG: TableToolbarFilterConfig[] = [
  {
    property: 'inserted',
    type: 'select-min-max',
    placeholder: 'Amount Inserted',
    partialValidation: true,
  },
  {
    type: 'select',
    property: 'stage',
    showFooter: true,
    hideApplyButton: true,
    placeholder: 'Status',
    clearText: 'Clear filter',
    label: 'Transaction status',
    options: Object.keys(CONSTANTS.SALES_STATUS).map((key) => ({
      value: key,
      label: startCase(camelCase(key)),
    })),
  },
  {
    type: 'select-ids',
    property: 'customer_id',
    placeholder: 'Any Customer',
    inputPlaceholder: 'Customer ID',
    label: 'Transaction Customer ID',
    mapperSelectedValue: (value: string) => `Customer ${value}`,
    mapperSelectedValues: (count: number) => `Customer ID (${count})`,
    validate: validateFilterById('customer'),
  },
  {
    property: 'atm_id',
    type: 'select-ids',
    placeholder: 'Any Machine',
    inputPlaceholder: 'Machine ID',
    label: 'Transaction Machine ID',
    mapperSelectedValue: (value: string) => `Machine ${value}`,
    mapperSelectedValues: (count: number) => `Machine ID (${count})`,
    validate: validateFilterById('machine'),
  },
];

export const TRANSACTIONS_STATS_DEFAULT_VALUES: TransactionsStats = {
  total_btc: 0,
  total_sent: 0,
  total_fees: 0,
  total_count: 0,
  total_sales: 0,
  total_enviando: 0,
  total_transactions: 0,
};

/**
 * Transaction details Table configuration: this configuration will be used to generate all the Table columns with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const TRANSACTION_DETAILS_COLUMNS: Columns<TransactionDetail> = {
  defaultValue: '-',
  valueSx: {
    textAlign: 'right',
  },
  items: [
    {
      label: 'Sale ID',
      value: 'id',
      valueMapper: (value: TransactionDetail['id']) =>
        value && (
          <ClickableText
            fixIcon
            text={value}
            name="Sales ID"
            clipboardValue={value}
            sx={{
              '& span': {
                whiteSpace: 'normal',
                wordBreak: 'break-all',
              },
            }}
          />
        ),
    },
    {
      label: 'Transaction Hash',
      value: 'transaction_hash',
      valueMapper: (value: TransactionDetail['transaction_hash']) =>
        value && (
          <TableColumnBlockstream
            fixIcon
            name="TX Hash"
            to={value}
            text={value}
            clipboardValue={value}
            sx={{
              '& span': {
                whiteSpace: 'normal',
                wordBreak: 'break-all',
              },
            }}
          />
        ),
    },
    {
      label: 'Transaction UTC Date',
      value: 'created_at',
      valueMapper: (value: TransactionDetail['created_at']) =>
        value && (
          <TableColumnDate
            value={value}
            variant="subtitle2"
            options={{
              timeZone: 'UTC',
            }}
          />
        ),
    },
    {
      label: 'Transaction Local Date',
      value: 'local_created_at',
      valueMapper: (value: TransactionDetail['local_created_at'], { local_time_zone }) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: local_time_zone,
              normalizeAsUTC: false,
              performUtcToZonedTime: false,
            }}
            variant="subtitle2"
          />
        ),
    },
    {
      label: 'Order Type',
      value: 'order_type',
    },
    {
      label: 'Customer',
      value: 'customer',
      valueMapper: (customer: Customer, data) => (
        <TableColumnCustomer data={{ ...customer, id: data.customer_id }} link fixIcon />
      ),
    },
    {
      label: 'Inserted (USD)',
      value: 'inserted',
      valueMapper: (value: TransactionDetail['inserted']) => formatCurrency(value),
    },
    {
      label: 'Sent (BTC)',
      value: 'crypto_amount',
      valueMapper: (value: TransactionDetail['crypto_amount'] = 0) => `₿ ${value}`,
    },
    {
      label: 'Sent (USD)',
      value: 'sent',
      valueMapper: (value: TransactionDetail['sent']) => formatCurrency(value),
    },
    {
      label: 'Rate',
      value: 'rate',
      valueMapper: (value: TransactionDetail['rate'], data: TransactionDetail) =>
        formatCurrencyBySymbol(value, data.currency_code),
    },
    {
      label: 'Status',
      value: 'stage',
      valueMapper: (value: TransactionDetail['stage']) =>
        value && <TransactionDetailsStatus value={value} />,
    },
    {
      label: 'Bitstop Fee (USD)',
      value: 'fee',
      valueMapper: (value: TransactionDetail['fee'], data: TransactionDetail) =>
        formatCurrencyBySymbol(value, data.currency_code),
    },
    {
      label: 'Address',
      value: 'address',
      valueMapper: (value: TransactionDetail['address']) =>
        value && (
          <ClickableText
            fixIcon
            text={value}
            name="Address"
            clipboardValue={value}
            sx={{
              '& span': {
                whiteSpace: 'normal',
                wordBreak: 'break-all',
              },
            }}
          />
        ),
    },
    {
      value: 'atm_id',
      label: 'Machine',
      valueMapper: (value: TransactionDetail['atm_id'], data: TransactionDetail) =>
        value && (
          <TableColumnMachine fixIcon link data={{ name: data.atm?.name, id: value } as Machine} />
        ),
      valueSx: {
        textAlign: 'right',
        '& h6': ONE_LINE_ELLIPSIS,
      },
    },
    {
      value: 'batched',
      label: 'Batched',
      valueMapper: (value: TransactionDetail['batched']) => (value ? 'Yes' : 'No'),
    },
  ],
};

export const BATCH_DETAILS_COLUMNS: Columns<TransactionsSale> = {
  ...SALES_COLUMNS,
  hideSort: true,
};
