import { Typography } from '@mui/material';

import type { Customer } from '@/types';

interface CustomerAddressProps {
  customer: Customer;
}

const CustomerAddress = ({
  customer: { address, city, state = '', zipcode = '' },
}: CustomerAddressProps) => (
  <>
    <Typography variant="body2" fontWeight="inherit">
      {address}
    </Typography>
    <Typography variant="body2" fontWeight="inherit">
      {city ? `${city}, ` : ''} {state} {zipcode}
    </Typography>
  </>
);

export default CustomerAddress;
