import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { Customer } from '@/types';

export const customerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getV1Customer: build.query<ApiResponse<Customer>, number>({
      query: (id) =>
        athena({
          url: `v1/customers/${id}`,
        }),
    }),
  }),
});

export const { useGetV1CustomerQuery, useLazyGetV1CustomerQuery } = customerApi;

export const {
  endpoints: { getV1Customer },
} = customerApi;
