import api from '@/store/api';
import { athena, proxy } from '@/store/api/instances';
import type { ApiResponse } from '@bitstopco/bitstop-theme';
import type { OperatorSettings, OperatorSettingsRequest } from 'types/operatorSettings';

export const operatorSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateOperatorSetting: build.mutation<OperatorSettings, OperatorSettingsRequest>({
      query: (data) =>
        proxy({
          url: 'v2/unity/settings/update',
          data,
          method: 'PATCH',
          showError: true,
        }),
      invalidatesTags: ['OperatorSettings'],
      async onQueryStarted(reqData, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          operatorSettingsApi.util.updateQueryData('getOperatorSetting', undefined, (draft) => {
            draft.data = { ...draft.data, ...reqData };
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getOperatorSetting: build.query<ApiResponse<OperatorSettings>, void>({
      query: () =>
        athena({
          url: 'v2/operatorsetting',
          method: 'GET',
          showError: true,
        }),
      providesTags: ['OperatorSettings'],
    }),
  }),
});

export const { useUpdateOperatorSettingMutation, useGetOperatorSettingQuery } = operatorSettingsApi;

export const {
  endpoints: { updateOperatorSetting, getOperatorSetting },
} = operatorSettingsApi;
