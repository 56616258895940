import type { ConfigureStoreOptions } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import logger from 'redux-logger';

import api from './api';
import reducers from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const rootReducer = combineReducers({
  router: routerReducer,
  [api.reducerPath]: api.reducer,
  ...reducers,
});

// Extra middlewares
let middlewares = [routerMiddleware, api.middleware];

// Development middlewares
if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_REDUX_LOGGER === 'true') {
  middlewares = middlewares.concat([logger]);
}

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
    ...options,
  });

export const store = createStore();
export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
