import Tabs from '@/components/tabs/Tabs';
import TabsContainer from '@/components/tabs/TabsContainer';

import { PANELS, PANELS_ROUTES } from '@/constants/app/transaction';

interface TransactionTabsProps {
  onChange: (newValue: string) => void;
}

const TransactionTabs = ({ onChange }: TransactionTabsProps) => (
  <TabsContainer>
    <Tabs panels={PANELS} routes={PANELS_ROUTES} onChange={onChange} />
  </TabsContainer>
);

export default TransactionTabs;
