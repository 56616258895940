import { useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { Alert, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import { useGetUserRolesQuery, usePostCreateUserMutation } from '@/store/api/services/users/users';

import SCHEMAS from '@/forms/schemas';

import type { CreateUserWithRoleModel } from '@/types';

/**
 * Create User Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const UserCreate = ({ hide }: ModalChildrenProps) => {
  const { data = [] } = useGetUserRolesQuery();
  const [createUser] = usePostCreateUserMutation();
  const [selectedRoleMessage, setSelectedRoleMessage] = useState<string | undefined>();

  const baseSchema = useMemo(() => {
    const schema = cloneDeep(SCHEMAS.CREATE_USER_WITH_ROLE);
    schema.properties.role.uniforms.options = data.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    }));

    return schema;
  }, [data]);

  const { schema } = useForm(baseSchema);

  const handleFormModel = (model: CreateUserWithRoleModel) => {
    const message = data?.find(({ id }) => id === model?.role)?.description;
    setSelectedRoleMessage(message);
  };

  const handleSubmit = async ({
    phone: { dialCode, phone },
    role,
    ...data
  }: CreateUserWithRoleModel) => {
    try {
      await createUser({ phone, country_code: dialCode, role_ids: [role], ...data }).unwrap();

      toast.success('User created successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<CreateUserWithRoleModel>
      showInlineError
      schema={schema}
      onSubmit={handleSubmit}
      onChangeModel={handleFormModel}
    >
      <DialogContent sx={{ my: 2 }} data-test="create-user-modal">
        <AutoFields />

        {selectedRoleMessage && (
          <Alert
            sx={{
              mt: 1,
              p: 1,
              '> div': {
                p: 0,
              },
            }}
            severity="info"
          >
            <Typography variant="caption">{selectedRoleMessage}</Typography>
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField data-test="create-user-modal-submit-btn">Create User</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default UserCreate;
