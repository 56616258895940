import type { ChangeEvent } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';

interface NotificationsSettingsFormAccordionProps {
  data: { id: string; title: string; validations: { id: string; title: string }[] };
  values: boolean[];
  expanded?: boolean;
  onChange: (expanded: boolean, index: number) => void;
  onExpand: (expanded: boolean) => void;
}
/**
 * Notifications Settings Form Accordion item logic
 *
 * @param {NotificationsSettingsFormAccordionProps} {
 *   data: { id, title, validations },
 *   values,
 *   expanded,
 *   onChange,
 *   onExpand,
 * }
 * @return {*}
 */
const NotificationsSettingsFormAccordion = ({
  data: { id, title, validations },
  values,
  expanded,
  onChange,
  onExpand,
}: NotificationsSettingsFormAccordionProps) => {
  const handleSwitch = (index: number) => (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked, index);
  };

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={(_, expanded) => onExpand(expanded)}
      sx={{
        mt: 2,
        '&::before': {
          display: 'none',
        },
      }}
      data-test={'notifications-settings-form-item'}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon />}
        sx={{
          minHeight: 'auto',
          px: 0,
          '.MuiAccordionSummary-content': {
            m: 0,
          },
        }}
      >
        <Typography sx={{ fontSize: 12, fontWeight: 700 }}>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          pt: 0,
          px: 0,
        }}
      >
        <Box px={1}>
          {validations.map((validation, index) => (
            <FormGroup key={`notification-setting-${title}-${validation.id}`}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values[index]}
                    onChange={handleSwitch(index)}
                    data-test={`notifications-settings-form-switch-${id}-${validation.id}`}
                  />
                }
                label={validation.title}
              />
            </FormGroup>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationsSettingsFormAccordion;
