import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import { usePutCommandQueueStatusMutation } from '@/store/api/services/machines/machine';

import { MACHINES_COMMAND_STATUSES } from '@/constants/app/machines';

import type { CommandStatusModel } from '@/types';

/**
 * Status Machine Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const StatusMachine = ({ hide, props }: ModalChildrenProps) => {
  /**
   * Get the update machine status mutation
   */
  const [processCommand, { isLoading }] = usePutCommandQueueStatusMutation();

  /**
   * Create the form schema
   */
  const { schema } = useForm(SCHEMAS.CHANGE_MACHINE_STATUS);

  const handleSubmit = async ({ status }: CommandStatusModel) => {
    /**
     * Submit the new status
     */
    try {
      await processCommand({
        status,
        atmId: props?.data?.id,
      }).unwrap();
      toast.success(`Machine status set to "${MACHINES_COMMAND_STATUSES[status].label}"`);
      hide();
    } catch {
      //
    }
  };

  return (
    <AutoForm<CommandStatusModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to machine</Button>
        <SubmitField variant="contained" disabled={isLoading}>
          Apply Changes
        </SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default StatusMachine;
