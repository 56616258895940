import { Chip, FormControlLabel } from '@mui/material';

import CustomCheckbox from '@/components/ui/CustomCheckbox';

interface EditLocationScheduleChipProps {
  day: string;
  checked: boolean;
  disabled: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined;

  rangeValues: any;
  onChangeRange: any;
}

const EditLocationScheduleChip = ({
  day,
  checked,
  disabled,
  onChange,
}: EditLocationScheduleChipProps) => (
  <>
    <Chip
      size="small"
      color="primary"
      variant="filled"
      disabled={disabled}
      label={
        <FormControlLabel
          control={
            <CustomCheckbox
              name={day}
              variant="white"
              checked={checked}
              onChange={onChange}
              data-test="operation-schedule-day"
            />
          }
          labelPlacement="start"
          label={day}
          sx={{
            margin: 0,
            '& span': {
              fontSize: '12px !important',
            },
          }}
        />
      }
    />
  </>
);

export default EditLocationScheduleChip;
