import type { ReactNode } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import type { SxProps } from '@mui/material';

interface MachineMachineTabTitleProps {
  title: string;
  sxDivider?: SxProps;
  sxContainer?: SxProps;
  renderButton?: ReactNode;
}

const MachineMachineTabTitle = ({
  title,
  sxDivider,
  sxContainer,
  renderButton,
}: MachineMachineTabTitleProps) => (
  <>
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={sxContainer}>
      <Typography variant="h6">{title}</Typography>
      {renderButton}
    </Stack>
    <Divider sx={{ mx: -2, my: 2, ...sxDivider }} />
  </>
);

export default MachineMachineTabTitle;
