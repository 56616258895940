import { Paper, Typography } from '@mui/material';

const CustomerManualVerificationPlaceholder = () => (
  <Paper elevation={0} sx={{ py: 6, px: 4, backgroundColor: 'neutral.100' }}>
    <Typography variant="body2" textAlign="center" color="text.secondary">
      Customer has not requested a manual review.
    </Typography>
  </Paper>
);

export default CustomerManualVerificationPlaceholder;
