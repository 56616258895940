import type { ApiResponse } from '@bitstopco/bitstop-theme';

import { getLastEndpointDataByRequestId } from '@/helpers/api';
import type { RootState } from '@/store';

import type { Location, LocationDetail, Machine, MachineDetail } from '@/types';

// todo: Understand how these selector work and determine if needed for locations
// export const selectLastGetLocationId = (state: RootState) =>
//   state.common.persistence.lastGetLocationId;

// export const selectLastGetLocationsId = (state: RootState) =>
//   state.common.persistence.lastGetLocationsId;

// export const selectLastGetLocation = (state: RootState) => {
//   if (!state.common.persistence.lastGetLocationId) {
//     return null;
//   }

//   return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetLocationId)
//     ?.data as ApiResponse<LocationDetail>;
// };

// export const selectLastGetLocations = (state: RootState) => {
//   if (!state.common.persistence.lastGetLocationsId) {
//     return null;
//   }

//   return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetMachinesId)
//     ?.data as ApiResponse<Machine[]>;
// };

export const selectLastGetLocationId = (state: RootState) =>
  state.common.persistence.lastGetLocationId;

export const selectLastGetLocationsId = (state: RootState) =>
  state.common.persistence.lastGetLocationsId;

export const selectLastGetLocation = (state: RootState) => {
  if (!state.common.persistence.lastGetLocationId) {
    return null;
  }

  return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetLocationId)
    ?.data as ApiResponse<LocationDetail>;
};

export const selectLastGetLocations = (state: RootState) => {
  if (!state.common.persistence.lastGetLocationsId) {
    return null;
  }

  return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetLocationsId)
    ?.data as ApiResponse<Location[]>;
};

export const selectLastGetMachineId = (state: RootState) =>
  state.common.persistence.lastGetMachineId;

export const selectLastGetMachinesId = (state: RootState) =>
  state.common.persistence.lastGetMachinesId;

export const selectLastGetMachine = (state: RootState) => {
  if (!state.common.persistence.lastGetMachineId) {
    return null;
  }

  return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetMachineId)
    ?.data as ApiResponse<MachineDetail>;
};

export const selectLastGetMachines = (state: RootState) => {
  if (!state.common.persistence.lastGetMachinesId) {
    return null;
  }

  return getLastEndpointDataByRequestId(state, state.common.persistence.lastGetMachinesId)
    ?.data as ApiResponse<Machine[]>;
};
