import { useParams } from 'react-router-dom';

import CashLogsTable from '@/components/ui/CashLogsTable';

/**
 * Machine Cash Logs Table
 * @return {*}
 */
const MachineCashLogs = () => {
  const { id = '0' } = useParams();

  return <CashLogsTable machineId={+id} />;
};

export default MachineCashLogs;
