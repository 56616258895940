import type { ClickableTextProps } from '@bitstopco/bitstop-theme';

import TableColumnClickableText from '@/components/ui/table/TableColumnClickableText';
import type { TableColumnClickableTextProps } from '@/components/ui/table/TableColumnClickableText';

import { NOT_AVAILABLE } from '@/constants/app/config';

import type { Customer, LinkedCustomer, TransactionsSale } from '@/types';

type TransactionsCustomer = TransactionsSale['customer'] & { id: number; full_name?: string };

interface TableColumnCustomerProps {
  data: Customer | LinkedCustomer | TransactionsCustomer;
  link?: boolean;
  size?: TableColumnClickableTextProps['size'];
  fixIcon?: ClickableTextProps['fixIcon'];
}

const TableColumnCustomer = ({ data, link, size, fixIcon }: TableColumnCustomerProps) => {
  const id = String(data.id || '');
  const text = data.full_name || `${data.first_name || NOT_AVAILABLE} ${data.last_name || ''}`;
  const to = link ? `${window.location.origin}/customer/${id}` : undefined;

  return (
    <TableColumnClickableText
      id={id}
      to={to}
      text={text}
      size={size}
      fixIcon={fixIcon}
      clipboardValue={id}
      name="Customer ID"
      overrideTooltipLinkText="Go to Customer"
    />
  );
};

export default TableColumnCustomer;
