import TabList from '@mui/lab/TabList';
import { Tab } from '@mui/material';

interface TabsProps {
  panels: string[];
  routes: string[];
  onChange: (newValue: string) => void;
}

/**
 * Custom Mui TabList implementation for multiple tabs
 * * For more information see also
 * * https://mui.com/material-ui/api/tabs/
 * * https://mui.com/material-ui/api/tab-list/
 *
 * @param {TabsProps} { panels, routes, onChange }
 * @return {*}
 */
const Tabs = ({ panels, routes, onChange }: TabsProps) => {
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    onChange(newValue);
  };

  return (
    <TabList onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
      {panels.map((label, index) => (
        <Tab key={`tab-${label}`} label={label} value={routes[index]} />
      ))}
    </TabList>
  );
};

export default Tabs;
