/**
 * Fills a template string with values.
 * @param {string} template - The template string with placeholders.
 * @param {Record<string, any>} values - An object containing values to replace placeholders.
 * @returns {string} The template with placeholders replaced by values.
 */
export const fillTemplate = (template: string, values: Record<string, any>) => {
  let filledTemplate = template;

  try {
    const keys = Object.keys(values);
    const dynamicFunction = new Function(...keys, `return \`${template}\``) as (
      ...args: any[]
    ) => string;

    filledTemplate = dynamicFunction(...keys.map((key) => values[key]));
  } catch {
    //
  }

  return filledTemplate;
};

/**
 * Try parse a JSON string value
 *
 * @param {string} jsonString
 * @return {*}
 */
export const tryParseJSONObject = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch {
    //
  }

  return false;
};
