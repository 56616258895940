import type { ChipProps } from '@/components/ui/Chip';

const unban = {
  label: 'Unban',
  color: 'success' as const,
  variant: 'filled' as const,
};

const ban = {
  label: 'Ban',
  color: 'error' as const,
  variant: 'filled' as const,
};

/**
 * Note types mapping for label, icons and color
 */
export const NOTE_TYPES: Record<
  string,
  {
    label: string;
    color?: ChipProps['color'];
    variant?: ChipProps['variant'];
    system?: boolean;
  }
> = {
  inquiry_completed: {
    label: 'Inquiry Completed',
    variant: 'filled',
    color: 'success',
  },
  inquiry_requested: {
    label: 'Inquiry Requested',
    variant: 'filled',
    color: 'info',
  },
  inquiry_created: {
    label: 'Inquiry Created',
    variant: 'filled',
    color: 'info',
  },
  inquiry_retried: {
    label: 'Inquiry Retried',
    variant: 'filled',
    color: 'warning',
  },
  inquiry_expired: {
    label: 'Inquiry Expired',
    system: true,
    variant: 'filled',
    color: 'error',
  },
  inquiry_failed: {
    label: 'Inquiry Failed',
    variant: 'filled',
    color: 'error',
  },
  custom: {
    label: 'Custom',
    variant: 'filled',
    color: 'info',
  },
  document: {
    label: 'Document',
    variant: 'filled',
    color: 'info',
  },
  sms_send: {
    label: 'SMS Send',
    variant: 'filled',
    color: 'info',
  },
  sms_receive: {
    label: 'SMS Receive',
    variant: 'filled',
    color: 'info',
  },
  ofac: {
    label: 'Ofac',
    color: 'error',
    system: true,
    variant: 'filled',
  },
  request_survey: {
    label: 'Request Survey',
    variant: 'filled',
    color: 'info',
  },
  survey_answer: {
    label: 'Survey Answer',
    variant: 'filled',
    color: 'info',
  },
  scan_id: {
    label: 'Scan Id',
    variant: 'filled',
    color: 'info',
  },
  request_id: {
    label: 'Request Id',
    variant: 'filled',
    color: 'info',
  },
  suspend: {
    label: 'Suspend',
    color: 'warning',
    variant: 'filled',
  },
  ban,
  unban,
  admin_ban: { ...ban, label: 'Admin Ban' },
  admin_unban: { ...unban, label: 'Admin Unban' },
};

/**
 * Note templates, all the related note template data by note_type
 */
export const NOTE_TEMPLATES = {
  suspend: {
    modalTitle: 'Suspend Customer',
    title: 'Are you sure you would like to suspend ${first_name}?',
    subtitle:
      'This will block the customer ability to do a transaction until an operator removes the suspension.',
    buttonText: 'Yes, suspend customer',
    buttonVariant: 'error' as const,
  },
  ban: {
    modalTitle: 'Ban Customer',
    title: 'Are you sure you would like to ban ${first_name}?',
    subtitle:
      'This will block the customer ability to do a transaction until an operator removes the ban.',
    buttonText: 'Yes, ban customer',
    buttonVariant: 'error' as const,
  },
  unban: {
    modalTitle: 'Reinstate Customer',
    title: 'Are you sure you would like to reinstate ${first_name}?',
    subtitle: 'This will activate the customer account again to purchase Bitcoin.',
    buttonText: 'Yes, reinstate customer',
    buttonVariant: 'primary' as const,
  },
  request_id: {
    modalTitle: 'Government ID',
    title: 'Request KYC Action',
    subtitle: 'This will create a request for your customer ${first_name}.',
    buttonText: 'Send request',
    buttonVariant: 'primary' as const,
  },
  request_survey: {
    modalTitle: 'Source of Funds Survey',
    title: 'Request KYC Action',
    subtitle: 'This will create a request for your customer ${first_name}.',
    buttonText: 'Send request',
    buttonVariant: 'primary' as const,
  },
  custom: {
    modalTitle: 'Custom Note',
    title: '',
    subtitle: '',
    buttonText: 'Create Note',
    buttonVariant: 'primary' as const,
  },
  sms_send: {
    modalTitle: 'SMS Send',
    title: '',
    subtitle: '',
    buttonText: 'Create Note',
    buttonVariant: 'primary' as const,
  },
  scan_id: {
    modalTitle: 'Request ID Scan',
    title: 'Are you sure you would like to request ID scan to ${first_name}',
    subtitle:
      'This request will force customer to scan ID on their next login in order to continue to use the service.',
    buttonText: 'Yes, send request',
    buttonVariant: 'primary' as const,
  },
};

/**
 * Mapping from note type to customer status: this mapping is used
 * for the note creation that updates the customer status
 */
export const NOTE_TYPE_CUSTOMER_STATUS = {
  suspend: 'suspended' as const,
  ban: 'banned' as const,
  unban: 'unbanned' as const,
  scan_id: 'scan_id' as const,
};
