import type { Columns } from '@bitstopco/bitstop-theme';

import LinkedAccountsDotsMenu from '@/components/customer/tabs/linkedAccounts/CustomerLinkedAccountsDotsMenu';
import CustomerPotentialMatchesButtons from '@/components/customer/tabs/linkedAccounts/CustomerPotentialMatchesButtons';
import CustomersStatus from '@/components/customers/tableColumns/CustomersStatus';

import TableColumnCustomer from '@/components/ui/table/TableColumnCustomer';
import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableHeaderTooltipIcon from '@/components/ui/table/TableHeaderDateTooltipIcon';

import { parsePhoneNumber } from '@/helpers/phone';

import { DATE_FORMATS, LOCAL_TIME_LEGEND } from '../date';

import type { LinkedCustomer } from '@/types';

/**
 * Linked accounts Table configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const LINKED_ACCOUNTS_COLUMNS: Columns<LinkedCustomer> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Customer',
      valueMapper: (_, data) => <TableColumnCustomer data={data} link />,
    },
    {
      value: 'phone_number',
      label: 'Phone Number',
      valueMapper: (phone: LinkedCustomer['phone_number']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
    },
    {
      value: 'kyc_status',
      label: 'Status',
      hideSort: true,
      valueMapper: (value: LinkedCustomer['kyc_status']) =>
        value && <CustomersStatus value={value} />,
    },
    {
      value: 'last_updated_at',
      label: 'Linked At',
      labelMapper: (value: string) => (
        <TableHeaderTooltipIcon label={value} tooltipTitle={LOCAL_TIME_LEGEND} />
      ),
      valueMapper: (value: LinkedCustomer['last_updated_at']) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.fullLocal} />,
    },
    {
      value: '_',
      label: '',
      hideSort: true,
      valueMapper: (_, data) => <LinkedAccountsDotsMenu linkedCustomer={data} />,
    },
  ],
};

/**
 * Potential matches Table configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const POTENTIAL_MATCHES_COLUMNS: Columns<LinkedCustomer> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Customer',
      valueMapper: (_, data) => <TableColumnCustomer data={data} link />,
    },
    {
      value: 'phone_number',
      label: 'Phone Number',
      valueMapper: (phone: LinkedCustomer['phone_number']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
    },
    {
      value: 'kyc_status',
      label: 'Status',
      hideSort: true,
      valueMapper: (value: LinkedCustomer['kyc_status']) =>
        value && <CustomersStatus value={value} />,
    },
    {
      value: 'linked_on',
      label: 'Matched At',
      labelMapper: (value: string) => (
        <TableHeaderTooltipIcon label={value} tooltipTitle={LOCAL_TIME_LEGEND} />
      ),
      valueMapper: (value: LinkedCustomer['linked_on']) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.fullLocal} />,
    },
    {
      value: '_',
      label: '',
      hideSort: true,
      valueMapper: (_, data) => <CustomerPotentialMatchesButtons linkedCustomer={data} />,
    },
  ],
};

/**
 * Remove match Table configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const REMOVE_MATCH_COLUMNS: Columns<LinkedCustomer> = {
  defaultValue: '-',
  items: [
    {
      value: 'id',
      label: 'Customer',
      valueMapper: (_, data) => <TableColumnCustomer data={data} />,
      hideSort: true,
    },
    {
      value: 'phone_number',
      label: 'Phone Number',
      valueMapper: (phone: LinkedCustomer['phone_number']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
      hideSort: true,
    },
    {
      value: 'kyc_status',
      label: 'Status',
      valueMapper: (value: LinkedCustomer['kyc_status']) => <CustomersStatus value={value} />,
      hideSort: true,
    },
  ],
};

export const POTENTIAL_MATCHES_TABLE_BUTTONS = [
  {
    label: 'Remove',
    variant: 'outlined' as const,
    action: 'customer-remove-match',
  },
  {
    label: 'Link',
    variant: 'contained' as const,
    action: 'customer-link-account',
  },
];

export const LIKED_ACCOUNTS_TABS = [
  {
    label: 'Linked Accounts',
    value: '/linked-accounts',
  },
  {
    label: 'Potential Matches',
    value: '/potential-matches',
  },
];
