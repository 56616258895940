import { formatCurrency } from '@bitstopco/bitstop-theme/helpers';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PaidIcon from '@mui/icons-material/Paid';

import { getDateRangeFilter } from '@/helpers/api';
//import RuleIcon from '@mui/icons-material/Rule';

export const CUSTOMERS_STATUSES = [
  {
    value: 'cleared',
    label: 'Cleared',
    color: 'success',
  },
  {
    value: 'banned',
    label: 'Banned',
    color: 'error',
  },
  {
    value: 'unbanned',
    label: 'Unbanned',
    color: 'success',
  },
  {
    value: 'suspended',
    label: 'Suspended',
    color: 'warning',
  },
  {
    value: 'scan_id',
    label: 'Scan Id',
    color: 'warning',
  },
  {
    value: 'none',
    label: 'Cleared',
    color: 'success',
  },
  {
    value: 'ofac',
    label: 'Banned',
    color: 'error',
  },
  {
    value: 'admin_ban',
    label: 'Banned',
    color: 'error',
  },
  {
    value: 'admin_unban',
    label: 'Unbanned',
    color: 'success',
  },
];

export const CUSTOMERS_POLICIES = [
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Basic Info',
    value: 'basic_info',
  },
  {
    label: 'Manual Approval',
    value: 'manual_approval',
  },
  {
    label: 'ID and Selfie',
    value: 'government_id_and_selfie',
  },
  {
    label: 'Scan ID',
    value: 'government_id_number',
  },
  {
    label: 'SSN',
    value: 'ssn',
  },
  {
    label: 'Date Of Birth',
    value: 'date_of_birth',
  },
];

/**
 * Customers top card configuration
 */
export const CUSTOMERS_CARDS = [
  {
    id: 'enrollments',
    title: 'Enrollments (Last 30 days)',
    icon: GroupAddIcon,
  },
  {
    id: 'total_fees',
    title: 'Total Fiat (Last 30 days)',
    icon: PaidIcon,
    mapValue: (value: number) => formatCurrency(value),
  },
  /* {
    id: 'waiting_manual_approval',
    title: 'Waiting For Manual Approval',
    icon: RuleIcon,
  },*/
];

export const TOTAL_FEES_FILTERS = getDateRangeFilter();
export const ENROLLMENTS_FILTERS = getDateRangeFilter('enrolled_on');
