import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

import { FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material';

import { NOTIFICATIONS_SETTINGS } from '@/constants/app/notifications/notifications';

import type { NotificationSettings, PutNotificationUserSettingsRequest } from '@/types';

import NotificationsSettingsFormAccordion from './NotificationsSettingsFormAccordion';

/**
 * Notifications Settings Form logic
 *
 * @interface NotificationsSettingsFormProps
 */
interface NotificationsSettingsFormProps {
  userSettings: NotificationSettings[];
  onChange: (data: PutNotificationUserSettingsRequest[]) => void;
}

// Default state values
const initialState = new Array(NOTIFICATIONS_SETTINGS.length);

NOTIFICATIONS_SETTINGS.forEach((setting, index) => {
  initialState[index] = new Array(setting.validations.length).fill(false);
});

const initialAccordionStatuses = new Array(NOTIFICATIONS_SETTINGS.length).fill(true);

const NotificationsSettingsForm = ({ userSettings, onChange }: NotificationsSettingsFormProps) => {
  const [switchAllValue, setSwitchAllValue] = useState(false);
  const [switchValues, setSwitchValues] = useState(initialState);
  const [accordionStatuses, setAccordionStatuses] = useState(initialAccordionStatuses);

  // We convert the switches states to a valid payload
  // and trigger the onChange event
  useEffect(() => {
    const values = switchValues.map((values, index) => {
      const item = NOTIFICATIONS_SETTINGS[index];
      const notificationCategoryId = item.id;

      const validations = item.validations.reduce(
        (obj, { id }, validationIndex) => {
          obj[id] = values[validationIndex];

          return obj;
        },
        {} as Record<string, boolean>,
      );

      return {
        ...validations,
        notificationCategoryId,
      } as PutNotificationUserSettingsRequest;
    });

    onChange(values);
  }, [switchValues]);

  useEffect(() => {
    // Copy the previous values
    const prevValues = JSON.parse(JSON.stringify(switchValues));

    // Are all false by default, if we found a true value we will be changing this flag.
    let areAllFalse = true;

    userSettings.forEach(({ notificationCategoryId, shouldSendToEmail, shouldSendToSms }) => {
      // Get the user local setting
      const index = NOTIFICATIONS_SETTINGS.findIndex(
        ({ id }) => id.toLowerCase() === notificationCategoryId.toLowerCase(),
      );

      // Index found
      if (index !== -1) {
        prevValues[index] = [shouldSendToEmail, shouldSendToSms];

        if (areAllFalse) {
          areAllFalse = !prevValues[index].some((item: boolean) => item === true);
        }
      }
    });

    setSwitchValues(prevValues);

    if (areAllFalse) {
      setSwitchAllValue(true);
    }
  }, [userSettings]);

  // Handle setting switches state
  const handleSwitch = (index: number) => (checked: boolean, verificationItemIndex: number) => {
    const newValues = JSON.parse(JSON.stringify(switchValues));
    newValues[index][verificationItemIndex] = checked;

    setSwitchValues(newValues);
    checked && setSwitchAllValue(false);
  };

  // Handle Pause all switch state
  const handleSwitchAll = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      setSwitchValues(initialState);
    }

    setSwitchAllValue(checked);
  };

  // Handle Accordions state
  const handleExpand = (index: number) => (status: boolean) => {
    const newStatuses = [...accordionStatuses];
    newStatuses[index] = status;

    setAccordionStatuses(newStatuses);
  };

  return (
    <div data-test="notifications-settings-form">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt={4} mb={2}>
        <Typography variant="overline" color="text.disabled">
          Categories
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={switchAllValue}
                onChange={handleSwitchAll}
                data-test="notification-setting-pause-all"
              />
            }
            label="Pause all"
          />
        </FormGroup>
      </Stack>

      {NOTIFICATIONS_SETTINGS.map((notificationSetting, index) => (
        <NotificationsSettingsFormAccordion
          key={`notification-setting-${notificationSetting.id}`}
          values={switchValues[index]}
          data={notificationSetting}
          onChange={handleSwitch(index)}
          onExpand={handleExpand(index)}
          expanded={accordionStatuses[index]}
        />
      ))}
    </div>
  );
};

export default NotificationsSettingsForm;
