import { Box, useTheme } from '@mui/material';

interface NotificationsDetailContentProps {
  content: string;
}

const NotificationsDetailContent = ({ content }: NotificationsDetailContentProps) => {
  const { palette } = useTheme();

  return (
    <Box
      data-test="notifications-detail-content"
      dangerouslySetInnerHTML={{ __html: content }}
      sx={{
        '& td': {
          textAlign: 'left',
        },
        '& p': {
          mt: 1,
          mb: 0,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '160%',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          letterSpacing: '0.15px',
        },
        '& a.btn': {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          borderRadius: '6px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          letterSpacing: '0.15px',
          textTransform: 'capitalize',
        },
        '& a.btn.btn-primary': {
          backgroundColor: palette.primary.main,
        },
        '& a.btn.btn-primary:hover': {
          backgroundColor: palette.primary.dark,
        },
        '& a.btn.btn-secondary': {
          backgroundColor: palette.secondary.main,
        },
        '& a.btn.btn-secondary:hover': {
          backgroundColor: palette.secondary.dark,
        },
      }}
    />
  );
};

export default NotificationsDetailContent;
