import { useCallback, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type {
  ApiListingRequest,
  TablePageSelectorProps,
  TableWrapperRef,
} from '@bitstopco/bitstop-theme';
import {
  PermissionsRestrictedView,
  TablePageSelector,
  TableWrapper,
  useModal,
} from '@bitstopco/bitstop-theme';
import { Grid, Stack, Switch, Typography } from '@mui/material';
import BatchingSettingsSection from './BatchingSettingsSection';

import CardTable from '@/components/ui/card/CardTable';
import { useGetBatchesQuery } from '@/store/api/services/transactions/batches';

import { DEFAULT_PAGINATION } from '@/constants/api';

import { BATCHING_COLUMNS } from '@/constants/app/transactions';

import type { TransactionsBatch } from '@/types';

import {
  useGetOperatorSettingQuery,
  useUpdateOperatorSettingMutation,
} from '@/store/api/services/operator/operatorSettings';

import toast from 'react-hot-toast';

/**
 * Transactions Batching Table
 * * For tables we have the TableWrapper component from the Bitstop Theme, for more information please visit the Bitstop Theme documentation
 * @return {*}
 */
const TransactionsBatching = () => {
  const [payload, setPayload] = useState<ApiListingRequest>();

  const { show } = useModal();

  const tableRef = useRef<TableWrapperRef>(null);

  /**
   * Get Transactions batching filtered by the desired payload
   */
  const {
    data: {
      data: batches = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetBatchesQuery(payload || skipToken);

  const [updateOperatorSetting] = useUpdateOperatorSettingMutation();

  const {
    data: { data: operatorSettings } = {},
    isLoading: isLoadingOperatorSetting,
  } = useGetOperatorSettingQuery();

  const handleBatchingEnabledSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const checked = event.target.checked;
      await updateOperatorSetting({
        batching_enabled: checked,
      }).unwrap();

      toast.success(`Batching has been ${checked ? 'enabled' : 'disabled'}`);
    } catch {}
  };

  const handleRowClick = (item: TransactionsBatch) => {
    show('batching-details', { ...item });
  };

  const handleChangeSettingsClick = () => {
    show('batching-settings');
  };

  const renderCustomPageSelector = useCallback(
    (props: TablePageSelectorProps) => (
      <Grid container justifyContent="space-between">
        <Grid item>
          <TablePageSelector {...props} />
        </Grid>
        <PermissionsRestrictedView to="operator_settings.modify">
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                ml: 2,
                mr: 3,
                mb: 1.5,
              }}
            >
              <Switch
                data-test="enable-batching-switch"
                onChange={handleBatchingEnabledSwitchChange}
                checked={!!operatorSettings?.batching_enabled}
              />
              <Typography variant="body2" component="div">
                Enable Batching
              </Typography>
            </Stack>
          </Grid>
        </PermissionsRestrictedView>
      </Grid>
    ),
    [operatorSettings, handleBatchingEnabledSwitchChange],
  );

  return (
    <CardTable data-test="batches-table">
      <BatchingSettingsSection
        p={3}
        pb={5.5}
        isLoading={isLoadingOperatorSetting}
        operatorSettings={operatorSettings}
        onClickChangeSettings={handleChangeSettingsClick}
      />
      <TableWrapper<TransactionsBatch>
        data={batches}
        ref={tableRef}
        hideDateRangePicker
        sortByDefaultValue="id"
        hideToolbar
        isLoading={isFetching || !payload}
        columns={BATCHING_COLUMNS}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        tableProps={{
          placeholder: {
            empty: {
              title: 'No batching found',
              content:
                "We can't find any batching that matches your search, please try a different request",
            },
          },
        }}
        renderPageSelector={renderCustomPageSelector}
        onChange={setPayload}
        onRowClick={handleRowClick}
      />
    </CardTable>
  );
};

export default TransactionsBatching;
