import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import QuestionAnswerSharpIcon from '@mui/icons-material/QuestionAnswerSharp';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import { LocationCity } from '@mui/icons-material';

import AccountBalanceIcon from '@/icons/AccountBalanceIcon';
import ChartPieIcon from '@/icons/ChartPieIcon';
import CustomUserGroupIcon from '@/icons/CustomUserGroupIcon';
import CustomUsersIcon from '@/icons/CustomUsersIcon';
import DocumentTextIcon from '@/icons/DocumentTextIcon';
import HeroSolidSwitchHorizontalIcon from '@/icons/HeroSolidSwitchHorizontalIcon';
import ImageSearchIcon from '@/icons/ImageSearchIcon';
import TemplateIcon from '@/icons/TemplateIcon';

import type { SidebarItemDataExtended } from '@/types';

/**
 * Sidebar configuration
 * * Sidebar items can be configured here, we can redirect the links internally externally or redirect them to Nonce V1 prepending the NONCE_V1_HOST constant
 *
 * * Each element can have multiple sub items using the `items` prop.
 *
 * ! This is only for the sidebar configuration, if you want to hide a specific view you will need to remove it from the router too. (view.tsx)
 */
export const SIDEBAR_ITEMS: SidebarItemDataExtended[] = [
  {
    icon: TemplateIcon,
    title: 'Dashboard',
    href: '/',
  },
  {
    icon: HeroSolidSwitchHorizontalIcon,
    title: 'Sales',
    href: '/sales/transactions',
    permission: 'operator_settings.view',
  },
  {
    icon: SmartphoneIcon,
    title: 'Machines',
    href: '/machines/atms',
  },
  {
    icon: CustomUsersIcon,
    title: 'Customers',
    href: '/customers',
  },
  {
    icon: LocationCity,
    title: 'Locations',
    href: '/locations',
  },
  {
    icon: AccountBalanceIcon,
    title: 'Wallet',
    href: '/wallet',
  },
  {
    icon: ImageSearchIcon,
    title: 'Compliance',
    href: '/compliance',
    permission: 'kyc.view',
  },
  {
    icon: DocumentTextIcon,
    title: 'Invoices',
    href: '/invoices',
    permission: 'invoices.view',
  },
  {
    icon: ChartPieIcon,
    title: 'Stats',
    href: '/stats',
    permission: 'customer_statistics.view',
  },
  {
    icon: AccountCircleIcon,
    title: 'Users',
    href: '/users',
    permission: 'users.view',
  },
  {
    icon: CustomUserGroupIcon,
    title: 'Affiliates',
    href: '/affiliates',
    permission: 'affiliates.view',
  },
  {
    icon: QuestionAnswerSharpIcon,
    title: 'Technical Support',
    href: 'https://bitstoptechnicalsupport.zendesk.com/hc/en-us',
    external: true,
    externalIcon: true,
  },
];

export const SIDEBAR_ITEMS_SETTINGS: SidebarItemDataExtended[] = [
  {
    icon: KeyboardBackspaceIcon,
    title: 'Settings',
    href: '/',
  },
  {
    icon: CompareArrowsIcon,
    title: 'Integrations',
    items: [
      {
        title: 'Blockchain Analytics',
        href: '/settings/integrations/blockchain-analytics',
      },
    ],
  },
  {
    icon: BuildOutlinedIcon,
    title: 'Maintenance',
    items: [
      {
        title: 'Provisioning QR code',
        href: '/settings/maintenance/provisioning-qr-code',
      },
    ],
  },
];
