import { useMemo } from 'react';

import { CONSTANTS } from '@bitstopco/bitstop-theme';

import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import type { BoxProps } from '@mui/material';
import { Box, Grid, Stack, Typography } from '@mui/material';

import theme from '@/helpers/theme';

import type { Customer, LinkedCustomer } from '@/types';

const InnerCard = ({ children }: { children: React.ReactNode }) => (
  <Box sx={{ m: 1, py: 1, px: 2, backgroundColor: '#FAFAFA', borderRadius: 1 }}>{children}</Box>
);

interface CustomerLinkAccountNewLinkProps extends BoxProps {
  customer: Customer;
  linkedCustomer: Customer | LinkedCustomer;
  variant?: 'link' | 'unlink';
}

const CustomerLinkAccountNewLink = ({
  customer,
  linkedCustomer,
  variant = 'link',
  ...props
}: CustomerLinkAccountNewLinkProps) => {
  const { icon, colors, title, borderStyle } = useMemo(() => {
    if (variant === 'link') {
      return {
        title: 'new link!',
        colors: {
          primary: theme.palette.success.main,
          secondary: '#4CAF5080',
        },
        borderStyle: 'solid',
        icon: <LinkIcon sx={{ color: 'success.main' }} />,
      };
    }

    return {
      colors: {
        primary: theme.palette.neutral!['300'],
        secondary: theme.palette.neutral!['200'],
      },
      borderStyle: 'dashed',
      icon: <LinkOffIcon sx={{ color: 'text.disabled' }} />,
    };
  }, [variant]);

  return (
    <Box {...props}>
      {title && (
        <Typography
          mb={1}
          color="success.main"
          variant="caption"
          textAlign="center"
          component="div"
        >
          {title}
        </Typography>
      )}

      <Grid container alignItems="center">
        <Grid item xs={12} sm={4}>
          <Box
            sx={({ palette }) => ({
              borderRadius: 1,
              border: `2px solid ${palette.neutral!['300']}`,
            })}
          >
            <InnerCard>
              <Typography variant="subtitle2" sx={{ ...CONSTANTS.ONE_LINE_ELLIPSIS }}>
                <strong>{customer?.full_name}</strong>
              </Typography>
              <Typography variant="caption" component="div">
                ID: {customer?.id}
              </Typography>
            </InnerCard>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
            <Box
              sx={{
                flex: 1,
                height: 2,
                backgroundColor: 'neutral.300',
                display: { xs: 'none', sm: 'block' },
              }}
            />
            <Box
              sx={{
                width: '40px',
                height: '40px',
                display: 'flex',
                background: 'white',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                outline: `6px solid ${colors.primary}`,
                border: `4px solid ${colors.secondary}`,
              }}
            >
              {icon}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: { xs: 'none', sm: 'block' },
                borderBottom: `2px ${borderStyle} ${colors.primary}`,
              }}
            />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              borderRadius: 1,
              border: `2px ${borderStyle} ${colors.primary}`,
            }}
          >
            <InnerCard>
              <Typography variant="subtitle2" sx={{ ...CONSTANTS.ONE_LINE_ELLIPSIS }}>
                <strong>{linkedCustomer.full_name}</strong>
              </Typography>
              <Typography variant="caption" component="div">
                ID: {linkedCustomer.id}
              </Typography>
            </InnerCard>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerLinkAccountNewLink;
