import Chip from '@/components/ui/Chip';

import { useGetUserRolesQuery } from '@/store/api/services/users/users';
import { useMemo } from 'react';

interface UsersRoleColumnProps {
  value?: number;
}

export const UsersRoleColumn = ({ value }: UsersRoleColumnProps) => {
  const { data = [] } = useGetUserRolesQuery();

  const label = useMemo(() => data.find(({ id }) => id === value)?.name || value, [data, value]);

  return <Chip size="small" color="primary" label={label} />;
};
