import { Checkbox } from '@mui/material';
import type { CheckboxProps } from '@mui/material';
import omit from 'lodash/omit';

import CheckboxCheckedBlue from '@/assets/checkbox-checked-blue.svg?react';
import CheckboxChecked from '@/assets/checkbox-checked.svg?react';
import CheckboxUncheckedBlue from '@/assets/checkbox-unchecked-blue.svg?react';
import CheckboxUnchecked from '@/assets/checkbox-unchecked.svg?react';

const SmallCheckbox = (
  props: CheckboxProps & {
    variant?: 'white' | 'blue';
  },
) => {
  const isWhite = props?.variant === 'white';
  const Checked = !isWhite ? CheckboxCheckedBlue : CheckboxChecked;
  const Unchecked = !isWhite ? CheckboxUncheckedBlue : CheckboxUnchecked;

  return (
    <Checkbox
      size="small"
      sx={{
        pr: 0,
      }}
      icon={<Unchecked />}
      checkedIcon={<Checked />}
      disableRipple
      {...omit(props, ['variant'])}
    />
  );
};

export default SmallCheckbox;
