import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material';

import { DEFAULT_TRANSACTION_TIME_LEGEND } from '@/constants/app/date';

export interface DateTooltipIconProps extends Omit<TooltipProps, 'title' | 'children'> {
  tooltipTitle?: string;
  icon?: React.ReactElement;
}

const DateTooltipIcon = ({
  tooltipTitle = DEFAULT_TRANSACTION_TIME_LEGEND,
  icon = <InfoIcon sx={{ color: 'text.secondary', fontSize: 18 }} />,
  ...props
}: DateTooltipIconProps) => (
  <Tooltip {...props} title={tooltipTitle} data-test="date-tooltip-icon">
    {icon}
  </Tooltip>
);

export default DateTooltipIcon;
