import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { walletProvider } from '@/store/api/instances';

import type { WalletsProvider } from '@/types';

/**
 * Wallets Providers RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const walletsProvidersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWalletsProvidersByCoinId: build.query<ApiResponse<WalletsProvider[]>, string>({
      query: (coinTypes) =>
        walletProvider({
          url: 'v1/providers',
          params: {
            coinTypes,
          },
        }),
    }),
  }),
});

export const { useGetWalletsProvidersByCoinIdQuery } = walletsProvidersApi;

export const {
  endpoints: { getWalletsProvidersByCoinId },
} = walletsProvidersApi;
