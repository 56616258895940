import type {
  ApiExportRequest,
  ApiListingFiltersOnlyRequest,
  ApiListingRequest,
  ApiResponse,
} from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena, unity } from '@/store/api/instances';

import type {
  ApiListingLocationIdRequest,
  TransactionDetail,
  TransactionImages,
  TransactionsSale,
  TransactionsStats,
} from '@/types';

/**
 * Transactions RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const transactionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransaction: build.query<ApiResponse<TransactionDetail>, string>({
      query: (id) =>
        athena({
          url: `v2/transaction/${id}`,
        }),
      providesTags: (_, __, id) => [{ type: 'Transaction', id }],
    }),
    getTransactionImages: build.query<ApiResponse<TransactionImages>, string>({
      query: (id) =>
        athena({
          url: `v2/transaction/${id}/images`,
        }),
      providesTags: (_, __, id) => [{ type: 'TransactionImages', id }],
    }),
    getTransactions: build.query<ApiResponse<TransactionsSale[]>, ApiListingRequest>({
      query: (data) =>
        athena({
          url: 'v2/transactions',
          method: 'POST',
          data,
        }),
      providesTags: (response) => {
        const result = response?.data || [];
        return [
          ...result.map(({ id }) => ({ type: 'Transactions', id }) as const),
          { type: 'Transactions' as const, id: 'LIST' },
        ];
      },
    }),
    getLocationTransactions: build.query<
      ApiResponse<TransactionsSale[]>,
      ApiListingLocationIdRequest
    >({
      query: ({ location_id, filter, ...data }) =>
        athena({
          url: 'v2/transactions',
          method: 'POST',
          data: {
            ...data,
            filter: {
              operator: 'and',
              operands: [
                ...(filter?.operands || []),
                {
                  property: 'location_id',
                  operator: 'equal',
                  value: location_id,
                },
              ],
            },
          },
        }),
    }),
    getTransactionsStats: build.query<ApiResponse<TransactionsStats>, ApiListingFiltersOnlyRequest>(
      {
        query: (data) => athena({ url: 'v2/transactions/stats', method: 'POST', data }),
      },
    ),
    getTransactionsExport: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data }) => athena({ url: `v2/transactions/${type}`, method: 'POST', data }),
    }),
    unfreezeTransaction: build.mutation<void, string>({
      query: (id) => unity({ url: `sales/${id}/reinstate`, method: 'POST' }),
    }),
    refundTransaction: build.mutation<void, string>({
      query: (id) => unity({ url: `sales/${id}/refund`, method: 'POST' }),
    }),
  }),
});

export const {
  useGetTransactionQuery,
  useGetTransactionsQuery,
  useGetLocationTransactionsQuery,
  useGetTransactionImagesQuery,
  useGetTransactionsStatsQuery,
  useGetTransactionsExportMutation,
  useUnfreezeTransactionMutation,
  useRefundTransactionMutation,
} = transactionsApi;

export const {
  endpoints: {
    getTransaction,
    getTransactions,
    getLocationTransactions,
    getTransactionImages,
    getTransactionsStats,
    getTransactionsExport,
    unfreezeTransaction,
    refundTransaction,
  },
} = transactionsApi;
