import chainalysisLogo from '@/assets/chainalysis-logo.svg';
import ellipticLogo from '@/assets/elliptic-logo.svg';

export const BA_MORE_POPOVER_DATA = [
  { label: 'Set as active', disabled: false },
  { label: 'Edit connection', disabled: false },
];
export const BA_MORE_POPOVER_ACTIONS = ['activate-vendor', 'edit-vendor'];

export const BLOCKCHAIN_ANALYTICS_IMAGES = {
  elliptic: ellipticLogo,
  chainalysis: chainalysisLogo,
};
