import { useMemo } from 'react';

import {
  Stack,
  /*, useMediaQuery*/
} from '@mui/material';

//import PermIdentityIcon from '@mui/icons-material/PermIdentity';
//import type { Theme } from '@mui/material';
//import StatsHeaderMobile from './headerStats/HeaderStatsMobile';

import { USER_POPOVER_ITEMS } from '@/constants/app/userPopover';

import { usePermissions } from '@bitstopco/bitstop-theme';
import AccountPopover from '../AccountPopover';

interface HeaderUserPopoverProps {
  userData: any;
  onLogout: () => void;
  onClickItem: (index: number) => void;
}

const HeaderUserPopover = ({ userData, onLogout, onClickItem }: HeaderUserPopoverProps) => {
  //const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isSettingsAllowed = usePermissions({
    permission: 'operator_settings.modify',
  })[1];

  const items = useMemo(() => {
    const options = {
      settings: isSettingsAllowed,
    };

    return USER_POPOVER_ITEMS.filter(
      ({ text }) => options?.[text.toLowerCase() as keyof typeof options],
    );
  }, [isSettingsAllowed]);

  return (
    <Stack direction="row">
      {/*lgDown && <StatsHeaderMobile />*/}
      <AccountPopover
        items={items}
        avatarSrc={userData?.avatar}
        username={`${userData?.first_name} ${userData?.last_name}`}
        onLogout={onLogout}
        onClickItem={onClickItem}
      />
    </Stack>
  );
};

export default HeaderUserPopover;
