import { createSlice } from '@reduxjs/toolkit';
import type { Location } from 'react-router';

import initialState from './state';

export const routerSlice = createSlice({
  name: 'common/router',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase('@@router/LOCATION_CHANGE', (state, action) => {
      const { location } = (action as unknown as { payload: { location: Location } }).payload;

      // When the location change we want to save a history of the visited pages0
      // This history is limited to 10 past items in order to prevent performance issues
      const newHistory = [location, ...state.history];

      if (newHistory.length > 10) newHistory.length = 10;

      state.history = newHistory;
    });
  },
});

export const actions = routerSlice.actions;
export default routerSlice.reducer;
