import { useMemo } from 'react';

import { useModal } from '@bitstopco/bitstop-theme';

import { useParams } from 'react-router';

import MorePopover from '@/components/ui/MorePopover';
import { getCustomerActionsByStatus } from '@/helpers/customer';
import { useCustomer } from '@/store/api/services/customers/customer';

import type { LinkedCustomer } from '@/types';

interface CustomerLinkedAccountsDotsMenuProps {
  linkedCustomer: LinkedCustomer;
}

const CustomerLinkedAccountsDotsMenu = ({
  linkedCustomer,
}: CustomerLinkedAccountsDotsMenuProps) => {
  const { id = '0' } = useParams();
  const { show } = useModal();

  const { customer, maxPurchaseLimit } = useCustomer(+id);

  const { items, itemLabels } = useMemo(() => {
    const items = [
      ...getCustomerActionsByStatus(linkedCustomer.kyc_status).filter(
        ({ value }) => value !== 'linkAccount',
      ),
      { value: 'unlinkAccount', label: 'Unlink account' },
    ];

    return {
      items,
      itemLabels: [...items.map(({ label }) => label)],
    };
  }, [linkedCustomer.kyc_status]);

  const handleClick = (index: number) => {
    const itemLabel = itemLabels[index];
    const value = items.find(({ label }) => label === itemLabel)?.value;

    if (value === 'unlinkAccount') {
      show('customer-unlink-account', { customer, maxPurchaseLimit, linkedCustomer });
      return;
    }

    show('customer-action-note', { customer: linkedCustomer, noteType: value });
  };

  return <MorePopover onClick={handleClick} items={itemLabels} />;
};

export default CustomerLinkedAccountsDotsMenu;
