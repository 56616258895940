import { LOGO } from '@bitstopco/bitstop-theme/constants';

import { Box, useMediaQuery } from '@mui/material';
import type { Theme } from '@mui/material';
import { Link } from 'react-router-dom';

import HeaderStats from '@/components/ui/header/headerStats/v1/HeaderStats';

const HeaderLogo = () => {
  //const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const logoSize = mdDown
    ? {
        width: 90,
        height: 18.03,
      }
    : {
        width: 148,
        height: 29.66,
      };

  return (
    <>
      <Box
        component={Link}
        to="/"
        sx={{
          mr: {
            lg: '24px',
            xs: 0,
          },
        }}
      >
        <img
          loading="lazy"
          src={LOGO}
          alt="bitstop logo"
          style={{ display: 'block', ...logoSize }}
        />
      </Box>
      {mdDown}
      <Box
        sx={(theme) =>
          mdDown
            ? {
                pb: 1,
                px: 2,
                left: 0,
                bottom: 10,
                width: '100%',
                display: 'flex',
                position: 'absolute',
                justifyContent: 'center',
                transform: 'translateY(100%)',
                backgroundColor: theme.palette.branding?.red,
              }
            : {}
        }
      >
        <HeaderStats />
      </Box>
    </>
  );
};

export default HeaderLogo;
