import MachinesStatus from '@/components/machines/tabs/atms/tableColumns/MachinesStatus';
import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableColumnMachine from '@/components/ui/table/TableColumnMachine';
import { parsePhoneNumber } from '@/helpers/phone';
import type { AffiliateAtm, AffiliateUser, Machine } from '@/types';

import { AffiliateMachinesRemoveColumn } from '@/components/affiliates/affiliate/tabs/machines/tableColumns/AffiliateMachinesRemoveColumn';
import type { Columns } from '@bitstopco/bitstop-theme';
import { DATE_FORMATS } from '../date';

export const AFFILIATE_USERS_COLUMNS: Columns<AffiliateUser> = {
  hideSort: true,
  valueSx: {
    maxWidth: 400,
  },
  items: [
    {
      value: 'id',
      label: 'ID',
    },
    {
      value: 'first_name',
      label: 'First Name',
    },
    {
      value: 'last_name',
      label: 'Last Name',
    },
    {
      label: 'Phone Number',
      value: 'phone',
      valueMapper: (phone: AffiliateUser['phone']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
    },
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'created_at',
      label: 'Created At',
      valueMapper: (value: AffiliateUser['created_at']) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
          />
        ),
    },
    {
      value: 'updated_at',
      label: 'Updated At',
      valueMapper: (value: AffiliateUser['updated_at']) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
          />
        ),
    },
  ],
};

/**
 * Affiliate Machines columns configuration: The columns configuration will be used to generate the table with the desired data
 * * You can learn more about the Table columns configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const AFFILIATE_MACHINES_COLUMNS: Columns<AffiliateAtm> = {
  defaultValue: '-',
  hideSort: true,
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Machine',
      valueMapper: (_, { id, name }) => (
        <TableColumnMachine
          data={
            {
              id,
              name,
            } as Machine
          }
        />
      ),
    },
    {
      value: 'machine_status_name',
      label: 'Status',
      valueMapper: (value: AffiliateAtm['machine_status_name']) =>
        value && <MachinesStatus value={value} />,
    },
    {
      value: 'serial',
      label: 'Serial',
    },
    {
      value: 'created_at',
      label: 'Created At',
      valueMapper: (value: AffiliateAtm['created_at']) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.fullVariantWithoutTimezone} />,
    },
    {
      label: 'Remove',
      valueMapper: (_, data) => <AffiliateMachinesRemoveColumn value={data} />,
    },
  ],
};
