/**
 * Create an array with 5 elements fill the array with 0s add 1 to each element's index
 */
export const ADDRESSES_LIST = Array(5)
  .fill(0)
  .map((_, index) => {
    const value = String(1 + index);

    return {
      value,
      key: value,
      label: value,
    };
  });

/**
 * Google address dictionary, we want to make a relation between the google address fields and the
 * fields in our DB
 */
export const GOOGLE_ADDRESS_FIELDS = [
  { name: 'city', values: ['locality'], format: 'short_name' },
  { name: 'state', values: ['administrative_area_level_1'], format: 'short_name' },
  { name: 'country', values: ['country'], format: 'short_name' },
  { name: 'zipcode', values: ['postal_code'], format: 'short_name' },
  { name: 'street_address', values: ['street_number', 'route'], format: 'long_name' },
  { name: 'address2', values: ['subpremise'], format: 'short_name' },
];
