import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import type { ClearIndicatorProps, GroupBase, OptionProps } from 'react-select';
import { components } from 'react-select';
import type { AsyncProps } from 'react-select/async';
import AsyncSelect from 'react-select/async';

import theme from '@/helpers/theme';

export interface OptionValue {
  value: string;
  label: string;
}

const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: React.PropsWithChildren<OptionProps<Option, IsMulti, Group>>,
) => {
  const data = props.data as OptionValue;

  return (
    <components.Option {...props}>
      <Box px={2} py={1} data-test="async-select-option">
        <Typography variant="body2">{data.label}</Typography>
        <Typography variant="body2" color="text.secondary">
          ID: {data.value}
        </Typography>
      </Box>
    </components.Option>
  );
};

const ClearIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: React.PropsWithChildren<ClearIndicatorProps<Option, IsMulti, Group>>,
) => (
  <components.ClearIndicator {...props}>
    <CloseIcon sx={{ color: '#898989', cursor: 'pointer', fontSize: 18 }} />
  </components.ClearIndicator>
);

const StyledAsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncProps<any, IsMulti, Group>,
) => (
  <AsyncSelect
    {...props}
    components={{
      Option,
      ClearIndicator,
    }}
    styles={{
      option: (_, state) => ({
        '&:nth-of-type(even)': {
          backgroundColor: theme.palette.neutral!['50'],
        },
        backgroundColor: state.isSelected ? theme.palette.neutral!['200'] : 'white',
      }),
      loadingMessage: (styles) => ({
        ...styles,
        fontSize: '14px',
      }),
      noOptionsMessage: (styles) => ({
        ...styles,
        fontSize: '14px',
      }),
      control: (styles, state) => ({
        ...styles,
        borderRadius: '6px',
        borderWidth: '1px',
        borderColor: state.isFocused ? theme.palette.primary.light : '#D5D8E1',
        boxShadow: 'none !important',
        boxSizing: 'border-box',
        padding: 0,
        minHeight: 36,
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          borderRadius: '5px',
          border: state.isFocused ? 'solid 1px' : '0',
          borderColor: theme.palette.neutral!['300'],
        },
        '&:hover': {
          borderColor: state.isFocused ? theme.palette.primary.light : '#000',
        },
      }),
      input: (styles) => ({
        ...styles,
        fontSize: 14,
        color: theme.palette.text.primary,
      }),
      placeholder: (styles) => ({
        ...styles,
        fontSize: 14,
        color: 'rgba(30, 33, 52, 0.38)',
      }),
      indicatorSeparator: () => ({}),
      dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none',
      }),
      valueContainer: (styles) => ({
        ...styles,
      }),
      singleValue: (styles) => ({
        ...styles,
        fontSize: '14px',
        color: theme.palette.text.primary,
      }),
    }}
  />
);

export default StyledAsyncSelect;
