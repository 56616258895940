import { useEffect, useState } from 'react';

import api from '@/store/api';
import { notification } from '@/store/api/instances';

import { NOTIFICATIONS_ITEMS_PER_PAGE } from '@/constants/app/notifications/notifications';

import type {
  Notification,
  NotificationSettingsResponse,
  NotificationsCountByCategoryResponse,
  NotificationsCountResponse,
  NotificationsRequest,
  NotificationsResponse,
  PutNotificationUserSettingsRequest,
} from '@/types';

/**
 * Notifications RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<NotificationsResponse, NotificationsRequest>({
      query: ({
        category,
        page = 1,
        unread = false,
        sortAscending = false,
        limit = NOTIFICATIONS_ITEMS_PER_PAGE,
      }) =>
        notification({
          url: 'inbox',
          params: {
            page,
            limit,
            sortAscending,
            Base64UrlEncode: false,
            query: JSON.stringify([
              {
                property: 'category',
                operator: 'equal',
                value: category,
              },
              ...(unread
                ? [
                    {
                      property: 'isRead',
                      operator: 'equal',
                      value: 'false',
                    },
                  ]
                : []),
            ]),
          },
        }),
      providesTags: (response) => {
        const result = response?.data || [];
        return [
          ...result.map(
            ({ id, category, isRead }) =>
              ({ type: 'Notifications', id, category, isRead }) as const,
          ),
          { type: 'Notifications' as const, id: 'LIST' },
        ];
      },
    }),
    getNotificationById: build.query<any, string>({
      query: (id) =>
        notification({
          url: `inbox/${id}/withhtml`,
        }),
    }),
    getNotificationsCount: build.query<NotificationsCountResponse, void>({
      query: () => notification({ url: 'inbox/count' }),
      providesTags: ['NotificationsCount'],
    }),
    getNotificationsCountByCategory: build.query<NotificationsCountByCategoryResponse, void>({
      query: () => notification({ url: 'inbox/countByCategory' }),
      providesTags: ['NotificationsCountByCategory'],
    }),
    getNotificationUserSettings: build.query<NotificationSettingsResponse, void>({
      query: () => notification({ url: 'notificationUserSettings' }),
      providesTags: ['NotificationUserSettings'],
    }),
    acknowledgeNotifications: build.mutation<string, { ids: string[] }>({
      query: (data) => notification({ url: 'inbox/acknowledge', method: 'POST', data }),
      invalidatesTags: [
        { type: 'Notifications' as const, id: 'LIST' },
        'NotificationsCount',
        'NotificationsCountByCategory',
      ],
    }),
    acknowledgeAllNotifications: build.mutation<string, void>({
      query: () => notification({ url: 'inbox/acknowledgeAll', method: 'POST' }),
      invalidatesTags: [
        { type: 'Notifications' as const, id: 'LIST' },
        'NotificationsCount',
        'NotificationsCountByCategory',
      ],
    }),
    putNotificationUserSettings: build.mutation<string, PutNotificationUserSettingsRequest>({
      query: (data) => notification({ url: 'notificationUserSettings', method: 'PUT', data }),
    }),
  }),
});

export const {
  // Queries
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useGetNotificationsCountQuery,
  useGetNotificationsCountByCategoryQuery,
  useGetNotificationUserSettingsQuery,
  useLazyGetNotificationsQuery,

  // Mutations
  useAcknowledgeNotificationsMutation,
  useAcknowledgeAllNotificationsMutation,
  usePutNotificationUserSettingsMutation,
} = notificationsApi;

export const {
  endpoints: {
    getNotifications,
    getNotificationById,
    getNotificationsCount,
    getNotificationsCountByCategory,
    getNotificationUserSettings,
    acknowledgeNotifications,
    acknowledgeAllNotifications,
    putNotificationUserSettings,
  },
} = notificationsApi;

export const useNotifications = ({ category, unread }: { category: string; unread?: boolean }) => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const [notificationsChunks, setNotificationsChunks] = useState<Notification[][]>([]);

  const [getNotifications, { isLoading, isFetching }] = useLazyGetNotificationsQuery();

  useEffect(() => {
    reset();
  }, [unread]);

  useEffect(() => {
    (async () => {
      try {
        const { data: notifications = [] } = await getNotifications({
          page,
          unread,
          category,
        }).unwrap();

        setHasMore(
          page === 1 && (notifications.length || 0) < NOTIFICATIONS_ITEMS_PER_PAGE
            ? false
            : !!notifications.length,
        );

        setNotificationsChunks((oldData = []) => {
          const newChunks = [...oldData];
          newChunks[page - 1] = notifications;

          return newChunks.filter(Boolean);
        });
      } catch {
        //
      }
    })();
  }, [page, unread, category]);

  const reset = () => {
    setPage(1);
    setHasMore(false);
    setNotificationsChunks([]);
  };

  const notifications = notificationsChunks?.flat() || [];

  return {
    page,
    reset,
    hasMore,
    setPage,
    notifications,
    isLoadingNotifications: isLoading,
    isFetchingNotifications: isFetching,
  };
};
