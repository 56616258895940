import { CssBaseline, ThemeProvider } from '@mui/material';
// Import the theme instance with the default branding color palette.
import { Toaster } from 'react-hot-toast';

import theme from '@/helpers/theme';
import ModalProviderWrapper from '@/modals/ModalProvider';

// Import simplebar custom styles
import 'simplebar-react/dist/simplebar.min.css';

import Router from './routes';

const App = () => (
  <ThemeProvider theme={theme}>
    <ModalProviderWrapper>
      <CssBaseline />
      <Router />
      <Toaster containerClassName="toast-notification" />
    </ModalProviderWrapper>
  </ThemeProvider>
);

export default App;
