import { useCallback, useEffect } from 'react';

interface useInfiniteScrollNextFixProps {
  elementId?: string;
  isLoading?: boolean;
  hasMoreItems?: boolean;
  fetchMoreItems: () => void;
}
/**
 * Fix for Infinite scroll can't call "next" function if page (or div) has no scroll
 *
 * @param {useInfiniteScrollNextFixProps} {
 *   isLoading,
 *   hasMoreItems,
 *   fetchMoreItems,
 * }
 */
const useInfiniteScrollNextFix = ({
  isLoading,
  hasMoreItems,
  fetchMoreItems,
  elementId = 'scrollableDiv',
}: useInfiniteScrollNextFixProps) => {
  const fetchCallback = useCallback(() => {
    fetchMoreItems();
  }, [fetchMoreItems]);

  useEffect(() => {
    const mainElement = document.getElementById(elementId);
    const hasScroll = mainElement ? mainElement.scrollHeight > mainElement.clientHeight : false;

    if (!hasScroll && hasMoreItems && !isLoading) {
      fetchMoreItems();
    }
  }, [isLoading, hasMoreItems, fetchCallback]);
};

export default useInfiniteScrollNextFix;
