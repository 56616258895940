import { useEffect, useState } from 'react';

import { Loader, useModal } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { DialogContent, Stack, Typography } from '@mui/material';
import format from 'date-fns/format';

import NotificationsBreadcrumb from '@/components/notifications/NotificationsBreadcrumb';
import NotificationsDetailContent from '@/components/notifications/NotificationsDetailContent';
import {
  useAcknowledgeNotificationsMutation,
  useGetNotificationByIdQuery,
} from '@/store/api/services/notifications/notifications';

import { DATE_FORMATS } from '@/constants/app/date';

import type { Notification } from '@/types';

/**
 * Notifications Detail modal
 *
 * @param {ModalChildrenProps} { props }
 * @return {*}
 */
const NotificationsDetail = ({ props }: ModalChildrenProps) => {
  const data = useState<Notification>(props?.data)[0];
  const [acknowledgeNotifications] = useAcknowledgeNotificationsMutation();

  const {
    data: {
      data: { contentAsHtml = '' },
    } = { data: {} },
    isLoading,
  } = useGetNotificationByIdQuery(data.id);

  const { show } = useModal();

  useEffect(() => {
    if (!data.isRead) {
      acknowledgeNotifications({ ids: [data.id] });
    }
  }, []);

  const handleGoNotifications = () => {
    show('notifications');
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DialogContent sx={{ py: 1.5 }} data-test="notifications-detail">
        <NotificationsBreadcrumb title={data.category} onClick={handleGoNotifications} />

        <Typography
          mt={4}
          display="block"
          variant="overline"
          color="text.disabled"
          textTransform="uppercase"
        >
          {data.category} notification detail
        </Typography>

        <Stack mt={2} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1" data-test="notifications-detail-title">
            {data?.title}
          </Typography>
          <Typography variant="caption" data-test="notifications-detail-date">
            {format(new Date(data.createdAt), DATE_FORMATS.simpleHours)}
          </Typography>
        </Stack>

        <NotificationsDetailContent content={contentAsHtml} />
      </DialogContent>
    </>
  );
};

export default NotificationsDetail;
