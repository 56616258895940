import type {
  CountryCode,
  FormatNumberOptions,
  NumberFormat,
  PhoneNumber,
} from 'libphonenumber-js';
import { parsePhoneNumber as baseParsePhoneNumber } from 'libphonenumber-js';

/**
 * Parse a phone number using libphonenumber-js
 ** The libphonenumber-js instance is returned as fallback
 *
 * @param {Phone} phone
 * @return {*}
 */
export const parsePhoneNumber = ({
  phone,
  formatOptions,
  defaultCountry = 'US',

  format = 'NATIONAL',
  addCountryCode = true,
}: {
  phone: string;
  format?: NumberFormat;
  defaultCountry?:
    | CountryCode
    | {
        extract?: boolean;
        defaultCallingCode?: string;
        defaultCountry?: CountryCode;
      };
  formatOptions?: FormatNumberOptions;
  addCountryCode?: boolean;
}) => {
  let parseError: unknown;
  let parsedPhoneNumber: PhoneNumber | undefined;

  let parsed = phone;
  try {
    parsedPhoneNumber = baseParsePhoneNumber(phone, defaultCountry);
    parsed = parsedPhoneNumber.format(format, formatOptions);

    if (addCountryCode) {
      // Add the country code prefix
      parsed = `+${parsedPhoneNumber.countryCallingCode} ${parsed}`;
    }
  } catch (error) {
    parseError = error;
    // Something went wrong trying to parse the phone number
  }

  return {
    parsed,
    parseError,
    _: parsedPhoneNumber,
  };
};

/**
 * Normalize the phone number format.
 * Converts (x) format to +x and adds +1 if missing.
 * @param phone - The phone number to be normalized.
 * @returns The normalized phone number.
 */
export const normalizePhoneNumber = (phone: string) => {
  // If the phone number is already valid, we want to return it without changes
  if (phone.indexOf('+') !== -1) {
    return phone;
  }

  // Remove non-digits from the phone number
  const digitsOnly = phone.replace(/\D/g, '');

  // Check if the phone number starts with (x)
  const match = phone.match(/\((\d+)\)/);

  if (match) {
    // Extract the country code from (x) and concatenate it with the rest of the digits
    return `+${match[1]}${digitsOnly.slice(match[1].length)}`;
  }

  // If no (x) found, assume default country code +1 and append the digits
  return `+1${digitsOnly}`;
};

export const formatPhoneNumber = (phone: string) => {
  // Remove all non-numeric characters except '+'
  const cleaned = phone.replace(/[^\d+]/g, '');

  // If it's a US number, format it as (XXX) XXX-XXXX
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // If it includes a country code, add a space after the code
  if (cleaned.startsWith('+') && cleaned.length > 10) {
    return `${cleaned.slice(0, cleaned.length - 10)} (${cleaned.slice(-10, -7)}) ${cleaned.slice(-7, -4)}-${cleaned.slice(-4)}`;
  }

  return phone; // Fallback to original if it doesn't match common formats
};
