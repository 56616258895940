import { useState } from 'react';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { DialogContent, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

/**
 * Qr code Modal
 * ! For more information about the qrcode.react package see
 * ! https://github.com/zpao/qrcode.react
 *
 * @param {ModalChildrenProps} { props }
 * @return {*}
 */
const QrCode = ({ props }: ModalChildrenProps) => {
  const value = useState(props?.data || '')[0];
  return (
    <DialogContent data-test="qr-code-modal">
      <Stack gap={2} justifyContent="center" my={2}>
        <QRCodeSVG value={value} style={{ margin: '0 auto' }} />
        <Typography variant="body2" textAlign="center">
          {value}
        </Typography>
      </Stack>
    </DialogContent>
  );
};

export default QrCode;
