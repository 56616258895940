import { ClickableText } from '@bitstopco/bitstop-theme';
import type { ClickableTextProps } from '@bitstopco/bitstop-theme';

import { getBlockexplorerURL } from '@/helpers/links';

export interface TableHashActionProps extends ClickableTextProps {
  to: string;
  isAddress?: boolean;
}

/**
 *  Blockstream Table Column
 *
 * @param {TableHashActionProps} { value }
 * @return {*}
 */
const TableColumnBlockstream = ({ to, isAddress, ...props }: TableHashActionProps) => (
  <ClickableText
    {...props}
    to={getBlockexplorerURL(to, isAddress)}
    overrideTooltipLinkText="Go to Blockstream Explorer"
  />
);

export default TableColumnBlockstream;
