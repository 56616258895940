import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Breadcrumbs, Link, Typography } from '@mui/material';

interface NotificationsSettingsBreadcrumbProps {
  title: string;
  onClick?: () => void;
}
/**
 * Notifications settings breadcrumb presentational component
 *
 * @param {NotificationsSettingsBreadcrumbProps} { title, onClick }
 */
const NotificationsBreadcrumb = ({ title, onClick }: NotificationsSettingsBreadcrumbProps) => (
  <Breadcrumbs data-test="notifications-breadcrumb">
    <Link
      underline="hover"
      sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      color="text.disabled"
      variant="subtitle2"
      onClick={onClick}
    >
      <KeyboardBackspaceIcon sx={{ mr: 2, color: 'error.main', fontSize: 20 }} />
      Notifications
    </Link>
    <Typography color="text.primary" variant="subtitle2" textTransform="capitalize">
      {title}
    </Typography>
  </Breadcrumbs>
);

export default NotificationsBreadcrumb;
