import toast from 'react-hot-toast';

import { AutoField, AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import SCHEMAS from '@/forms/schemas';

import { useAffiliate } from '@/store/api/services/affiliates/affiliates';

import type { CreateAffiliateModel } from '@/types';

/**
 * Create Affiliate Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const AffiliateCreate = ({ hide }: ModalChildrenProps) => {
  const { createAffiliateOrg } = useAffiliate();

  const { schema } = useForm(SCHEMAS.CREATE_AFFILIATE);

  const handleSubmit = async ({
    phone: { phone, dialCode: country_code },
    ...data
  }: CreateAffiliateModel) => {
    try {
      await createAffiliateOrg({ ...data, phone, country_code });
      toast.success('Affiliate organization and user created successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<CreateAffiliateModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ mt: 1, mb: 2 }} data-test="create-affiliate-modal">
        <Typography variant="h6" mb={2}>
          Affiliate Organization
        </Typography>

        <AutoFields fields={['name', 'description']} />

        <Divider sx={{ my: 3 }} />

        <Stack gap={0.5} mb={2}>
          <Typography variant="h6">Affiliate User Details</Typography>
          <Typography variant="body2">
            This user wll have limited viewing access to the sales from machines assigned to the
            organization.
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <AutoField name="first_name" />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutoField name="last_name" />
          </Grid>
        </Grid>
        <AutoFields fields={['email', 'phone']} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField data-test="create-affiliate-modal-submit-btn">Create Affiliate</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default AffiliateCreate;
