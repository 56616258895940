import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type {
  ApiListingRequest,
  TablePageSelectorProps,
  TableWrapperRef,
} from '@bitstopco/bitstop-theme';
import { TablePageSelector, TableWrapper } from '@bitstopco/bitstop-theme';
import { useModal } from '@bitstopco/bitstop-theme';

import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { useParams } from 'react-router';

import SalesStatsCards from '@/components/ui/SalesStatsCards';
import CardTable from '@/components/ui/card/CardTable';
import {
  useGetTransactionsExportMutation,
  useGetTransactionsQuery,
  useGetTransactionsStatsQuery,
} from '@/store/api/services/transactions/transactions';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { SALES_COLUMNS } from '@/constants/app/machine';
import { MACHINE_SALES_STATS } from '@/constants/app/machine';

import type { TransactionsSale } from '@/types';

/**
 * Machines Sales Table
 * * For tables we have the TableWrapper component from the Bitstop Theme, for more information please visit the Bitstop Theme documentation
 * @return {*}
 */
const MachineSales = () => {
  const { id = '0', saleId } = useParams();
  const { show } = useModal();

  const [exportMutation] = useGetTransactionsExportMutation();

  const [payload, setPayload] = useState<ApiListingRequest>();

  const tableRef = useRef<TableWrapperRef>(null);

  const computedPayload = useMemo(() => {
    if (!payload) {
      return skipToken;
    }

    const _payload = cloneDeep(payload);
    _payload.filter ??= { operands: [] };
    _payload.filter.operands.push({
      operator: 'equal',
      property: 'atm_id',
      value: id,
    });

    if (!_payload.filter.operator) {
      _payload.filter.operator = 'and';
    }

    return _payload;
  }, [payload]);

  const statsPayload = useMemo(() => {
    if (typeof computedPayload === 'symbol') {
      return computedPayload;
    }

    return omit(computedPayload, ['pagination', 'page', 'items_per_page']);
  }, [computedPayload]);

  /**
   * Get the Machine transactions filtered by the desired payload
   */
  const {
    data: {
      data = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching,
  } = useGetTransactionsQuery(computedPayload);

  const {
    data: { data: stats } = {},
  } = useGetTransactionsStatsQuery(statsPayload);

  useEffect(() => {
    /**
     * If a saleId is preselected, we want to show the machine transaction details modal
     */
    if (saleId) {
      handleRow({ id: saleId } as TransactionsSale);
    }
  }, []);

  const handleRow = ({ id }: TransactionsSale) => {
    /**
     * Show the machine transaction details modal
     */
    show('transaction-details', {
      data: {
        id,
        hideAtmValue: true,
      },
    });
  };

  // Custom Page Selector, this is necessary since we are using a custom SalesStatsCards
  const renderPageSelector = useCallback(
    (props: TablePageSelectorProps) => (
      <>
        <SalesStatsCards stats={stats} mapData={MACHINE_SALES_STATS} sx={{ mx: 3, mb: 2 }} />
        <TablePageSelector {...props} />
      </>
    ),
    [stats],
  );

  const handleExport = (type: string) => {
    // Use the getExportFile helper to export the Table data using the current payload
    tableRef.current?.getExportFile({
      type,
      mutation: exportMutation,
      overridePayload: computedPayload as ApiListingRequest,
      fileName: 'Nonce-Sales-Report',
    });
  };

  return (
    <CardTable>
      <TableWrapper<TransactionsSale>
        data={data}
        ref={tableRef}
        hideSearch
        isLoading={isFetching || !payload}
        dateRangeFilterKey="created_at"
        sortByDefaultValue="created_at"
        columns={SALES_COLUMNS}
        renderPageSelector={renderPageSelector}
        totalPages={metaPagination?.total_pages}
        totalItems={metaPagination?.total_items}
        tableProps={{
          placeholder: {
            empty: {
              title: 'No sales found',
              content:
                "We can't find any sale that matches your search, please try a different request",
            },
          },
        }}
        onChange={setPayload}
        onRowClick={handleRow}
        onActionMenuItemClick={handleExport}
      />
    </CardTable>
  );
};

export default MachineSales;
