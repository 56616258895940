import { DEFAULT_LISTING_PAYLOAD } from '@/constants/api';

export const BATCHING_MINIMUM_USD_RANGE = {
  minimum: 20,
  maximum: 3000,
};

export const BATCH_DETAILS_PAGINATION = {
  ...DEFAULT_LISTING_PAYLOAD.pagination,
  items_per_page: 500,
};
