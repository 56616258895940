import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import {
  useAssignMachineToLocationMutation,
  useGetInstallableMachinesQuery,
} from '@/store/api/services/locations/locations';
import {
  type ApiListingRequest,
  type ModalChildrenProps,
  TableWrapper,
  type TableWrapperRef,
} from '@bitstopco/bitstop-theme';

import { DEFAULT_PAGINATION } from '@/constants/api';
import { INSTALLED_MACHINES_COLUMNS } from '@/constants/app/machines';

import type { AssignMachineToLocationRequest } from '@/types/location';

const InstallMachine = ({ hide, props }: ModalChildrenProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [multiSelectValues, setMultiSelectValues] = useState<boolean[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [payload, setPayload] = useState<ApiListingRequest>({
    pagination: {
      current_page: 1,
      items_per_page: 10,
      sort_ascending: true,
      sort_by: 'id',
    },
    search_text: '', // Initial empty search
  });
  const tableRef = useRef<TableWrapperRef>(null);

  const {
    data: {
      data = [],
      meta: { pagination: metaPagination = DEFAULT_PAGINATION } = {},
    } = {},
    isFetching, // use isFetching to manage loading state
  } = useGetInstallableMachinesQuery(payload);

  const [assignAtm, { isLoading: isAssigningAtm }] = useAssignMachineToLocationMutation();

  const handleSubmit = async () => {
    try {
      const selectedMachines = data?.filter((_, index) => multiSelectValues[index]);
      if (!selectedMachines || selectedMachines.length === 0) {
        toast.error('Please select at least one machine to assign.');
        return;
      }
      const payload: AssignMachineToLocationRequest = {
        location_id: props.data.location_id,
        atm_ids: selectedMachines.map((machine) => machine.id),
        product_types: [],
      };
      await assignAtm(payload).unwrap();
      toast.success('Machines successfully assigned to the location!');
      hide();
    } catch (error: any) {
      if (error?.message) {
        toast.error(error.message);
      }
      console.error('Error assigning machines:', error);
    }
  };

  const handleSearch = () => {
    setPayload((prev) => ({
      search_text: searchTerm || undefined, // Update search_text in payload
      pagination: prev?.pagination || {
        current_page: 1,
        items_per_page: 10,
        sort_ascending: true,
        sort_by: 'id',
      },
    }));
  };

  useEffect(() => {
    if (data?.length) {
      setMultiSelectValues(Array(data.length).fill(false));
    }
  }, [data]);

  // Sync local loading state with isFetching from query
  useEffect(() => {
    setLoading(isFetching); // Set local loading state based on query's isFetching
  }, [isFetching]);

  return (
    <>
      <DialogContent sx={{ py: 2 }}>
        <Typography variant="h6">Select Machine</Typography>

        <Typography mt={1} variant="body1" sx={{ mb: 0.65 }}>
          Search for a machine below to install at{' '}
          {props?.data?.location_name && (
            <Typography variant="body1" color="error" component="span">
              {props.data?.location_name}
            </Typography>
          )}
          .
        </Typography>

        {/* Custom Search Input with Button */}
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by machine's name, serial, or ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') handleSearch();
          }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            ),
          }}
          sx={{ '& .MuiInputBase-root': { pr: 0 }, mb: 2 }}
        />

        <Stack>
          <TableWrapper
            ref={tableRef}
            data={data}
            hideDateRangePicker
            isLoading={isLoading} // Use local loading state
            columns={INSTALLED_MACHINES_COLUMNS}
            multiSelectValues={multiSelectValues}
            onMultiSelectValuesChange={setMultiSelectValues}
            itemsPerPageDefaultValue={5}
            totalPages={metaPagination?.total_pages}
            totalItems={metaPagination?.total_items}
            onChange={setPayload}
            tableProps={{
              placeholder: {
                empty: {
                  title: 'No machines found',
                  content:
                    "We can't find any machine that matches your search, please try a different request",
                },
              },
            }}
            actionMenuItems={[]}
            hideSearch={true}
            onRowClick={(row) => {
              const rowIndex = data.findIndex((item) => item.id === row.id);
              if (rowIndex !== -1) {
                setMultiSelectValues((prev) => {
                  const newValues = [...prev];
                  newValues[rowIndex] = !newValues[rowIndex];
                  return newValues;
                });
              }
            }}
            sx={{
              '& .MuiBox-root': {
                pt: 0,
              },
              '& .MuiTable-root': {
                minWidth: 720,
              },
            }}
          />
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <LoadingButton variant="contained" onClick={handleSubmit} loading={isAssigningAtm}>
          Add Machine to Location
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default InstallMachine;
