import type { MachineStatusEnum } from '@/types/generated/heartbeat-api';

export const HEARTBEAT_STATUS_COLORS: Record<MachineStatusEnum, string> = {
  0: 'action.selected',
  1: 'error.main',
  2: 'warning.main',
  3: 'success.main',
};

export const HEARTBEAT_STATUS_LABELS: Record<MachineStatusEnum, string> = {
  0: 'No Data',
  1: 'Down',
  2: 'Connection Lost',
  3: 'Online',
};
