import { Box, Typography } from '@mui/material';
import { lazy } from 'react';

import { CONSTANTS, ClickableText, TableColumnStatus } from '@bitstopco/bitstop-theme';
import {
  formatCurrency,
  formatCurrencyBySymbol,
  getCurrencySymbol,
} from '@bitstopco/bitstop-theme/helpers';

import type { Columns, TableColumnSaleStatusCodes } from '@bitstopco/bitstop-theme';
import type { Column } from '@bitstopco/bitstop-theme/components/Table';

import MachineCashLogs from '@/components/machine/tabs/cashLogs/MachineCashLogs';
import MachineMonitoring from '@/components/machine/tabs/monitoring/MachineMonitoring';
import MachineSales from '@/components/machine/tabs/sales/MachineSales';
import MachinesStatus from '@/components/machines/tabs/atms/tableColumns/MachinesStatus';

import DotItem from '@/components/machine/tabs/machine/MachineMachineStatusDot';

import TransactionsSaleSent from '@/components/transactions/tabs/sales/tableColumns/TransactionsSaleSent';
import TableColumnBlockstream from '@/components/ui/table/TableColumnBlockstream';
import TableColumnCustomer from '@/components/ui/table/TableColumnCustomer';

import type { MachineDetail, TransactionsSale } from '@/types';

import TableColumnDate from '@/components/ui/table/TableColumnDate';
import TableHeaderTooltipIcon from '@/components/ui/table/TableHeaderDateTooltipIcon';

const MachineMachine = lazy(() => import('@/components/machine/tabs/machine/MachineMachine'));

/**
 * Machine Panels configuration (Tabs)
 * * PANELS: tab names
 * * PANELS_ROUTES: tab routes
 * * PANELS_COMPONENTS: tab related components
 */
export const PANELS = ['Machine', 'Sales', 'Cash Logs', 'Monitoring'];
export const PANELS_ROUTES = [
  '/machine/:id',
  '/machine/:id/sales',
  '/machine/:id/cash-logs',
  '/machine/:id/monitoring',
];
export const PANELS_COMPONENTS = [MachineMachine, MachineSales, MachineCashLogs, MachineMonitoring];

/**
 * Machine details List configuration: this configuration will be used to generate all the List items with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const MACHINE_DETAILS: Columns<MachineDetail> = {
  defaultValue: '-',
  items: [
    {
      label: 'Name',
      value: 'slug',
      valueSx: CONSTANTS.TWO_LINES_ELLIPSIS,
    },
    {
      label: 'Location',
      value: 'location',
      valueMapper: (value: MachineDetail['location']) =>
        value && Number.parseInt(value.id) !== 0 ? (
          <Box>
            <Typography variant="body2">{value.slug}</Typography>
            <ClickableText
              fixIcon
              text={value.id}
              clipboardValue={value.id}
              to={`/location/${value.id}`}
              name="Location ID"
              sx={{
                '& span': {
                  whiteSpace: 'normal',
                },
              }}
            />
          </Box>
        ) : null,
    },
    {
      label: 'Timezone',
      value: 'location.local_time_zone',
      defaultValue: 'Etc/UTC',
    },
    {
      label: 'Serial',
      value: 'serial',
    },
    {
      label: 'Machine ID',
      value: 'id',
      valueMapper: (value: MachineDetail['id']) =>
        value && (
          <ClickableText
            fixIcon
            text={value.toString()}
            clipboardValue={value.toString()}
            name="Machine ID"
            sx={{
              '& span': {
                whiteSpace: 'normal',
              },
            }}
          />
        ),
    },
    {
      label: 'Pickup ID',
      value: 'pick_up_id',
    },
    {
      label: 'Version',
      value: 'app_version',
    },
    // {
    //   label: 'Hours',
    //   value: 'location.hours',
    //   valueMapper: (value: MachineDetail['location']['hours']) =>
    //     value && (
    //       <div
    //         dangerouslySetInnerHTML={{
    //           __html: (Array.isArray(value) ? value : ['-']).join('<br/>'),
    //         }}
    //       />
    //     ),
    // },
  ],
};

const machineStatusesCommonStyles = {
  labelSx: {
    minWidth: 150,
  },
  valueSx: {
    overflow: 'hidden',
    textAlign: { xs: 'right', lg: 'left' },
  },
};

/**
 * Machine statuses List configuration: this configuration will be used to generate all the List items with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const MACHINE_STATUSES: Columns<MachineDetail> = {
  defaultValue: '-',
  items: [
    {
      value: 'status',
      label: 'Machine Status',
      valueMapper: (value: MachineDetail['status']) => value && <MachinesStatus value={value} />,
    },
    {
      label: 'Last Seen',
      value: 'last_seen_humanized',
    },
    {
      label: 'Last Restart',
      value: 'last_restart_humanized',
    },
    {
      label: 'Cash Total',
      value: 'total_worth_of_bills',
      valueMapper: (value: MachineDetail['total_worth_of_bills']) => formatCurrency(value),
    },
    {
      label: 'Command In Progress',
      value: 'formattedCommandInProgress',
    },
    {
      label: 'Last Command',
      value: 'lastCommand',
    },
    {
      label: 'Current Command',
      value: 'currentCommand',
    },
  ].map((item) => ({ ...item, ...machineStatusesCommonStyles })),
};

const statusesConfig = {
  valueSx: {
    marginLeft: 0,
  },
  labelSx: {
    fontWeight: 500,
  },
};

export const MACHINE_STATUSES_COL1: (manufacturer: string) => Columns<MachineDetail> = (
  manufacturer,
) => {
  const items: Column<MachineDetail>[] = [];
  if (manufacturer === 'kole') {
    items.push({
      label: 'BNR Device',
      value: 'bulk_note_recycler_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    });
  } else {
    items.push({
      label: 'Bill Dispenser',
      value: 'bill_dispenser_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    });
  }
  items.push(
    {
      label: 'Barcode Scanner',
      value: 'barcode_scanner_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    },
    {
      label: 'Printer',
      value: 'printer_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    },
    {
      label: 'Imaging Device',
      value: 'imaging_device_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    },
  );

  return {
    ...statusesConfig,
    items,
  };
};

export const MACHINE_STATUSES_COL2: (manufacturer: string) => Columns<MachineDetail> = (
  manufacturer,
) => {
  const items: Column<MachineDetail>[] = [];
  if (manufacturer !== 'kole') {
    items.push({
      label: 'Bill Acceptor',
      value: 'bill_acceptor_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    });
  }
  items.push(
    {
      label: 'Card Reader',
      value: 'card_reader_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    },
    {
      label: 'Phidget Board',
      value: 'phidget_board_status',
      valueMapper: (value: number) => <DotItem value={value} />,
    },
  );

  return {
    ...statusesConfig,
    items,
  };
};

/**
 * Machine statuses dots List configuration (MACHINE_STATUSES_COL1 + MACHINE_STATUSES_COL2): this configuration will be used to generate all the List items with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const MACHINE_STATUSES_DOTS = (manufacturer: string) => [
  MACHINE_STATUSES_COL1(manufacturer),
  MACHINE_STATUSES_COL2(manufacturer),
];

/**
 * Machine sales Table configuration: this configuration will be used to generate all the Table columns with the desired data
 * * You can learn more about the List items configuration in the Theme Live docs: https://github.com/bitstopco/bitstop-theme#live-docs
 */
export const SALES_COLUMNS: Columns<TransactionsSale> = {
  defaultValue: '-',
  labelSx: {
    whiteSpace: 'nowrap',
  },
  valueSx: {
    whiteSpace: 'nowrap',
  },
  items: [
    {
      value: 'id',
      label: 'Sale ID',
      hideSort: true,
      valueMapper: (value: TransactionsSale['id']) =>
        value && (
          <ClickableText
            text={value}
            name="Sale ID"
            clipboardValue={value}
            truncate={{ twoSegments: true }}
          />
        ),
    },
    {
      value: 'transaction_hash',
      label: 'TX Hash',
      hideSort: true,
      valueMapper: (value: TransactionsSale['transaction_hash']) =>
        value && (
          <TableColumnBlockstream
            name="TX Hash"
            to={value}
            text={value}
            clipboardValue={value}
            truncate={{ twoSegments: true }}
          />
        ),
    },
    {
      value: 'created_at',
      label: 'Date',
      labelMapper: (value: string) => <TableHeaderTooltipIcon label={value} />,
      valueMapper: (value: TransactionsSale['created_at'], { local_created_at, local_time_zone }) =>
        value && (
          <TableColumnDate
            value={value}
            options={{
              timeZone: 'UTC',
            }}
            auxiliaryDate={{
              value: local_created_at,
              options: {
                timeZone: local_time_zone,
                normalizeAsUTC: false,
                performUtcToZonedTime: false,
              },
            }}
          />
        ),
    },
    {
      value: 'customer_id',
      label: 'Customer',
      valueMapper: (id: TransactionsSale['customer_id'], { customer }) =>
        customer && <TableColumnCustomer data={{ ...customer, id }} link />,
    },
    {
      value: 'inserted',
      label: 'Inserted',
      valueMapper: (value: TransactionsSale['inserted'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'crypto_amount',
      label: 'Sent',
      valueMapper: (value: TransactionsSale['crypto_amount'], data) => (
        <TransactionsSaleSent {...{ value: value?.toString(), data }} />
      ),
    },
    {
      value: 'rate',
      label: 'Rate',
      valueMapper: (value: TransactionsSale['rate'], data) =>
        formatCurrencyBySymbol(value, data.currency_code),
      valueSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'stage',
      label: 'Status',
      valueMapper: (value: TransactionsSale['stage']) =>
        value && <TableColumnStatus value={value as TableColumnSaleStatusCodes} />,
      valueSx: {
        textAlign: 'center',
        pr: 5,
      },
    },
    {
      value: 'fee',
      label: 'Fee',
      valueMapper: (value: TransactionsSale['fee'], data) =>
        `${getCurrencySymbol(data.currency_code)}${value || 0}`,
      labelSx: {
        textAlign: 'right',
      },
    },
    {
      value: 'address',
      label: 'Address',
      hideSort: true,
      valueMapper: (value: TransactionsSale['address']) =>
        value && (
          <ClickableText
            text={value}
            clipboardValue={value}
            name="Address"
            truncate={{ twoSegments: true }}
          />
        ),
    },
  ],
};

/**
 * Machine Action button configuration
 * * MACHINE_ACTIONS: actions labels
 * * MACHINE_ACTIONS_MODALS: actions related modals ids
 */
export const MACHINE_ACTIONS = [
  { label: 'Restart Machine', action: 'machine-restart', disabled: false },
  { label: 'Change Machine Status', action: 'machine-status', disabled: false },
  // 'Edit Location Details',
  { label: 'Edit Machine Details', action: 'machine-edit', disabled: false },
];

export const MACHINE_ACTIONS_MODALS = ['machine-restart', 'machine-status', 'machine-edit'];

/**
 * Machine valid hours, this constant will be used to generate the schedule component
 */
export const MACHINE_VALID_HOURS = ['24-Hours', ...CONSTANTS.DAY_HOURS];

export const MACHINE_RANGES_PLACEHOLDERS = ['Opens at...', 'Closes at...'];

export const MACHINE_RANGES_FIRST = ['', ...MACHINE_VALID_HOURS];
export const MACHINE_RANGES_SECOND = ['', ...CONSTANTS.DAY_HOURS];

export const MACHINE_RANGES_RANGES_OPTIONS = [MACHINE_RANGES_FIRST, MACHINE_RANGES_SECOND];

export const MACHINE_TAG_COLORS = [
  '#1ABC9C',
  '#2ECC71',
  '#3498DB',
  '#9B59B6',
  '#34495E',
  '#16A085',
  '#27AE60',
  '#2980B9',
  '#8E44AD',
  '#2C3E50',
  '#F1C40F',
  '#E67E22',
  '#E74C3C',
  '#C8CDD0',
  '#95A5A6',
  '#F39C12',
  '#D35400',
  '#C0392B',
  '#BDC3C7',
  '#7F8C8D',
];

export const DEFAULT_MACHINE_TAG_COLOR = MACHINE_TAG_COLORS[9];

export const MACHINE_SALES_STATS = {
  total_sales: {
    label: 'Total Sales',
    mapper: (value: number) => formatCurrency(value),
  },
  total_fees: {
    label: 'Total Fees',
    mapper: (value: number) => formatCurrency(value),
  },
  total_transactions: {
    label: 'Total transactions',
    mapper: (value: number) => value.toString(),
  },
};

export const MACHINES_BULK_MAX_TAGS_ALLOWED = 1;
