import { useState } from 'react';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';

import type { AddNewProviderModel } from '@/types';

/**
 * Wallets Add New Provider Modal
 * TODO: WIP
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const AddNewProvider = ({ hide, props }: ModalChildrenProps) => {
  const [selectedProvider] = useState<Record<string, any>>({});
  // TODO: get the provider data
  const { schema } = useForm(SCHEMAS.ADD_NEW_PROVIDER, props?.data);

  const handleSubmit = async (/*data: AddNewProviderModel*/) => {
    try {
      // TODO: perform add provider request
      toast.success('New Provider added successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<AddNewProviderModel>
      onChange={(field /*, value*/) => {
        if (field !== 'provider') {
          return;
        }

        // set the selected provider here or doit using the form ref
        //setSelectedProvider;
      }}
      showInlineError
      schema={schema}
      onSubmit={handleSubmit}
    >
      <DialogContent sx={{ my: 2 }}>
        <Stack direction="row" spacing={2}>
          <img loading="lazy" src={selectedProvider.image} width="24" height={24} alt="Coin" />
          <Typography variant="h6">{selectedProvider.name} provider</Typography>
        </Stack>

        <Typography variant="body1" mt={1}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna.
        </Typography>

        <Box mt={2}>
          <AutoFields />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to Settings</Button>
        <SubmitField label="Save" />
      </DialogActions>
    </AutoForm>
  );
};

export default AddNewProvider;
