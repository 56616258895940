import { ModalProvider } from '@bitstopco/bitstop-theme';
import type { Modals } from '@bitstopco/bitstop-theme';

import AffiliateAddMachines from './modules/affiliates/AddMachines';
import AffiliateAddUser from './modules/affiliates/AddUser';
import CreateAffiliate from './modules/affiliates/CreateAffiliate';
import BatchDetails from './modules/batching/BatchDetails';
import BatchingSettings from './modules/batching/BatchingSettings';
import ActivateVendor from './modules/blockchainAnalytics/ActivateVendor';
import ConnectVendor from './modules/blockchainAnalytics/ConnectVendor';
import EditVendor from './modules/blockchainAnalytics/EditVendor';
import ActionNote from './modules/customers/ActionNote';
import CustomNote from './modules/customers/CustomNote';
import LinkAccount from './modules/customers/LinkAccount';
import RemoveMatch from './modules/customers/RemoveMatch';
import UnlinkAccount from './modules/customers/UnlinkAccount';
import UploadDocument from './modules/customers/UploadDocument';
import CreateLocation from './modules/locations/CreateLocation';
import EditLocation from './modules/locations/EditLocation';
import EditLocationPrompt from './modules/locations/EditLocationPrompt';
import InstallMachine from './modules/locations/InstallMachine';
import LocationCustomNote from './modules/locations/LocationCustomNote';
import LocationEditNote from './modules/locations/LocationEditNote';
import PublishLocation from './modules/locations/PublishLocation';
import RemoveMachine from './modules/locations/RemoveMachine';
import RemoveMachines from './modules/locations/RemoveMachines';
import UnpublishLocation from './modules/locations/UnpublishLocation';
import ClearCashboxMachine from './modules/machine/ClearCashboxMachine';
import EditMachine from './modules/machine/EditMachine';
import RestartMachine from './modules/machine/RestartMachine';
import StatusMachine from './modules/machine/StatusMachine';
import BulkAssociateTags from './modules/machines/BulkAssociateTags';
import BulkUnassociateTags from './modules/machines/BulkUnassociateTags';
import CreateMerchant from './modules/merchants/CreateMerchant';
import Notifications from './modules/notifications/Notifications';
import NotificationsDetail from './modules/notifications/NotificationsDetail';
import NotificationsSettings from './modules/notifications/NotificationsSettings';
import DeleteTag from './modules/tag/DeleteTag';
import RefundTransaction from './modules/transaction/RefundTransaction';
import TransactionDetails from './modules/transaction/TransactionDetails';
import UnfreezeTransaction from './modules/transaction/UnfreezeTransaction';
import CreateUser from './modules/users/CreateUser';
import UserEditRole from './modules/users/EditRole';
import BitgoSettings from './modules/wallet/BitgoSettings';
import GenerateAddressesv1 from './modules/wallet/GenerateAddresses';
import AddNewProvider from './modules/wallets/AddNewProvider';
import ConnectWallet from './modules/wallets/ConnectWallet';
import EditWallet from './modules/wallets/EditWallet';
import GenerateAddresses from './modules/wallets/GenerateAddresses';
import QrCode from './modules/wallets/QrCode';

const MODALS: Modals[] = [
  {
    id: 'tag-delete',
    title: 'Delete Tag',
    component: DeleteTag,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576 },
    },
  },
  {
    id: 'connect-wallet',
    title: 'Connect Wallet',
    component: ConnectWallet,
    size: 'sm',
  },
  {
    id: 'edit-wallet',
    title: 'Edit Wallet',
    component: EditWallet,
    size: 'sm',
  },
  {
    id: 'add-provider',
    title: 'Add new provider',
    component: AddNewProvider,
    size: 'sm',
  },
  {
    id: 'qr-code',
    title: 'Deposit Address',
    component: QrCode,
    size: 'sm',
  },
  {
    id: 'generate-addresses',
    title: 'Generate New Addresses',
    component: GenerateAddresses,
    size: 'sm',
  },
  {
    id: 'wallet-generate-addresses',
    title: 'Generate New Addresses',
    component: GenerateAddressesv1,
    size: 'sm',
  },
  {
    id: 'machine-restart',
    title: 'Restart Machine',
    component: RestartMachine,
    size: 'sm',
  },
  {
    id: 'machine-status',
    title: 'Change Machine Status',
    component: StatusMachine,
    size: 'sm',
  },
  {
    id: 'machine-edit',
    title: 'Edit Machine Details',
    component: EditMachine,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 600, width: '100%' },
    },
  },
  {
    id: 'location-create',
    title: 'New Location',
    component: CreateLocation,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 600, width: '100%' },
    },
  },
  {
    id: 'machine-clear-cashbox',
    title: 'Clear Cashbox',
    component: ClearCashboxMachine,
    size: 'sm',
  },
  {
    id: 'transaction-details',
    title: 'Sale Details',
    component: TransactionDetails,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 552 },
    },
  },
  {
    id: 'customer-action-note',
    title: 'Customer Note',
    component: ActionNote,
    size: 'sm',
  },
  {
    id: 'custom-note',
    title: 'Custom Note',
    component: CustomNote,
    size: 'sm',
  },
  {
    id: 'location-custom-note',
    title: 'Custom Note',
    component: LocationCustomNote,
    size: 'sm',
  },
  {
    id: 'location-edit-note',
    title: 'Edit Note',
    component: LocationEditNote,
    size: 'sm',
  },
  {
    id: 'upload-document',
    title: 'Upload document',
    component: UploadDocument,
    size: 'sm',
  },
  {
    id: 'connect-vendor',
    title: 'Connect new vendor',
    component: ConnectVendor,
    size: 'sm',
  },
  {
    id: 'activate-vendor',
    title: 'Activate Vendor',
    component: ActivateVendor,
    size: 'sm',
  },
  {
    id: 'edit-vendor',
    title: 'Edit Vendor',
    component: EditVendor,
    size: 'sm',
  },
  {
    id: 'notifications',
    title: 'Notifications',
    component: Notifications,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 552, width: '100%' },
    },
  },
  {
    id: 'notifications-settings',
    title: 'Notifications',
    component: NotificationsSettings,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 552, width: '100%' },
    },
  },
  {
    id: 'notifications-detail',
    title: 'Notifications',
    component: NotificationsDetail,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 552, width: '100%' },
    },
  },
  {
    id: 'customer-link-account',
    title: 'Link Account',
    component: LinkAccount,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 586, width: '100%' },
    },
  },
  {
    id: 'customer-unlink-account',
    title: 'Unlink Account',
    component: UnlinkAccount,
    size: 'md',
    PaperProps: {
      sx: { maxWidth: 720 },
    },
  },
  {
    id: 'customer-remove-match',
    title: 'Remove Match',
    component: RemoveMatch,
    size: 'sm',
  },
  {
    id: 'batching-settings',
    title: 'Batching settings',
    component: BatchingSettings,
    size: 'sm',
  },
  {
    id: 'batching-details',
    title: 'Batching details',
    component: BatchDetails,
    size: 'xl',
  },
  {
    id: 'machines-bulk-associate-tags',
    title: 'Add Tag',
    component: BulkAssociateTags,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'machines-bulk-unassociate-tags',
    title: 'Remove Tag',
    component: BulkUnassociateTags,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'wallet-bitgo-settings',
    title: 'Bitgo Settings',
    component: BitgoSettings,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'user-edit-role',
    title: 'Edit Role',
    component: UserEditRole,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'user-create',
    title: 'Create User',
    component: CreateUser,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'affiliate-create',
    title: 'Create Affiliate',
    component: CreateAffiliate,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'affiliate-add-user',
    title: 'Add Affiliate User',
    component: AffiliateAddUser,
    size: 'sm',
    PaperProps: {
      sx: { maxWidth: 576, width: '100%' },
    },
  },
  {
    id: 'affiliate-add-machines',
    title: 'Add Machines',
    component: AffiliateAddMachines,
    size: 'lg',
  },
  {
    id: 'location-edit',
    title: 'Edit Location Details',
    component: EditLocation,
    anchor: 'right',
    type: 'drawer',
    PaperProps: {
      sx: { maxWidth: 600, width: '100%' },
    },
  },
  {
    id: 'location-publish',
    title: 'Publish Location',
    component: PublishLocation,
    size: 'sm',
  },
  {
    id: 'location-edit-prompt',
    title: 'Edit Location Details',
    component: EditLocationPrompt,
    size: 'sm',
  },
  {
    id: 'location-unpublish',
    title: 'Unpublish Location',
    component: UnpublishLocation,
    size: 'sm',
  },
  {
    id: 'location-install-machine',
    title: 'Install Machine',
    component: InstallMachine,
    size: 'md',
  },
  {
    id: 'location-remove-machines',
    title: 'Remove Machines',
    component: RemoveMachines,
    size: 'sm',
  },
  {
    id: 'location-remove-machine',
    title: 'Remove Machine',
    component: RemoveMachine,
    size: 'sm',
  },
  {
    id: 'merchant-create',
    title: 'Create Merchant',
    component: CreateMerchant,
    size: 'sm',
  },
  {
    id: 'unfreeze-transaction',
    title: 'Unfreeze Transaction',
    component: UnfreezeTransaction,
    size: 'sm',
  },
  {
    id: 'refund-transaction',
    title: 'Refund Transaction',
    component: RefundTransaction,
    size: 'sm',
  },
];

const ModalProviderWrapper = ({ children }: { children: React.ReactNode }) => (
  <ModalProvider modals={MODALS}>
    <>{children}</>
  </ModalProvider>
);

export default ModalProviderWrapper;
