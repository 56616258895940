import { useEffect } from 'react';

import { Authentication } from '@bitstopco/bitstop-theme';
import { ErrorBoundary } from 'react-error-boundary';

import { initGoogleScript } from '@/helpers/googleLoader';
import { MainLayout } from '@/layouts';

import { MAIN_ROUTES, SETTINGS_ROUTES } from '@/constants/app/views';

import ErrorPage from '@/pages/Error';

const privateRoutes = [
  {
    element: <MainLayout settings />,
    children: [...SETTINGS_ROUTES],
  },
  {
    element: <MainLayout />,
    children: [...MAIN_ROUTES],
  },
];

const Router = () => {
  useEffect(() => {
    initGoogleScript();
  }, []);

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Authentication userAuthenticatedRoutes={privateRoutes} />
    </ErrorBoundary>
  );
};

export default Router;
