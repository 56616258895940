import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Stack, TextField } from '@mui/material';
import { MuiTelInput as PhoneField } from 'mui-tel-input';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import toast from 'react-hot-toast';

import { useCreateMerchantMutation } from '@/store/api/services/merchants/merchants';

import { isValidPhone } from '@/forms';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

const CreateMerchantSchema = z.object({
  owner_first_name: z.string().min(2, 'First Name is Required'),
  owner_last_name: z.string().min(2, 'Last Name is Required'),
  owner_email: z.string().email('Valid Email is Required'),
  owner_phone_number: isValidPhone(z.string(), 'Valid Phone Number is Required'),
});

type CreateMerchantSchemaType = z.infer<typeof CreateMerchantSchema>;

const CreateMerchant = ({ hide }: ModalChildrenProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateMerchantSchemaType>({
    resolver: zodResolver(CreateMerchantSchema),
    mode: 'onSubmit',
  });

  const [createMerchant] = useCreateMerchantMutation();

  const onSubmit = async (payload: CreateMerchantSchemaType) => {
    try {
      await createMerchant(payload).unwrap();
      toast.success('Merchant created successfully');
      hide('merchant-create');
    } catch (error: any) {
      if (error?.message) {
        const err: { message: string } = error;
        toast.error(err.message);
      }
    }
  };

  return (
    <>
      <DialogContent sx={{ py: 3 }}>
        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            <TextField
              sx={{ flex: 1 }}
              label="First Name *"
              fullWidth
              error={!!errors.owner_first_name}
              helperText={errors?.owner_first_name?.message}
              size="small"
              {...register('owner_first_name')}
            />
            <TextField
              sx={{ flex: 1 }}
              label="Last Name *"
              fullWidth
              error={!!errors.owner_last_name}
              helperText={errors?.owner_last_name?.message}
              size="small"
              {...register('owner_last_name')}
            />
          </Stack>
          <Controller
            name="owner_phone_number"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <PhoneField
                defaultCountry="US"
                label="Phone Number *"
                size="small"
                value={value}
                onChange={(_, info) => onChange(info.numberValue)}
                error={!!errors.owner_phone_number}
                helperText={errors?.owner_phone_number?.message}
              />
            )}
          />
          <TextField
            label="Email *"
            fullWidth
            error={!!errors.owner_email}
            helperText={errors?.owner_email?.message}
            size="small"
            {...register('owner_email')}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => hide('merchant-create')}>Discard</Button>
        <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
          Create Merchant
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateMerchant;
