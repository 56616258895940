import { useState } from 'react';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import { useNotes } from '@/store/api/services/kyc/notes';

import { CUSTOMER_INIT_VALUES } from '@/constants/app/customers/customer';

import type { Customer, CustomerNoteModel } from '@/types';

const NOTE_TYPE = 'custom';

/**
 * Customer Custom Note Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const CustomNote = ({ hide, props }: ModalChildrenProps) => {
  const customer = useState<Customer>(props?.customer || CUSTOMER_INIT_VALUES)[0];

  const { schema } = useForm(SCHEMAS.CUSTOM_NOTE);

  const { createNote } = useNotes(customer);

  const handleSubmit = async (data: CustomerNoteModel) => {
    try {
      await createNote({
        ...data,
        type: NOTE_TYPE,
      }).unwrap();

      toast.success('Note created successfully');

      hide();
    } catch {
      //
    }
  };

  return (
    <AutoForm<CustomerNoteModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back</Button>
        <SubmitField variant="contained">Create Note</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default CustomNote;
