import { env } from '@bitstopco/bitstop-theme';

export const BLOCKEXPLORER_BASE_URL = `${import.meta.env.VITE_BLOCKSTREAM_URL}${
  env.isTestnet() ? '/testnet/tx/' : '/tx/'
}`;

export const BLOCKEXPLORER_BASE_URL_ADDRESS = `${import.meta.env.VITE_BLOCKSTREAM_URL}${
  env.isTestnet() ? '/testnet/address/' : '/address/'
}`;

/**
 * Get the block explorer URL prepending his domain
 *
 * @param {string} value
 */
export const getBlockexplorerURL = (value: string, isAddress = false) =>
  `${isAddress ? BLOCKEXPLORER_BASE_URL_ADDRESS : BLOCKEXPLORER_BASE_URL}${value}`;
