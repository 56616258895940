import { useEffect, useState } from 'react';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { usePutCommandQueueRestartMutation } from '@/store/api/services/machines/machine';

/**
 * Restart Machine Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const RestartMachine = ({ hide, props }: ModalChildrenProps) => {
  /**
   * State to manage the command in progress status value
   */
  const [hasCommandInProgress, setHasCommandInProgress] = useState(false);

  /**
   * Get the Restart Machine mutation
   */
  const [restartMachine, { isLoading }] = usePutCommandQueueRestartMutation();

  useEffect(() => {
    /**
     * The first time we open this modal we want to know if there's a command in progress
     */
    setHasCommandInProgress(props?.formattedCommandInProgress === 'Yes');
  }, []);

  const handleRestart = async () => {
    /**
     * Submit the restart machine action
     */
    try {
      await restartMachine(props?.data?.id).unwrap();
      toast.success(`Restart command successfully sent to machine ${props?.data?.id}`);
      hide();
    } catch {
      //
    }
  };

  if (hasCommandInProgress) {
    return (
      <>
        <DialogContent sx={{ my: 2 }}>
          <Box>
            <Typography variant="h6" mb={1}>
              Unable to restart machine
            </Typography>
            <Alert color="warning" icon={<ErrorIcon />} sx={{ alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle2" color="primary.main">
                  Command in Progress
                </Typography>
                <Typography variant="body2" color="primary.main">
                  Cannot issue restart, please try again in a few minutes.
                </Typography>
              </Box>
            </Alert>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={hide}>
            Back to machine
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Box>
          <Typography variant="h6" mb={1}>
            Are you sure you would like to restart this machine?
          </Typography>
          <Typography variant="body1">This restart action cannot be undone.</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to machine</Button>
        <LoadingButton
          variant="contained"
          onClick={handleRestart}
          loading={isLoading}
          disabled={isLoading}
        >
          Yes, restart machine
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RestartMachine;
