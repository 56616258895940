import { memo } from 'react';

import { Timeline as TimelineBase } from '@mui/lab';
import type { TimelineDotProps } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItemElm, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box } from '@mui/material';

import TimelineDot from '../TimelineDot';

import TimelineAvatar from './TimelineAvatar';

export interface TimelineItem {
  name?: string;
  content: JSX.Element;
  icon: JSX.Element | string;
}

export interface TimelineProps {
  items: TimelineItem[];
  dotProps?: TimelineDotProps;
}

const Timeline = ({ items, dotProps }: TimelineProps) => (
  <TimelineBase
    data-test="timeline"
    sx={{
      p: 0,
      m: 0,
      [`& .${timelineItemClasses.root}:before`]: {
        flex: 0,
        padding: 0,
      },
    }}
  >
    {items.map(({ content, icon, name }, index) => {
      if (icon === 'initial') {
        icon = <TimelineAvatar name={name} />;
      }

      return (
        <TimelineItemElm
          key={`timeline-item-${index}`}
          data-test="timeline-item"
          sx={{
            minHeight: items.length - 1 <= index ? 'auto' : '70px',
          }}
        >
          <TimelineSeparator>
            <TimelineDot {...dotProps}>{icon}</TimelineDot>
            {index < items.length - 1 && (
              <TimelineConnector sx={{ backgroundColor: 'rgba(225, 227, 234, 0.9)' }} />
            )}
          </TimelineSeparator>
          <Box ml={2} width="100%" overflow="hidden">
            {content}
          </Box>
        </TimelineItemElm>
      );
    })}
  </TimelineBase>
);

export default memo(Timeline);
