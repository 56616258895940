import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Stack, TextField } from '@mui/material';
import { MuiTelInput as PhoneField } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import EditLocationSchedule from '@/components/location/schedule/EditLocationSchedule';
import { isValidPhone } from '@/forms';
import { AddressSearch, MerchantSelect, type Place } from '@/forms/components';
import { useCreateLocationMutation } from '@/store/api/services/locations/location';

import type { Merchant } from '@/types';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

const CreateLocationSchema = z.object({
  place_id: z.string().optional(),
  hours: z.array(z.string()),
  name: z.string().min(2, 'Location Name is Required'),
  business_contact_name: z.string().min(2, 'Business Contact Name is Required'),
  business_contact_phone: isValidPhone(z.string(), 'Valid Phone Number is Required'),
  merchant_id: z.string().min(2, 'Merchant is Required'),
  street_address: z.string().min(2, 'Valid Address is Required'),
  address2: z.string().optional(),
  city: z.string().min(2, 'Valid City is Required'),
  zipcode: z.string().min(5, 'Valid Zipcode is Required'),
  state: z.string().min(2, 'Valid State is Required'),
  country: z.string().min(2, 'Valid Country is Required'),
});

type CreateLocationSchemaType = z.infer<typeof CreateLocationSchema>;

const CreateLocation = ({ hide }: ModalChildrenProps) => {
  const [lastSearch, setLastSearch] = useState<Place>();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateLocationSchemaType>({
    resolver: zodResolver(CreateLocationSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    setValue('hours', ['24/7']);
  }, [setValue]);

  const [createLocation] = useCreateLocationMutation();

  const onSubmit = async (payload: CreateLocationSchemaType) => {
    if (
      lastSearch?.street_address !== payload.street_address ||
      lastSearch?.address2 !== payload.address2 ||
      lastSearch?.city !== payload.city ||
      lastSearch?.country !== payload.country ||
      lastSearch?.state !== payload.state ||
      lastSearch?.zipcode !== payload.zipcode
    ) {
      payload.place_id = undefined;
    }
    try {
      await createLocation(payload).unwrap();
      toast.success('Location created successfully');
      hide();
    } catch (error: any) {
      if (error?.message) {
        const err: { message: string } = error;
        toast.error(err.message);
      }
    }
  };

  const handleAddressSearch = (place: Place) => {
    Object.keys(place).forEach((key) => {
      setValue(key as keyof CreateLocationSchemaType, place[key], { shouldValidate: true });
    });
    setLastSearch(place);
  };

  return (
    <>
      <DialogContent sx={{ py: 4 }}>
        <Stack gap={2}>
          <TextField
            label="Location Name *"
            fullWidth
            error={!!errors.name}
            helperText={errors?.name?.message}
            size="small"
            {...register('name')}
          />
          <TextField
            label="Business Contact Name *"
            size="small"
            fullWidth
            error={!!errors.business_contact_name}
            helperText={errors?.business_contact_name?.message}
            {...register('business_contact_name')}
          />
          <Controller
            name="business_contact_phone"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <PhoneField
                defaultCountry="US"
                label="Business Contact Phone *"
                size="small"
                value={value}
                onChange={(_, info) => onChange(info.numberValue ?? '')}
                error={!!errors.business_contact_phone}
                helperText={errors?.business_contact_phone?.message}
              />
            )}
          />

          <Controller
            name="merchant_id"
            control={control}
            defaultValue=""
            render={({ field: { onChange } }) => {
              const [_value, setValue] = useState<Merchant | null>(null);
              return (
                <MerchantSelect
                  label="Merchant *"
                  labelProps={{ sx: { fontSize: 14 }, error: !!errors.merchant_id }}
                  buttonProps={{ size: 'medium' }}
                  textFieldProps={{
                    size: 'small',
                    error: !!errors.merchant_id,
                    helperText: errors.merchant_id?.message,
                  }}
                  value={_value}
                  onChange={(val) => {
                    setValue(val);
                    onChange(val?.id ?? '');
                  }}
                />
              );
            }}
          />

          <Divider sx={{ my: 2 }} />

          <AddressSearch onPlaceSelect={handleAddressSearch} size="small" />

          <TextField
            label="Address *"
            size="small"
            fullWidth
            error={!!errors.street_address}
            helperText={errors?.street_address?.message}
            {...register('street_address')}
          />

          <TextField
            label="Apartment, suite, etc. *"
            size="small"
            fullWidth
            error={!!errors.address2}
            helperText={errors?.address2?.message}
            {...register('address2')}
          />

          <Stack gap={2} direction="row">
            <TextField
              label="City *"
              size="small"
              fullWidth
              error={!!errors.city}
              helperText={errors?.city?.message}
              {...register('city')}
            />
            <TextField
              label="Zipcode *"
              size="small"
              fullWidth
              error={!!errors.zipcode}
              helperText={errors?.zipcode?.message}
              {...register('zipcode')}
            />
          </Stack>

          <Stack gap={2} direction="row">
            <TextField
              label="State *"
              size="small"
              fullWidth
              error={!!errors.state}
              helperText={errors?.state?.message}
              {...register('state')}
            />
            <TextField
              label="Country *"
              size="small"
              fullWidth
              error={!!errors.country}
              helperText={errors?.country?.message}
              {...register('country')}
            />
          </Stack>

          <Divider sx={{ mt: 2 }} />

          <Controller
            name="hours"
            control={control}
            render={({ field: { value, onChange } }) => (
              <EditLocationSchedule initValue={value} onChange={onChange} />
            )}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Discard</Button>
        <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onSubmit)}>
          Create Location
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreateLocation;
