import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/helpers/axiosBaseQuery';

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'api',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: axiosBaseQuery(),
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    //Customers related Tags
    'Customer',
    'Customers',
    'CustomerNotes',
    'LinkedCustomers',

    //Locations
    'Locations',
    'Location',
    'LocationNotes',
    'LocationsSummary',
    'InstallableMachines',

    //Sales (General) related Tags
    'Sales',

    //Wallets related Tags
    'Wallets',
    'WalletsTransactions',
    'WalletsProvidersConfigs',

    //Wallet
    'WalletTransactions',
    'WalletBitgoConfiguration',

    //Wallet Info (Common) related Tags
    'ErgotWalletInfo',

    //Transactions related Tags
    'Transaction',
    'Transactions',
    'TransactionImages',
    'TransactionsBatches',

    //Machine related Tags
    'Machine',
    'Machines',
    'MachinesCashLogs',
    'MachineLastCommand',
    'MachineGeolocation',

    //Tag related Tags
    'TagsByTerm',

    //Blockchain Analytics
    'BlockchainAnalyticsConfigs',
    'OperatorBlockchainAnalyticsConfigs',

    //Notification
    'Notifications',
    'NotificationsCount',
    'NotificationsCountByCategory',
    'NotificationUserSettings',

    //Notes
    'Notes',

    //Inquiries
    'Inquiries',

    //Operator Settings
    'OperatorSettings',

    //Invoices
    'Invoices',

    //Stats
    'Stats',
    'ChartStats',
    'EnrollmentsStats',

    //Users
    'Users',
    'Roles',

    //Affiliates
    'Affiliates',
    'AffiliateUsers',
    'AffiliateMachines',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});

export default api;
