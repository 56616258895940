import type { ApiResponse } from '@bitstopco/bitstop-theme';

import { useMemo } from 'react';

import api from '@/store/api';
import { operator } from '@/store/api/instances';

import { BLOCKCHAIN_ANALYTICS_IMAGES } from '@/constants/app/blockchainAnalytics';

import type {
  BlockchainAnalyticsConfig,
  BlockchainAnalyticsEditAPiKeyModel,
  BlockchainAnalyticsModel,
  BlockchainAnalyticsOperatorConfig,
} from '@/types';

/**
 * Blockchain Analytics RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const blockchainAnalyticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOperatorBlockchainAnalyticsConfigurations: build.query<
      ApiResponse<BlockchainAnalyticsOperatorConfig[]>,
      void
    >({
      query: () =>
        operator({
          url: 'v2/BlockchainAnalytics',
          method: 'POST',
          data: {
            pagination: {
              current_page: 1,
              sort_by: 'active',
              sort_ascending: true,
              items_per_page: 1000,
            },
          },
        }),
      providesTags: ['OperatorBlockchainAnalyticsConfigs'],
    }),
    getBlockchainAnalyticsConfigurations: build.query<
      ApiResponse<BlockchainAnalyticsConfig[]>,
      void
    >({
      query: () => operator({ url: 'v2/BlockchainAnalytic/vendors', method: 'GET' }),
      providesTags: ['BlockchainAnalyticsConfigs'],
    }),
    connectBlockchainAnalytics: build.mutation<ApiResponse<string>, BlockchainAnalyticsModel>({
      query: (data) =>
        operator({ url: 'v2/BlockchainAnalytic', method: 'POST', data, showError: true }),
      invalidatesTags: ['OperatorBlockchainAnalyticsConfigs'],
    }),
    editBlockchainAnalyticsApiKey: build.mutation<
      ApiResponse<string>,
      BlockchainAnalyticsEditAPiKeyModel
    >({
      query: (data) =>
        operator({ url: 'v2/BlockchainAnalytic/api_key', method: 'PUT', data, showError: true }),
      invalidatesTags: ['OperatorBlockchainAnalyticsConfigs'],
    }),
    activeBlockchainAnalytics: build.mutation<ApiResponse<string>, { vendor: string }>({
      query: (data) =>
        operator({ url: 'v2/BlockchainAnalytic/active', method: 'PUT', data, showError: true }),
      invalidatesTags: ['OperatorBlockchainAnalyticsConfigs'],
    }),
  }),
});

export const {
  //Queries
  useGetBlockchainAnalyticsConfigurationsQuery,
  useGetOperatorBlockchainAnalyticsConfigurationsQuery,

  //Mutations
  useActiveBlockchainAnalyticsMutation,
  useConnectBlockchainAnalyticsMutation,
  useEditBlockchainAnalyticsApiKeyMutation,
} = blockchainAnalyticsApi;

export const {
  endpoints: {
    activeBlockchainAnalytics,
    connectBlockchainAnalytics,
    editBlockchainAnalyticsApiKey,
    getBlockchainAnalyticsConfigurations,
    getOperatorBlockchainAnalyticsConfigurations,
  },
} = blockchainAnalyticsApi;

export const useBlockchainAnalytics = () => {
  const {
    data: { data: configs = [] } = { data: null },
    isLoading: isLoadingConfigs,
  } = useGetBlockchainAnalyticsConfigurationsQuery();
  const { data: _vendors, isLoading: isLoadingVendors } =
    useGetOperatorBlockchainAnalyticsConfigurationsQuery();

  const vendors = useMemo(
    () =>
      (_vendors?.data || []).map((data) => ({
        ...data,
        image:
          BLOCKCHAIN_ANALYTICS_IMAGES?.[data.vendor as keyof typeof BLOCKCHAIN_ANALYTICS_IMAGES],
        friendly_name: configs?.find(({ id }) => id === data.vendor)?.friendly_name || data.vendor,
      })) as (BlockchainAnalyticsOperatorConfig & {
        image: React.ElementType;
        friendly_name: string;
      })[],
    [configs, _vendors],
  );

  return { configs, vendors, isLoadingConfigs, isLoadingVendors };
};
