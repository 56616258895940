import { useEffect, useState } from 'react';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { usePutCommandQueueClearCashboxMutation } from '@/store/api/services/machines/machine';

/**
 * Machine Clear Cashbox Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const ClearCashboxMachine = ({ hide, props }: ModalChildrenProps) => {
  const [hasCommandInProgress, setHasCommandInProgress] = useState(false);

  /**
   * Get the clearCashbox mutation
   */
  const [clearCashbox, { isLoading }] = usePutCommandQueueClearCashboxMutation();

  useEffect(() => {
    setHasCommandInProgress(props?.formattedCommandInProgress === 'Yes');
  }, []);

  const handleConfirm = async () => {
    /**
     * Submit the clearCashbox mutation
     */
    try {
      await clearCashbox(props?.data?.id).unwrap();
      toast.success('Cash box remote clear command submitted successfully');
      hide();
    } catch {
      //
    }
  };

  if (hasCommandInProgress) {
    return (
      <>
        <DialogContent sx={{ my: 2 }}>
          <Box>
            <Typography variant="h6" mb={1}>
              Unable to restart machine
            </Typography>
            <Alert color="warning" icon={<ErrorIcon />} sx={{ alignItems: 'center' }}>
              <Box>
                <Typography variant="subtitle2" color="primary.main">
                  Command in Progress
                </Typography>
                <Typography variant="body2" color="primary.main">
                  Cannot issue restart, please try again in a few minutes.
                </Typography>
              </Box>
            </Alert>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={hide}>
            Back to machine
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Box>
          <Typography variant="h6" mb={1}>
            You are about to clear the cash box for this machine
          </Typography>
          <Typography variant="body1">This is irreversible. Are you sure?</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to machine</Button>
        <LoadingButton
          variant="contained"
          onClick={handleConfirm}
          loading={isLoading}
          disabled={isLoading}
        >
          Ok
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ClearCashboxMachine;
