import { memo } from 'react';

import { useModal } from '@bitstopco/bitstop-theme';

import { Button, Stack } from '@mui/material';
import { useParams } from 'react-router';

import { useCustomer } from '@/store/api/services/customers/customer';

import { POTENTIAL_MATCHES_TABLE_BUTTONS } from '@/constants/app/customers/linkedAccounts';

import type { LinkedCustomer } from '@/types';

export interface CustomerPotentialMatchesButtonsProps {
  linkedCustomer: LinkedCustomer;
}

const CustomerPotentialMatchesButtons = ({
  linkedCustomer,
}: CustomerPotentialMatchesButtonsProps) => {
  const { id = '0' } = useParams();

  const { show } = useModal();

  const { customer, maxPurchaseLimit } = useCustomer(+id);

  const handleButton = (action: string) => () => {
    show(action, { customer, linkedCustomer, maxPurchaseLimit });
  };

  return (
    <Stack spacing={2} direction="row">
      {POTENTIAL_MATCHES_TABLE_BUTTONS.map(({ label, action, ...props }) => (
        <Button
          {...props}
          size="small"
          key={`potential-matches-table-button-${label}`}
          onClick={handleButton(action)}
          data-test="potential-matches-table-button"
        >
          {label}
        </Button>
      ))}
    </Stack>
  );
};

export default memo(CustomerPotentialMatchesButtons);
