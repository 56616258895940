import { api, session } from '@bitstopco/bitstop-theme';

import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios from 'axios';
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig as BaseAxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';
import toast from 'react-hot-toast';

export type AxiosRequestConfig = BaseAxiosRequestConfig<any> & {
  showError?: boolean;
};

const errorInterceptor = ({ response }: AxiosError) => {
  const message: string = api.parseResponseErrors({
    data: response?.data,
    status: response?.status,
  });

  if ((response?.config as AxiosRequestConfig).showError) {
    toast.error(message);
  }

  throw { message, data: response?.data, status: response?.status };
};

const requestInterceptor =
  (props?: { headers?: string[]; tokenPrefixes?: string[] }) =>
  (config: InternalAxiosRequestConfig<any>) => {
    const { headers = ['Authorization'], tokenPrefixes = ['Bearer'] } = props || {};

    const token = session.getValue();

    if (token) {
      config.headers = { ...config?.headers } as any;

      headers.forEach((header, index) => {
        config.headers![header] = `${tokenPrefixes?.[index] || ''} ${token}`;
      });
    }

    return config;
  };

const baseInstance = axios.create({
  baseURL: '/api/',
});

baseInstance.interceptors.request.use(requestInterceptor());
baseInstance.interceptors.response.use((response) => response, errorInterceptor);

export const getCustomInstance = ({
  baseURL,
  headers,
  tokenPrefixes,
}: {
  baseURL: string;
  headers?: string[];
  tokenPrefixes?: string[];
}) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(requestInterceptor({ headers, tokenPrefixes }));
  instance.interceptors.response.use((response) => response, errorInterceptor);

  return (props: BaseQueryProps) => ({
    ...props,
    instance,
  });
};

type BaseQueryProps = {
  url: string;
  showError?: boolean;
  instance?: AxiosInstance;
} & BaseAxiosRequestConfig;

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      showError?: boolean;
      instance?: AxiosInstance;
    } & BaseAxiosRequestConfig,
    unknown,
    unknown
  > =>
  async ({ showError, instance, ...axiosProps }) => {
    try {
      const _instance = instance ? instance : baseInstance;

      const result = await _instance({
        showError,
        ...axiosProps,
      } as AxiosRequestConfig);
      return { data: result.data, meta: { headers: result.headers } };
    } catch (error) {
      return {
        error,
      };
    }
  };

export default axiosBaseQuery;
