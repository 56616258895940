import { authReducer } from '@bitstopco/bitstop-theme';

import { Stack } from '@mui/material';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import HeaderNotifications from './HeaderNotifications';
import HeaderUserPopover from './HeaderUserPopover';

import { useAppDispatch, useAppSelector } from '@/store';

import { DEFAULT_ERROR_MESSAGE } from '@/constants/app/config';
import { POPOVER_ACTIONS } from '@/constants/app/views';

const {
  actions: authActions,
  selectors: { userInfo },
} = authReducer;

const HeaderRightContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userInfo);

  const handleLogout = async () => {
    try {
      dispatch(authActions.logout());
      navigate('/auth/login');
    } catch {
      toast.error(DEFAULT_ERROR_MESSAGE);
    }
  };

  const handlePopoverItem = (index: number) => {
    navigate(POPOVER_ACTIONS[index]);
  };

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <HeaderNotifications />
      <HeaderUserPopover
        userData={userData}
        onLogout={handleLogout}
        onClickItem={handlePopoverItem}
      />
    </Stack>
  );
};

export default HeaderRightContent;
