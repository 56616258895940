import { useState } from 'react';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import CustomerLinkAccountNewLink from '@/components/customer/tabs/linkedAccounts/CustomerLinkAccountNewLink';
import { useUnlinkAccountMutation } from '@/store/api/services/customers/customer';

import { CUSTOMER_INIT_VALUES } from '@/constants/app/customers/customer';

import type { Customer, LinkedCustomer } from '@/types';

/**
 * Customer Unlink Account Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const UnlinkAccount = ({ hide, props }: ModalChildrenProps) => {
  const customer = useState<Customer>(props?.customer || CUSTOMER_INIT_VALUES)[0];
  const linkedCustomer = useState<LinkedCustomer>(props?.linkedCustomer || CUSTOMER_INIT_VALUES)[0];

  const [unlinkAccount, { isLoading }] = useUnlinkAccountMutation();

  const handleSubmit = async () => {
    try {
      await unlinkAccount({
        association_id: linkedCustomer.customers_link_id,
      });
      hide();
    } catch {
      //
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6" data-test="unlink-account-text">
          Are you sure you would like to unlink{' '}
          <Typography color="text.secondary" component="span">
            {linkedCustomer.full_name}
          </Typography>{' '}
          from {customer.full_name}?
        </Typography>

        <Typography mt={1} variant="body1">
          This will remove the link created between these two accounts.
        </Typography>

        <CustomerLinkAccountNewLink
          mt={4}
          mb={2}
          variant="unlink"
          customer={customer}
          linkedCustomer={linkedCustomer}
        />
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="unlink-account-back-button">
          Back to Linked Accounts
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          data-test="unlink-account-submit-button"
        >
          Yes, unlink account
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UnlinkAccount;
