import { MuiTelInput as PhoneField } from 'mui-tel-input';
import { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import EditLocationSchedule from '@/components/location/schedule/EditLocationSchedule';
import { isValidPhone } from '@/forms';
import { MerchantSelect } from '@/forms/components';
import {
  useGetLocationQuery,
  useUpdateLocationMutation,
} from '@/store/api/services/locations/location';

import type { Merchant } from '@/types';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

const UpdateLocationSchema = z.object({
  location_id: z.number(),
  merchant_id: z.string().min(2, 'Merchant is Required'),
  name: z.string().min(2, 'Location Name is Required'),
  hours: z.array(z.string()),
  business_contact_name: z.string().min(2, 'Business Contact Name is Required'),
  business_contact_phone: isValidPhone(z.string(), 'Valid Phone Number is Required'),
});

type UpdateLocationSchemaType = z.infer<typeof UpdateLocationSchema>;

const EditLocation = ({ hide, props }: ModalChildrenProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<UpdateLocationSchemaType>({
    resolver: zodResolver(UpdateLocationSchema),
    mode: 'onSubmit',
  });

  const { data: locationData, isLoading } = useGetLocationQuery(+props?.data?.location_id);
  const [updateLocation] = useUpdateLocationMutation();

  useEffect(() => {
    if (!locationData?.data) return;
    const {
      merchant,
      location_id,
      location_name,
      business_contact_name,
      business_contact_phone,
      hours,
    } = locationData.data;
    setValue('merchant_id', merchant?.id ?? '');
    setValue('location_id', location_id);
    setValue('name', location_name ?? '');
    setValue('business_contact_name', business_contact_name ?? '');
    setValue('business_contact_phone', business_contact_phone ?? '');
    setValue('hours', hours ?? ['24/7']);
  }, [locationData]);

  const onSubmit = async (payload: UpdateLocationSchemaType) => {
    try {
      await updateLocation(payload).unwrap();
      toast.success('Location details updated successfully');
      hide();
    } catch (error: any) {
      toast.error(error?.message || 'Failed to update location details');
    }
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" py={2}>
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <>
      <DialogContent sx={{ py: 4 }}>
        <Stack gap={2} data-test="edit-location-form">
          <TextField
            label="Location Name *"
            fullWidth
            error={!!errors.name}
            helperText={errors?.name?.message}
            size="small"
            {...register('name')}
          />

          <TextField
            label="Business Contact Name *"
            size="small"
            fullWidth
            error={!!errors.business_contact_name}
            helperText={errors?.business_contact_name?.message}
            {...register('business_contact_name')}
          />

          <Controller
            name="business_contact_phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <PhoneField
                defaultCountry="US"
                label="Business Contact Phone *"
                size="small"
                value={value}
                onChange={(_, info) => onChange(info.numberValue)}
                error={!!errors.business_contact_phone}
                helperText={errors?.business_contact_phone?.message}
              />
            )}
          />

          <Controller
            name="merchant_id"
            control={control}
            defaultValue={locationData?.data.merchant?.id}
            render={({ field: { onChange } }) => {
              const [_value, setValue] = useState<Merchant | null>(
                locationData?.data.merchant ?? null,
              );
              return (
                <MerchantSelect
                  label="Merchant *"
                  labelProps={{ sx: { fontSize: 14 }, error: !!errors.merchant_id }}
                  buttonProps={{ size: 'medium' }}
                  textFieldProps={{
                    size: 'small',
                    error: !!errors.merchant_id,
                    helperText: errors.merchant_id?.message,
                  }}
                  value={_value}
                  onChange={(val) => {
                    setValue(val);
                    onChange(val?.id ?? '');
                  }}
                />
              );
            }}
          />

          <Divider sx={{ my: 2 }} />

          <TextField
            label="Full Address"
            size="small"
            fullWidth
            disabled
            name="full_address"
            value={locationData?.data.full_address}
          />
          <TextField
            label="Address"
            size="small"
            fullWidth
            disabled
            name="street_address"
            value={locationData?.data.street_address}
          />
          <TextField
            label="Apartment, suite, etc."
            size="small"
            fullWidth
            disabled
            name="address2"
            value={locationData?.data.address2}
          />
          <Stack gap={2} direction="row">
            <TextField
              label="City"
              size="small"
              fullWidth
              disabled
              name="city"
              value={locationData?.data.city}
            />
            <TextField
              label="Zip Code"
              size="small"
              fullWidth
              disabled
              name="zipcode"
              value={locationData?.data.zipcode}
            />
          </Stack>
          <Stack gap={2} direction="row">
            <TextField
              label="State"
              size="small"
              fullWidth
              disabled
              name="state"
              value={locationData?.data.state}
            />
            <TextField
              label="Country"
              size="small"
              fullWidth
              disabled
              name="country"
              value={locationData?.data.country}
            />
          </Stack>

          <Divider sx={{ mt: 2 }} />

          <EditLocationSchedule
            initValue={locationData?.data.hours}
            onChange={(value) => setValue('hours', value)}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => hide()}>Discard changes</Button>
        <LoadingButton
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default EditLocation;
