import type { LocationDetail, LocationInstalledMachines } from '@/types/location';
import type { Columns, TableToolbarFilterConfig } from '@bitstopco/bitstop-theme';
import { CONSTANTS, ClickableText } from '@bitstopco/bitstop-theme';

export const LOCATION_DETAILS: Columns<LocationDetail> = {
  defaultValue: '-',
  items: [
    {
      label: 'Name',
      value: 'location_name',
      valueSx: CONSTANTS.TWO_LINES_ELLIPSIS,
    },
    {
      label: 'Location ID',
      value: 'location_id',
      valueMapper: (value: LocationDetail['location_id']) =>
        value && (
          <ClickableText
            fixIcon
            text={value.toString()}
            clipboardValue={value.toString()}
            name="Location ID"
            sx={{
              '& span': {
                whiteSpace: 'normal',
              },
            }}
          />
        ),
    },
    {
      label: 'Address',
      value: 'full_address',
      valueSx: CONSTANTS.TWO_LINES_ELLIPSIS,
    },
    {
      label: 'Merchant',
      value: 'merchant_details',
    },
    {
      label: 'Created At (UTC)',
      value: 'created_at',
      valueMapper: (value: LocationDetail['created_at']) =>
        value ? new Date(value).toUTCString() : '-',
    },
    {
      label: 'Created At (Local Date)',
      value: 'created_at',
      valueMapper: (value: LocationDetail['created_at']) =>
        value ? new Date(value).toLocaleString() : '-',
    },
    {
      label: 'Hours',
      value: 'hours',
      valueMapper: (value: LocationDetail['hours']) =>
        value && (
          <div
            dangerouslySetInnerHTML={{
              __html: (Array.isArray(value) ? value : ['-']).join('<br/>'),
            }}
          />
        ),
    },
  ],
};

export const INSTALLED_MACHINES_COLUMNS: Columns<LocationInstalledMachines> = {
  items: [
    { value: 'atm_id', label: 'Machine' },
    {
      value: 'date_time_installed',
      label: 'Installed At',
      valueMapper: (value: LocationInstalledMachines['date_time_installed']) =>
        value ? new Date(value).toLocaleString() : '-',
    },
    {
      value: 'date_time_uninstalled',
      label: 'Removed At',
      valueMapper: (value: LocationInstalledMachines['date_time_uninstalled']) =>
        value ? new Date(value).toLocaleString() : '-',
    },
  ],
};

export const INSTALLED_MACHINES_FILTERS_CONFIG: TableToolbarFilterConfig[] = [
  {
    property: 'atm_id',
    label: 'Location',
    operator: 'equal',
    type: 'select',
  },
  {
    property: 'date_time_installed',
    label: 'Installed Date',
    type: 'select',
  },
];

/**
 * Location valid hours, this constant will be used to generate the schedule component
 */
export const LOCATION_VALID_HOURS = ['24-Hours', ...CONSTANTS.DAY_HOURS];

export const LOCATION_RANGES_PLACEHOLDERS = ['Opens at...', 'Closes at...'];

export const LOCATION_RANGES_FIRST = ['', ...LOCATION_VALID_HOURS];
export const LOCATION_RANGES_SECOND = ['', ...CONSTANTS.DAY_HOURS];

export const LOCATION_RANGES_RANGES_OPTIONS = [LOCATION_RANGES_FIRST, LOCATION_RANGES_SECOND];
