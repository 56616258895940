import { useEffect, useState } from 'react';

import { useAppDispatch } from '@/store';
import { getBanners } from '@/store/api/services/banners/banners';
import { getPolicies } from '@/store/api/services/common/policies';
import { getNoteTemplates, getNoteTypes } from '@/store/api/services/kyc/notes';
import { getKycStatuses } from '@/store/api/services/kyc/statuses';
import {
  getNotificationUserSettings,
  getNotificationsCountByCategory,
} from '@/store/api/services/notifications/notifications';
import { getBlockchainAnalyticsConfigurations } from '@/store/api/services/operator/blockchainAnalytics';
import { getUserRoles } from '@/store/api/services/users/users';

import { getWalletsCoin } from '@/store/api/services/wallets/coins';
import { getWalletsProvidersByCoinId } from '@/store/api/services/wallets/providers';

/**
 * The prefetch actions can be disabled
 * ! This is useful if we want to shadow deploy a new functionality without prefetching the related data
 */
const PREFETCH_SERVICES_ENABLER = {
  kyc: true,
  wallets: false,
  policies: true,
  notification: true,
  blockchainAnalytics: true,
  banners: true,
  users: true,
};
/**
 * Hook to prefetch the desired async store data
 *
 * @return {*}
 */
const usePrefetchResources = () => {
  const [isPrefetching, setIsPrefetching] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (PREFETCH_SERVICES_ENABLER.wallets) {
        try {
          // Prefetch Wallets related content
          const { data } = await dispatch(getWalletsCoin.initiate()).unwrap();

          data.forEach(({ id }) => {
            dispatch(getWalletsProvidersByCoinId.initiate(id));
          });
        } catch {}
      }

      if (PREFETCH_SERVICES_ENABLER.users) {
        // Prefetch user roles list
        dispatch(getUserRoles.initiate());
      }

      if (PREFETCH_SERVICES_ENABLER.blockchainAnalytics) {
        // Prefetch blockchain analytics configurations
        dispatch(getBlockchainAnalyticsConfigurations.initiate());
      }

      if (PREFETCH_SERVICES_ENABLER.notification) {
        // Prefetch notifications count and settings
        dispatch(getNotificationUserSettings.initiate());
        dispatch(getNotificationsCountByCategory.initiate());
      }

      if (PREFETCH_SERVICES_ENABLER.policies) {
        // Prefetch policies
        dispatch(getPolicies.initiate());
      }

      if (PREFETCH_SERVICES_ENABLER.kyc) {
        // Prefetch kyc
        dispatch(getNoteTypes.initiate());
        dispatch(getKycStatuses.initiate());
        dispatch(getNoteTemplates.initiate());
      }

      if (PREFETCH_SERVICES_ENABLER.banners) {
        // Prefetch Banners list
        dispatch(getBanners.initiate());
      }

      setIsPrefetching(false);
    })();
  }, []);

  return { isPrefetching };
};

export default usePrefetchResources;
