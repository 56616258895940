import { AutoFields, AutoForm, SubmitField, authReducer, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import { useCreateLocationNoteMutation } from '@/store/api/services/kyc/notes';

import { getOperatorId } from '@/helpers/jwt';
import { useAppSelector } from '@/store';
import type { LocationCustomNoteModel } from '@/types';

/**
 * Customer Custom Note Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const LocationCustomNote = ({ hide, props }: ModalChildrenProps) => {
  const { schema } = useForm(SCHEMAS.LOCATION_CUSTOM_NOTE);
  const [createLocationCustomNote] = useCreateLocationNoteMutation();

  const {
    selectors: { userInfo },
  } = authReducer;

  const userData = useAppSelector(userInfo);

  const handleSubmit = async (data: LocationCustomNoteModel) => {
    try {
      await createLocationCustomNote({
        location_id: props.location_id,
        operator_id: getOperatorId(),
        note_text: data.content,
        author: `${userData?.first_name} ${userData?.last_name}`,
      }).unwrap();
      toast.success('Note created successfully');
      hide();
    } catch {
      //
      toast.error('Error creating note for this location');
    }
  };

  return (
    <AutoForm<LocationCustomNoteModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back</Button>
        <SubmitField variant="contained">Create Note</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default LocationCustomNote;
