import { Stack, Typography } from '@mui/material';

import { useGetPrecioQuery } from '@/store/api/services/common/precio';
import { useGetSalesStatsQuery } from '@/store/api/services/common/sales';
import { useGetErgotWalletInfoQuery } from '@/store/api/services/common/wallet';

const headersStatsV1 = () => {
  const { data: sales } = useGetSalesStatsQuery();
  const { data: precio } = useGetPrecioQuery();
  const { data: walletInfo } = useGetErgotWalletInfoQuery();

  const totalSales = sales?.total_sales || 0;
  const price = precio?.formattedValue || '0';
  const walletBalance = walletInfo?.balance || 0;

  return (
    <Stack
      direction="row"
      spacing={{ xs: 1.5, sm: 2 }}
      sx={{
        '& span': {
          whiteSpace: 'nowrap',
          fontSize: { xs: 10, sm: 12 },
        },
      }}
      data-test="header-stats"
    >
      <Typography variant="caption">1 BTC = ${price}</Typography>
      <Typography variant="caption">Sales Today: {totalSales}</Typography>
      <Typography variant="caption">Wallet Balance: ₿ {walletBalance}</Typography>
    </Stack>
  );
};

export default headersStatsV1;
