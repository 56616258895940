import { useEffect, useMemo, useState } from 'react';

import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import toast from 'react-hot-toast';

import CustomerReasonForm from '@/components/customer/CustomerReasonForm';
import SCHEMAS from '@/forms/schemas';
import { fillTemplate } from '@/helpers/string';
import { useNoteTemplates, useNotes } from '@/store/api/services/kyc/notes';

import { CUSTOMER_INIT_VALUES } from '@/constants/app/customers/customer';

import type { Customer, NoteType, ReasonModel } from '@/types';

/**
 * Suspend Customer Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const ActionNote = ({ hide, props, setTitle }: ModalChildrenProps) => {
  const noteType = useState<NoteType>(props?.noteType || 'custom_note')[0];
  const customer = useState<Customer>(props?.customer || CUSTOMER_INIT_VALUES)[0];

  const { createNote } = useNotes(customer);
  const { title, subtitle, buttonText, buttonVariant, content, modalTitle } =
    useNoteTemplates(noteType);

  const { computedTitle, computedSubtitle } = useMemo(() => {
    const computedTitle = fillTemplate(title, customer);
    const computedSubtitle = fillTemplate(subtitle, customer);

    return { computedTitle, computedSubtitle };
  }, [title, subtitle]);

  useEffect(() => {
    modalTitle && setTitle(modalTitle);
  }, [modalTitle]);

  const handleSubmit = async ({ reason }: ReasonModel) => {
    try {
      await createNote({
        reason,
        content,
        type: noteType,
      }).unwrap();

      toast.success(`${modalTitle} action sent`);

      hide();
    } catch {
      //
    }
  };

  return (
    <CustomerReasonForm<ReasonModel>
      content={content}
      title={computedTitle}
      submitText={buttonText}
      schema={SCHEMAS.REASON}
      subtitle={computedSubtitle}
      submitColor={buttonVariant}
      onBack={hide}
      onSubmit={handleSubmit}
    />
  );
};

export default ActionNote;
