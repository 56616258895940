import { useState } from 'react';

import { useModal } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import NotificationsBreadcrumb from '@/components/notifications/NotificationsBreadcrumb';
import NotificationsSettingsForm from '@/components/notifications/settings/NotificationsSettingsForm';
import { useAppDispatch } from '@/store';
import {
  notificationsApi,
  useGetNotificationUserSettingsQuery,
  usePutNotificationUserSettingsMutation,
} from '@/store/api/services/notifications/notifications';

import type { PutNotificationUserSettingsRequest } from '@/types';

/**
 * Notifications Settings modal
 *
 * @param {ModalChildrenProps} { hide }
 * @return {*}
 */
const NotificationsSettings = ({ hide }: ModalChildrenProps) => {
  const { show } = useModal();
  const dispatch = useAppDispatch();
  const {
    data: { data } = { data: [] },
  } = useGetNotificationUserSettingsQuery();
  const [saveNotificationUserSettings] = usePutNotificationUserSettingsMutation();

  const [isSaving, setIsSaving] = useState(false);
  const [pendingChanges, setPendingChanges] = useState<PutNotificationUserSettingsRequest[]>([]);

  const handleGoNotifications = () => {
    show('notifications');
  };

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      for (const change of pendingChanges) {
        await saveNotificationUserSettings(change);
      }

      await dispatch(notificationsApi.util.invalidateTags(['NotificationUserSettings']));

      toast.success('Settings saved successfully');
      hide();
    } catch {
      toast.error('Something went wrong, try again later');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <DialogContent sx={{ py: 1.5 }}>
        <NotificationsBreadcrumb title="Settings" onClick={handleGoNotifications} />
        <NotificationsSettingsForm userSettings={data} onChange={setPendingChanges} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={handleGoNotifications} data-test="notifications-settings-back-button">
          Go back to notifications
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isSaving}
          disabled={isSaving}
          endIcon={<CheckIcon />}
          data-test="notifications-settings-save-button"
        >
          Save changes
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default NotificationsSettings;
