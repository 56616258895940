import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import { history, store } from '@/store';

import App from './App.tsx';

import '@/helpers/sentry.ts';

import './index.css';

//TODO: <React.StrictMode> removed for now since it is having conflicts with uniforms in development mode
ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
