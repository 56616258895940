import api from '@/store/api';
import { ergot } from '@/store/api/instances';

import type { ErgotWalletInfo } from '@/types';

/**
 * Wallet RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 * ! This API instance is used for the OLD Ergot wallet endpoints
 * ! This information is used in the Header
 */
export const commonWalletApi = api.injectEndpoints({
  endpoints: (build) => ({
    getErgotWalletInfo: build.query<ErgotWalletInfo['result'], void>({
      query: () => ergot({ url: 'wallet/info' }),
      transformResponse: (response: ErgotWalletInfo) => response?.result || {},
      providesTags: ['ErgotWalletInfo'],
    }),
  }),
});

export const { useGetErgotWalletInfoQuery } = commonWalletApi;

export const {
  endpoints: { getErgotWalletInfo },
} = commonWalletApi;
