import { Box, Chip, Grid, Typography } from '@mui/material';

import type { Tag } from '@/types';

export interface TagListProps {
  containerId?: string | number;
  maxTagsAllowed?: number;
  selectedTagIds?: string[];
  tagsCollection?: Tag[];

  onClickAddTag?: (target: HTMLElement) => void;
  onClickDeleteTag?: (tagId: string) => void;
}

const TagList = ({
  containerId = '',

  tagsCollection,

  onClickAddTag,
  onClickDeleteTag,

  maxTagsAllowed,
  selectedTagIds = [],
}: TagListProps) => {
  const handleAddTag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onClickAddTag?.(event.currentTarget);
  };

  const isAddTagsButtonVisible =
    onClickAddTag && (maxTagsAllowed === undefined || maxTagsAllowed > selectedTagIds.length);

  if (!onClickAddTag && !selectedTagIds?.length) {
    return (
      <Typography variant="body2" whiteSpace="nowrap">
        No tags added
      </Typography>
    );
  }

  return (
    <Grid container direction="row" spacing={1} data-test="tag-list-container">
      {selectedTagIds.map((tagId) => {
        const tag = tagsCollection?.find(({ id }) => id === tagId);

        if (!tag) {
          return null;
        }

        return (
          <Grid item key={`tags-list-${containerId}-${tagId}`}>
            <Chip
              size="small"
              component="span"
              label={tag.name}
              sx={{
                color: 'white',
                backgroundColor: tag.color,
              }}
              onDelete={onClickDeleteTag ? () => onClickDeleteTag?.(tagId) : undefined}
            />
          </Grid>
        );
      })}

      {isAddTagsButtonVisible && (
        <Grid item>
          <Box onClick={handleAddTag} role="button" data-test="tag-list-add-new-tag">
            <Chip
              size="small"
              variant="outlined"
              label="+ Add new tag"
              sx={(theme) => ({
                border: `1px dashed ${theme.palette.grey['500']}`,
                borderRadius: 1,
                cursor: 'pointer',
              })}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default TagList;
