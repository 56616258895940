import { memo } from 'react';

import { Tooltip } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import get from 'lodash/get';

import { BATCHING_STATUSES } from '@/constants/app/transactions';

export interface TransactionsBatchingStatusProps {
  value: boolean;
}

const TransactionsBatchingStatus = ({ value }: TransactionsBatchingStatusProps) => {
  const _value = value ? 'yes' : 'no';
  const theme = useTheme();
  const { Icon, color } = BATCHING_STATUSES?.[_value] || { Icon: null, color: null };

  const label = _value.replace(/_/g, ' ');

  if (!Icon || !color) {
    return null;
  }

  const htmlColor = get(theme.palette, color, 'info.main');

  return (
    <Tooltip title={label}>
      <Icon htmlColor={htmlColor} sx={{ fontSize: 16 }} />
    </Tooltip>
  );
};

export default memo(TransactionsBatchingStatus);
