import { memo } from 'react';

import type { ChipProps } from '@mui/material';
import { Chip } from '@mui/material';

import { getInquiryActivityStatus } from '@/helpers/customer';

interface CustomerKycVerificationsItemActivityStatusProps {
  value: string;
}

/**
 * Kyc Verifications Status Table mapper
 *
 * @param {CustomerKycVerificationsItemActivityStatusProps} {
 *   value,
 * }
 * @return {*}
 */
const CustomerKycVerificationsItemActivityStatus = ({
  value,
}: CustomerKycVerificationsItemActivityStatusProps) => {
  const { chipColor = 'primary' } = getInquiryActivityStatus(value);

  return (
    <Chip
      size="small"
      label={value}
      color={chipColor as ChipProps['color']}
      component="span"
      sx={{
        right: 5,
        bottom: 5,
        fontSize: '12px',
        position: 'absolute',
        border: '2px solid #D5D8E1',
        textTransform: 'capitalize',
      }}
    />
  );
};

export default memo(CustomerKycVerificationsItemActivityStatus);
