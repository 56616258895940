import { useRemoveMachineFromLocationMutation } from '@/store/api/services/locations/locations';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

const RemoveMachine = ({ hide, props }: ModalChildrenProps) => {
  const [removeAtm, { isLoading, isError, isSuccess }] = useRemoveMachineFromLocationMutation();
  const [error, setError] = useState<string | null>(null);
  const atmId = props?.data?.atm_id;
  const locationName = props?.data?.location_name;

  const handleSubmit = async () => {
    if (!atmId) return;
    try {
      setError(null);
      await removeAtm({ location_id: props?.data?.location_id, atm_id: atmId }).unwrap();
      toast.success(`Machine ${atmId} removed successfully from location ${locationName}`);
      hide();
    } catch (err: any) {
      setError(err?.data?.meta || 'An unknown error occurred');
    }
  };

  useEffect(() => {
    return () => {
      setError(null);
    };
  }, [hide]);

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        {isSuccess && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Machine successfully removed from location <b>{locationName}</b>.
          </Alert>
        )}

        {isError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}

        {!isLoading && !isError && (
          <>
            <Typography variant="h6">
              Are you sure you want to remove ATM <b>{atmId}</b> from this location?
            </Typography>
            <Alert severity="warning" icon={<ErrorIcon />} sx={{ mt: 2 }}>
              <AlertTitle>Removing ATM from location</AlertTitle>
              <Typography variant="body1">
                This action will remove ATM <b>{atmId}</b> from location <b>{locationName}</b>.
              </Typography>
            </Alert>
          </>
        )}

        {isLoading && (
          <Typography mt={1} variant="body1">
            Removing ATM from location...
          </Typography>
        )}
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton color="error" variant="contained" onClick={handleSubmit} loading={isLoading}>
          Remove ATM
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RemoveMachine;
