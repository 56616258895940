import { Box } from '@mui/material';

import TabsPanels from '@/components/tabs/TabsPanels';

import {
  PANELS,
  PANELS_COMPONENTS,
  PANELS_ROUTES,
} from '@/constants/app/notifications/notificationsTabs';

const NotificationsTabsPanels = () => (
  <Box sx={{ display: 'flex', overflow: 'hidden', flex: '1 1 auto' }}>
    <TabsPanels
      fullWidth
      fullHeight
      name="machines"
      panels={PANELS}
      routes={PANELS_ROUTES}
      components={PANELS_COMPONENTS}
    />
  </Box>
);

export default NotificationsTabsPanels;
