import { useState } from 'react';

import {
  AutoFields,
  AutoForm,
  OutlinedRoundImage,
  SubmitField,
  useForm,
} from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import { useEditBlockchainAnalyticsApiKeyMutation } from '@/store/api/services/operator/blockchainAnalytics';

import type { BlockchainAnalyticsEditAPiKeyModel } from '@/types';

/**
 * Blockchain Analytics Edit Vendor Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const ActivateVendor = ({ hide, props }: ModalChildrenProps) => {
  const [vendor] = useState(props?.newVendor);

  /**
   * Get the editBlockchainAnalyticsApiKey mutation
   */
  const [editBlockchainAnalyticsApiKey] = useEditBlockchainAnalyticsApiKeyMutation();

  /**
   * Get the form schema
   */
  const { schema } = useForm(SCHEMAS.UPDATE_VENDOR, {
    //initValues: { api_key: vendor?.api_key },
  });

  const handleSubmit = async (data: BlockchainAnalyticsEditAPiKeyModel) => {
    const vendorId = vendor?.vendor;

    /**
     * If there is not vendor selected we want to cancel the submit process
     */
    if (!vendorId) {
      return;
    }

    /**
     * Submit the new vendor information
     */
    await editBlockchainAnalyticsApiKey({ ...data, vendor: vendorId }).unwrap();
    toast.success('Vendor updated successfully');
    hide();
  };

  return (
    <AutoForm<BlockchainAnalyticsEditAPiKeyModel>
      showInlineError
      schema={schema}
      onSubmit={handleSubmit}
    >
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">Blockchain Analytics vendor</Typography>
        <Typography variant="body1">Update your connection credentials.</Typography>

        {vendor && (
          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <OutlinedRoundImage
              renderImage={<Avatar src={vendor?.image} sx={{ width: 32, height: 32 }} />}
              variant="success"
            />
            <Typography sx={{ color: 'text.primary' }}>{vendor?.vendor}</Typography>
          </Stack>
        )}

        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField label="Save Changes" />
      </DialogActions>
    </AutoForm>
  );
};

export default ActivateVendor;
