import type {
  ApiExportRequest,
  ApiListingFiltersOnlyRequest,
  ApiListingRequest,
  ApiResponse,
} from '@bitstopco/bitstop-theme';

import { normalizeCustomerData } from '@/helpers/customer';
import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { Customer } from '@/types';

/**
 * Customers RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const customersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<ApiResponse<Customer[]>, ApiListingRequest>({
      query: (data) =>
        athena({
          url: 'v2/customers',
          method: 'POST',
          data,
        }),

      transformResponse: (response: ApiResponse<Customer[]>) => {
        if (response.data) {
          return {
            ...response,
            data: response.data.map((customer) => normalizeCustomerData(customer)),
          } as ApiResponse<Customer[]>;
        }

        return response;
      },
      providesTags: (result) => {
        const data = result?.data || [];

        return [
          ...data.map(({ id }) => ({ type: 'Customers', id }) as const),
          { type: 'Customers' as const, id: 'LIST' },
        ];
      },
    }),
    getCustomersCount: build.query<ApiResponse<number>, ApiListingFiltersOnlyRequest>({
      query: (data) =>
        athena({
          url: 'v2/customers/count',
          method: 'POST',
          data,
        }),
    }),
    getCustomersExport: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data }) =>
        athena({
          url: `v2/customers/${type}`,
          method: 'POST',
          data,
        }),
    }),
  }),
});

export const {
  // Queries
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useGetCustomersCountQuery,

  // Mutations
  useGetCustomersExportMutation,
} = customersApi;

export const {
  endpoints: { getCustomers, getCustomersCount, getCustomersExport },
} = customersApi;
