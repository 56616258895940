import BasePopover from './BasePopover';
import type { BasePopoverProps } from './BasePopover';

interface MorePopoverProps {
  items: BasePopoverProps['items'];
  onClick?: (index: number) => void;
}

const MorePopover = (props: MorePopoverProps) => (
  <BasePopover buttonType="iconButton" dataTest="more-popover" {...props} />
);

export default MorePopover;
