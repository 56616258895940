import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import theme from '@/helpers/theme';

import { CASH_LOGS_BILLS } from '@/constants/app/machines';

interface MachinesLastRestartProps {
  value: string;
  data: Record<string, any>;
}

const MachinesCashLogsBills = ({ data }: MachinesLastRestartProps) => (
  <TableContainer sx={{ border: `1px solid ${theme.palette.neutral?.[300]}`, borderRadius: '6px' }}>
    <Table>
      <TableHead sx={{ border: 0 }}>
        <TableRow>
          {CASH_LOGS_BILLS.map(({ id, label }) => (
            <TableCell
              key={`bills-table-head-cell-${id}`}
              sx={{ minWidth: '80px', p: '2px 16px', textAlign: 'center' }}
            >
              {label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {CASH_LOGS_BILLS.map(({ id }) => (
            <TableCell
              key={`bills-table-body-cell-${id}`}
              component="th"
              scope="row"
              sx={{ minWidth: '80px', p: '6px 16px', textAlign: 'center' }}
            >
              {data?.[id] || 0}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default MachinesCashLogsBills;
