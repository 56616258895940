import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Button, FormLabel, Stack, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { useGetMerchantsQuery } from '@/store/api/services/merchants/merchants';
import { useModal } from '@bitstopco/bitstop-theme';

import type { Merchant } from '@/types';
import type { ButtonProps, FormLabelProps, TextFieldProps } from '@mui/material';

type MerchantSelectProps = {
  value?: Merchant | null;
  onChange?: (merchant: Merchant | null) => void;
  hasCreateButton?: boolean;
  label?: string;
  textFieldProps?: TextFieldProps;
  buttonProps?: ButtonProps;
  labelProps?: FormLabelProps;
};

export const MerchantSelect = ({
  value,
  onChange,
  hasCreateButton = true,
  label,
  labelProps,
  textFieldProps,
  buttonProps,
}: MerchantSelectProps) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>('');

  const { show } = useModal();
  const { data, isFetching, refetch } = useGetMerchantsQuery({
    search_text: query,
    pagination: {
      current_page: 1,
      items_per_page: 25,
      sort_ascending: false,
      sort_by: 'created_at',
    },
  });

  const options = useMemo(() => {
    let options: Merchant[] = [];
    if (data?.data) options = data.data;
    if (value) options = [value, ...options];
    return options;
  }, [data]);

  useEffect(() => {
    refetch();
  }, [open]);

  const handleSearchChange = (search: string) => setQuery(search);

  return (
    <Stack gap={0.5}>
      {label && <FormLabel {...labelProps}>{label}</FormLabel>}
      <Stack direction="row" gap={1}>
        {hasCreateButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => show('merchant-create')}
            {...buttonProps}
          >
            <AddIcon fontSize="small" sx={{ mr: 1 }} />
            Create Merchant
          </Button>
        )}
        <Autocomplete
          freeSolo
          sx={{ flex: 1 }}
          open={open}
          value={value}
          options={options}
          loading={isFetching}
          getOptionLabel={(option) => {
            if (typeof option === 'string') return option;
            const { owner_first_name, owner_last_name, owner_email } = option;
            return `${owner_first_name} ${owner_last_name} / ${owner_email}`;
          }}
          onInputChange={(_, value) => {
            if (value === '') onChange?.(null);
            handleSearchChange(value);
          }}
          onChange={(_, value) => onChange?.(typeof value === 'string' ? null : value)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={null}
              placeholder="Search merchants..."
              variant="outlined"
              {...textFieldProps}
            />
          )}
        />
      </Stack>
    </Stack>
  );
};
