import type { ApiListingRequest } from '@bitstopco/bitstop-theme';

import { locallyPaginatedData } from '@/helpers/api';

import api from '@/store/api';
import { ergot } from '@/store/api/instances';

import type { PostCreateUserRequest, PutEditRoleRequest, Role, User } from '@/types';

/**
 * Users RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<User[], void>({
      query: () =>
        ergot({
          url: 'users',
        }),
      providesTags: ['Users'],
    }),
    getUserRoles: build.query<Role[], void>({
      query: () =>
        ergot({
          url: 'roles',
        }),
      providesTags: ['Roles'],
    }),
    putEditUserRole: build.mutation<void, PutEditRoleRequest>({
      query: (data) =>
        ergot({
          data,
          method: 'PUT',
          url: 'users/roles',
          showError: true,
        }),
      invalidatesTags: ['Users'],
    }),
    postCreateUser: build.mutation<void, PostCreateUserRequest>({
      query: (data) =>
        ergot({
          data,
          method: 'POST',
          url: 'users',
          showError: true,
        }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  // Queries
  useGetUsersQuery,
  useGetUserRolesQuery,

  // Mutations
  usePutEditUserRoleMutation,
  usePostCreateUserMutation,
} = usersApi;

export const {
  endpoints: { getUsers, getUserRoles, putEditUserRole, postCreateUser },
} = usersApi;

export const useGetPaginatedUsers = (payload?: ApiListingRequest) => {
  const { data: users = [], isFetching } = useGetUsersQuery(undefined, { skip: !payload });

  const data = locallyPaginatedData(users, payload);

  return {
    data,
    isFetching,
  };
};
