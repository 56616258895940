import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { useParams } from 'react-router';

import { useGetCustomerQuery } from '@/store/api/services/customers/customer';

import CustomerManualVerificationPlaceholder from './CustomerManualVerificationPlaceholder';
import CustomerManualVerificationSatisfied from './CustomerManualVerificationSatisfied';

const CustomerManualVerification = () => {
  const { id } = useParams();
  const {
    data: { data: customer } = {},
  } = useGetCustomerQuery(+id!);

  const manualApprovalPolicy = customer!.manual_approval_enrollment_policy;

  return (
    <Card data-test="customer-manual-verification">
      <Box my={1.5} mx={3}>
        <Typography variant="h6">Manual Verification</Typography>
      </Box>

      <Divider />

      <CardContent>
        {manualApprovalPolicy ? (
          <CustomerManualVerificationSatisfied policy={manualApprovalPolicy} customer={customer!} />
        ) : (
          <CustomerManualVerificationPlaceholder />
        )}
      </CardContent>
    </Card>
  );
};

export default CustomerManualVerification;
