import { AutoFields } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';

import { tooltipClasses } from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';

import theme from '@/helpers/theme';
import toast from 'react-hot-toast';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    backgroundColor: theme.palette.grey[700],
  },
}));

/**
 * Tooltip content presentation component
 *
 */
const TooltipContent = () => (
  <Box py={0.5}>
    <Typography variant="subtitle2">Recommendations</Typography>
    <Box pl={0.5}>
      <Typography variant="caption" component="div">
        • Do not deposit more than $25K USD per Address
      </Typography>
      <Typography variant="caption" component="div">
        • Generate 5 address to Loremp Ipsum Dolorem
      </Typography>
    </Box>
  </Box>
);

/**
 * Wallets New Addresses Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const GenerateAddresses = ({ hide }: ModalChildrenProps) => {
  const handleSubmit = () => {
    /**
     * Submit the getWalletAddresses request
     */
    try {
      // TODO: perform generate new wallet addresses
      toast.success('Addresses generated successfully');
      hide();
    } catch {}
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">
          How many addresses do you want to generate?
          <HtmlTooltip
            arrow
            title={<TooltipContent />}
            placement="top"
            sx={{
              ml: 1,
            }}
          >
            <IconButton>
              <InfoIcon htmlColor={theme.palette.info.main} />
            </IconButton>
          </HtmlTooltip>
        </Typography>

        <Box mt={2}>
          <AutoFields />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to Funding</Button>
        <Button onClick={handleSubmit}>Generate addresses </Button>
      </DialogActions>
    </>
  );
};

export default GenerateAddresses;
