import { CardSimple, Loader } from '@bitstopco/bitstop-theme';
import type { CardSimpleProps } from '@bitstopco/bitstop-theme';

import { Box, Grid } from '@mui/material';
import type { SxProps } from '@mui/material';

export interface StatsCardsProps {
  sx?: SxProps;
  mapData: Record<
    string,
    {
      label: string;
      mapper: (value: number) => string;
    }
  >;
  isLoading?: boolean;
  itemsPerLine?: number;
  stats?: Record<string, any>;
  variant?: CardSimpleProps['variant'];
  paperProps?: CardSimpleProps['paperProps'];
}

const SalesStatsCards = ({
  sx,
  stats,
  mapData,
  variant,
  isLoading,
  paperProps,
  itemsPerLine = 3,
}: StatsCardsProps) => {
  if (isLoading) {
    return <Loader size={18} />;
  }

  if (!stats) {
    return null;
  }

  return (
    <Box sx={sx}>
      <Grid container spacing={2}>
        {Object.keys(mapData).map((key) => {
          const item = mapData[key as keyof typeof mapData];
          const label = item.label;
          const value = item.mapper(stats?.[key as keyof typeof stats] || 0);
          return (
            <Grid item key={key} xs={12} lg={12 / itemsPerLine}>
              <CardSimple
                title={label}
                content={value}
                paperProps={paperProps}
                variant={variant}
                ellipsis
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SalesStatsCards;
