import type { ApiExportRequest, ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { MachinesCashLog } from '@/types';

/**
 * Machines Cash Logs RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const cashLogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCashLogs: build.query<ApiResponse<MachinesCashLog[]>, ApiListingRequest>({
      query: (data) => athena({ url: 'v2/boxClears', method: 'POST', data }),
      providesTags: (response) => {
        const result = response?.data || [];
        return [
          ...result.map(({ id }) => ({ type: 'MachinesCashLogs', id }) as const),
          { type: 'MachinesCashLogs' as const, id: 'LIST' },
        ];
      },
    }),
    getCashLogsExport: build.mutation<BlobPart, ApiExportRequest>({
      query: ({ type, data }) =>
        athena({
          url: `v2/boxClears/${type}`,
          method: 'POST',
          data,
        }),
    }),
  }),
});

export const { useGetCashLogsQuery, useGetCashLogsExportMutation } = cashLogsApi;

export const {
  endpoints: { getCashLogs, getCashLogsExport },
} = cashLogsApi;
