import type { Columns } from '@bitstopco/bitstop-theme';

import StopIcon from '@mui/icons-material/Stop';
import { HiCheckCircle, HiIdentification, HiMinusCircle } from 'react-icons/hi';
import { MdAccessTimeFilled } from 'react-icons/md';

import CustomerKycVerificationsItemActivitySsn from '@/components/customer/tabs/kyc/verifications/CustomerKycVerificationsItemActivitySsn';
import CustomerKycVerificationsItemActivityStatus from '@/components/customer/tabs/kyc/verifications/CustomerKycVerificationsItemActivityStatus';

import TableColumnDate from '@/components/ui/table/TableColumnDate';

import { parsePhoneNumber } from '@/helpers/phone';

import theme from '@/helpers/theme';

import type { Customer, InquiryStatus } from '@/types';

import { DATE_FORMATS } from '../date';

const {
  palette: { text, error, warning, success },
} = theme;

const INQUIRY_STATUS_COMPLETED = {
  isAccordion: true,
  titleColor: success.main,
  status: 'completed',
  chipColor: 'success',
  humanizedStatus: 'successfully',
  activityIcon: null,
  icon: <HiCheckCircle color={success.main} size={20} data-type="completed" />,
  background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${success.main}`,
  titleTemplate: '<strong>${firstName}</strong> completed a <strong>${type}</strong> successfully.',
};

export const INQUIRY_STATUSES = {
  approved: INQUIRY_STATUS_COMPLETED,
  completed: INQUIRY_STATUS_COMPLETED,
  default: {
    isAccordion: true,
    titleColor: text.primary,
    status: '',
    chipColor: 'primary',
    humanizedStatus: 'in progress',
    activityIcon: null,
    icon: <MdAccessTimeFilled color={warning.main} size={20} data-type="default" />,
    background: 'rgba(225, 227, 234, 0.9)',
    titleTemplate: 'The <strong>${type}</strong> request is in progress.',
  },
  created: {
    isAccordion: true,
    titleColor: text.primary,
    status: '',
    chipColor: 'primary',
    humanizedStatus: 'created',
    activityIcon: 'initial',
    icon: <MdAccessTimeFilled color={warning.main} size={20} data-type="created" />,
    background: 'rgba(225, 227, 234, 0.9)',
    titleTemplate: '<strong>${firstName}</strong> sent a <strong>${type}</strong> request.',
  },
  initiated: {
    isAccordion: true,
    titleColor: text.primary,
    status: '',
    chipColor: 'primary',
    humanizedStatus: 'initiated',
    activityIcon: null,
    icon: <MdAccessTimeFilled color={warning.main} size={20} data-type="initiated" />,
    background: 'rgba(225, 227, 234, 0.9)',
    titleTemplate: 'The <strong>${type}</strong> request is pending.',
  },
  notsubmitted: {
    isAccordion: false,
    titleColor: text.secondary,
    status: '',
    chipColor: 'info',
    humanizedStatus: 'not submitted',
    activityIcon: null,
    icon: <HiMinusCircle color={text.disabled} size={20} data-type="notsubmitted" />,
    background: 'rgba(225, 227, 234, 0.9)',
    titleTemplate: '${type} is pending to be submitted.',
  },
  expired: {
    isAccordion: false,
    titleColor: warning.main,
    chipColor: 'warning',
    humanizedStatus: 'expired',
    activityIcon: null,
    icon: <MdAccessTimeFilled color={warning.main} size={20} data-type="expired" />,
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${warning.main}`,
    titleTemplate: 'The <strong>${type}</strong> request has expired.',
  },
  failed: {
    isAccordion: true,
    titleColor: error.main,
    chipColor: 'error',
    humanizedStatus: 'failed',
    activityIcon: null,
    icon: <MdAccessTimeFilled color={error.main} size={20} data-type="failed" />,
    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${error.main}`,
    titleTemplate: 'The <strong>${type}</strong> request has failed.',
  },
};

export const ACTIVITY_STATUSES = {
  default: INQUIRY_STATUSES.default,
  failed: INQUIRY_STATUSES.failed,
  errored: INQUIRY_STATUSES.failed,
  expired: INQUIRY_STATUSES.expired,
  active: INQUIRY_STATUSES.created,
  processed: INQUIRY_STATUSES.completed,
  passed: INQUIRY_STATUSES.completed,
  initiated: INQUIRY_STATUSES.initiated,
};

export const INQUIRIES = {
  governmentIdAndSelfie: {
    ...INQUIRY_STATUSES,
    title: 'Government ID and selfie inquiry',
    subtitle:
      'Send a link with a web form to the user which requires a picture of a Government ID to be taken front and back as well as a selfie. This information is collected and verified against several parameters for authenticity.',
  },
  governmentIdAndDatabase: {
    ...INQUIRY_STATUSES,
    title: 'Government ID, selfie and SSN inquiry',
    subtitle:
      'Enhanced Verification request - Request Government ID, Selfie and SSN verification from the user.',
  },
  databaseOnly: {
    ...INQUIRY_STATUSES,
    title: 'SSN inquiry',
    subtitle: 'Request and verify social security number and validate it against ID verification.',
  },
  documentUploadPoi: {
    ...INQUIRY_STATUSES,
    title: 'Proof of income inquiry',
    subtitle: 'POF doc request - Request proof of funds document from the user.',
  },
};

export const GOVERNMENT_ID_COLUMNS: Columns = {
  defaultValue: '-',
  items: [
    {
      value: 'nameFirst',
      label: 'First Name',
    },
    {
      value: 'nameLast',
      label: 'Last Name',
    },
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: string) => <CustomerKycVerificationsItemActivityStatus value={value} />,
    },
    {
      value: 'idClass',
      label: 'ID Class',
    },
    {
      value: 'identificationNumber',
      label: 'ID Number',
    },
    {
      value: 'issueDate',
      label: 'Date Issued',
      valueMapper: (value: string) =>
        value && (
          <TableColumnDate
            value={value}
            format={DATE_FORMATS.simple}
            options={{ timeZone: 'UTC' }}
            variant="subtitle2"
          />
        ),
    },
    {
      value: 'birthdate',
      label: 'Birthdate',
    },
    {
      value: 'expirationDate',
      label: 'Expiration Date',
      valueMapper: (value: string) =>
        value && (
          <TableColumnDate
            value={value}
            format={DATE_FORMATS.simple}
            options={{ timeZone: 'UTC' }}
            variant="subtitle2"
          />
        ),
    },
    {
      value: 'addressStreet1',
      label: 'Address Street',
    },
    {
      value: 'addressCity',
      label: 'Address City',
    },
    {
      value: 'addressSubdivision',
      label: 'Address Subdivision',
    },
    {
      value: 'addressPostalCode',
      label: 'Address Postal Code',
    },
    {
      value: 'countryCode',
      label: 'Country',
    },
    {
      value: 'submittedAt',
      label: 'Submitted At',
      valueMapper: (value: string) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.full} variant="subtitle2" />,
    },
  ],
};

export const SELFIE_COLUMNS: Columns = {
  defaultValue: '-',
  items: [
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: string) => <CustomerKycVerificationsItemActivityStatus value={value} />,
    },
    {
      value: 'submittedAt',
      label: 'Submitted At',
      valueMapper: (value: string) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.full} variant="subtitle2" />,
    },
  ],
};

export const DATABASE_ONLY_COLUMNS: Columns = {
  defaultValue: '-',
  items: [
    {
      value: 'nameFirst',
      label: 'First Name',
    },
    {
      value: 'nameLast',
      label: 'Last Name',
    },
    {
      value: 'phoneNumber',
      label: 'Phone Number',
      valueMapper: (phone: Customer['phone_number']) =>
        phone &&
        parsePhoneNumber({
          phone,
        }).parsed,
    },
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: string) => <CustomerKycVerificationsItemActivityStatus value={value} />,
    },
    {
      value: 'identificationNumber',
      label: 'SSN',
      valueMapper: (value: string) =>
        value && <CustomerKycVerificationsItemActivitySsn value={value} />,
    },
    {
      value: 'submittedAt',
      label: 'Submitted At',
      valueMapper: (value: string) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.full} variant="subtitle2" />,
    },
  ],
};

export const DOCUMENT_GENERIC_COLUMNS: Columns = {
  defaultValue: '-',
  items: [
    {
      value: 'status',
      label: 'Status',
      valueMapper: (value: string) => <CustomerKycVerificationsItemActivityStatus value={value} />,
    },
    {
      value: 'createdAt',
      label: 'Created At',
      valueMapper: (value: string) =>
        value && <TableColumnDate value={value} format={DATE_FORMATS.full} variant="subtitle2" />,
    },
  ],
};

export const INQUIRIES_ACTIVITIES = {
  'verification/government-id': {
    table: {
      title: 'Government ID Verification Attributes',
      icon: <HiIdentification color={text.disabled} size={20} />,
      columns: GOVERNMENT_ID_COLUMNS,
    },
    attachments: [
      { name: 'Government ID Front Photo', url: 'frontPhotoUrl' },
      { name: 'Government ID Back Photo', url: 'backPhotoUrl' },
    ],
  },
  'verification/selfie': {
    table: {
      title: 'Selfie Video Verification Attributes',
      icon: <HiIdentification color={text.disabled} size={20} />,
      columns: SELFIE_COLUMNS,
    },
    attachments: [{ name: 'Center Selfie Photo', url: 'centerPhotoUrl' }],
  },
  'verification/database': {
    table: {
      title: 'SSN Verification Attributes',
      icon: <HiIdentification color={text.disabled} size={20} />,
      columns: DATABASE_ONLY_COLUMNS,
    },
    attachments: [],
  },
  'document/generic': {
    table: {
      title: 'Document Proof of Income Verification Attributes',
      icon: <HiIdentification color={text.disabled} size={20} />,
      columns: DOCUMENT_GENERIC_COLUMNS,
    },
    attachments: [{ name: 'Proof of Income', url: 'filesNormalized' }],
  },
};

export const BASE_VERIFICATIONS = [
  /*{
    icon: <StopIcon sx={{ color: 'error.main', fontSize: 14 }} />,
    background: 'rgba(225, 227, 234, 0.9)',
    usdRate: 'free' as const,
    templateKind: 'request-government-id',
    title: 'Government ID',
    subtitle: 'Request a photo of ID from user via text.',
    status: 'notsubmitted' as InquiryStatus,
    modal: 'request_id',
  },*/
  {
    icon: <StopIcon sx={{ color: 'error.main', fontSize: 14 }} />,
    background: 'rgba(225, 227, 234, 0.9)',
    usdRate: 'free' as const,
    templateKind: 'request-source-of-funds-survey',
    title: 'Source of funds survey',
    subtitle: 'Send user source of funds KYC survey.',
    status: 'notsubmitted' as InquiryStatus,
    modal: 'request_survey',
    divider: true,
  },
];
