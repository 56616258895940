import { Fragment } from 'react';

import { usePopover } from '@bitstopco/bitstop-theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';

const Item = ({ icon, text, index, onClickItem }: any) => (
  <ListItem disablePadding>
    <ListItemButton onClick={() => onClickItem?.(index)} sx={{ padding: '8px 20px' }}>
      {icon && <ListItemIcon sx={{ minWidth: 'auto', mr: '20px' }}>{icon}</ListItemIcon>}
      <ListItemText
        primary={text}
        sx={{
          '& p': {
            color: '#506176',
            fontWeight: 500,
            lineHeight: '24px',
          },
        }}
      />
    </ListItemButton>
  </ListItem>
);

interface Item {
  text: string;
  icon?: any;
}

export interface AccountPopoverProps {
  username?: string;
  textColor?: string;
  avatarSrc?: string;
  items?: Item[];
  onLogout?: () => void;
  onClickItem?: (index: number) => void;
}

const AccountPopover = ({
  username,

  onLogout,
  onClickItem,

  items = [],
  avatarSrc = '',
  textColor = 'neutral.500',
}: AccountPopoverProps) => {
  const { anchorRef, open, handleOpen, handleClose } = usePopover();

  const handleClickItem = (index: number) => {
    onClickItem?.(index);
    handleClose();
  };

  const handleLogout = () => {
    onLogout?.();
    handleClose();
  };

  return (
    <>
      <Box
        data-test="account-popover"
        onClick={open ? handleClose : handleOpen}
        ref={anchorRef}
        sx={{
          ml: 2,
          display: 'flex',
          cursor: 'pointer',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={avatarSrc}
          variant="rounded"
          sx={{
            width: 40,
            height: 40,
          }}
        />
        <Box
          sx={{
            ml: 1,
            flex: 1,
            minWidth: 120,
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none',
            },
          }}
        >
          <div>
            <Typography
              sx={{
                color: textColor,
              }}
              variant="caption"
            >
              {''}
            </Typography>
            <Typography sx={{ color: 'primary.contrastText' }} variant="subtitle2">
              {username}
            </Typography>
          </div>
          <ExpandMoreIcon
            sx={{
              ml: 1,
              color: 'primary.contrastText',
            }}
          />
        </Box>
      </Box>
      <Popover
        data-test="account-popover-content"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 240,
            display: 'flex',
            borderRadius: '6px',
            background: '#FFFFFF',
            flexDirection: 'column',
            border: '1px solid #D5D8E1',
            boxShadow: '0px 8px 12px rgba(9, 30, 66, 0.08)',
          },
        }}
      >
        <List disablePadding>
          {items.map(({ text, icon }, index) => (
            <Fragment key={`account-popover-item-${index}`}>
              <Item {...{ text, icon, index, onClickItem: handleClickItem }} />
              <Divider />
            </Fragment>
          ))}
          <Item
            {...{
              index: -1,
              text: 'Log Out',
              icon: <LogoutIcon htmlColor="black" />,
              onClickItem: handleLogout,
            }}
          />
        </List>
      </Popover>
    </>
  );
};

export default AccountPopover;
