import { useMemo } from 'react';

import { TWO_LINES_ELLIPSIS } from '@bitstopco/bitstop-theme/constants';
import { truncateText } from '@bitstopco/bitstop-theme/helpers';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import dateFormat from 'date-fns/format';

import ProfileImg from '@/assets/bs-profile.png';

import { DATE_FORMATS } from '@/constants/app/date';

import type { Notification } from '@/types';

interface NotificationsListItemProps {
  data: Notification;
  onClick: () => void;
}

/**
 * Notifications List Item presentational component
 *
 * @param {NotificationsListItemProps} { data, onClick }
 * @return {*}
 */
const NotificationsListItem = ({ data, onClick }: NotificationsListItemProps) => {
  const theme = useTheme();

  const truncatedContent = useMemo(
    () => truncateText(data?.content || '', { maxLength: 60 }),
    [data?.content],
  );

  return (
    <>
      <Stack
        direction="row"
        spacing={1.5}
        sx={{ py: 2, pl: 1.5, pr: 1, cursor: 'pointer' }}
        role="button"
        onClick={onClick}
        data-test="notifications-list-item"
      >
        <Stack direction="row" spacing={1.5} alignItems="center" height="max-content">
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              backgroundColor: 'success.main',
              visibility: data.isRead ? 'hidden' : 'visible',
            }}
            data-test="notifications-list-item-dot"
          />

          <img
            loading="lazy"
            src={ProfileImg}
            width={28}
            height={28}
            style={{ borderRadius: '50%' }}
            alt="Profile"
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography variant="subtitle2">{data.title}</Typography>
              <Typography variant="caption" color="text.secondary" whiteSpace="nowrap">
                {dateFormat(new Date(data.createdAt), DATE_FORMATS.simpleHours)}
              </Typography>
            </Stack>
            <Typography variant="body2" mt={0.5} sx={TWO_LINES_ELLIPSIS}>
              {truncatedContent}
            </Typography>
          </Box>

          <ArrowForwardIosOutlinedIcon
            sx={{ ml: 2, fontSize: 16, color: theme.palette.text.disabled }}
          />
        </Stack>
      </Stack>
      <Divider />
    </>
  );
};

export default NotificationsListItem;
