import { memo } from 'react';

import type { ClickableTextProps } from '@bitstopco/bitstop-theme';
import { truncateText } from '@bitstopco/bitstop-theme/helpers';

import TableColumnClickableText from '@/components/ui/table/TableColumnClickableText';

import { NOT_AVAILABLE } from '@/constants/app/config';

import type { Machine } from '@/types';

interface TableColumnMachineProps {
  data: Machine;
  link?: boolean;
  fixIcon?: ClickableTextProps['fixIcon'];
  truncate?: ClickableTextProps['truncate'];
}

const TableColumnMachine = ({ data, link, fixIcon, truncate }: TableColumnMachineProps) => {
  const id = String(data.id || NOT_AVAILABLE);
  const to = link ? `${window.location.origin}/machine/${id}` : undefined;
  const text = data.slug || data.name || NOT_AVAILABLE;
  const truncatedText = truncate ? truncateText(text, truncate) : text;

  return (
    <TableColumnClickableText
      id={id}
      to={to}
      text={truncatedText}
      fixIcon={fixIcon}
      clipboardValue={id}
      name="Machine ID"
      overrideTooltipLinkText="Go to Machine"
    />
  );
};

export default memo(TableColumnMachine);
