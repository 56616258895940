import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import usePathInfo from '@/hooks/usePathInfo';

interface DefaultRouteWithParamProps {
  path: string;
}

export const DefaultRouteWithParam = ({ path }: DefaultRouteWithParamProps) => {
  const { paramVarNames, params } = usePathInfo();

  const to = useMemo(() => {
    const paramName = paramVarNames?.[0];
    const value = params?.[paramName] || '';
    return path.replace(`:${paramName}`, value);
  }, [path, params, paramVarNames]);

  return <Navigate to={to} replace />;
};
