import { useEffect, useState } from 'react';

import type { ModalChildrenProps, TableWrapperRef } from '@bitstopco/bitstop-theme';
import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { useRef } from 'react';

import { TableWrapper } from '@bitstopco/bitstop-theme';

import { useGetTransactionsQuery } from '@/store/api/services/transactions/transactions';

import type { TransactionsBatch, TransactionsSale } from '@/types';

import { BATCH_DETAILS_PAGINATION } from '@/constants/app/batching';
import { BATCH_DETAILS_COLUMNS } from '@/constants/app/transactions';

const BatchDetails = ({ hide, props, setTitle }: ModalChildrenProps) => {
  const { tx_hash, id: batch_id } = useState<TransactionsBatch>(props)[0];
  const requestPayload = {
    filter: {
      operator: 'equal',
      property: 'batch_id',
      value: batch_id,
    },
    pagination: { ...BATCH_DETAILS_PAGINATION },
  };

  const {
    data: { data: transactions = [] } = {},
    isFetching,
  } = useGetTransactionsQuery(requestPayload);

  useEffect(() => {
    setTitle(`Batch ${batch_id}`);
  }, [batch_id]);

  const tableRef = useRef<TableWrapperRef>(null);

  return (
    <>
      <DialogContent>
        {tx_hash && (
          <Stack spacing={0.5} my={2}>
            <Typography variant="h6">Batch Tx ID</Typography>
            <Typography color="text.secondary" fontWeight={400} variant="body1">
              {tx_hash}
            </Typography>
          </Stack>
        )}
        <Typography color="text.secondary" variant="subtitle2" mb={0.5}>
          Count: {transactions.length}
        </Typography>
        <TableWrapper<TransactionsSale>
          data={transactions}
          ref={tableRef}
          isLoading={isFetching}
          hideToolbar
          hideDateRangePicker
          hidePageSelector
          columns={BATCH_DETAILS_COLUMNS}
          tableProps={{
            placeholder: {
              empty: {
                title: 'No sales found',
                content: "We can't find any sale for this batch.",
              },
            },
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={hide} data-test="batch-details-back-button">
          Back to batching
        </Button>
      </DialogActions>
    </>
  );
};

export default BatchDetails;
