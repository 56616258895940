import { AutoFields, AutoForm, SubmitField, authReducer, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import { useUpdateLocationNoteMutation } from '@/store/api/services/kyc/notes'; // Assuming this mutation exists for updating notes

import { getOperatorId } from '@/helpers/jwt';
import { useAppSelector } from '@/store';
import type { LocationEditNoteModel } from '@/types';

/**
 * Location Edit Note Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const LocationEditNote = ({ hide, props }: ModalChildrenProps) => {
  // Initialize the form with schema
  const { schema } = useForm(SCHEMAS.LOCATION_EDIT_NOTE);

  // Mutation hook to update the note
  const [updateLocationNote] = useUpdateLocationNoteMutation();

  const {
    selectors: { userInfo },
  } = authReducer;

  const userData = useAppSelector(userInfo);

  // Pre-fill form data with existing note content
  const formData = {
    content: props?.note_content || '', // Use prior note content or default to an empty string
    author: `${userData?.first_name} ${userData?.last_name}`,
  };

  const handleSubmit = async (data: { content: string }) => {
    try {
      await updateLocationNote({
        location_id: props.location_id,
        operator_id: getOperatorId(),
        note_id: props.note_id,
        note_text: data.content, // Use form submission data
        author: `${userData?.first_name} ${userData?.last_name}`,
      }).unwrap();
      toast.success('Note updated successfully');
      hide();
    } catch {
      toast.error('Error updating note for this location');
    }
  };

  return (
    <AutoForm<LocationEditNoteModel>
      showInlineError
      schema={schema}
      model={formData} // <-- Inject prior note content here
      onSubmit={handleSubmit}
    >
      <DialogContent sx={{ my: 2, maxHeight: '400px', overflowY: 'auto' }}>
        <AutoFields omitFields={['author']} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back</Button>
        <SubmitField variant="contained">Update Note</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default LocationEditNote;
