import { Box } from '@mui/material';

import theme from '@/helpers/theme';

interface CustomerActionIconProps {
  icon: React.ElementType;
}
const CustomerActionIcon = ({ icon: Icon }: CustomerActionIconProps) => (
  <Box display="flex" mr={1}>
    <Icon color={theme.palette.text.disabled} size={18} />
  </Box>
);

export default CustomerActionIcon;
