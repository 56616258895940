import { authReducer, themeReducer } from '@bitstopco/bitstop-theme';

import { combineReducers } from '@reduxjs/toolkit';

//Common Reducers
import commonPersistenceReducer from './modules/common/persistence';
import commonRouterReducer from './modules/common/router';

//Pages Reducers
import pageWalletReducer from './modules/pages/wallet';

const commonReducer = combineReducers({
  router: commonRouterReducer,
  persistence: commonPersistenceReducer,
});

const pagesReducer = combineReducers({
  wallet: pageWalletReducer,
});

const rootReducer = {
  auth: authReducer.store,
  theme: themeReducer.store,

  common: commonReducer,
  pages: pagesReducer,
};

export default rootReducer;
