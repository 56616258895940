import api from '@/store/api';
import { ergot } from '@/store/api/instances';

import type { UpdatePolicyPayload } from '@/types';

export const PoliciesApi = api.injectEndpoints({
  endpoints: (build) => ({
    updatePolicy: build.mutation<string, UpdatePolicyPayload>({
      query: ({ customer_id, ...data }) =>
        ergot({
          url: `customer/${customer_id}/policy`,
          data,
          method: 'PUT',
          showError: true,
        }),
      invalidatesTags: (_, __, { customer_id }) => [
        { type: 'Customers', id: 'LIST' },
        { type: 'Notes', id: customer_id },
        { type: 'Customer', id: customer_id },
      ],
    }),
  }),
});

export const {
  // Mutations
  useUpdatePolicyMutation,
} = PoliciesApi;

export const {
  endpoints: { updatePolicy },
} = PoliciesApi;
