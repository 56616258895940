import { Avatar } from '@mui/material';

interface TimelineAvatarProps {
  name?: string;
}

const TimelineAvatar = ({ name = '' }: TimelineAvatarProps) => (
  <Avatar
    sx={{
      width: 20,
      height: 20,
      fontSize: 10,
      color: 'white',
      bgcolor: 'primary.main',
    }}
  >
    {name}
  </Avatar>
);

export default TimelineAvatar;
