import { CUSTOMER_ACTIONS } from '@/constants/app/customers/customer';
import { CUSTOMERS_STATUSES } from '@/constants/app/customers/customers';
import {
  ACTIVITY_STATUSES,
  INQUIRIES,
  INQUIRIES_ACTIVITIES,
} from '@/constants/app/customers/inquiries';

import type {
  Customer,
  Included,
  Inquiries,
  InquiriesMapper,
  Inquiry,
  InquiryActivity,
  InquiryActivityMapper,
  InquiryMapper,
  InquiryStatusMapper,
  KycStatus,
  LinkedCustomer,
} from '@/types';

import { NOT_AVAILABLE } from '@/constants/app/config';
import { CUSTOMER_ACTIONS_BY_STATUS } from '@/constants/app/customers/customer';

import { normalizePhoneNumber } from './phone';
import { fillTemplate } from './string';

/**
 * Checks if a given status is valid for a customer.
 *
 * This function is used to validate if the provided status exists within the
 * predefined CUSTOMERS_STATUSES.
 *
 * @param {string} status - The customer status to be checked.
 * @returns {boolean} - Returns true if the status is valid, false otherwise.
 */
export const isValidCustomerStatus = (status: string) =>
  CUSTOMERS_STATUSES.findIndex(({ value }) => value === status) !== -1;

/**
 * Fetches the full customer status object based on the provided status string.
 *
 * This function is used to retrieve the full status object which might include
 * additional properties other than the status value itself, from the predefined
 * CUSTOMERS_STATUSES.
 *
 * @param {string} status - The customer status string.
 * @returns {Object | undefined} - Returns the full status object if found, otherwise undefined.
 */
export const getCustomerStatus = (status: string) =>
  CUSTOMERS_STATUSES.find(({ value }) => value === status);

/**
 * Get Inquiry activity status object based on the provided status string.
 *
 * @param {string} status
 */
export const getInquiryActivityStatus = (status: string) =>
  ACTIVITY_STATUSES[status as keyof typeof ACTIVITY_STATUSES] || ACTIVITY_STATUSES.default;

export const getDefaultRequestActivity = (createdAt: string) =>
  ({
    id: '',
    relationships: [],
    icon: 'initial',
    type: 'request',
    attributes: {
      createdAt,
      status: 'active',
    },
  }) as Included;

/**
 * Get inquiry activities information mapped with local custom data (icons, colors, titles, and more)
 *
 * @param {{
 *   inquiryName: string;
 *   included: Included[];
 *   createdAt?: string;
 *   customerFirstName: string;
 * }} {
 *   inquiryName,
 *   included,
 *   createdAt
 *   customerFirstName,
 * }
 * @return {*}
 */
export const getComputedInquiryActivities = ({
  included,
  inquiryName,
  customerFirstName,
  createdAt = new Date().toISOString(),
}: {
  createdAt?: string;
  inquiryName: string;
  included: Included[];
  customerFirstName: string;
}) => {
  // Omit invalid activities
  let sorted = included.filter(({ type }) => !!INQUIRIES_ACTIVITIES[type as keyof InquiryActivity]);

  // Sort desc
  sorted.sort((a, b) => {
    const dateA = new Date(a?.attributes?.completedAt || a?.attributes?.createdAt || new Date());
    const dateB = new Date(b?.attributes?.completedAt || b?.attributes?.createdAt || new Date());

    return +dateB - +dateA;
  });

  // The first activity is always the request activity
  sorted = [...sorted, getDefaultRequestActivity(createdAt)];

  // Return the computed inquiry activities
  return sorted.map((data) => {
    const activity = INQUIRIES_ACTIVITIES[
      data.type as keyof InquiryActivity
    ] as InquiryActivityMapper;

    const statusData = getInquiryActivityStatus(data?.attributes?.status);

    const title = fillTemplate(statusData.titleTemplate, {
      type: inquiryName,
      firstName: customerFirstName,
    });

    return {
      data,
      title,
      activity,
      icon: statusData.activityIcon || statusData.icon,
      date: new Date(data?.attributes?.completedAt || data?.attributes?.createdAt || new Date()),
    };
  });
};

/**
 * Get inquiry information mapped with local custom data (icons, colors, titles, and more)
 *
 * @param {{
 *   inquiryId: string;
 *   inquiries: Inquiries;
 * }} {
 *   inquiryId,
 *   inquiries,
 * }
 * @return {*}
 */
export const getComputedInquiry = ({
  inquiryId,
  inquiries,
}: {
  inquiryId: string;
  inquiries: Inquiries;
}) => {
  const inquiry = inquiries[inquiryId as keyof typeof inquiries] as Inquiry;

  const computedInquiry: Inquiry & InquiryMapper = {
    ...inquiry,
    ...INQUIRIES[inquiryId as keyof InquiriesMapper],
  };

  const attributes = inquiry.data?.data?.attributes;
  const usdRate = inquiry.included?.template?.usdRate;

  const createdAt = attributes?.createdAt;
  const status = attributes?.status?.toLowerCase() || 'notsubmitted';

  const { icon, titleColor, isAccordion, background } = (computedInquiry[
    status as keyof InquiryMapper
  ] || computedInquiry.notsubmitted) as InquiryStatusMapper;

  const included = inquiry.data?.included;

  return {
    icon,
    status,
    usdRate,
    included,
    createdAt,
    background,
    titleColor,
    isAccordion,
    templateKind: inquiryId,
    title: computedInquiry.title,
    subtitle: computedInquiry.subtitle,
  };
};

/**
 * Get All computed inquiries activities timeline
 *
 * @param {{
 *   inquiries: Inquiries;
 *   customerFirstName: string;
 * }} {
 *   inquiries,
 *   customerFirstName,
 * }
 * @return {*}
 */
export const getComputedInquiriesTimeline = ({
  inquiries,
  customerFirstName,
}: {
  inquiries: Inquiries;
  customerFirstName: string;
}) => {
  let timeline: Record<string, any>[] = [];

  Object.keys(inquiries).forEach((inquiryId) => {
    const { title: inquiryName, included = [] } = getComputedInquiry({ inquiryId, inquiries });

    const activities = getComputedInquiryActivities({ inquiryName, included, customerFirstName });

    timeline = [...timeline, ...activities];
  });

  // Sort desc
  timeline.sort((a, b) => +b.date - +a.date);

  return timeline;
};

/**
 * Normalize Customer Names adding full name and first_name default value
 *
 * @param {(Customer)} data
 * @return {*}
 */
export const normalizeCustomerData = (data: Partial<Customer>) => {
  if (data) {
    const first_name = data.first_name || NOT_AVAILABLE;
    const last_name = data.last_name || '';
    const full_name = data.full_name || NOT_AVAILABLE;

    return {
      ...data,
      first_name,
      last_name,
      full_name,
      // We want to normalize the phone number since the value from the backend is not
      // 100% consistent (sometimes we get the country code with the (X) format and sometimes not)
      phone_number: data.phone_number && normalizePhoneNumber(data.phone_number),
    };
  }

  return data;
};

/**
 * Normalize a LinkedCustomer object: adding the full_name prop, empty first_name will default to 'n/a' and phone number normalized according to normalizePhoneNumber
 *
 * @param {(LinkedCustomer)} data
 * @return {*}
 */
export const normalizeLinkedCustomerData = (data: LinkedCustomer) => {
  if (!data) return null;

  const normalizedCustomerData = normalizeCustomerData(data);

  const full_name =
    `${normalizedCustomerData.first_name} ${normalizedCustomerData.last_name}`.trim();

  return {
    ...data,
    ...normalizedCustomerData,
    full_name,
  };
};

/*
 * Get customer actions by status
 *
 * @param {KycStatus} status
 * @return {*}
 */
export const getCustomerActionsByStatus = (status: KycStatus) => {
  // Valid items by customer status
  const validItems =
    CUSTOMER_ACTIONS_BY_STATUS?.[status as keyof typeof CUSTOMER_ACTIONS_BY_STATUS] || [];

  // We want to filter the actions by the valid items
  const actions = Object.keys(CUSTOMER_ACTIONS).filter((key) => validItems.indexOf(key) !== -1);

  // Sort by status order
  actions.sort((a, b) => validItems.indexOf(a) - validItems.indexOf(b));

  return actions.map((key) => CUSTOMER_ACTIONS[key as keyof typeof CUSTOMER_ACTIONS]);
};

/**
 * Transform the customers base filters to a valid backend valid complex filter
 *
 * @param {Record<string, any>} filters
 */
export const transformCustomersFilterPayload = (filters: Record<string, any>) => {
  if (typeof filters === 'object') {
    if (Array.isArray(filters)) {
      for (let i = 0; i < filters.length; i++) {
        transformCustomersFilterPayload(filters[i]);
      }
    } else {
      for (const key in filters) {
        if (key === 'property' && filters[key] === 'policies') {
          filters.property =
            filters.value === 'manual_approval'
              ? 'has_manual_approval_enrollment_policy'
              : `${filters.value}_enrollment_policy.is_satisfied`;
          filters.value = false;
        } else if (
          key === 'property' &&
          filters[key] === 'kyc_status' &&
          filters.value === 'cleared'
        ) {
          filters.value = undefined;
          filters.property = undefined;

          filters.operator = 'or';
          filters.operands = [
            {
              operator: 'equal',
              property: 'kyc_status',
              value: 'cleared',
            },
            {
              operator: 'equal',
              property: 'kyc_status',
              value: 'none',
            },
          ];
        } else {
          transformCustomersFilterPayload(filters[key]);
        }
      }
    }
  }
};
