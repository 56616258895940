import type { ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';

import { getLegacyPaginatedPayload } from '@/helpers/api';

import api from '@/store/api';
import { athena, operator } from '@/store/api/instances';

import { useAppDispatch } from '@/store';
import type {
  Affiliate,
  AffiliateAtm,
  AffiliateUser,
  PostCreateAffiliateOrganizationRequest,
  PostCreateAffiliateOrganizationResponse,
  PostCreateAffiliateUserRequest,
  RemoveAffiliateMachinesRequest,
} from '@/types';

type AffiliateApiListingRequest = ApiListingRequest & {
  affiliateId: string | null;
};

/**
 * Affiliates RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const affiliatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAffiliates: build.query<ApiResponse<Affiliate[]>, ApiListingRequest>({
      query: (params) =>
        athena({
          params: getLegacyPaginatedPayload(params),
          url: 'affiliates',
        }),
      providesTags: ['Affiliates'],
    }),
    getAffiliateUsers: build.query<ApiResponse<AffiliateUser[]>, AffiliateApiListingRequest>({
      query: ({ affiliateId, ...params }) =>
        athena({
          params: getLegacyPaginatedPayload(params),
          url: `affiliate/${affiliateId}/users`,
        }),
      providesTags: ['AffiliateUsers'],
    }),
    getAffiliateMachines: build.query<ApiResponse<AffiliateAtm[]>, AffiliateApiListingRequest>({
      query: ({ affiliateId, ...params }) =>
        athena({
          params: {
            ...getLegacyPaginatedPayload(params),
            query: JSON.stringify([
              {
                property: 'affiliate_id',
                operator: 'equal',
                value: affiliateId,
              },
            ]),
          },
          url: 'atms/recommended_replacement',
        }),
      providesTags: ['AffiliateMachines'],
    }),
    postCreateAffiliateOrganization: build.mutation<
      PostCreateAffiliateOrganizationResponse,
      PostCreateAffiliateOrganizationRequest
    >({
      query: (data) =>
        operator({
          data,
          method: 'POST',
          url: 'affiliate',
          showError: true,
        }),
    }),
    postCreateAffiliateUser: build.mutation<void, PostCreateAffiliateUserRequest>({
      query: (data) =>
        operator({
          data,
          method: 'POST',
          url: 'affiliate/users',
          showError: true,
        }),
    }),
    addAffiliateMachines: build.mutation<void, RemoveAffiliateMachinesRequest>({
      query: ({ affiliate_id, ...data }) =>
        operator({
          data,
          method: 'POST',
          url: `affiliate/${affiliate_id}/atms`,
          showError: true,
        }),
      invalidatesTags: ['AffiliateMachines'],
    }),
    removeAffiliateMachines: build.mutation<void, RemoveAffiliateMachinesRequest>({
      query: ({ affiliate_id, ...data }) =>
        operator({
          data,
          method: 'DELETE',
          url: `affiliate/${affiliate_id}/atms`,
          showError: true,
        }),
      invalidatesTags: ['AffiliateMachines'],
    }),
  }),
});

export const {
  // Queries
  useGetAffiliatesQuery,
  useGetAffiliateUsersQuery,
  useGetAffiliateMachinesQuery,

  // Mutations
  usePostCreateAffiliateOrganizationMutation,
  usePostCreateAffiliateUserMutation,
  useAddAffiliateMachinesMutation,
  useRemoveAffiliateMachinesMutation,
} = affiliatesApi;

export const {
  endpoints: {
    getAffiliates,
    getAffiliateUsers,
    getAffiliateMachines,
    postCreateAffiliateOrganization,
    postCreateAffiliateUser,
    addAffiliateMachines,
    removeAffiliateMachines,
  },
} = affiliatesApi;

export const useAffiliate = () => {
  const dispatch = useAppDispatch();

  const [createAffiliateOrganization] = usePostCreateAffiliateOrganizationMutation();
  const [createAffiliateUser] = usePostCreateAffiliateUserMutation();

  const createAffiliateOrg = async ({
    email,
    phone,
    country_code,
    first_name,
    last_name,
    ...organization
  }: PostCreateAffiliateOrganizationRequest &
    Omit<PostCreateAffiliateUserRequest, 'affiliate_id'>) => {
    const {
      data: { id: affiliate_id },
    } = await createAffiliateOrganization(organization).unwrap();

    await createAffiliateUser({
      email,
      phone,
      last_name,
      first_name,
      affiliate_id,
      country_code,
    }).unwrap();

    dispatch(affiliatesApi.util.invalidateTags(['Affiliates']));
  };

  return { createAffiliateOrg };
};
