import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { Banner } from 'types';

export const bannersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBanners: build.query<ApiResponse<Banner[]>, void>({
      query: () =>
        athena({
          url: 'v2/banners',
        }),
    }),
  }),
});

export const { useGetBannersQuery } = bannersApi;

export const {
  endpoints: { getBanners },
} = bannersApi;
