import { jwt, session } from '@bitstopco/bitstop-theme';

/**
 * Get Operator ID from JWT Token
 *
 * @return {*} {string}
 */
export const getOperatorId = () => {
  try {
    const token = session.getValue();
    const {
      user: { operator_id },
    } = jwt.parseJwt(token!);
    return operator_id;
  } catch {
    return '';
  }
};
