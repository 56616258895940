import toast from 'react-hot-toast';

import { Loader } from '@bitstopco/bitstop-theme';
import { IconButton } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useRemoveAffiliateMachinesMutation } from '@/store/api/services/affiliates/affiliates';

import { getOperatorId } from '@/helpers/jwt';

import type { AffiliateAtm } from '@/types';

interface AffiliateMachinesRemoveColumnProps {
  value: AffiliateAtm;
}

export const AffiliateMachinesRemoveColumn = ({ value }: AffiliateMachinesRemoveColumnProps) => {
  const [removeMachines, { isLoading }] = useRemoveAffiliateMachinesMutation();

  const handleRemove = async () => {
    try {
      await removeMachines({
        operator_id: getOperatorId(),
        affiliate_id: value.affiliate_id,
        atm_ids: [value.id],
      }).unwrap();

      toast.success(`Atm ${value.id} removed successfully`);
    } catch {}
  };

  return (
    <IconButton onClick={handleRemove} disabled={isLoading}>
      {isLoading ? <Loader size={16} /> : <DeleteOutlineIcon />}
    </IconButton>
  );
};
