import { memo } from 'react';

import { Stack, Typography } from '@mui/material';

import theme from '@/helpers/theme';

import type { TransactionsSale } from '@/types';

interface TransactionsSaleTypeProps {
  value: string;
  data: TransactionsSale;
}

const TransactionsSaleType = ({ data, value }: TransactionsSaleTypeProps) => {
  return (
    <Stack direction="row">
      <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
        {value}
      </Typography>

      {data?.coin_type ? (
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={{ ml: 1, textTransform: 'capitalize' }}
        >
          ({data?.coin_type || ''})
        </Typography>
      ) : (
        '-'
      )}
    </Stack>
  );
};

export default memo(TransactionsSaleType);
