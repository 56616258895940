import { memo } from 'react';

import { Stack, Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';

import { formatDate } from '@bitstopco/bitstop-theme/helpers';

import { DATE_FORMATS } from '@/constants/app/date';

interface TableColumnsDateBaseProps extends TypographyProps {
  value: string;
  format?: string;
  options?: {
    timeZone?: string;
    normalizeAsUTC?: boolean;
    performUtcToZonedTime?: boolean;
  };
}

interface TableColumnDateProps extends TableColumnsDateBaseProps {
  auxiliaryDate?: TableColumnsDateBaseProps;
}

const TableColumnDateBase = ({ value, options, format, ...props }: TableColumnsDateBaseProps) => {
  const defaultFormat = options?.timeZone === 'UTC' ? DATE_FORMATS.fullUtc : DATE_FORMATS.fullLocal;
  const formattedValue = formatDate(value, format || defaultFormat, options);

  return (
    <Typography variant="caption" {...props}>
      {formattedValue}
    </Typography>
  );
};

const TableColumnDate = ({ auxiliaryDate, ...dateColumnProps }: TableColumnDateProps) => (
  <Stack>
    <TableColumnDateBase {...dateColumnProps} data-test="table-column-date" />

    {!!auxiliaryDate && (
      <TableColumnDateBase
        fontSize={10}
        color="neutral.700"
        {...auxiliaryDate}
        format={auxiliaryDate?.format}
        data-test="table-column-date-auxiliary"
      />
    )}
  </Stack>
);

export default memo(TableColumnDate);
