import { useGetAllTagsByTermQuery } from '@/store/api/services/common/tags';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';

import TagsMenu from '@/components/tags/TagsMenu';
import TagList from '@/components/ui/TagList';
import { MACHINES_BULK_MAX_TAGS_ALLOWED } from '@/constants/app/machine';
import useTags from '@/hooks/useTags';
import type { ExtendedMachinesTag, MachinesTag } from '@/types';
import type { AxiosError } from 'axios';
import pluralize from 'pluralize';
import { useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as getUuidV4 } from 'uuid';

const BulkAssociateTags = ({ props, hide }: ModalChildrenProps) => {
  const { data: tagsList = [] } = useGetAllTagsByTermQuery('');
  const selectedAtmGlobalIds = useState<string[]>(props?.selectedAtmGobalIds)[0];
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<ExtendedMachinesTag[]>([]);

  const selectedTagIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags]);
  const computedTagsCollection: MachinesTag[] = useMemo(
    () => Array.from(new Set([...selectedTags, ...tagsList])),
    [tagsList, selectedTags],
  );

  const {
    anchorEl,
    openTagsMenu,
    closeTagsMenu,
    showDeleteTagModal,

    createTagsResources,

    updateTag,
    createTag,
  } = useTags();

  const handleOpenTagsMenu = () => {
    openTagsMenu(containerRef.current!);
  };

  const handleDeleteTagsResources = (tagId: string) => {
    !isLoading && setSelectedTags((prevState) => prevState.filter((_tag) => _tag.id !== tagId));
  };

  const handleCreateTags = (tagId: string) => {
    if (!selectedTagIds.includes(tagId!)) {
      const tag = tagsList.find(({ id }) => id === tagId);
      tag && setSelectedTags((prevState) => [...prevState, tag]);
    }
  };

  const handleUpdateCreateTags = async (tag: Partial<MachinesTag>) => {
    setSelectedTags((prevState) => [
      ...prevState,
      {
        ...tag,
        id: tag.id || getUuidV4(),
        status: !tag.id ? 'create' : 'update',
      } as ExtendedMachinesTag,
    ]);
  };

  const handleSaveTagsOnSubmit = async () => {
    try {
      setIsLoading(true);

      const _selectedTagIds = [...selectedTagIds];
      const updatedTags = selectedTags.filter(({ status }) => status === 'update');
      const createdTags = selectedTags.filter(({ status }) => status === 'create');

      const updateTagPromises = updatedTags.map(({ status, ...tag }) => updateTag(tag).unwrap());
      await Promise.all(updateTagPromises);

      for (const newTag of createdTags) {
        const { status, ...payload } = newTag;
        const createTagResult = await createTag(payload).unwrap();
        const tagIndex = _selectedTagIds.findIndex((tagId) => tagId === newTag.id);
        _selectedTagIds[tagIndex] = createTagResult.data;
      }

      if (_selectedTagIds.length && selectedAtmGlobalIds.length) {
        await createTagsResources({
          tag_ids: _selectedTagIds,
          resource_ids: selectedAtmGlobalIds,
        }).unwrap();
      }
      toast.success('The machines have been updated with the chosen tag');
      hide();
    } catch (error) {
      const { message } = error as AxiosError;
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const subtitle = useMemo(() => {
    const selectedAtmGlobalIdsCount = selectedAtmGlobalIds.length;
    const pluralizedTerm = pluralize('machine', selectedAtmGlobalIdsCount);

    return `The next changes will be applied to the ${selectedAtmGlobalIdsCount} selected ${pluralizedTerm} in the previous list.`;
  }, [selectedAtmGlobalIds]);

  return (
    <>
      <DialogContent sx={{ my: 2 }} data-test="bulk-associate-tags-modal">
        <Typography variant="h6" data-test="bulk-associate-tags-modal-title">
          Bulk edit action
        </Typography>
        <Typography variant="body1" mt={1} data-test="bulk-associate-tags-modal-subtitle">
          {subtitle}
        </Typography>

        <Stack mt={3} gap={0.25}>
          <Typography variant="caption" component="div">
            New Tag
          </Typography>
          <div ref={containerRef}>
            <TagList
              maxTagsAllowed={MACHINES_BULK_MAX_TAGS_ALLOWED}
              selectedTagIds={selectedTagIds}
              tagsCollection={computedTagsCollection}
              onClickAddTag={handleOpenTagsMenu}
              onClickDeleteTag={handleDeleteTagsResources}
            />

            <TagsMenu
              anchorEl={anchorEl}
              selectedTagIds={selectedTagIds}
              tagsCollection={computedTagsCollection}
              onClose={closeTagsMenu}
              onClickAddTag={handleCreateTags}
              onClickDeleteTagFromMenu={showDeleteTagModal}
              onClickDeleteTagFromList={handleDeleteTagsResources}
              onSubmitTagUpsert={handleUpdateCreateTags}
            />
          </div>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to machine</Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={selectedTagIds.length === 0}
          onClick={handleSaveTagsOnSubmit}
          data-test="bulk-associate-tags-modal-submit-btn"
        >
          Add Tag
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BulkAssociateTags;
