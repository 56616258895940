import { useUnfreezeTransactionMutation } from '@/store/api/services/transactions/transactions';
import { type ModalChildrenProps, useModal } from '@bitstopco/bitstop-theme';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';

const UnfreezeTransaction = ({ hide, props }: ModalChildrenProps) => {
  const { show } = useModal();
  const [unfreezeTransaction, { isLoading }] = useUnfreezeTransactionMutation();

  const data = useState(props)[0];

  const handleBack = () => {
    hide();
    show('transaction-details', {
      data: {
        id: data.id,
      },
    });
  };

  const handleSubmit = async () => {
    try {
      await unfreezeTransaction(data.id).unwrap();

      toast.success('Transaction was unfrozen successfully');

      hide();
    } catch {}
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="body2" color="text.primary" fontSize={16}>
          Are you sure you want to unfreeze this transaction?
        </Typography>
        <Typography variant="body2" color="text.secondary" fontSize={16}>
          Unfreezing a transaction will send the bitcoin to the user.
        </Typography>
        <Alert
          icon={<InfoIcon fontSize="inherit" sx={{ color: 'text.disabled' }} />}
          sx={{ backgroundColor: 'neutral.100', mt: 1.5 }}
        >
          <Typography variant="subtitle2" color="text.primary">
            Reason frozen
          </Typography>
          <Typography variant="body2" color="text.secondary" fontWeight={'normal'} fontSize={14}>
            {data.reason}
          </Typography>
        </Alert>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={handleBack}>Back to details</Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Yes, unfreeze
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default UnfreezeTransaction;
