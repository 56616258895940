import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

import { DATE_FORMATS as BASE_DATE_FORMATS } from '@bitstopco/bitstop-theme/constants/date';

/**
 * date-fns date forms
 * * https://date-fns.org/v2.30.0/docs/format
 */
export const DATE_FORMATS = {
  ...BASE_DATE_FORMATS,
  fullLocal: 'EEE, MM/dd/yy - hh:mm aa (O)',
  fullUtc: "EEE, MM/dd/yy - hh:mm aa '(UTC)'",
  dateHoursLocal: 'MM/dd/yyyy - hh:mm aa (O)',
};

export const LOCAL_TIME_LEGEND = 'Your local time';
export const DEFAULT_MACHINE_TIME_LEGEND = 'UTC date-time and Localized Machine date-time';
export const DEFAULT_TRANSACTION_TIME_LEGEND = 'UTC date-time and Localized Transaction date-time';

// Get the current date
const currentDate = new Date();

// Get the first and last days of the current week (week starts on Monday)
export const FIRST_DAY_OF_WEEK = startOfWeek(currentDate, { weekStartsOn: 1 }); // Week starts on Monday
export const LAST_DAY_OF_WEEK = endOfWeek(currentDate, { weekStartsOn: 1 }); // Week ends on Sunday

// Get the first and last days of the current month
export const FIRST_DAY_OF_MONTH = startOfMonth(currentDate);
export const LAST_DAY_OF_MONTH = endOfMonth(currentDate);

// Get the first and last days of the current year
export const FIRST_DAY_OF_YEAR = startOfYear(currentDate);
export const LAST_DAY_OF_YEAR = endOfYear(currentDate);

const dateFormat = 'yyyy-MM-dd';
// Format the dates (optional, you can format them as needed)
export const FORMATTED_TODAY = format(currentDate, dateFormat);
export const FORMATTED_FIRST_DAY_OF_WEEK = format(FIRST_DAY_OF_WEEK, dateFormat);
export const FORMATTED_LAST_DAY_OF_WEEK = format(LAST_DAY_OF_WEEK, dateFormat);
export const FORMATTED_FIRST_DAY_OF_MONTH = format(FIRST_DAY_OF_MONTH, dateFormat);
export const FORMATTED_LAST_DAY_OF_MONTH = format(LAST_DAY_OF_MONTH, dateFormat);
export const FORMATTED_FIRST_DAY_OF_YEAR = format(FIRST_DAY_OF_YEAR, dateFormat);
export const FORMATTED_LAST_DAY_OF_YEAR = format(LAST_DAY_OF_YEAR, dateFormat);

export const CURRENT_WEEK_DATE_RANGE = {
  range_start: FORMATTED_FIRST_DAY_OF_WEEK,
  range_end: FORMATTED_LAST_DAY_OF_WEEK,
};

export const CURRENT_MONTH_DATE_RANGE = {
  range_start: FORMATTED_FIRST_DAY_OF_MONTH,
  range_end: FORMATTED_LAST_DAY_OF_MONTH,
};

export const CURRENT_YEAR_DATE_RANGE = {
  range_start: FORMATTED_FIRST_DAY_OF_YEAR,
  range_end: FORMATTED_LAST_DAY_OF_YEAR,
};

export const ALL_TIME_DATE_RANGE = {
  range_start: '1970-01-01',
  range_end: FORMATTED_TODAY,
};
