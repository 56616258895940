import type { PersistenceState } from './types';

const initialState: PersistenceState = {
  lastGetMachineId: undefined,
  lastGetMachinesId: undefined,
  lastGetLocationId: undefined,
  lastGetLocationsId: undefined,
};

export default initialState;
