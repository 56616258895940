import { matchPath, useLocation, useParams } from 'react-router-dom';

import { ROUTES_PATHS } from '@/constants/app/views';

const usePathInfo = () => {
  const { pathname } = useLocation();
  const params = useParams();

  // Find the matching route pattern
  const patternPath = ROUTES_PATHS.find((route) => matchPath(route, pathname));

  // Construct the path without parameters
  let pathWithoutParam = pathname;
  if (patternPath) {
    pathWithoutParam = patternPath.replace(/:([^/]+)/g, '').replace('//', '/');
    if (pathWithoutParam.endsWith('/')) {
      // Remove trailing slash if present
      pathWithoutParam = pathWithoutParam.slice(0, -1);
    }
  }

  // Get the parameter variable names
  const paramVarNames = Object.keys(params);

  return { pathname, patternPath, pathWithoutParam, params, paramVarNames };
};

export default usePathInfo;
