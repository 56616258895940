import { useCallback } from 'react';

import { authReducer } from '@bitstopco/bitstop-theme';

import { useAppSelector } from '@/store';
import api from '@/store/api';
import { athena, atlas, ergot } from '@/store/api/instances';

import { NOTE_TEMPLATES, NOTE_TYPE_CUSTOMER_STATUS } from '@/constants/app/customers/notes';

import type {
  CreateDocumentRequest,
  CreateLocationNoteRequest,
  CreateNoteRequest,
  CreateNoteResponse,
  Customer,
  EditLocationNoteRequest,
  EditLocationNoteResponse,
  GetLocationNotesResponse,
  GetNoteTemplatesResponse,
  GetNoteTypesResponse,
  GetNotesResponse,
  LocationCreateNoteResponse,
  NoteType,
} from '@/types';

import { customerApi } from '../customers/customer';

export const notesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomerNotes: build.query<GetNotesResponse, string>({
      query: (customerId) =>
        ergot({
          url: 'kyc/notes',
          method: 'GET',
          params: {
            customer_id: customerId,
          },
        }),
      providesTags: (_, __, id) => [{ type: 'Notes', id }],
    }),
    getLocationNotes: build.query<GetLocationNotesResponse, string>({
      query: (locationId) => {
        return athena({
          url: `v2/locations/${locationId}/notes`,
          method: 'GET',
        });
      },
      providesTags: (_, __, location_id) => [{ type: 'LocationNotes', location_id }],
    }),
    getNoteTemplates: build.query<GetNoteTemplatesResponse, void>({
      query: () =>
        ergot({
          url: 'kyc/operator/note_templates',
          method: 'GET',
        }),
    }),
    getNoteTypes: build.query<GetNoteTypesResponse, void>({
      query: () =>
        ergot({
          url: 'kyc/note_types',
          method: 'GET',
        }),
    }),
    createLocationNote: build.mutation<LocationCreateNoteResponse, CreateLocationNoteRequest>({
      query: ({ location_id, note_text, author }) => {
        return atlas({
          url: `v2/locations/${location_id}/notes`,
          method: 'POST',
          data: {
            note_text,
            author,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      },
      invalidatesTags: (_, __, { location_id }) => [
        { type: 'LocationNotes' as const, location_id },
      ],
    }),
    createCustomerNote: build.mutation<CreateNoteResponse, CreateNoteRequest>({
      query: (data) =>
        ergot({
          url: 'kyc/notes',
          method: 'POST',
          params: {
            customer_id: data.customer_id,
          },
          data,
          showError: true,
        }),
      onQueryStarted: async ({ customer_id, note_type }, { dispatch, queryFulfilled }) => {
        // Optimistic response for customer

        // We want to continue with the optimistic response only for the notes that updates the customer status
        if (!Object.keys(NOTE_TYPE_CUSTOMER_STATUS).includes(note_type)) {
          return;
        }

        // Add optimistic kyc_status to the customer state based on the note_type
        const patchResult = dispatch(
          customerApi.util.updateQueryData('getCustomer', customer_id, (draft) => {
            draft.data.kyc_status =
              NOTE_TYPE_CUSTOMER_STATUS[note_type as keyof typeof NOTE_TYPE_CUSTOMER_STATUS];
          }),
        );

        try {
          await queryFulfilled;

          // Lets wait a little bit for the update customer status data
          setTimeout(() => {
            dispatch(
              customerApi.util.invalidateTags([{ type: 'Customer' as const, id: customer_id }]),
            );
          }, 1000);
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { customer_id }) => [
        { type: 'Customers' as const, id: 'LIST' },
        { type: 'Notes' as const, id: customer_id },
        { type: 'LinkedCustomers' as const, id: 'LIST' },
      ],
    }),
    createCustomerDocument: build.mutation<CreateNoteResponse, CreateDocumentRequest>({
      query: ({ file, customer_id, ...data }) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key as keyof typeof data] as string);
        });

        formData.append('file', file, file.name);

        return ergot({
          url: `kyc/uploads/${customer_id}`,
          method: 'POST',
          data: formData,
          showError: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      invalidatesTags: (_, __, { customer_id }) => [
        { type: 'Notes' as const, id: customer_id },
        { type: 'Customer' as const, id: customer_id },
      ],
    }),
    updateLocationNote: build.mutation<EditLocationNoteResponse, EditLocationNoteRequest>({
      query: ({ location_id, note_id, ...data }) => {
        return atlas({
          url: `v2/locations/${location_id}/notes/${note_id}`,
          method: 'PUT',
          data: {
            note_text: data.note_text,
            author: data.author,
          },
        });
      },
      onQueryStarted: async ({ location_id, note_id, ...data }, { dispatch, queryFulfilled }) => {
        // Optimistic update
        const locationIdStr = location_id.toString();
        const patchResult = dispatch(
          notesApi.util.updateQueryData('getLocationNotes', locationIdStr, (draft) => {
            const note = draft.data.find((n) => n.id === note_id.toString());
            if (note) {
              note.note_text = data.note_text;
              note.author = data.author;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_, __, { location_id }) => [
        { type: 'LocationNotes' as const, location_id },
      ],
    }),
  }),
});

export const {
  // Queries
  useGetCustomerNotesQuery,
  useGetLocationNotesQuery,
  useGetNoteTypesQuery,
  useGetNoteTemplatesQuery,

  // Mutations
  useCreateCustomerNoteMutation,
  useCreateCustomerDocumentMutation,
  useCreateLocationNoteMutation,
  useUpdateLocationNoteMutation,
} = notesApi;

export const {
  endpoints: {
    getCustomerNotes,
    getNoteTypes,
    getNoteTemplates,
    createCustomerNote,
    createCustomerDocument,
    createLocationNote,
    updateLocationNote,
  },
} = notesApi;

export const useNotes = (customer: Customer) => {
  const user = useAppSelector(authReducer.selectors.userInfo);

  const [createCustomerNote] = useCreateCustomerNoteMutation();
  const [createCustomerDocument] = useCreateCustomerDocumentMutation();

  const createNote = useCallback(
    ({
      type,
      reason,
      content = '',
      authorType = 'operator',
    }: {
      reason: string;
      type: NoteType;
      content?: string;
      authorType?: 'operator';
    }) => {
      const payload = {
        reason,
        content,
        customer_id: customer.id,
        operator_id: user.context.operator_id,
        author_id: user.id,
        author_name: `${user.first_name} ${user.last_name}`,
        note_type: type,
        author_type: authorType,
        customer_first_name: customer.first_name || '',
        customer_last_name: customer.last_name || '',
      };

      return createCustomerNote(payload);
    },
    [user, customer],
  );

  const uploadDocument = useCallback(
    ({ file, reason }: { file: File; reason: string }) => {
      const payload = {
        reason,
        customer_id: customer.id,
        author_id: user.id,
        author_name: `${user.first_name} ${user.last_name}`,
        first_name: customer.first_name || '',
        last_name: customer.last_name || '',
      };

      return createCustomerDocument({ ...payload, file });
    },
    [user, customer],
  );

  return { createNote, uploadDocument };
};

export const useNoteTemplates = (type: string) => {
  const { data } = useGetNoteTemplatesQuery();
  const template = data?.find(({ note_type }) => note_type === type) || { content: '' };
  const localTemplate = NOTE_TEMPLATES[type as keyof typeof NOTE_TEMPLATES];

  return { ...template, ...localTemplate };
};
