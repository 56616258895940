import { memo } from 'react';

import { Typography } from '@mui/material';

import { NOT_AVAILABLE } from '@/constants/app/config';

interface MachinesCashLogsMachineProps {
  name?: string;
  address?: string;
}

const MachinesCashLogsMachine = ({
  name = NOT_AVAILABLE,
  address = '',
}: MachinesCashLogsMachineProps) => (
  <>
    <Typography variant="subtitle2">{name}</Typography>
    <Typography variant="caption">{address}</Typography>
  </>
);

export default memo(MachinesCashLogsMachine);
