import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Stack } from '@mui/material';

interface NotificationsTabFooterProps {
  isLoading?: boolean;
  onClickSettings: () => void;
  onClickMarkAllRead: () => void;
}

/**
 * Notifications Tab Footer
 * Go back button and Mark all as read logic
 *
 * @return {*}
 */
const NotificationsTabFooter = ({
  isLoading,
  onClickSettings,
  onClickMarkAllRead,
}: NotificationsTabFooterProps) => (
  <Box flex="0 1 auto">
    <Divider />
    <Stack py={3} px={4} direction="row" justifyContent="space-between">
      <Button
        onClick={onClickSettings}
        startIcon={<SettingsOutlinedIcon />}
        data-test="notifications-tab-go-settings-button"
      >
        Go to Settings
      </Button>
      <LoadingButton
        startIcon={<DoneAllOutlinedIcon />}
        onClick={onClickMarkAllRead}
        loading={isLoading}
        data-test="notifications-tab-all-read-button"
      >
        Mark all as read
      </LoadingButton>
    </Stack>
  </Box>
);

export default NotificationsTabFooter;
