import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';

export const USER_POPOVER_ITEMS = [
  /*{
    text: 'View my profile',
    icon: <PermIdentityIcon htmlColor="black" />,
  },*/
  {
    text: 'Settings',
    icon: <SettingsApplicationsOutlinedIcon htmlColor="black" />,
  },
];
