import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { athena } from '@/store/api/instances';

import type { Policy } from '@/types';

/**
 * Policies RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const policiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPolicies: build.query<ApiResponse<Policy[]>, void>({
      query: () =>
        athena({
          url: 'v2/policies',
          method: 'POST',
          data: {
            pagination: {
              sort_by: 'id',
              current_page: 1,
              sort_ascending: true,
              items_per_page: 1000,
            },
          },
        }),
    }),
  }),
});

export const {
  // Queries
  useGetPoliciesQuery,
} = policiesApi;

export const {
  endpoints: { getPolicies },
} = policiesApi;
