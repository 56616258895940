import { Suspense } from 'react';
import type { LazyExoticComponent } from 'react';

import { Loader } from '@bitstopco/bitstop-theme';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';

interface TabsPanelsProps {
  name: string;
  panels: string[];
  routes: string[];
  components: (LazyExoticComponent<(props: any) => JSX.Element> | (() => JSX.Element))[];
  fullWidth?: boolean;
  fullHeight?: boolean;
}

/**
 * Custom Mui TabPanel implementation for multiple panels
 * * For more information see also https://mui.com/material-ui/api/tab-panel
 * @param {TabsPanelsProps} { name, panels, routes, components }
 * @return {*}
 */
const TabsPanels = ({
  name,
  panels,
  routes,
  components,
  fullWidth,
  fullHeight,
}: TabsPanelsProps) => {
  const width = fullWidth ? '100%' : 'initial';
  const height = fullHeight ? '100%' : 'initial';

  return (
    <Box data-test={`${name}-tab-content`} width={width} height={height}>
      {panels.map((_, index) => {
        const PanelComponent = components[index];
        return (
          <TabPanel key={`${name}-panel-${index}`} value={routes[index]} sx={{ p: 0, height }}>
            <Suspense fallback={<Loader />}>
              <PanelComponent />
            </Suspense>
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default TabsPanels;
