import { useState } from 'react';

import toast from 'react-hot-toast';

import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider } from '@mui/material';

import SCHEMAS from '@/forms/schemas';

import {
  affiliatesApi,
  usePostCreateAffiliateUserMutation,
} from '@/store/api/services/affiliates/affiliates';

import { useAppDispatch } from '@/store';
import type { CreateUserModel } from '@/types';

/**
 * Affiliate Add User Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const AffiliateAddUser = ({ hide, props }: ModalChildrenProps) => {
  const affiliate_id = useState(props)[0];

  const dispatch = useAppDispatch();

  const [addUser] = usePostCreateAffiliateUserMutation();

  const { schema } = useForm(SCHEMAS.CREATE_USER);

  const handleSubmit = async ({ phone: { dialCode, phone }, ...data }: CreateUserModel) => {
    try {
      await addUser({ affiliate_id, phone, country_code: dialCode, ...data }).unwrap();
      dispatch(affiliatesApi.util.invalidateTags(['AffiliateUsers']));

      toast.success('User added successfully');
      hide();
    } catch {}
  };

  return (
    <AutoForm<CreateUserModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }} data-test="affiliate-add-user-modal">
        <AutoFields />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <SubmitField data-test="affiliate-add-user-modal-submit-btn">Add User</SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default AffiliateAddUser;
