import { ClickableText } from '@bitstopco/bitstop-theme';
import type { ClickableTextProps } from '@bitstopco/bitstop-theme';

import { Box as BaseBox, Typography, styled } from '@mui/material';
import type { BoxProps } from '@mui/material';
import omit from 'lodash/omit';

import { NOT_AVAILABLE } from '@/constants/app/config';

type Size = 'regular' | 'large';

const Box = styled((props: BoxProps & { size?: Size }) => <BaseBox {...omit(props, ['size'])} />)(
  ({ theme, size }) => ({
    ...(size === 'large' && {
      '& h6': {
        ...theme.typography.h5,
      },
      '& span': {
        ...theme.typography.body2,
      },
    }),
  }),
);

export interface TableColumnClickableTextProps extends ClickableTextProps {
  id?: string;
  size?: Size;
}

const TableColumnClickableText = ({
  id = NOT_AVAILABLE,
  text = NOT_AVAILABLE,
  name = 'ID',
  size = 'regular',
  ...props
}: TableColumnClickableTextProps) => (
  <Box display="inline-grid" size={size}>
    <Typography variant="subtitle2">{text}</Typography>
    <Box>
      <ClickableText name={name} text={`ID: ${id}`} {...props} />
    </Box>
  </Box>
);

export default TableColumnClickableText;
