import { TIMEZONE, TODAY_FORMATTED } from '@bitstopco/bitstop-theme/constants';
import { formatCurrency } from '@bitstopco/bitstop-theme/helpers';

import api from '@/store/api';
import { ergot } from '@/store/api/instances';

import type { TransactionsStatsRequest, TransactionsStatsResponse } from '@/types';

export const DEFAULT_SALES_STATS_PAYLOAD: TransactionsStatsRequest = {
  timezone: TIMEZONE,
};

/**
 * Sales RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const SalesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSalesStats: build.query<TransactionsStatsResponse, TransactionsStatsRequest | void>({
      query: (payload = DEFAULT_SALES_STATS_PAYLOAD) => {
        //default payload for get all general sales
        const params: TransactionsStatsRequest = {
          ...DEFAULT_SALES_STATS_PAYLOAD,
          ...payload,
        };

        if (!payload?.range_end || !payload?.range_start) {
          params.range_end = params.range_start = TODAY_FORMATTED;
        }

        return ergot({ url: 'sales-stats', params });
      },
      transformResponse: (sales: TransactionsStatsResponse) => {
        const _sales: any = { ...sales };
        Object.keys(_sales).forEach((key) => {
          _sales[key] = ['total_transactions', 'total_count'].includes(key)
            ? _sales[key]
            : formatCurrency(_sales[key]);
        });

        return _sales;
      },
      providesTags: ['Sales'],
    }),
  }),
});

export const { useGetSalesStatsQuery } = SalesApi;

export const {
  endpoints: { getSalesStats },
} = SalesApi;
