import { useModal } from '@bitstopco/bitstop-theme';

import type { Notification } from '@/types';

import NotificationsListItem from './NotificationsListItem';

interface NotificationsListProps {
  data: Notification[];
}

/**
 * Notifications s List
 *
 * @param {NotificationsListProps} { data }
 * @return {*}
 */
const NotificationsList = ({ data }: NotificationsListProps) => {
  const { show } = useModal();

  // Show the notifications detail drawer
  const handleItem = (index: number) => () => {
    show('notifications-detail', { data: data[index] });
  };

  return (
    <>
      {data.map((notification, index) => (
        <NotificationsListItem
          data={notification}
          key={`notifications-item-${index}`}
          onClick={handleItem(index)}
        />
      ))}
    </>
  );
};

export default NotificationsList;
