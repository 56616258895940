import { useMemo } from 'react';

import { MenuItem, Select, Stack, Typography } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import {
  LOCATION_RANGES_FIRST,
  LOCATION_RANGES_PLACEHOLDERS,
  LOCATION_RANGES_RANGES_OPTIONS,
  LOCATION_VALID_HOURS,
} from '@/constants/app/location';

interface EditLocationScheduleRangeProps {
  day: string;
  values: string[];
  onChange: (newValues: string[]) => void;
}

const EditLocationScheduleRange = ({ day, values, onChange }: EditLocationScheduleRangeProps) => {
  const isSingle = values[0] === LOCATION_VALID_HOURS[0];
  const selects = useMemo(
    () => (isSingle ? [LOCATION_RANGES_FIRST] : LOCATION_RANGES_RANGES_OPTIONS),
    [isSingle],
  );

  const handleOnChange = (index: number) => (event: SelectChangeEvent<string>) => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    onChange(newValues);
  };

  return (
    <Stack spacing={0} mt={2}>
      <Typography variant="subtitle2">{day}</Typography>
      <Stack direction="row" spacing={2}>
        {selects.map((items, index) => {
          let _items: string[] = items;
          if (index === 1) {
            const _index = items.findIndex((value) => value === values?.[0]);
            _items = items.filter((_, index) => index !== _index || _ === '');
          }
          return (
            <Select
              key={`Location-date-range-${index}`}
              fullWidth
              size="small"
              displayEmpty
              value={values[index]}
              onChange={handleOnChange(index)}
              data-test="operation-schedule-select"
            >
              {_items.map((item, indexSub) => (
                <MenuItem key={item} value={item} disabled={indexSub === 0}>
                  {item || LOCATION_RANGES_PLACEHOLDERS[index]}
                </MenuItem>
              ))}
            </Select>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default EditLocationScheduleRange;
