import { memo } from 'react';

import { CRYPTO_CURRENCIES, CURRENCIES } from '@bitstopco/bitstop-theme/constants';
import { formatCurrency } from '@bitstopco/bitstop-theme/helpers';

import { Stack, Typography } from '@mui/material';

import theme from '@/helpers/theme';

import type { TransactionsSale } from '@/types';

interface TransactionsSaleSentProps {
  value: string;
  data: TransactionsSale;
}

const TransactionsSaleSent = ({ data, value }: TransactionsSaleSentProps) => {
  const { symbol } = CRYPTO_CURRENCIES[data?.coin_type as keyof typeof CRYPTO_CURRENCIES] || '';
  const currencySymbol = CURRENCIES[data?.currency_code as keyof typeof CURRENCIES] || '$';

  return (
    <Stack>
      {value && (
        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }} textAlign="right">
          {symbol} {value}
        </Typography>
      )}

      <Typography variant="body2" color={theme.palette.text.secondary} textAlign="right">
        {data.sent ? formatCurrency(+(data.sent || 0), currencySymbol) : '-'}
      </Typography>
    </Stack>
  );
};

export default memo(TransactionsSaleSent);
