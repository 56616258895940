import SCHEMAS from '@/forms/schemas';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';
import { AutoFields, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import {
  useGetOperatorSettingQuery,
  useUpdateOperatorSettingMutation,
} from '@/store/api/services/operator/operatorSettings';

import type { OperatorSettingsUpdateModel } from '@/types';

import toast from 'react-hot-toast';

const BatchingSettings = ({ hide }: ModalChildrenProps) => {
  const [updateOperatorSetting] = useUpdateOperatorSettingMutation();
  const {
    data: {
      data: { batching_minimum_usd = 0 } = {},
    } = {},
  } = useGetOperatorSettingQuery();

  const { schema } = useForm(SCHEMAS.OPERATOR_SETTINGS, {
    initValues: {
      batching_minimum_usd: {
        value: batching_minimum_usd.toString(),
        floatValue: batching_minimum_usd,
      },
    },
  });

  const handleSubmit = async ({ batching_minimum_usd }: OperatorSettingsUpdateModel) => {
    try {
      await updateOperatorSetting({
        batching_minimum_usd: batching_minimum_usd.floatValue,
      }).unwrap();
      toast.success('Batching settings successfully changed.');
      hide();
    } catch {}
  };

  return (
    <AutoForm<OperatorSettingsUpdateModel> showInlineError schema={schema} onSubmit={handleSubmit}>
      <DialogContent sx={{ my: 2 }}>
        <Stack direction="column">
          <Typography variant="h6" mb={0.5}>
            Rules
          </Typography>
          <Typography variant="body1" mb={2}>
            Set up the rules for a transaction to be considered into a batch
          </Typography>
          <AutoFields />
          <Alert
            icon={<InfoIcon fontSize="inherit" sx={{ color: 'text.disabled' }} />}
            sx={{ backgroundColor: 'neutral.100', mt: 1.5 }}
          >
            <Typography variant="subtitle2" color="text.primary">
              Batching
            </Typography>
            <Typography variant="body2" color="text.secondary">
              All transactions under{' '}
              <Typography variant="body2" color="text.primary" component="span">
                ${batching_minimum_usd}
              </Typography>{' '}
              will be batched every 30 minutes.
            </Typography>
          </Alert>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="link-account-back-button">
          Back to batching
        </Button>
        <SubmitField label="Save changes" />
      </DialogActions>
    </AutoForm>
  );
};

export default BatchingSettings;
