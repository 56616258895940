import { useContext, useEffect, useState } from 'react';

import { PermissionsContext } from '@bitstopco/bitstop-theme';

import { SIDEBAR_ITEMS, SIDEBAR_ITEMS_SETTINGS } from '@/constants/app/sidebar';

import type { SidebarItemDataExtended } from '@/types';

const useSidebarPermissions = (settings?: boolean) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarItems, setSidebarItems] = useState<SidebarItemDataExtended[]>([]);

  const { isAllowedTo } = useContext(PermissionsContext);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      /**
       * If we are showing a settings view, we want to change the sidebar buttons
       */
      const sidebarItems = !settings ? SIDEBAR_ITEMS : SIDEBAR_ITEMS_SETTINGS;

      const filteredSidebarItems: SidebarItemDataExtended[] = [];
      for (const item of sidebarItems) {
        if (item.permission) {
          const isAllowed = await isAllowedTo(item.permission, 'AND');

          if (isAllowed) {
            filteredSidebarItems.push(item);
          }
        } else {
          filteredSidebarItems.push(item);
        }
      }

      setSidebarItems(filteredSidebarItems);

      setIsLoading(false);
    })();
  }, [settings]);

  return { isLoading, sidebarItems };
};

export default useSidebarPermissions;
