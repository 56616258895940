import { useState } from 'react';

import { OutlinedRoundImage } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import EastIcon from '@mui/icons-material/East';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { useActiveBlockchainAnalyticsMutation } from '@/store/api/services/operator/blockchainAnalytics';

import type { BlockchainAnalyticsOperatorConfig } from '@/types';

/**
 * Blockchain Analytics active vendor modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const ActivateVendor = ({ hide, props }: ModalChildrenProps) => {
  const [newVendor] = useState(props?.newVendor);
  const [activeVendor] = useState<BlockchainAnalyticsOperatorConfig>(props?.activeVendor);

  /**
   * Get the activateBlockchainAnalyticsVendor mutation
   */
  const [activateBlockchainAnalyticsVendor, { isLoading }] = useActiveBlockchainAnalyticsMutation();

  const handleActivate = async () => {
    const vendorId = newVendor?.vendor;

    /**
     * If the vendor ID is invalid we want to cancel the submit process
     */
    if (!vendorId) {
      return;
    }

    try {
      /**
       * Submit the mutation using the desired vendor ID
       */
      await activateBlockchainAnalyticsVendor({ vendor: vendorId }).unwrap();
      toast.success('New vendor activated successfully');
      hide();
    } catch {
      //
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">Please confirm that you want to activate this vendor</Typography>
        <Typography variant="body1">
          This action will activate the selected vendor and will disable the current one.
        </Typography>

        <Stack direction="row" mt={2} spacing={2}>
          {activeVendor && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <OutlinedRoundImage
                  size="small"
                  renderImage={<Avatar src={activeVendor?.image} sx={{ width: 18, height: 18 }} />}
                  variant="secondary"
                />

                <Typography sx={{ textDecoration: 'line-through', color: 'text.disabled' }}>
                  {activeVendor?.friendly_name}
                </Typography>
              </Stack>

              <EastIcon />
            </Stack>
          )}

          {newVendor && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <OutlinedRoundImage
                renderImage={<Avatar src={newVendor?.image} sx={{ width: 32, height: 32 }} />}
                variant="success"
              />
              <Typography sx={{ color: 'text.primary' }}>{newVendor?.vendor}</Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Cancel</Button>
        <LoadingButton
          variant="contained"
          onClick={handleActivate}
          loading={isLoading}
          disabled={isLoading}
        >
          Yes, activate
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ActivateVendor;
