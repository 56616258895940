import type { Permission } from '@bitstopco/bitstop-theme';

export const fetchPermission =
  (userPermissions?: Record<string, string[]>) =>
  async (permission: Permission): Promise<boolean> => {
    try {
      const [name, value] = permission.split('.');
      return (userPermissions?.[name as keyof typeof userPermissions] || []).indexOf(value) !== -1;
    } catch {
      return false;
    }
  };
