import { useEffect, useMemo, useState } from 'react';

import { WEEK_DAYS_LONG, WEEK_DAYS_SHORT } from '@bitstopco/bitstop-theme/constants';
import { getConsecutive } from '@bitstopco/bitstop-theme/helpers';

import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import numeral from 'numeral';

import CustomCheckbox from '@/components/ui/CustomCheckbox';

import { LOCATION_VALID_HOURS } from '@/constants/app/location';

import EditLocationScheduleChip from './EditLocationScheduleChip';
import EditLocationScheduleRange from './EditLocationScheduleRange';

const DEFAULT_WEEK_VALUES: boolean[] = new Array(WEEK_DAYS_SHORT.length).fill(false);
const DEFAULT_RANGE_VALUES: string[][] = Array.from({ length: WEEK_DAYS_SHORT.length }, () =>
  Array(2).fill(''),
);

const getDaysInRange = (dayRange: string) => {
  const [startDay, endDay] = dayRange.split('-');

  // Find the indices of the start and end days in WEEK_DAYS_SHORT array
  const startIndex = WEEK_DAYS_SHORT.findIndex((day) => day === startDay);
  let endIndex = WEEK_DAYS_SHORT.findIndex((day) => day === endDay);

  if (startIndex === -1 || endIndex === -1) {
    // Handle invalid day range
    throw new Error('Invalid day range');
  }

  // If the end day comes before the start day, adjust the end index to consider the full week
  if (endIndex < startIndex) {
    endIndex += WEEK_DAYS_SHORT.length;
  }

  // Create an array containing all the days between start and end days
  const daysInRange: string[] = [];
  for (let i = startIndex; i <= endIndex; i++) {
    const indexInWeek = i % WEEK_DAYS_SHORT.length;
    daysInRange.push(WEEK_DAYS_SHORT[indexInWeek]);
  }

  return daysInRange;
};

const getValidSchema = (data: string[] = []) => {
  const daysChecked: boolean[] = [...DEFAULT_WEEK_VALUES];
  const rangeValues: string[][] = cloneDeep(DEFAULT_RANGE_VALUES);

  if (data?.[0] === '24/7' || !data?.length) {
    return { everydayChecked: true, daysChecked, rangeValues };
  }

  data.forEach((item) => {
    try {
      const values = item.split(': ');
      if (values[1].toLowerCase() !== 'closed') {
        let days = [values[0]];
        if (values[0].indexOf('-') !== -1) {
          // multiple days
          days = getDaysInRange(values[0]);
        }

        days.forEach((day) => {
          const index = WEEK_DAYS_SHORT.indexOf(day);
          daysChecked[index] = true;

          const hours = values[1].split(' - ');

          hours.forEach((h, i) => {
            const is24 = hours[0].toLowerCase().indexOf('24-hour') !== -1;

            if (is24) {
              rangeValues[index][i] = LOCATION_VALID_HOURS[0];
            } else {
              const hourValues = h.split(' ');
              const hourSplit = hourValues[0].split(':');
              const value = numeral(+hourSplit[0]).format('00');
              rangeValues[index][i] = `${value}:${hourSplit[1]} ${hourValues[1]}`;
            }
          });
        });
      }
    } catch {
      // something went wrong trying to set a valid rangeValues
    }
  });

  return { everydayChecked: false, daysChecked, rangeValues };
};

const getValidSchedule = (
  daysChecked: boolean[],
  everydayChecked: boolean,
  rangeValues: string[][],
) => {
  if (everydayChecked) {
    return ['24/7'];
  }

  if (daysChecked.every((v) => !v)) {
    return ['Mon-Sun: Closed'];
  }

  const items: string[] = [];
  const baseConsecutive: number[] = [];

  rangeValues.forEach((values, index) => {
    const opens = daysChecked[index] ? values?.[0] : null;
    const closes = daysChecked[index] ? values?.[1] : null;

    if (opens === '24-Hours') {
      items.push(`${WEEK_DAYS_SHORT[index]}: 24-hour`);
    } else if (!isEmpty(opens) && !isEmpty(closes)) {
      items.push(`${WEEK_DAYS_SHORT[index]}: ${opens} - ${closes}`);
    } else {
      baseConsecutive.push(index);
    }
  });

  const consecutive = getConsecutive(baseConsecutive) as number[][];
  consecutive.forEach((values) => {
    const first = values[0];
    const last = values[values.length - 1];

    const text =
      values.length <= 1
        ? `${WEEK_DAYS_SHORT[first]}: Closed`
        : `${WEEK_DAYS_SHORT[first]}-${WEEK_DAYS_SHORT[last]}: Closed`;

    items.splice(first, 0, text);
  });

  return items;
};

interface EditLocationScheduleProps {
  initValue?: string[];
  onChange?: (schedule: string[]) => void;
}

const EditLocationSchedule = ({ initValue, onChange }: EditLocationScheduleProps) => {
  const [everydayChecked, setEverydayChecked] = useState(true);
  const [daysChecked, setDaysChecked] = useState(DEFAULT_WEEK_VALUES);
  const [rangeValues, setRangeValues] = useState(DEFAULT_RANGE_VALUES);

  useEffect(() => {
    if (everydayChecked) {
      setDaysChecked(DEFAULT_WEEK_VALUES);
      setRangeValues(DEFAULT_RANGE_VALUES);
    }
  }, [everydayChecked]);

  useEffect(() => {
    const { everydayChecked, daysChecked, rangeValues } = getValidSchema(initValue);

    setEverydayChecked(everydayChecked);
    setDaysChecked(daysChecked);
    setRangeValues(rangeValues);
  }, []);

  useEffect(() => {
    const schedule = getValidSchedule(daysChecked, everydayChecked, rangeValues);
    onChange?.(schedule);
  }, [daysChecked, everydayChecked, rangeValues]);

  const validDateRanges = useMemo(() => {
    if (everydayChecked) {
      return [];
    }

    return WEEK_DAYS_LONG.map((item, index) => (daysChecked[index] ? item : null));
  }, [daysChecked, everydayChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEverydayChecked(event.target.checked);
  };

  const handleChangeDays = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDaysChecked((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = event.target.checked;

      return newValue;
    });
  };

  const handleChangeRanges = (index: number) => (newValues: any) => {
    setRangeValues((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = newValues;

      return newValue;
    });
  };

  return (
    <Box mt={2}>
      <Typography variant="h6">Operation Schedule</Typography>

      <FormControlLabel
        labelPlacement="start"
        label="24/7 (Every day, Monday to Sunday)"
        control={
          <CustomCheckbox
            name="24/7"
            checked={everydayChecked}
            onChange={handleChange}
            data-test="operation-schedule-24"
          />
        }
        sx={{
          margin: 0,
          '& span': {
            fontSize: '12px !important',
          },
        }}
      />

      <Stack spacing={1} direction="row" mt={1}>
        {WEEK_DAYS_SHORT.map((day, index) => (
          <EditLocationScheduleChip
            key={`edit-Location-chip-${day}`}
            day={day}
            disabled={everydayChecked}
            checked={daysChecked[index]}
            onChange={handleChangeDays(index)}
            rangeValues={rangeValues}
            onChangeRange={setRangeValues}
          />
        ))}
      </Stack>

      <Stack spacing={3} direction="column" mt={1}>
        {validDateRanges.map((day, index) => {
          if (!day) {
            return null;
          }

          return (
            <EditLocationScheduleRange
              key={`edit-Location-range-${day}`}
              day={day}
              values={rangeValues[index]}
              onChange={handleChangeRanges(index)}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default EditLocationSchedule;
