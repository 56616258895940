import { useMemo, useState } from 'react';

import { AutoField, AutoForm, SubmitField, useForm } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import toast from 'react-hot-toast';

import SCHEMAS from '@/forms/schemas';
import {
  useBlockchainAnalytics,
  useConnectBlockchainAnalyticsMutation,
} from '@/store/api/services/operator/blockchainAnalytics';

import type { BlockchainAnalyticsModel } from '@/types';

/**
 * Blockchain Analytics connect vendor Modal
 *
 * @param {ModalChildrenProps} { hide }
 * @return {*}
 */
const ConnectVendor = ({ hide }: ModalChildrenProps) => {
  const [error, setError] = useState(false);
  const [isVendorPopulated, setIsVendorPopulated] = useState(false);

  /**
   * Get the vendors and config
   */
  const { vendors, configs } = useBlockchainAnalytics();

  /**
   * Get the connectBlockchainAnalytics mutation
   */
  const [connectBlockchainAnalytics, { isLoading }] = useConnectBlockchainAnalyticsMutation();

  /**
   * Create a new base schema using the default config values
   */
  const { baseSchema } = useMemo(() => {
    const baseSchema = cloneDeep(SCHEMAS.CONNECT_VENDOR);

    if (configs?.length) {
      baseSchema.properties.vendor.uniforms.options = configs?.map(({ id }) => ({
        key: id,
        value: id,
        label: configs.find(({ id: _id }) => _id === id)?.friendly_name,
      }));
    }

    return { baseSchema };
  }, [configs]);

  /**
   * Get the form schema
   */
  const { schema } = useForm(baseSchema);

  const handleSubmit = async (data: BlockchainAnalyticsModel) => {
    /**
     * IF there are any error we don't want to continue with the submit process
     */
    if (error) {
      return;
    }

    /**
     * Submit the connectBlockchainAnalytics mutation
     */

    try {
      await connectBlockchainAnalytics({
        ...data,
        //Optional
        //account: 'test'
      }).unwrap();
      toast.success('New vendor connected successfully');
      hide();
    } catch {
      //
    }
  };

  return (
    <AutoForm<BlockchainAnalyticsModel>
      data-test="connect-new-vendor"
      showInlineError
      schema={schema}
      onChange={(field, value) => {
        setError(false);

        const found = vendors.find(({ vendor }) => vendor === value);

        if (field === 'vendor' && value) {
          setIsVendorPopulated(!found);
        }

        if (found) {
          setError(true);
        }
      }}
      onSubmit={handleSubmit}
    >
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">Blockchain Analytics vendor</Typography>
        <Typography variant="body1">
          Select the vendor you’d like to connect with and input your connection credentials.
        </Typography>
        <AutoField name="vendor" />
        {error && (
          <Typography color="error.main" variant="caption">
            • This vendor is already set up, please select a different one.
          </Typography>
        )}

        {isVendorPopulated && <AutoField name="api_key" />}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide}>Back to Settings</Button>
        <SubmitField variant="contained" disabled={isLoading || error}>
          Save and continue
        </SubmitField>
      </DialogActions>
    </AutoForm>
  );
};

export default ConnectVendor;
