import { Chip as BaseChip } from '@mui/material';
import type { ChipProps as BaseChipProps } from '@mui/material';

export interface ChipProps extends BaseChipProps {
  rightIcon?: BaseChipProps['icon'];
  component?: React.ElementType<any>;
}
/**
 * Custom Chip implementation to enable right icon placement (this will override the delete functionality)
 *
 * @param {ChipProps} { rightIcon, ...props }
 */
const Chip = ({ rightIcon, ...props }: ChipProps) => (
  <BaseChip
    {...props}
    {...(rightIcon && {
      deleteIcon: rightIcon,
      onDelete: () => {
        //
      },
      sx: {
        ...props?.sx,
        '& .MuiChip-deleteIcon': {
          cursor: 'default',
        },
      },
    })}
  />
);

export default Chip;
