import { useMemo } from 'react';

import { Chip } from '@mui/material';
import type { ChipProps } from '@mui/material';
import startCase from 'lodash/startCase';

import { useGetMachinesSeveritiesQuery } from '@/store/api/services/machines/machines';

export interface TransactionStatusColumnProps {
  value: string;
}

const MachinesStatus = ({ value }: TransactionStatusColumnProps) => {
  const { data: severities = [] } = useGetMachinesSeveritiesQuery();

  const { label, color } = useMemo(() => {
    const severityFound = severities.find(({ key }) => key === value);

    if (!severityFound) {
      return {
        label: startCase(value),
        color: 'info' as ChipProps['color'],
      };
    }

    return severityFound;
  }, [value, severities]);

  return <Chip size="small" label={label} color={color} component="span" />;
};

export default MachinesStatus;
