import { Typography } from '@mui/material';

const MachineMachineStatusDot = ({ value }: { value: number }) => {
  const color = value === 0 ? 'success.main' : 'error.main';

  return (
    <Typography
      variant="body2"
      color={color}
      component="div"
      sx={{ fontSize: 18, marginRight: 1, lineHeight: '22px' }}
      data-test={`dot-item-status-${color}`}
    >
      ●
    </Typography>
  );
};

export default MachineMachineStatusDot;
