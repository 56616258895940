import type { ApiResponse } from '@bitstopco/bitstop-theme';

import api from '@/store/api';
import { walletProvider } from '@/store/api/instances';

import type { WalletsCoin } from '@/types';

/**
 * Wallets Coins RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const walletsCoinsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWalletsCoin: build.query<ApiResponse<WalletsCoin[]>, void>({
      query: () =>
        walletProvider({
          url: 'v1/coins',
        }),
    }),
  }),
});

export const { useGetWalletsCoinQuery } = walletsCoinsApi;

export const {
  endpoints: { getWalletsCoin },
} = walletsCoinsApi;
