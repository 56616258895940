import { useEffect, useMemo, useState } from 'react';

import type { ApiListingRequest, ApiResponse } from '@bitstopco/bitstop-theme';
import { formatCurrency } from '@bitstopco/bitstop-theme/helpers';

import { normalizeCustomerData, normalizeLinkedCustomerData } from '@/helpers/customer';
import api from '@/store/api';
import { athena, ergot, proxy } from '@/store/api/instances';

import { CUSTOMER_VALID_NOTE_TYPE_AS_STATUS } from '@/constants/app/customers/customer';
import { TRANSACTIONS_STATS_DEFAULT_VALUES } from '@/constants/app/transactions';

import type {
  Customer,
  CustomersTiersMaxResponse,
  KycStatus,
  LinkAccountRequest,
  LinkedCustomer,
  RemoveMatchRequest,
  UnLinkAccountRequest,
} from '@/types';

import { useGetTransactionsStatsQuery } from '../transactions/transactions';

/**
 * Customer RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const customerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomer: build.query<ApiResponse<Customer>, number>({
      query: (id) =>
        athena({
          url: `v2/customer/${id}`,
        }),
      transformResponse: (response: ApiResponse<Customer>) => {
        if (response?.data) {
          let kyc_status = response.data?.kyc_status;

          /**
           * If the kyc_note_type is a valid note type as status we want to transform the response and return that
           * note type as status
           */
          if (CUSTOMER_VALID_NOTE_TYPE_AS_STATUS.includes(response.data?.kyc_note_type)) {
            kyc_status = response.data?.kyc_note_type as KycStatus;
          }

          return {
            ...response,
            data: {
              ...(normalizeCustomerData(response.data) as Customer),
              kyc_status,
            },
          };
        }

        return response;
      },
      providesTags: (_, __, id) => [{ type: 'Customer', id }],
    }),
    getLinkedCustomers: build.query<
      ApiResponse<LinkedCustomer[]>,
      ApiListingRequest & { customerId: string }
    >({
      query: ({ customerId, ...data }) =>
        athena({
          url: `v2/linkedCustomers/${customerId}`,
          method: 'POST',
          data,
        }),
      transformResponse: (response: ApiResponse<LinkedCustomer[]>) => {
        if (response.data) {
          return {
            ...response,
            data: response.data.map((linkedCustomer) =>
              normalizeLinkedCustomerData(linkedCustomer),
            ),
          } as ApiResponse<LinkedCustomer[]>;
        }

        return response;
      },
      providesTags: (result) => {
        const data = result?.data || [];

        return [
          ...data.map(({ id }) => ({ type: 'LinkedCustomers', id }) as const),
          { type: 'LinkedCustomers' as const, id: 'LIST' },
        ];
      },
    }),
    getCustomerTiersMax: build.query<CustomersTiersMaxResponse, number>({
      query: (id) =>
        ergot({
          url: `customer/${id}/tiers/max`,
        }),
    }),
    removeMatch: build.mutation<string, RemoveMatchRequest>({
      query: (data) =>
        proxy({
          url: 'nonce_dashboard/customers/remove-potential-match',
          data,
          method: 'POST',
          showError: true,
        }),
      invalidatesTags: [{ type: 'LinkedCustomers', id: 'LIST' }],
    }),
    linkAccount: build.mutation<string, LinkAccountRequest>({
      query: (data) =>
        proxy({
          url: 'nonce_dashboard/customers/link',
          data,
          method: 'POST',
          showError: true,
        }),

      invalidatesTags: [{ type: 'LinkedCustomers', id: 'LIST' }],
    }),
    unlinkAccount: build.mutation<string, UnLinkAccountRequest>({
      query: (data) =>
        proxy({
          url: 'nonce_dashboard/customers/unlink',
          data,
          method: 'POST',
          showError: true,
        }),

      invalidatesTags: [{ type: 'LinkedCustomers', id: 'LIST' }],
    }),
  }),
});

export const {
  // Queries
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useGetLinkedCustomersQuery,
  useGetCustomerTiersMaxQuery,
  useLazyGetCustomerTiersMaxQuery,

  // Mutations
  useRemoveMatchMutation,
  useLinkAccountMutation,
  useUnlinkAccountMutation,
} = customerApi;

export const {
  endpoints: {
    getCustomer,
    getLinkedCustomers,
    getCustomerTiersMax,
    removeMatch,
    linkAccount,
    unlinkAccount,
  },
} = customerApi;

export const useCustomer = (id?: number) => {
  const skip = !id;

  const [getTiersMax] = useLazyGetCustomerTiersMaxQuery();

  const {
    data: { data: customer, included } = {},
    isLoading: isLoadingCustomer,
    isFetching: isFetchingCustomer,
    error: errorCustomer,
  } = useGetCustomerQuery(id!, { skip });

  const [limitMax, setLimitMax] = useState<number>();
  const [isLoadingTiersMax, setIsLoadingTierMax] = useState(false);

  useEffect(() => {
    (async () => {
      if (id) {
        try {
          setIsLoadingTierMax(true);
          const { data } = await getTiersMax(id!).unwrap();
          setLimitMax(data.enrollment_tiers?.[0]?.limit_max);
        } catch {
          //
        } finally {
          setIsLoadingTierMax(false);
        }
      }
    })();
  }, [id]);

  const error = errorCustomer;
  const isLoading = isLoadingCustomer || isLoadingTiersMax;
  const isFetching = isFetchingCustomer || isLoadingTiersMax;

  const maxPurchaseLimit = useMemo(
    () => (limitMax && limitMax >= 0 ? formatCurrency(limitMax) : '-'),
    [limitMax],
  );

  return {
    error,
    included,
    isLoading,
    isFetching,
    customer,
    maxPurchaseLimit,
  };
};

export const useCustomerStats = (id: number) => {
  const {
    data: { data: customerStats } = { data: TRANSACTIONS_STATS_DEFAULT_VALUES },
    isLoading: isLoadingCustomerStats,
  } = useGetTransactionsStatsQuery({
    filter: {
      value: id,
      operator: 'equal',
      property: 'customer_id',
    },
  });

  return { customerStats, isLoadingCustomerStats };
};
