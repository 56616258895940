import uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';

import type { ApiResponse } from '@bitstopco/bitstop-theme';

import type { RootState } from '@/store';
import api from '@/store/api';
import { athena } from '@/store/api/instances';
import { selectLastGetMachine } from '@/store/reducers/modules/common/persistence/selectors';

import type {
  CreateTagRequest,
  MachineDetail,
  MachinesTag,
  TagConstraints,
  TagStats,
  TagsResourcesAssociationRequest,
  UpdateTagRequest,
} from '@/types';

import { getMachineByGlobalId, machineApi } from '../machines/machine';

/**
 * Tags RTK-Query API
 * For more information about RTK-Query see
 * * https://redux-toolkit.js.org/rtk-query/overview
 */
export const tagsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTagStatsById: build.query<ApiResponse<TagStats>, string>({
      query: (id) => athena({ url: `v2/Tag/${id}/stats` }),
    }),
    getAllTagsByTerm: build.query<MachinesTag[], string>({
      query: (term = '') => {
        //default payload for get all tags by term
        const data = {
          pagination: {
            sort_by: 'id',
            current_page: 1,
            items_per_page: 500,
            sort_ascending: true,
          },
          filter: {
            value: term,
            property: 'name',
            operator: 'contains',
          },
        };

        return athena({ url: 'v2/tags', method: 'POST', data });
      },
      transformResponse: (response: ApiResponse<MachinesTag[]>) => response?.data || [],
      providesTags: ['TagsByTerm'],
    }),
    getTagConstraints: build.query<ApiResponse<TagConstraints>, void>({
      query: () => athena({ url: 'v2/tag/constraints' }),
    }),
    createTag: build.mutation<ApiResponse<string>, CreateTagRequest>({
      query: (data) => athena({ url: 'v2/tag', method: 'POST', data }),
      onQueryStarted: async (newTag, { dispatch, getState, queryFulfilled }) => {
        //Optimistic response for create tag

        try {
          const state = getState() as RootState;
          const machine = selectLastGetMachine(state)!;

          //Add optimistic tag to the machine state
          dispatch(
            machineApi.util.updateQueryData('getMachine', machine.data.id, (draft) => {
              const newId = `optimistic-${uuid()}`;
              draft.included ??= { tags: [] };
              (draft.included.tags ?? []).push({ ...newTag, id: newId });
            }),
          );

          const {
            data: { data: id },
          } = await queryFulfilled;

          //Add created tag to the last get all tags by term query state (this is required in "createTagsResources" query)
          dispatch(
            tagsApi.util.updateQueryData('getAllTagsByTerm', '', (draft) => {
              draft.push({ id, ...newTag });
            }),
          );
        } catch {
          //
        }
      },
      invalidatesTags: ['TagsByTerm', { type: 'Machines' as const, id: 'LIST' }],
    }),
    updateTag: build.mutation<ApiResponse<string>, UpdateTagRequest>({
      query: ({ id, ...data }) => athena({ url: `v2/tag/${id}`, method: 'PUT', data }),
      onQueryStarted: (updatedTag, { dispatch, queryFulfilled, getState }) => {
        //Optimistic response for update tag

        try {
          const state = getState() as RootState;
          const machine = selectLastGetMachine(state)!;

          //update the updated tag from the machine state (tag_ids, included.tags)
          const patchResult = dispatch(
            machineApi.util.updateQueryData('getMachine', machine.data.id, (draft) => {
              draft.included ??= { tags: [] };
              const newTags = (draft.included.tags || []).filter(({ id }) => updatedTag.id !== id);

              newTags.push(updatedTag);

              draft.included.tags = newTags;
            }),
          );

          queryFulfilled.catch(patchResult.undo);
        } catch {
          //
        }
      },
      invalidatesTags: ['TagsByTerm', { type: 'Machines' as const, id: 'LIST' }],
    }),
    deleteTag: build.mutation<ApiResponse<string>, string>({
      query: (id) => athena({ url: `v2/tag/${id}`, method: 'DELETE' }),
      onQueryStarted: (tagId, { dispatch, queryFulfilled, getState }) => {
        //Optimistic response for remove tag

        try {
          const state = getState() as RootState;
          const machine = selectLastGetMachine(state)!;

          //remove the deleted tag from the machine state (tag_ids, included.tags)
          const patchResult = dispatch(
            machineApi.util.updateQueryData('getMachine', machine.data.id, (draft) => {
              draft.data.tag_ids = draft.data.tag_ids.filter((id) => tagId !== id);
              draft.included ??= { tags: [] };
              const newTags = (draft.included.tags || []).filter(({ id }) => tagId !== id);

              draft.included.tags = newTags;
            }),
          );

          queryFulfilled.catch(patchResult.undo);
        } catch {
          //
        }
      },
      invalidatesTags: ['TagsByTerm', { type: 'Machines' as const, id: 'LIST' }],
    }),
    createTagsResources: build.mutation<ApiResponse<string>, TagsResourcesAssociationRequest>({
      query: (data) => athena({ url: 'v2/tags/resources', method: 'POST', data }),
      onQueryStarted: async ({ tag_ids, resource_ids }, { dispatch, queryFulfilled, getState }) => {
        //Optimistic response for add machine tags resources

        try {
          const results: any[] = [];
          const state = getState() as RootState;
          const allTags = (await dispatch(getAllTagsByTerm.initiate(''))).data || [];

          //add the new linked tag to the machine state
          resource_ids.forEach((globalAtmId) => {
            const atmId = getMachineByGlobalId(globalAtmId)(state);

            if (atmId) {
              const patchResult = dispatch(
                machineApi.util.updateQueryData(
                  'getMachine',
                  atmId,
                  (draft: ApiResponse<MachineDetail>) => {
                    draft.data.tag_ids = [...draft.data.tag_ids, ...tag_ids].filter(
                      (id) => id.indexOf('optimistic-') === -1,
                    );

                    draft.included ??= { tags: [] };
                    const oldTags = (draft.included.tags || []).filter(
                      ({ id }) => id.indexOf('optimistic-') === -1,
                    );
                    const newTags = allTags.filter(({ id }) => tag_ids.indexOf(id) !== -1);

                    draft.included.tags = uniqBy([...oldTags, ...newTags], 'id');
                  },
                ),
              );

              results.push(patchResult);
            }
          });

          queryFulfilled.catch(() => {
            results.forEach((patchResult) => {
              patchResult.undo();
            });
          });
        } catch {
          //
        }
      },
      invalidatesTags: [{ type: 'Machines' as const, id: 'LIST' }],
    }),
    deleteTagsResources: build.mutation<ApiResponse<string>, TagsResourcesAssociationRequest>({
      query: (data) => athena({ url: 'v2/tags/resources', method: 'DELETE', data }),
      onQueryStarted: ({ tag_ids, resource_ids }, { dispatch, queryFulfilled, getState }) => {
        //Optimistic response for removed machine tags resources

        try {
          const state = getState() as RootState;
          const results: any[] = [];

          resource_ids.forEach((globalAtmId) => {
            const atmId = getMachineByGlobalId(globalAtmId)(state);

            if (atmId) {
              const patchResult = dispatch(
                machineApi.util.updateQueryData('getMachine', atmId, (draft) => {
                  draft.data.tag_ids =
                    draft.data.tag_ids.filter((id) => tag_ids.indexOf(id) === -1) || [];
                }),
              );

              results.push(patchResult);
            }
          });

          queryFulfilled.catch(() => {
            results.forEach((patchResult) => {
              patchResult.undo();
            });
          });
        } catch {
          //
        }
      },
      invalidatesTags: [{ type: 'Machines' as const, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetTagStatsByIdQuery,
  useGetAllTagsByTermQuery,
  useGetTagConstraintsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useCreateTagsResourcesMutation,
  useDeleteTagsResourcesMutation,
} = tagsApi;

export const {
  endpoints: {
    getTagStatsById,
    getAllTagsByTerm,
    getTagConstraints,
    createTag,
    updateTag,
    deleteTag,
    createTagsResources,
    deleteTagsResources,
  },
} = tagsApi;
