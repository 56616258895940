import { useState } from 'react';

import { Table } from '@bitstopco/bitstop-theme';
import type { ModalChildrenProps } from '@bitstopco/bitstop-theme';

import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';

import { useRemoveMatchMutation } from '@/store/api/services/customers/customer';

import { CUSTOMER_INIT_VALUES } from '@/constants/app/customers/customer';
import { REMOVE_MATCH_COLUMNS } from '@/constants/app/customers/linkedAccounts';

import type { LinkedCustomer } from '@/types';

/**
 * Customer Remove Match Modal
 *
 * @param {ModalChildrenProps} { hide, props }
 * @return {*}
 */
const RemoveMatch = ({ hide, props }: ModalChildrenProps) => {
  const linkedCustomer = useState<LinkedCustomer>(props?.linkedCustomer || CUSTOMER_INIT_VALUES)[0];

  const [removeMatch, { isLoading }] = useRemoveMatchMutation();

  const handleSubmit = async () => {
    try {
      // Request remove match
      await removeMatch({
        association_id: linkedCustomer.customers_link_id,
      });

      hide();
    } catch {
      //
    }
  };

  return (
    <>
      <DialogContent sx={{ my: 2 }}>
        <Typography variant="h6">
          Are you sure you would like to remove this potential match?
        </Typography>

        <Typography mt={1} variant="body1">
          This remove action cannot be undone.
        </Typography>

        <Box
          sx={{
            mt: 3,
            '& table': {
              minWidth: 'auto',
            },
            '& table thead tr': {
              py: 0.5,
              borderBottom: '1px solid #E1E3EA',
            },
            '& table th': {
              py: 0.5,
            },
            '& table td': {
              py: 1,
            },
          }}
        >
          <Table data={[linkedCustomer]} columns={REMOVE_MATCH_COLUMNS} sort="asc" sortBy="id" />
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={hide} data-test="remove-match-back-button">
          Back to Linked Accounts
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
          data-test="remove-match-submit-button"
        >
          Yes, remove match
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RemoveMatch;
